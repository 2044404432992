import {useEvent} from 'effector-react';
import {setDashboardModalOpenEv} from '../../../models/overviewModel/index.js';
import style from '../Overview.module.css';
import {Button, Popover, Tooltip} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    BarChartOutlined, InfoCircleOutlined,
    ProjectOutlined
} from '@ant-design/icons';
import {
    actualityDictionary,
    infoSourceDictionary
} from '../../../dictionaries/info_source_dictionary.js';

export default function OverviewCard(props) {
    const {
        title,
        cards,
        cardClick,
        field,
        activeCategory,
        activeSubCategory,
        setIsOpen
    } = props;

    const openDashboardModal = useEvent(setDashboardModalOpenEv)

    const openModal = (type) => {
        setIsOpen({visible: true, modalType: type});
    };

    return (
        <div
            className={style.overview_card}
            onClick={cardClick}
            data-category={field}
            data-subcategory={cards[0].subCategory}
            data-type="card"
        >
            <h1 className={[style.overview_card_title, field === 'projectsRealisation' ? style.center : ''].join(' ')}>
                {title}
            </h1>
            <div className={style.cards_wrapper}>
                {cards.map((item) => {
                    return (<div style={{display: 'flex', flexDirection: 'column', width: cards.length === 1 ? '100%' : `${100 / cards.length - 1.5}%`,}}>
                            {
                                item?.header
                                    ? <h1 className={style.overview_card_title} style={{textAlign: 'center'}}>{item.header}</h1>
                                    : null
                            }
                            <div
                                className={[
                                    style.overview_subcard,
                                    activeSubCategory.length > 0 &&
                                    activeSubCategory !== item.subCategory
                                        ? style.not_active
                                        : '',
                                ].join(' ')}
                                style={{
                                    background:
                                        item.type === 'neutral'
                                            ? '#eedc501a'
                                            : item.type === 'positive'
                                                ? '#05CD991A'
                                                : '#EE5D501A',
                                    border:
                                        activeSubCategory.length > 0 &&
                                        activeSubCategory === item.subCategory
                                            ? item.type === 'neutral'
                                                ? '2px solid #eedc50'
                                                : item.type === 'positive'
                                                    ? '2px solid #05CD99'
                                                    : '2px solid #EE5D50'
                                            : '',
                                    padding:
                                        activeSubCategory.length > 0 &&
                                        activeSubCategory === item.subCategory
                                            ? '8px'
                                            : '12px',
                                }}
                                data-category={field}
                                data-subcategory={item.subCategory}
                                data-type="subcard"
                            >
                                <div className={item.breakWord ? style.title_break : style.overview_subcard_title}>{item.title}</div>
                                <div>
                                    <Popover title={<div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                        <span>{`Источник данных: ${infoSourceDictionary[item.subCategory]}`}</span>
                                        <span>{`Актуализация: ${actualityDictionary[item.subCategory]}`}</span>
                                    </div>
                                    }>
                                        <InfoCircleOutlined style={{height: 16}}/>
                                    </Popover>
                                </div>
                                {item.subCategory === 'problemProjects' ||
                                item.subCategory === 'projectCount' ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={style.overview_value}>{item.value}</div>
                                        <div
                                            className={style.overview_value}
                                            style={{ fontSize: '22px', marginLeft: '10px' }}
                                        >
                                            {item.measure}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className={style.overview_value}>{item.value}</div>
                                        <div
                                            className={style.overview_value}
                                            style={{ fontSize: '28px', marginTop: '0px' }}
                                        >
                                            {item.measure}
                                        </div>
                                    </>
                                )}

                                <div className={style.overview_subcard_footer}>
                                    <div className={style.overview_subcard_date}>{item.date}</div>
                                    <div style={{display: 'flex', gap: 4}}>
                                        {item.projectsButton === true ? (
                                            <Tooltip title={'Просмотр проектов'} placement={'bottom'}>
                                                <Button
                                                    className={style.modal_button}
                                                    icon={<ProjectOutlined />}
                                                    onClick={() => openModal(item.subCategory)}
                                                />
                                            </Tooltip>
                                        ) : (
                                            ''
                                        )}
                                        {item.dashboardButton === true ? (
                                            <Tooltip title={'Количество созданных проектов'} placement={'top'}>
                                                <Button
                                                    className={style.dashboard_button}
                                                    icon={<BarChartOutlined />}
                                                    onClick={() => openDashboardModal(true)}
                                                />
                                            </Tooltip>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {field !== 'projectsRealisation' ? (
                                        <div
                                            className={style.overview_subcard_percent}
                                            style={{
                                                color:
                                                    item.type === 'neutral'
                                                        ? '#eedc50'
                                                        : item.type === 'positive'
                                                            ? '#05CD99'
                                                            : '#EE5D50',
                                            }}
                                        >
                                            {item.percent ?? ''}%
                                            {item.subCategory === 'problemProjSum' ? (
                                                <ArrowUpOutlined />
                                            ) : item.type !== 'neutral' ? (
                                                item.type === 'positive' ? (
                                                    <ArrowUpOutlined />
                                                ) : (
                                                    <ArrowDownOutlined />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}