import { createEvent } from 'effector';
import { createGate } from 'effector-react';

export const sendOverviewCallEv = createEvent();

export const changeShowProjectDotsEv = createEvent();

export const changeActiveProblemRespEv = createEvent();

export const getProblemProjectsEv = createEvent();

export const setIsModalOpenEv = createEvent();

export const getDashboardsDataEv = createEvent();
export const setDashboardModalOpenEv = createEvent();

export const ManufacturersGate = createGate();
export const setOtpModalEv = createEvent();
export const resetOtpModalEv = createEvent();

export const sendOtpCallEv = createEvent();

export const setBizModalEv = createEvent();
export const resetBizModalEv = createEvent();

export const setDistrictModalEv = createEvent();
export const resetDistrictModalEv = createEvent();

export const setBizPollsStatsModalEv = createEvent();
export const resetBizPollsStatsModalEv = createEvent();
export const BizPollsStatsModalGate = createGate();

export const downloadProjectsEv = createEvent();