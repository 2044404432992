import {
  createObjectEv,
  deleteCreatedObjectEv,
  resetCreatedObjectsEv,
} from '../models/createObjectModel/index.js';

// FIXME Class for custom buttons (found in internet)
export class ExtendDrawBar {
  constructor(opt) {
    const ctrl = this;
    ctrl.draw = opt.draw;
    ctrl.buttons = opt.buttons || [];
    ctrl.onAddOrig = opt.draw.onAdd;
    ctrl.onRemoveOrig = opt.draw.onRemove;
  }

  onAdd(map) {
    const ctrl = this;
    ctrl.map = map;
    ctrl.elContainer = ctrl.onAddOrig(map);
    ctrl.buttons.forEach((b) => {
      ctrl.addButton(b);
    });
    return ctrl.elContainer;
  }

  onRemove(map) {
    const ctrl = this;
    ctrl.buttons.forEach((b) => {
      ctrl.removeButton(b);
    });
    ctrl.onRemoveOrig(map);
  }

  addButton(opt) {
    const ctrl = this;
    const elButton = document.createElement('button');
    elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    elButton.addEventListener(opt.on, opt.action);
    ctrl.elContainer.appendChild(elButton);
    opt.elButton = elButton;
  }

  removeButton(opt) {
    opt.elButton.removeEventListener(opt.on, opt.action);
    opt.elButton.remove();
  }
}

export const saveObject = (e) => {
  const feature = window.draw.getAll().features[0];
  window.draw.set({
    type: 'FeatureCollection',
    features: [
      {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: e.features[0].geometry.coordinates,
        },
      },
    ],
  });
  createObjectEv(e.features[0]);
};

export const deleteObject = (e) => {
  deleteCreatedObjectEv();
};

export const deleteAll = () => {
  resetCreatedObjectsEv();
  window.draw.set({
    type: 'FeatureCollection',
    features: [],
  });
};

export const changeLayerVisibility = (layer, mode) => {
  window.map.setLayoutProperty(layer, 'visibility', mode);
};
