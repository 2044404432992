import {filterProjects} from "../projects/index.js";

export const prepareBinDict = (projects) => {
    return Object.fromEntries(projects.map(item => {
        return [item.organization_id, item.gov_id]
    }))
}

// export const prepareFilteredBinDict = (activeFilters, rawData) => {
//     return prepareBinDict(filteredProjects)
// }

export const prepareBinOptions = (binDict) => {
    return Object.entries(binDict).map(([key, value]) => {
        return {
            value: value,
            label: value
        }
    })
}
