import { createGate } from 'effector-react';
import { createEvent } from 'effector';

export const BizPollGate = createGate();
export const BizPollResultsGate = createGate();

export const getBinInfoEv = createEvent();

export const resetWrongTypeModalEv = createEvent();

export const submitFormEv = createEvent();

export const downloadResultsEv = createEvent();

export const resetGovCantVoteModalEv = createEvent();
