import { sample } from 'effector';
import {
  $activeFilters,
  changeActiveFilterEv,
  resetActiveFiltersEv,
} from '../activeFiltersModel/index.js';
import {
  $districtsLayerPolygons,
  $districtsLayerPolygonsFiltered,
  $districtsLayerPolygonsStart,
  $selectedDistrict,
} from './stores.js';
import {
  filterDistrictsById,
  filterDistrictsByRegionId,
  prepareDistrictsFeatures,
  prepareDistrictsFilteredFeatures,
  prepareDistrictsOrgs,
  prepareDistrictsOtp,
  prepareDistrictsOverview,
} from '../../counters/districtsLayer/index.js';
import { $regionsLayerPolygons } from '../regionsLayerModel/index.js';
import {
  resetDistrictsLayerPolygonsFilteredEv,
  updateDistrictsByOverviewEv,
  updateDistrictsEv,
} from './events.js';
import { $globalStore, $projectsStore } from '../globalModel/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { $otpStore, $overviewStore } from '../overviewModel/index.js';
import { $orgsData } from '../organizationsModel/index.js';

$districtsLayerPolygonsFiltered.reset(resetDistrictsLayerPolygonsFilteredEv);

sample({
  source: $districtsLayerPolygons,
  clock: $selectedDistrict.updates,
  filter: (source, clock) => clock !== 0,
  fn: (source, clock) => filterDistrictsById(source, clock),
  target: $districtsLayerPolygons,
});

sample({
  source: $selectedDistrict,
  clock: changeActiveFilterEv,
  filter: (source, clock) => clock.field === 'district_id',
  fn: (source, clock) => {
    if (clock.value === source) {
      return 0;
    }
    return clock.value;
  },
  target: $selectedDistrict,
});

sample({
  source: $selectedDistrict,
  clock: changeActiveFilterEv,
  filter: (source, clock) => clock.field === 'region_id',
  fn: (source, clock) => {
    if (clock.value === 0) {
      return 0;
    }
    return source;
  },
  target: $selectedDistrict,
});

// sample({
// 	source: [$selectedDistrict, $districtsLayerPolygonsStart],
// 	clock: $regionsLayerPolygons.updates,
// 	filter: ([selectedDistrict, districtsLayerPolygonsStart], clock) => {
// 		return clock.length !== 17 && selectedDistrict === 0
// 	},
// 	fn: ([selectedDistrict, districtsLayerPolygonsStart], clock) => {
// 		const region_ids = clock.map(item => item.id)
// 		return filterDistrictsByRegionId(region_ids, districtsLayerPolygonsStart)
// 	},
// 	target: $districtsLayerPolygons
// })

// sample({
// 	source: $districtsLayerPolygonsStart,
// 	clock: $regionsLayerPolygons.updates,
// 	filter: (source, clock) => clock.length === 17,
// 	fn: (source, clock) => source,
// 	target: $districtsLayerPolygons,
// })

sample({
  source: $districtsLayerPolygons,
  clock: updateDistrictsEv,
  fn: (source, clock) => {
    return prepareDistrictsFeatures(source, clock);
  },
  target: [$districtsLayerPolygons, $districtsLayerPolygonsStart],
});

sample({
  source: [$globalStore, $districtsLayerPolygonsStart, $activeFilters],
  clock: $projectsStore.updates,
  filter: ([global, startData], clock) =>
    startData.length > 0 && !window.location.href.includes('manufacturers'),
  fn: ([global, startData, activeFilters], clock) => {
    if (clock.length === global.projects.length) {
      return startData;
    }

    const result = prepareDistrictsFilteredFeatures(clock, startData);
    if (result.length === 0) {
      return startData.filter(
        (item) => item.properties.district_id === activeFilters.district_id
      );
    }

    return result;
  },
  target: [$districtsLayerPolygons, updateDistrictsByOverviewEv],
});

sample({
  clock: $districtsLayerPolygonsFiltered.updates,
  target: $districtsLayerPolygons,
});

sample({
  source: $districtsLayerPolygonsStart,
  clock: resetDistrictsLayerPolygonsFilteredEv,
  target: $districtsLayerPolygons,
});

sample({
  clock: resetActiveFiltersEv,
  fn: () => 0,
  target: $selectedDistrict,
});

// FIXME Part of dashboard logic
sample({
  source: [$districtsLayerPolygons, $activeFilters],
  clock: $overviewStore.updates,
  filter: ([startData, activeFilters], clock) => {
    const workingIndexes = [
      'taxes',
      'people',
      'medium_companies',
      'mio_performance',
      'business_inspections',
      // 'projectCount',
      // 'problemProjects',
    ];
    return (
      workingIndexes.includes(activeFilters.subCategory) &&
      window.location.href.includes('dashboard')
    );
  },
  fn: ([startData, activeFilters], clock) => {
    let polygons = startData;
    if (activeFilters.region_id > 0) {
      polygons = polygons.filter(
        (item) => item.properties.region_id === activeFilters.region_id
      );
    }

    if (activeFilters.district_id > 0) {
      polygons = polygons.filter(
        (item) => item.properties.district_id === activeFilters.district_id
      );
    }

    return prepareDistrictsOverview(
      clock[activeFilters.subCategory].map_data,
      polygons
    );
  },
  target: $districtsLayerPolygons,
});

sample({
  source: [$districtsLayerPolygonsStart, $overviewStore, $activeFilters],
  clock: updateDistrictsByOverviewEv,
  filter: ([districts, overviewData, activeFilters], clock) => {
    const workingIndexes = [
      'taxes',
      'people',
      'medium_companies',
      'mio_performance',
      'business_inspections',
      // 'projectCount',
      // 'problemProjects',
    ];
    return (
      workingIndexes.includes(activeFilters.subCategory) &&
      window.location.href.includes('dashboard')
    );
  },
  fn: ([startData, overviewData, activeFilters], clock) => {
    let filteredData = startData;

    if (activeFilters.region_id > 0) {
      filteredData = startData.filter(
        (item) => item.properties.region_id === activeFilters.region_id
      );
    }

    if (activeFilters.district_id > 0) {
      filteredData = startData.filter(
        (item) => item.id === activeFilters.district_id
      );
    }

    return prepareDistrictsOverview(
      overviewData[activeFilters.subCategory].map_data,
      filteredData
    );
  },
  target: $districtsLayerPolygons,
});

sample({
  source: [$districtsLayerPolygonsStart, $activeFilters],
  clock: $otpStore.updates,
  fn: ([startData, activeFilters], otpStore) => {
    let filteredData = startData;

    if (activeFilters.region_id > 0) {
      filteredData = startData.filter(
        (item) => item.properties.region_id === activeFilters.region_id
      );
    }

    if (activeFilters.district_id > 0) {
      filteredData = startData.filter(
        (item) => item.id === activeFilters.district_id
      );
    }

    return prepareDistrictsOtp(otpStore.map_data, filteredData);
  },
  target: $districtsLayerPolygons,
});

sample({
  source: $districtsLayerPolygonsStart,
  clock: $otpStore.updates,
  filter: (startData, clock) => {
    return startData.every((item) => !item.properties.otp_count);
  },
  fn: (startData, otpStore) => {
    return prepareDistrictsOtp(otpStore.map_data, startData);
  },
  target: $districtsLayerPolygonsStart,
});

sample({
  source: [$districtsLayerPolygonsStart, $districtsLayerPolygons, $otpStore],
  clock: $activeFilters.updates,
  filter: ([startData, otpStore], activeFilters) =>
    window.location.href.includes('manufacturers'),
  fn: ([startData, data, otpStore], activeFilters) => {
    const source = startData.length > 0 ? startData : data;

    let result = [];

    if (activeFilters.region_id !== 0) {
      result = source.filter(
        (item) =>
          item.properties.region_id === activeFilters.region_id &&
          item.properties.otp_count
      );
    }

    if (activeFilters.district_id !== 0) {
      result = source.filter(
        (item) =>
          item.id === activeFilters.district_id && item.properties.otp_count
      );
    }

    if (result.length === 0) {
      result = source;
    }

    return result;
  },
  target: $districtsLayerPolygons,
});

sample({
  source: $districtsLayerPolygonsStart,
  clock: $orgsData.updates,
  fn: (startData, orgsData) => {
    return prepareDistrictsOrgs(startData, orgsData);
  },
  target: $districtsLayerPolygons,
});

sample({
  source: [$districtsLayerPolygonsStart, $otpStore],
  clock: $activeFilters.updates,
  filter: ([startData, otpStore], activeFilters) =>
    window.location.href.includes('organizations'),
  fn: ([startData, otpStore], activeFilters) => {
    let result = [];

    if (activeFilters.region_id !== 0) {
      result = startData.filter(
        (item) =>
          item.properties.region_id === activeFilters.region_id &&
          item.properties.otp_count
      );
    }

    if (activeFilters.district_id !== 0) {
      result = startData.filter(
        (item) =>
          item.id === activeFilters.district_id && item.properties.otp_count
      );
    }

    if (result.length === 0) {
      result = startData;
    }

    return result;
  },
  target: $districtsLayerPolygons,
});

sample({
  source: $districtsLayerPolygonsStart,
  clock: $orgsData.updates,
  filter: (startData, clock) => {
    return startData.every((item) => !item.properties.orgsCount);
  },
  fn: (startData, orgsData) => {
    return prepareDistrictsOrgs(startData, orgsData);
  },
  target: $districtsLayerPolygonsStart,
});
