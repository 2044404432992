import {Col, Form, InputNumber, Row} from "antd";
import {numberParser} from "../utils";

const FinanceForm = () => <>
    <Form.Item name={'budgets'}>
        <Row>
            <Col span={8}>
                <Form.Item name={['budgets', 'own_budget']} label={'Собственные средства, в млн. тенге'} trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={['budgets', 'own_budget_foreign']}
                           label={'Собственные средства (иностранный капитал), в млн. тенге'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={8}>
                <Form.Item name={['budgets', 'borrowed_budget']} label={'Заемные средства, в млн. тенге'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={['budgets', 'borrowed_budget_foreign']}
                           label={'Заемные средства (иностранный капитал), в млн. тенге'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={8}>
                <Form.Item name={['budgets', 'gov_local']} label={'Бюджетные средства (местный бюджет), в млн. тенге'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={['budgets', 'gov_republican']}
                           label={'Бюджетные средства (республиканский бюджет), в млн. тенге'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Col>
        </Row>
    </Form.Item>
</>
export default FinanceForm