import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const OrgsDashboardGate = createGate()

export const sendOrgsCallEv = createEvent()

export const changeFiltersEv = createEvent()
export const resetFiltersEv = createEvent()
export const changeTableStateEv = createEvent()

export const exportExcelEv = createEvent()

export const openReportsModalEv = createEvent()
export const resetReportsModalEv = createEvent()

export const getReportsListEv = createEvent()
export const downloadReportEv = createEvent()

export const ReportsModalGate = createGate()