import { Button, Select, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import {
  ClearOutlined,
  FilterOutlined,
  TableOutlined,
} from '@ant-design/icons';
import styles from './OrganizationsDashboard.module.css';
import {
  $filters,
  changeFiltersEv,
  departmentsSelect,
  krpSelect,
  legalTypeSelect,
  openReportsModalEv,
  orgTypeSelect,
  resetFiltersEv,
  sendOrgsCallEv,
} from '../../models/organizationsModel/index.js';
import SwitchFilters from './SwitchFilters.jsx';
import { $userInfo } from '../../models/authModel/index.js';

export default function FiltersBlock() {
  const changeFilters = useUnit(changeFiltersEv);
  const resetFilters = useUnit(resetFiltersEv);
  const setModal = useUnit(openReportsModalEv);
  const filters = useUnit($filters);
  const applyFilters = useUnit(sendOrgsCallEv);
  const curUsr = useUnit($userInfo);

  const onSelect = (type, value) => {
    changeFilters({ [type]: value });
  };

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filtersSelects}>
        <div className={styles.filtersItem}>
          <span>Размерность</span>
          <Select
            options={krpSelect}
            dropdownStyle={{ width: 260 }}
            onChange={(value) => onSelect('krp', value)}
            mode="multiple"
            allowClear
            value={filters.krp ?? []}
          />
        </div>
        <div className={styles.filtersItem}>
          <span>Форма организации</span>
          <Select
            options={orgTypeSelect}
            dropdownStyle={{ width: 300 }}
            onChange={(value) => onSelect('org_type', value)}
            value={filters.org_type ?? null}
            allowClear
          />
        </div>
        <div className={styles.filtersItem}>
          <span>Юридический тип</span>
          <Select
            options={legalTypeSelect}
            onChange={(value) => onSelect('legal_type', value)}
            value={filters.legal_type ?? null}
            allowClear
          />
        </div>
        {/* <div className={styles.filtersItem}> */}
        {/*  <span>Департамент</span> */}
        {/*  <Select */}
        {/*      options={departmentsSelect} */}
        {/*      dropdownStyle={{width: 'fit-content'}} */}
        {/*      onChange={(value) => onSelect('department', value)} */}
        {/*      value={filters.department ?? null} */}
        {/*      allowClear */}
        {/*  /> */}
        {/* </div> */}
        <div className={styles.filtersItem}>
          <span>Приоритет</span>
          <Select
            options={[
              { label: 'Сильная отрасль', value: 'Сильная отрасль' },
              {
                label: 'Перспективная отрасль',
                value: 'Перспективная отрасль',
              },
            ]}
            dropdownStyle={{ width: 'fit-content' }}
            onChange={(value) => onSelect('priority', value)}
            value={filters.priority ?? null}
            mode="multiple"
            allowClear
          />
        </div>
        <div className={styles.filtersItem}>
          <span>Категория</span>
          <Select
            options={[
              {
                label: 'Сфера услуг парикмахерские и ателье',
                value: 'Сфера услуг парикмахерские и ателье',
              },
              { label: 'СТО', value: 'СТО' },
              { label: 'Магазины у дома', value: 'Магазины у дома' },
              { label: 'Horeca', value: 'Horeca' },
            ]}
            dropdownStyle={{ width: 300 }}
            mode="multiple"
            onChange={(value) => onSelect('activity_category', value)}
            value={filters.activity_category ?? null}
            allowClear
          />
        </div>
      </div>
      <SwitchFilters />
      <div style={{ display: 'flex', gap: 16 }}>
        <Button.Group>
          <Tooltip title="Применить фильтры">
            <Button
              onClick={() => applyFilters()}
              icon={<FilterOutlined />}
              type="primary"
              style={{ boxShadow: 'none' }}
            />
          </Tooltip>
          <Tooltip title="Сбросить фильтры">
            <Button
              onClick={() => resetFilters()}
              icon={<ClearOutlined />}
              danger
            />
          </Tooltip>
        </Button.Group>
        {curUsr.role !== 'guest' && (
          <Tooltip title="Экспорт страницы в Excel">
            <Button onClick={() => setModal(true)} icon={<TableOutlined />} />
          </Tooltip>
        )}
      </div>
    </div>
  );
}
