import {createStore} from "effector";

const initialState = {
	curatorsTableData: [],
	projectsTableData: [],
	projects_count: 0,
	total_count: 0.
}
export const $curatorsStore = createStore(initialState)

export const $curatorsStartStore = createStore(initialState)

export const $curatorsFilteredStore = createStore(initialState)
