import { createGate } from 'effector-react';
import { createEvent } from 'effector';

export const RequestsGate = createGate();

export const getReqByIdEv = createEvent();
export const resetSelectedEv = createEvent();

export const submitRequestEv = createEvent();
export const setReqModalEv = createEvent();
export const closeReqModalEv = createEvent();
export const setViewModalEv = createEvent();

export const confirmReqEv = createEvent();
export const declineReqEv = createEvent();

export const downloadReqFileEv = createEvent();

export const sendRequestEv = createEvent();
