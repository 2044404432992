import {createStore} from "effector";

//FIXME Fake data
const initialDots = [];

//FIXME Stores FEATURES for dots
export const $dotsLayerData = createStore(initialDots)

//FIXME Stores selected dot
export const $chosenDot = createStore({})

export const $dotsLayerStartData = createStore(initialDots)
export const $dotsLayerFilteredData = createStore(initialDots)
