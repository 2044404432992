import {createEffect} from 'effector';
import {api} from '../../api/axiosInstance.js';

export const getUnpublishedListFx = createEffect()
    .use(async () => (await api().get('/project/unpublished')).data)

export const approveProjectFx = createEffect()
    .use(async (id) => (await api().post(`/project/approve/${id}`)).data)

export const rejectProjectFx = createEffect()
    .use(async ({id, comment}) => (await api().post(`/project/reject/${id}`, comment)).data)