import {Col, Row, Skeleton, Table, Tooltip} from 'antd';
import { Line } from '@ant-design/charts';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import style from '../../components/ViewProjectComponents/ViewProjectComponents.module.css';
import {
  $organizationInfo, getOrganizationInfoFullFx,
  ViewOrganizationGate,
} from '../../models/viewOrganizationModel/index.js';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';

const formatText = (v) => {
  return v.length > 30 ? (
    <Tooltip title={v}>{`${v.slice(0, 36)}...`}</Tooltip>
  ) : (
    v
  );
};

export default function OrganizationInfo() {
  const organization = useStore($organizationInfo);
  const loading = useStore(getOrganizationInfoFullFx.pending);
  const { id } = useParams();

  const columns = [
    {
      title: 'Наименование товара',
      key: 'name',
      dataIndex: 'product_name',
    },
    {
      title: 'Код ТН ВЭД',
      key: 'tnved',
      dataIndex: 'tnved',
    },
    {
      title: 'Код КП ВЭД',
      key: 'kpved',
      dataIndex: 'kpved',
    },
    {
      title: 'Вид деятельности согласно ОКЭД (перв, вторич.)',
      dataIndex: 'oked',
      key: 'oked',
    },
    {
      title: 'Производственная мощность, кол-во единиц в год',
      dataIndex: 'power',
      key: 'power',
    },
    {
      title: 'единица измерения',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];

  return (
    <ContainerInner showTitle={false}>
      <ViewOrganizationGate gov_id={id} />
      <div className={style.block_title} style={{ marginBottom: 16 }}>
        О компании
      </div>
      <Row gutter={[20, 20]}>
        {loading && <Skeleton loading={loading}/>}
        {organization?.blocks?.map((item, index) => {
          return (
            <Col span={6} key={`${item.title}-${index}`}>
              <div className={style.project_view_card}>
                <div className={style.project_view_card_title}>
                  {item.title}
                </div>
                <div
                  className={style.project_view_card_text}
                  style={{ marginTop: '8px' }}
                >
                  {formatText(item.value)}
                </div>
              </div>
            </Col>
          );
        })}
        {organization?.graphs?.map((item, index) => {
          return (
            <Col span={8} key={`${item.title}-${index}`}>
              <div
                className={style.project_view_card}
                style={{ maxHeight: '247px' }}
              >
                <div className={style.project_view_card_title}>
                  {item.title}
                </div>
                <LineGraph data={item.values} />
              </div>
            </Col>
          );
        })}
      </Row>
      {/*<div className={style.block_title} style={{ margin: '16px 0' }}>*/}
      {/*  Производимые товары*/}
      {/*</div>*/}
      {/*<Row gutter={[20, 20]}>*/}
      {/*  <Col span={24}>*/}
      {/*    <Table dataSource={productsData.organizations} columns={columns} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </ContainerInner>
  );
}

function LineGraph({ data }) {
  const format = (v) => {
    return typeof v === 'number'
      ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
      : v;
  };

  const formatYLabel = (v) => {
    return v.length > 10 ? `${v.slice(0, 10)}...` : v;
  };

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    height: 150,
    color: '#4318FF',
    // label: { position: 'right', formatter: (v) => format(v.value), autoHide: true },
    yAxis: { label: { formatter: (v) => formatYLabel(v), autoHide: true } },
    xAxis: {
      label: {
        autoHide: true,
        formatter: (v) => {
          return new Intl.DateTimeFormat('ru-RU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }).format(new Date(v));
        },
      },
    },
    meta: {
      date: { alias: 'Дата' },
      value: { alias: 'Сумма', formatter: (v) => format(parseInt(v)) },
    },
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {data?.length > 0 ? <Line {...config} /> : 'Нет данных'}
    </div>
  );
}
