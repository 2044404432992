import {createGate} from "effector-react";
import {createEvent} from "effector";

export const DotsLayerGate = createGate()

export const selectDotEv = createEvent()

export const resetChosenDotEv = createEvent()

export const updateDotsEv = createEvent()

export const resetDotsLayerFilteredDataEv = createEvent()
