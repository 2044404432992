import {createEffect} from 'effector';
import {api} from '../../api/axiosInstance.js';

export const getOkedsListFx = createEffect()
    .use(async () => (await api().get('/priority-sectors/')).data);

export const getOkedByIdFx = createEffect()
    .use(async (id) => (await api().get(`/priority-sectors/${id}`)).data);

export const createOkedFx = createEffect()
    .use(async (payload) => (await api().post('/priority-sectors/', payload)).data);

export const editOkedFx = createEffect()
    .use(async ({id, payload}) => (await api().put(`/priority-sectors/${id}`, payload)).data);

export const deleteOkedFx = createEffect()
    .use(async (id) => (await api().delete(`/priority-sectors/${id}`)).data);
