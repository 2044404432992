import { combine, createStore } from 'effector';

const permsInitialState = { project: [], stages: [] };
const userInfoInitialState = {
  username: null,
  gov_id: null,
  role: null,
  region_id: null,
};

export const $authStatus = createStore(false);

export const $userInfo = createStore(userInfoInitialState);

export const $userEditPerms = createStore(permsInitialState);

export const $signedCert = createStore(null);

export const $regSuccess = createStore(false);

export const $userConfirmed = combine($userInfo, $authStatus, (u, a) => {
  if (u.role === 'potential_investor' || u.role === 'fundraiser') {
    return !!(a && u?.confirmed);
  }
  return a;
});

export const $regRequestSuccess = createStore(false)

export const $language = createStore(localStorage.getItem('lang') ?? 'ru');

export const $verificationStatus = createStore(false);

export const $editProfileStatus = createStore(false);
