import {Col, Divider, Form, InputNumber, Row} from "antd";
import {numberParser} from "../../utils.js";

const ElGasHeatTemplate = ({namespace, title}) => {
    return <>
        <Divider orientation={'left'} orientationMargin={0}>{title}</Divider>
        <Row style={{alignItems: 'center'}}>
            <Col span={4}>
                <Form.Item name={[...namespace, 'available_value']} label={'Потребность'} key={`${namespace}-1`}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0}/>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name={[...namespace, 'requested_value']} label={'Предоставлено государством'} key={`${namespace}-2`}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0}/>
                </Form.Item>
            </Col>
        </Row>
    </>
}

export default ElGasHeatTemplate