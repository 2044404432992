import { createEvent } from 'effector';
import { $regionsLayerPolygonsStart } from './stores.js';

export const selectRegionEv = createEvent();

export const resetSelectedRegionEv = createEvent();

export const updateRegionsEv = createEvent();

export const setRegionsLayerPolygonsStartEv = createEvent();

export const resetRegionsLayerPolygonsFilteredEv = createEvent();

export const updateRegionsByOverviewEv = createEvent();
