import {Button, Popconfirm, Popover, Table, Tag, Tooltip, Typography} from 'antd';
import { useUnit } from 'effector-react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {
  $mgpMaps,
  $mgpsData,
  deleteMGPEv,
  getMGPsListFx, MGPsGate,
  setMGPsModalEv,
} from '../../models/mgpModel/index.js';
import { $activitiesMap, $regionsMap } from '../../models/globalModel/index.js';
import MGPModal from './components/MGPModal.jsx';

export default function MGPEdit() {
  const data = useUnit($mgpsData);
  const {mgpMap, kspMap, typeMap, kindMap, targetMap, operatorsMap} = useUnit($mgpMaps)
  const loading = useUnit(getMGPsListFx.pending);
  const setModal = useUnit(setMGPsModalEv);
  const deleteMGP = useUnit(deleteMGPEv);

  const activitiesMap = useUnit($activitiesMap);
  const regionsMap = useUnit($regionsMap);

  const columns = [
    {
      title: 'МГП',
      dataIndex: 'program_id',
      key: 'mgp_id',
      width: 200,
      render: (id) => <Tooltip title={mgpMap[id]} placement={'topLeft'}>{mgpMap[id]}</Tooltip>
    },
    {
      title: 'Регион',
      dataIndex: 'region_ids',
      key: 'region',
      width: 150,
      render: (ids) => ids.map(id => <Tag key={id}>{regionsMap[id]}</Tag>),
    },
    {
      title: 'ОКЭД',
      dataIndex: 'activity_ids',
      key: 'oked',
      width: 200,
      render: (ids) => (
        <Popover
          title={
            <div style={{display: 'flex', gap: 4, maxWidth: 800, flexWrap: 'wrap'}}>
              {ids.map(id => <Tag>{activitiesMap[id]}</Tag>)}
            </div>
          }
          placement={'bottom'}
        >
          <Tag style={{ maxWidth: 200 }}>
            <Typography.Paragraph ellipsis>
              {activitiesMap[ids[0]]}
            </Typography.Paragraph>
          </Tag>
        </Popover>
      ),
    },
    {
      title: 'КСП',
      dataIndex: 'ksp_ids',
      key: 'category',
      width: 200,
      render: (ids) => ids.map(id => <Tag key={id}>{kspMap[id]}</Tag>),
    },
    {
      title: 'Тип поддержки',
      dataIndex: 'type_id',
      key: 'type',
      render: (id) => typeMap[id],
    },
    {
      title: 'Вид поддержки',
      dataIndex: 'kind_id',
      key: 'kind',
      render: (id) => kindMap[id],
    },
    {
      title: 'Финансовая',
      children: [
        {
          title: 'Цель',
          dataIndex: 'target_ids',
          key: 'target',
          render: (ids) => ids && ids.length > 0 ? ids.map(id => <Tag key={id}>{targetMap[id]}</Tag>) : '',
        },
        {
          title: 'Сумма',
          width: 300,
          key: 'sum',
          children: [
            {
              title: 'От',
              dataIndex: `sum_from`,
              render: (value) => value ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(value) : ''
            },
            {
              title: 'До',
              dataIndex: `sum_to`,
              render: (value) => value ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(value) : ''
            }
          ]
        },
        {
          title: 'Ставка',
          width: 300,
          key: 'rate',
          children: [
            {
              title: 'От',
              dataIndex: `rate_from`,
              render: (value) => value ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(value) : ''
            },
            {
              title: 'До',
              dataIndex: `rate_to`,
              render: (value) => value ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(value) : ''
            }
          ]
        },
        {
          title: 'Сроки, мес.',
          key: 'deadline',
          width: 300,
          children: [
            {
              title: 'От',
              dataIndex: `deadline_from`,
              render: (value) => value ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(value) : ''
            },
            {
              title: 'До',
              dataIndex: `deadline_to`,
              render: (value) => value ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(value) : ''
            }
          ]
        },
      ]
    },
    {
      title: 'Нефинансовая',
      children: [
        {
          title: 'Услуга',
          dataIndex: 'service',
        },
        {
          title: 'Подуслуга',
          dataIndex: 'sub_service',
        },
        {
          title: 'Стоимость',
          dataIndex: 'service_cost'
        },
        {
          title: 'Сроки',
          dataIndex: 'service_deadlines',
        },
        {
          title: 'Лимит',
          dataIndex: 'service_limit',
        },
      ]
    },
    {
      title: 'Оператор',
      dataIndex: 'operator',
      key: 'operator',
      width: 200,
      render: (id) => <Tooltip title={operatorsMap[id]} placement={'topLeft'}>{operatorsMap[id]}</Tooltip>,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (text) => <Tooltip title={text}>
          <Typography.Paragraph ellipsis style={{maxWidth: 100}}>{text}</Typography.Paragraph>
      </Tooltip>,
    },
    {
      title: 'Ссылка',
      dataIndex: 'link',
      key: 'link',
      render: (link) => <Link to={link} target={'_blank'}>{link}</Link>
    },
    {
      title: (
        <Tooltip title="Добавить">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModal({ open: true, type: 'create' })}
          />
        </Tooltip>
      ),
      key: 'actions',
      width: '96px',
      align: 'center',
      dataIndex: 'id',
      render: (id) => (
        <Button.Group>
          <Tooltip title="Редактировать">
            <Button
              icon={<EditOutlined />}
              onClick={() =>
                setModal({ open: true, type: 'edit', id: id })
              }
            />
          </Tooltip>
          <Popconfirm
            onConfirm={() => deleteMGP(id)}
            title="Вы уверены, что хотите удалить эту запись?"
            okText="Да"
            placement="left"
          >
            <Tooltip title="Удалить">
              <Button icon={<DeleteOutlined />} danger />
            </Tooltip>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <ContainerInner title="МГП">
      <MGPsGate />
      <MGPModal />
      <Table
        size={'small'}
        dataSource={data}
        columns={columns}
        loading={loading}
        bordered
        rowClassName={'table-row'}
        style={{ width: '100%', overflow: 'auto' }}
      />
    </ContainerInner>
  );
}
