import {sample} from "effector";
import {getOrganizationFx} from "./effects.js";
import {$organizationStore} from "./stores.js";
import {prepareOrganizationData} from "../../counters/viewSubject/index.js";

sample({
	clock: getOrganizationFx.doneData,
	fn: (organization) => {
		return prepareOrganizationData(organization)
	},
	target: $organizationStore
})

