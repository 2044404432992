import { Col, Row } from 'antd';
import style from './ViewProjectComponents.module.css';
import {useStore} from "effector-react";
import {$projectStore} from "../../models/viewProjectModel/index.js";

const data = {
  total: {
    title: 'Общая стоимость',
    value: '500,00 млн тенге ',
  },
  sources: [
    {
      title: 'Собственные средства',
      value: '150 млн тенге',
    },
    {
      title: 'Заемные средства',
      value: '350 млн тенге',
    },
    {
      title: 'Бюджетные средства (местный бюджет)',
      value: '0',
    },
    {
      title: 'Собственные средства (иностранный капитал)',
      value: '0',
    },
    {
      title: 'Заемные средства (иностранный капитал)',
      value: '0',
    },
    {
      title: 'Бюджетные средства (республиканский бюджет)',
      value: '0',
    },
  ],
};

function ProjectCostBudget() {
  const {budgets, budgets_total} = useStore($projectStore)

  return (
    <div>
      {/*<div className={style.block_title}>*/}
      {/*  Стоимость проекта и источники финансирования*/}
      {/*</div>*/}
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <div
            className={style.project_view_card}
            style={{ minHeight: '218px' }}
          >
            <div className={style.budget_title}>{'Общая стоимость'}</div>
            <div
              className={style.budget_value}
              style={{
                color: data.total.value !== '0' ? '#4318FF' : '#A3AED0',
                fontSize: '30px',
                marginTop: '16px',
              }}
            >
              {`${new Intl.NumberFormat('ru-RU').format(budgets_total)} млн. тенге`}
            </div>
          </div>
        </Col>
        <Col span={18}>
          <Row gutter={[20, 20]}>
            {budgets.map((item, index) => {
              return (
                <Col span={8} key={`${item.title}-${index}`}>
                  <div
                    className={style.project_view_card}
                    style={{ minHeight: '83px', padding: '16px 0px 16px 16px' }}
                  >
                    <div className={style.budget_title}>{item.source}</div>
                    <div
                      className={style.budget_value}
                      style={{
                        color: item.value !== '0' ? '#4318FF' : '#A3AED0',
                      }}
                    >
                      {item.value}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ProjectCostBudget;
