import React from 'react';
import {Card, Typography} from "antd";

const { Title } = Typography;
const ProjectInfo = ({projects}) => {
    console.log('projects',projects)
    return (
        <Card style={{boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)',marginTop:'20px'}}>
            <Title level={4} style={{margin: '0 0 10px 0',textAlign:'center'}}>Инвестиционные проекты</Title>
            {
              projects.map(item => <ProjectItem key={item.key} proj_name={item.proj_name} proj_cost={item.proj_cost}/>)
            }
        </Card>
    )
};

export default ProjectInfo;

const ProjectItem = ({proj_name, proj_cost}) => {
  return (
    <div style={{display:'flex', flexDirection:"column", gap:'7px'}}>
      <div style={{display:'flex', flexDirection:'column', borderLeft: '3px solid #FAAD14',paddingLeft:'10px'}}>
        <span style={{fontSize:'14px'}}>{proj_name || '(Пусто)'}</span>
        <span style={{fontSize:'20px'}}>{proj_cost || '(Пусто)'}</span>
        <span style={{color:'#ABA8AF',fontSize:'20px'}}>Сумма инвестиций</span>
      </div>
    </div>
  )
}
