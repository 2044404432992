import { sample } from 'effector';
import { $okedsData, $okedsModalState, $selectedOked } from './stores.js';
import {
  createOkedEv,
  deleteOkedEv,
  editOkedEv,
  EditOkedGate,
  OkedsGate,
  resetOkedsModalEv,
  setOkedsModalEv,
} from './events.js';
import {
  createOkedFx,
  deleteOkedFx,
  editOkedFx,
  getOkedByIdFx,
  getOkedsListFx,
} from './effects.js';
import {$activitiesMap, $districtsMap, $regionsMap} from '../globalModel/index.js';

$okedsData
  .on(getOkedsListFx.doneData, (state, payload) => payload)
  .reset(OkedsGate.close);

$selectedOked
  .on(getOkedByIdFx.doneData, (state, payload) => payload)
  .reset(resetOkedsModalEv);

$okedsModalState
  .on(setOkedsModalEv, (state, payload) => ({ ...state, ...payload }))
  .reset(resetOkedsModalEv);

sample({
  clock: [
    OkedsGate.open,
    createOkedFx.doneData,
    editOkedFx.doneData,
    deleteOkedFx.doneData,
  ],
  target: getOkedsListFx,
});

sample({
  clock: EditOkedGate.state,
  filter: ({ id }) => !!id,
  fn: ({ id }) => id,
  target: getOkedByIdFx,
});

sample({
  source: {activities: $activitiesMap, regions: $regionsMap, districts: $districtsMap},
  clock: createOkedEv,
  fn: ({activities, regions, districts}, values) => ({
    ...values,
    activity: activities[values.activity_id],
    region: regions[values.region_id],
    district: districts[values.district_id],
  }),
  target: createOkedFx,
});

sample({
  source: {activities: $activitiesMap, regions: $regionsMap, districts: $districtsMap},
  clock: editOkedEv,
  fn: ({activities, regions, districts}, values) => ({
    ...values,
    activity: activities[values.activity_id],
    region: regions[values.region_id],
    district: districts[values.district_id],
  }),
  target: editOkedFx,
});

sample({
  clock: deleteOkedEv,
  target: deleteOkedFx,
});
