import { sample } from 'effector';
import { getDictionariesFx } from './effects.js';
import { $dictionariesStore } from './stores.js';
import { RegRequestGate } from '../authModel/index.js';

$dictionariesStore.on(getDictionariesFx.doneData, (_, payload) => payload);

sample({
  clock: RegRequestGate.open,
  target: getDictionariesFx,
});
