import { createEvent } from 'effector';
import { createGate } from 'effector-react';

export const RegisterGate = createGate();

export const RegRequestGate = createGate();

export const VerifyGate = createGate();

export const loginEv = createEvent();
export const logoutEv = createEvent();

export const authorizeDSEv = createEvent();

export const completeRegEv = createEvent();

export const setAuthStatusEv = createEvent();

export const submitInvestorRegEv = createEvent();

export const changeLanguageEv = createEvent();

export const changePasswordEv = createEvent();

export const editProfileEv = createEvent();
export const resetEditProfileStatusEv = createEvent();
