import {Divider, Form, Select} from 'antd';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $regionsSelect } from '../../models/globalModel/index.js';
import {$organizationsSelect, $rolesSelect} from '../../models/projectCreateEditModel/index.js';

export default function ResponsibleSelects({
  name,
  stage,
  form,
  filterOption,
  executorOptions,
}) {
  const roleOptions = useStore($rolesSelect);
  const regionsOptions = useStore($regionsSelect);
  const organizationsSelect = useStore($organizationsSelect);

  const [orgsSel, setOrgsSel] = useState(organizationsSelect);

  const existingParticipant = !!Form.useWatch([stage, name, 'responsible_id'], form);

  const role = Form.useWatch([stage, name, 'role_id'], form);
  const region = Form.useWatch([stage, name, 'region_id'], form);
  const org = Form.useWatch([stage, name, 'organization_id'], form);

  useEffect(() => {
    if (organizationsSelect.length > 0) {
      if (role === 3 && region) {
        setOrgsSel(
          organizationsSelect.filter(
            (i) => i.role_id === role && i.region_id === region
          )
        );
        form.setFieldValue([stage, name, 'organization_id'], null);
      } else if (role) {
        setOrgsSel(organizationsSelect.filter((i) => i.role_id === role));
        if (region && role !== 3) {
          form.setFieldValue([stage, name, 'region_id'], null);
        }
        form.setFieldValue([stage, name, 'organization_id'], null);
      } else {
        setOrgsSel(organizationsSelect);
      }
    }
  }, [role, region, organizationsSelect]);

  const orgsFilterOption = (value, option) => {
    return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
    || option.gov_id.toString().toLowerCase().indexOf(value.toLowerCase()) >= 0
  }

  return (
    <>
      <Form.Item
        name={[name, 'responsible_id']}
        label="Из участников проекта"
        rules={[
          {
            required: !org,
            message: 'Пожалуйста выберите ответственного',
          },
        ]}
      >
        <Select
          filterOption={filterOption}
          showSearch
          options={executorOptions}
          allowClear
          dropdownStyle={{width: 500}}
        />
      </Form.Item>
      <Divider orientation={'left'} orientationMargin={0} plain>
        Добавить стороннего
      </Divider>
      <Form.Item
        name={[name, 'role_id']}
        label="Роль"
        rules={[
          {
            required: !existingParticipant,
            message: 'Пожалуйста выберите роль',
          },
        ]}
      >
        <Select options={roleOptions} filterOption={filterOption} allowClear/>
      </Form.Item>
      {role === 3 && (
        <Form.Item name={[name, 'region_id']} label="Регион">
          <Select options={regionsOptions} filterOption={filterOption} allowClear/>
        </Form.Item>
      )}
      <Form.Item
        name={[name, 'organization_id']}
        label="Организация"
        rules={[
          {
            required: !existingParticipant,
            message: 'Пожалуйста выберите организацию',
          },
        ]}
      >
        <Select options={orgsSel} filterOption={orgsFilterOption} allowClear showSearch
          dropdownStyle={{ width: 500 }}
        />
      </Form.Item>
    </>
  );
}
