export const regionIdFilter = (activeFilters, item) => {
  if (activeFilters.region_id > 0) {
    if (!(activeFilters.region_id === item.region_id)) return false;
  }
  return true;
};

export const searchSelectNameFilter = (activeFilters, item) => {
  const { name } = activeFilters;
  if (name && name.length > 0) {
    return item.name.toLowerCase().includes(name.toLowerCase());
  }
  return true;
};

export const searchSelectActivityFilter = (activeFilters, item) => {
  const { main_activity_id } = activeFilters;

  if (main_activity_id && main_activity_id?.length > 0) {
    if (!main_activity_id.some((id) => item.main_activity_id === id))
      return false;
  }
  return true;
};

export const okedFilter = (activeFilters, item) => {
  const { main_activity_id } = activeFilters;
  if (main_activity_id.length > 0) {
    if (!main_activity_id.some((id) => item.main_activity_id === id))
      return false;
  }
  return true;
};

export const projectTypeFilter = (activeFilters, item) => {
  const { project_type_id } = activeFilters;
  if (project_type_id.length > 0) {
    if (!project_type_id.some((id) => item.project_type_id === id))
      return false;
  }
  return true;
};

export const searchSelectOrgFilter = (activeFilters, item) => {
  const { organization_id } = activeFilters;

  if (organization_id && organization_id.length > 0) {
    if (!organization_id.some((id) => item.organization_id === id))
      return false;
  }
  return true;
};

export const districtIdFilter = (activeFilters, item) => {
  if (activeFilters.district_id > 0) {
    if (!(activeFilters.district_id === item.district_id)) return false;
  }
  return true;
};

export const searchOrgBinFilter = (activeFilters, item) => {
  if (activeFilters.organization_bin && activeFilters.organization_bin > 0) {
    if (!(activeFilters.organization_bin === item.gov_id)) return false;
  }
  return true;
};

export const regionGradientFilter = (activeFilters, item) => {
  if (activeFilters.regionIdByGradient.length > 0) {
    if (!activeFilters.regionIdByGradient.includes(item.region_id))
      return false;
  }
  return true;
};

export const problemProjectsFilter = (activeFilters, item) => {
  if (activeFilters.subCategory === 'problemProjects') {
    if (!(item.delay !== null)) return false;
  }
  return true;
};
