import { combine, createStore } from 'effector';
import { $globalStore } from '../globalModel/index.js';
import {prepareActivitiesTreeSelectWithValues, prepareRegionsDistrictsTree} from './utils.jsx';


export const $orgsData = createStore({
  region_data: null,
  oked_data: null,
  map_data: null,
});

export const $orgsTable = createStore({ data: [], count: 0 });

export const $filters = createStore({show_all: false, have_dvc: false, is_gov: false, have_contacts: false})
export const $tableControl = createStore({page: 1, limit: 100, sort_field: null, sort_order: null})

export const $activitiesSelect = combine(
  $globalStore,
  $orgsData,
  ({ activities }, { oked_data }) => activities && oked_data
      ? prepareActivitiesTreeSelectWithValues(activities, oked_data)
      : []
);

export const $regionsTree = combine($orgsData, ({ region_data }) => {
  return region_data ? prepareRegionsDistrictsTree(region_data) : [];
});

export const orgTypeSelect = [
  {label: 'АО', value: 'АО'},
  {label: 'ГУ', value: 'ГУ'},
  {label: 'ДРУГОЕ', value: 'ДРУГОЕ'},
  {label: 'КГУ', value: 'КГУ'},
  {label: 'ОБЩЕСТВЕННОЕ ОБЪЕДИНЕНИЕ', value: 'ОБЩЕСТВЕННОЕ ОБЪЕДИНЕНИЕ'},
  {label: 'ОБЩЕСТВЕННЫЙ ФОНД', value: 'ОБЩЕСТВЕННЫЙ ФОНД'},
  {label: 'ОБЪЕДИНЕНИЕ ЮРИДИЧЕСКИХ ЛИЦ', value: 'ОБЪЕДИНЕНИЕ ЮРИДИЧЕСКИХ ЛИЦ'},
  {label: 'ПКСК', value: 'ПКСК'},
  {label: 'ПОТРЕБИТЕЛЬСКИЙ КООПЕРАТИВ', value: 'ПОТРЕБИТЕЛЬСКИЙ КООПЕРАТИВ'},
  {label: 'ПРЕДСТАВИТЕЛЬСТВО', value: 'ПРЕДСТАВИТЕЛЬСТВО'},
  {label: 'ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ', value: 'ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ'},
  {label: 'РЕСПУБЛИКАНСКОЕ ГОСУДАРСТВЕННОЕ КАЗЕННОЕ ПРЕДПРИЯТИЕ', value: 'РЕСПУБЛИКАНСКОЕ ГОСУДАРСТВЕННОЕ КАЗЕННОЕ ПРЕДПРИЯТИЕ'},
  {label: 'ТОО', value: 'ТОО'},
  {label: 'ФИЛИАЛ', value: 'ФИЛИАЛ'}
]

export const legalTypeSelect = [
  {label: 'ИП', value: 'ИП'},
  {label: 'СП', value: 'СП'},
  {label: 'Филиал Иностр.ЮЛ', value: 'Филиал Иностр.ЮЛ'},
  {label: 'Филиал ЮЛ', value: 'Филиал ЮЛ'},
  {label: 'ЮЛ', value: 'ЮЛ'}
]

export const krpSelect = [
  {label: 'Малые предприятия (<= 5)', value: 'Малые предприятия (<= 5)'},
  {label: 'Малые предприятия (6 - 10)', value: 'Малые предприятия (6 - 10)'},
  {label: 'Малые предприятия (11-15)', value: 'Малые предприятия (11-15)'},
  {label: 'Малые предприятия (16-20)', value: 'Малые предприятия (16-20)'},
  {label: 'Малые предприятия (21-30)', value: 'Малые предприятия (21-30)'},
  {label: 'Малые предприятия (31-40)', value: 'Малые предприятия (31-40)'},
  {label: 'Малые предприятия (41-50)', value: 'Малые предприятия (41-50)'},
  {label: 'Малые предприятия (51-100)', value: 'Малые предприятия (51-100)'},
  {label: 'Средние предприятия (151-200)', value: 'Средние предприятия (151-200)'},
  {label: 'Средние предприятия (101-150)', value: 'Средние предприятия (101-150)'},
  {label: 'Средние предприятия (201-250)', value: 'Средние предприятия (201-250)'},
  {label: 'Крупные предприятия (251-500)', value: 'Крупные предприятия (251-500)'},
  {label: 'Крупные предприятия (501-1000)', value: 'Крупные предприятия (501-1000)'},
  {label: 'Крупные предприятия (>1000)', value: 'Крупные предприятия (>1000)'}
]

export const departmentsSelect = [
  {label: 'Департамент торговли', value: 'Департамент торговли'},
  {label: 'Департамент строительства и земельных отношений', value: 'Департамент строительства и земельных отношений'},
  {label: 'Департамент АПК и пищевой промышленности', value: 'Департамент АПК и пищевой промышленности'},
  {label: 'Департамент логистики и перевозок', value: 'Департамент логистики и перевозок'},
  {label: 'Департамент строительства и земельных отношений', value: 'Департамент строительства и земельных отношений'},
  {label: 'Департамент торговли', value: 'Департамент торговли'},
  {label: 'Департамент туризма', value: 'Департамент туризма'},
  {label: 'Департамент энергетики и ЖКХ', value: 'Департамент энергетики и ЖКХ'},
  {label: 'ДМУ', value: 'ДМУ'},
  {label: 'ДОП', value: 'ДОП'}
]

export const $reportsModal = createStore(false)
export const $reportsList = createStore([])