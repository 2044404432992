import React, {useState} from 'react';
import {Button, Collapse, ConfigProvider, Popconfirm, Row, Skeleton} from 'antd';
import {useParams} from 'react-router-dom';
import {useUnit, useStoreMap} from 'effector-react';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {$projectStore, ViewProjectGate} from '../../models/viewProjectModel/index.js';
import ProjectStagesTables from '../../components/ViewProjectComponents/ProjectStagesTables.jsx';
import ProjectCommonInfo from '../../components/ViewProjectComponents/ProjectCommonInfo.jsx';
import ProjectCostBudget from '../../components/ViewProjectComponents/ProjectCostBudget.jsx';
import ProjectParticipants from '../../components/ViewProjectComponents/ProjectParticipants.jsx';
import ProjectInvestorInfo from '../../components/ViewProjectComponents/ProjectInvestorInfo.jsx';
import ProjectResourcesInfo from '../../components/ViewProjectComponents/ProjectResourcesInfo.jsx';
import {$dictionariesMaps} from "../../models/dictionariesModel/index.js";
import {$activitiesMap} from "../../models/globalModel/index.js";
import ProjectSummary from "../../components/ViewProjectComponents/ProjectSummary.jsx";
import style from "../../components/ViewProjectComponents/ViewProjectComponents.module.css";
import {$userInfo} from '../../models/authModel/index.js';
import {approveProjectEv, setRejectModalEv} from '../../models/unpublishedProjectsModel/index.js';
import RejectProjectModal from '../../components/RejectProjectModal/RejectProjectModal.jsx';

function isApproved(npp, rpp, mio, curUsr) {
    const isNpp = curUsr?.region_id === 2 && curUsr?.role === 'chamber';
    const isRpp = curUsr?.region_id !== 2 && curUsr?.role === 'chamber';
    const isMio = curUsr?.role === 'mio';
    if (npp && isNpp) {
        return true;
    } else if (rpp && isRpp) {
        return true;
    } else if (mio && isMio) {
        return true;
    } else {
        return false;
    }
}

function isRejected(npp, rpp, mio, curUsr) {
    const isNpp = curUsr?.region_id === 2 && curUsr?.role === 'chamber';
    const isRpp = curUsr?.region_id !== 2 && curUsr?.role === 'chamber';
    const isMio = curUsr?.role === 'mio';
    if (npp === false && isNpp) {
        return true;
    } else if (rpp === false && isRpp) {
        return true;
    } else if (mio === false && isMio) {
        return true;
    } else {
        return false;
    }
}

function ViewProject() {
    const curUsr = useUnit($userInfo);
    const {id} = useParams();
    const infoLoading = useStoreMap($dictionariesMaps, (maps) => Object.values(Object.values(maps)[0]).length === 0)
    const activitiesMap = useUnit($activitiesMap)

    const approveProject = useUnit(approveProjectEv)
    const setRejectModal = useUnit(setRejectModalEv)

    const [showOverdue, setShowOverdue] = useState(false)


    const {
        project_id,
        rpp_approved,
        npp_approved,
        mio_approved,
        publish_on_process,
        name,
        main_activity_id,
    } = useUnit($projectStore);

    return <>
        {!infoLoading && <ViewProjectGate id={+id}/>}
        {(infoLoading || !name)
            ? <Skeleton loading={true} style={{width: 1296, margin: '0 auto'}}/>
            : <ContainerInner title={`${name} / ${activitiesMap[main_activity_id] ?? ''}`}>
                <ProjectSummary setShowOverdue={setShowOverdue}/>
                <ProjectStagesTables showOverdue={showOverdue} setShowOverdue={setShowOverdue}/>
                <ConfigProvider theme={{
                    components: {
                        Collapse: {
                            contentBg: '#f3f6fe',
                            contentPadding: '32px 16px'
                        }
                    }
                }}>
                    <Collapse defaultActiveKey={'investor'} destroyInactivePanel={false} style={{marginTop: 72}}>
                        <Collapse.Panel key={'common'} header={<div className={style.block_title}>Общие сведения</div>}>
                            <ProjectCommonInfo/>
                        </Collapse.Panel>
                        <Collapse.Panel key={'budget'}
                                        header={<div className={style.block_title}>Стоимость проекта и источники
                                            финансирования</div>}>
                            <ProjectCostBudget/>
                        </Collapse.Panel>
                        <Collapse.Panel key={'participants'}
                                        header={<div className={style.block_title}>Участники проекта</div>}>
                            <ProjectParticipants/>
                        </Collapse.Panel>
                        <Collapse.Panel key={'investor'} header={<div className={style.block_title}>Об инвесторе</div>}>
                            <ProjectInvestorInfo/>
                        </Collapse.Panel>
                        <Collapse.Panel key={'resources'} header={<div className={style.block_title}>
                            Потребность в ресурсах для реализации проекта
                        </div>}>
                            <ProjectResourcesInfo/>
                        </Collapse.Panel>
                    </Collapse>
                    <Row
                        style={{
                            marginTop: !(isApproved(npp_approved, rpp_approved, mio_approved, curUsr) || isRejected(npp_approved, rpp_approved, mio_approved, curUsr)) ? 16 : 0,
                            gap: 16
                    }}
                    >
                    {((curUsr.role === 'chamber' || curUsr.role === 'mio')
                        && !isApproved(npp_approved, rpp_approved, mio_approved, curUsr))
                        && publish_on_process
                        && <>
                        <Popconfirm title={'Вы уверены, что хотите одобрить данный проект?'}
                                    okText={'Да'}
                                    onConfirm={() => approveProject(project_id)}
                        >
                            <Button type={'primary'}>
                                Одобрить проект
                            </Button>
                        </Popconfirm>
                        </>}
                    {((curUsr.role === 'chamber' || curUsr.role === 'mio')
                        && !isRejected(npp_approved, rpp_approved, mio_approved, curUsr))
                        && publish_on_process
                        && <>
                            <Popconfirm title={'Вы уверены, что хотите отклонить данный проект?'}
                                        okText={'Да'}
                                        onConfirm={() => setRejectModal(project_id)}
                                        okButtonProps={{danger: true}}
                            >
                                <Button type={'primary'} danger>
                                    Отклонить проект
                                </Button>
                            </Popconfirm>
                        </>}
                    </Row>
                </ConfigProvider>
            </ContainerInner>}
        <RejectProjectModal />
    </>
}

export default ViewProject;
