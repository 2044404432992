import { createEffect } from 'effector';
import queryString from 'query-string';
import { api } from '../../api/axiosInstance.js';

export const getOrganizationsDataFx = createEffect().use(async (filters) => {
  const query = queryString.stringify(filters);
  return (await api().get(`/dashboard/registry${query.length > 0 ? `?${query}` : ''}`)).data;
});

export const getOrganizationsTableFx= createEffect().use(async (filters) => {
  const preQuery = filters ? { ...filters } : {};
  if (!preQuery?.page) {
    preQuery['page'] = 1;
  }
  if (!preQuery?.limit) {
    preQuery['limit'] = 100;
  }
  if (!preQuery?.sort_field) {
    delete preQuery['sort_field'];
  }
  if (!preQuery?.sort_order) {
    delete preQuery['sort_order'];
  }
  const query = queryString.stringify(preQuery);
  return (await api().get(`/dashboard/organizations${query.length > 0 ? `?${query}` : ''}`)).data;
});

export const getReportsListFx = createEffect()
    .use(async (type) => (await api().get(`/user-reports/${type ? `?report_type=${type}` : ''}`)).data)

export const downloadReportFx = createEffect()
    .use(async (id) => {
        const file = (await api().get(`/user-reports/download/${id}`, {responseType: 'blob'})).data
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)
        link.href = url
        link.target = '_blank'
        link.download = `Отчёт ${new Date().toLocaleDateString('ru-RU')}.xlsx`
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const exportToExcelFx = createEffect()
  .use(async (filters) => {
    const query = queryString.stringify(filters);
    return (await api().post(`/user-reports${query.length > 0 ? `?${query}` : ''}`)).data
  })
