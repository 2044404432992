import {createEvent} from "effector";
import {createGate} from "effector-react";

export const ForeignProjectsGate = createGate()

export const getForeignInvestmentsListEv = createEvent()

export const submitCreateFormEv = createEvent()
export const submitUpdateFormEv = createEvent()

export const verifyFormEv = createEvent()

export const downloadFormFileEv = createEvent()

export const setForProjModalStateEv = createEvent()
export const resetForProjModalStateEv = createEvent()