import { Col, Row } from 'antd';
import style from './ViewProjectComponents.module.css';
import {useStore} from "effector-react";
import {$projectStore, $projParticipantsInfo} from "../../models/viewProjectModel/index.js";
import {$dictionariesMaps} from "../../models/dictionariesModel/index.js";


function ProjectParticipants() {
  const {rolesMap} = useStore($dictionariesMaps);
  const participantsInfo = useStore($projParticipantsInfo)

  const {participants} = useStore($projectStore);

  return (
    <div>
      {/*<div className={style.block_title}>Участники проекта</div>*/}
      <Row gutter={[20, 20]}>
        {participants?.map((item, index) => {
          return (
            <Col span={6} key={`${item.name}-${index}`}>
              <div
                className={style.project_view_card}
                style={{ minHeight: '355px' }}
              >
                <div className={style.project_view_card_title}>{item.name}</div>
                <div className={style.participant_info_wrapper}>
                  <div className={style.participant_field}>БИН</div>
                  <div className={style.participant_info}>{item.gov_id}</div>
                </div>
                <div className={style.participant_info_wrapper}>
                  <div className={style.participant_field}>Организация</div>
                  <div className={style.participant_info}>
                    {participantsInfo[item.organization_id]?.name ?? '-'}
                  </div>
                </div>
                <div className={style.participant_info_wrapper}>
                  <div className={style.participant_field}>Доля участника</div>
                  <div className={style.participant_info}>{item.part}</div>
                </div>
                <div className={style.participant_info_wrapper}>
                  <div className={style.participant_field}>Роль</div>
                  <div className={style.participant_info}>{rolesMap[item.role_id]}</div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default ProjectParticipants;
