import {Col, Divider, Form, InputNumber, Row, Typography} from "antd";
import {numberParser} from "../../utils.js";

const WaterSupplyTemplate = ({namespace, title, total}) => {
    return <>
        <Divider orientation={'left'} orientationMargin={0}>{title}</Divider>
        <Typography.Title level={5}>{`Итого: ${total}`}</Typography.Title>
        <Row style={{alignItems: 'center'}}>
            <Col span={7}>
                <Form.Item name={[...namespace, 'available_value']} label={'Потребность (куб. м в час)'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0}/>
                </Form.Item>
            </Col>
            <Col span={7}>
                <Form.Item name={[...namespace, 'requested_value']} label={'Предоставлено государством'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0}/>
                </Form.Item>
            </Col>
        </Row>
    </>
}

export default WaterSupplyTemplate