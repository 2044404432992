import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  Upload,
} from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {
  $createEditModal,
  $createStatus,
  $selectedRequest,
  closeReqModalEv,
  createReqFx,
  editReqFx,
  getReqByIdFx,
  submitRequestEv,
} from '../../../models/investorRequestsModel/index.js';
import { $userInfo } from '../../../models/authModel/index.js';
import { $regionsSelect } from '../../../models/globalModel/index.js';
import {
  $countriesSelect,
  $createEditProjectUtils,
} from '../../../models/dictionariesModel/index.js';

export default function CreateEditRequestModal() {
  const {t} = useTranslation();
  const { open, type } = useUnit($createEditModal);
  const existing = useUnit($selectedRequest);

  const close = useUnit(closeReqModalEv);
  const status = useUnit($createStatus);
  const loadingEdit = useUnit(editReqFx.pending);
  const loadingCreate = useUnit(createReqFx.pending);
  const loadingSubmit = loadingEdit || loadingCreate;
  const loadingSelected = useUnit(getReqByIdFx.pending);

  const submit = useUnit(submitRequestEv);
  const user = useUnit($userInfo);
  const regions = useUnit($regionsSelect);
  const countries = useUnit($countriesSelect);
  const { truOptions, investUnitOptions, investActivityOptions } = useUnit(
    $createEditProjectUtils
  );

  const [reqForm] = Form.useForm();

  useEffect(() => {
    if (user.gov_id && type === 'create') {
      reqForm.setFieldValue('initiator', user.gov_id);
    }
  }, [user, type]);

  useEffect(() => {
    if (type === 'edit' && existing) {
      const payload = { ...existing };
      payload.deadline = [
        dayjs(existing.deadline_from),
        dayjs(existing.deadline_to),
      ];
      delete payload.deadline_from;
      delete payload.deadline_to;
      delete payload.files;
      reqForm.setFieldsValue(payload);
    }
  }, [open, type, existing]);

  const onClose = () => {
    reqForm.resetFields();
    close();
  };

  const onSubmit = (values) => {
    const payload = { ...values };
    payload.deadline_from = values.deadline[0].format('YYYY-MM-DD');
    payload.deadline_to = values.deadline[1].format('YYYY-MM-DD');
    delete payload.deadline;
    submit(payload);
  };

  useEffect(() => {
    if (status) {
      onClose();
    }
  }, [status]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      open={open}
      width="80vw"
      title={
        type === 'edit'
          ? t('Редактирование инвестиционного предложения')
          : t('Создание инвестиционного предложения')
      }
      okText={t('Подтвердить')}
      onCancel={onClose}
      okButtonProps={{
        onClick: () => reqForm.submit(),
        loading: loadingSubmit,
      }}
    >
      {type === 'edit' && loadingSelected ? (
        <Skeleton />
      ) : (
        <Form form={reqForm} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            name="name"
            label={t('Наименование проекта')}
            rules={[{ required: true, message: t('Обязательное поле') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('Описание проекта')}
            rules={[{ required: true, message: t('Обязательное поле') }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Row justify="space-between">
            <Col span={6}>
              <Form.Item
                name="cost"
                label={t('Стоимость проекта')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>тыс. $ США</span>
            </Col>
            <Col span={6}>
              <Form.Item
                name="activity_id"
                label={t('Отрасль')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Select
                  options={investActivityOptions}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="region_id"
                label={t('Регион')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Select
                  options={regions}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="initiator" label={t('Наименование компании инициатора')}>
            <Input disabled />
          </Form.Item>
          <Divider orientationMargin={0} orientation="left">
            {t('Мощность проекта')}
          </Divider>
          <Form.List name="powers" initialValue={[{ tru_type_id: null }]}>
            {(fields, { add, remove }) => (
              <Col span={24}>
                {fields.map((field, index) => (
                  <>
                    {index !== 0 && (
                      <Divider orientationMargin={0} orientation="left" />
                    )}
                    <Row justify="space-between">
                      <Col span={10}>
                        <Form.Item
                          name={[field.name, 'tru_type_id']}
                          label={t('Вид ТРУ')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <Select
                            options={truOptions}
                            showSearch
                            filterOption={filterOption}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name={[field.name, 'tnved']}
                          label={t('Наименование ТНВЭД')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={10}>
                        <Form.Item
                          name={[field.name, 'value']}
                          label={t('Кол-во')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name={[field.name, 'unit_id']}
                          label={t('Ед. измерения')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <Select
                            options={investUnitOptions}
                            showSearch
                            filterOption={filterOption}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ width: '20%' }} justify="space-between">
                      {index > 0 && (
                        <Button danger onClick={() => remove(field.name)}>
                          Отменить
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        {t('Добавить')}
                      </Button>
                    </Row>
                  </>
                ))}
              </Col>
            )}
          </Form.List>
          <Divider />
          <Form.Item
            name="sales_market"
            label={t('Рынок сбыта')}
            rules={[{ required: true, message: t('Обязательное поле') }]}
          >
            <Select
              options={countries}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          <Divider />
          <Row justify="space-between">
            <Col span={9}>
              <Form.Item
                name="export_products"
                label={t('Экспорт продукции')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Radio.Group
                  options={[
                    { label: t('Да'), value: true },
                    { label: t('Нет'), value: false },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="raw_materials_available"
                label={t('Наличие необходимого сырья для производства продукции')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Radio.Group
                  options={[
                    { label: t('Да'), value: true },
                    { label: t('Нет'), value: false },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="subsoil_rights_available"
                label={t('Наличие прав недропользования')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Radio.Group
                  options={[
                    { label: t('Да'), value: true },
                    { label: t('Нет'), value: false },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientationMargin={0} orientation="left">
            {t('Запасы месторождения')}
          </Divider>
          <Form.List name="deposits" initialValue={[{ tru_type_id: null }]}>
            {(fields, { add, remove }) => (
              <Col span={24}>
                {fields.map((field, index) => (
                  <>
                    {index !== 0 && (
                      <Divider orientationMargin={0} orientation="left" />
                    )}
                    <Row justify="space-between">
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, 'name']}
                          label={t('Наименование запасов')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, 'value']}
                          label={t('Кол-во')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name={[field.name, 'unit_id']}
                          label={t('Ед. измерения')}
                          rules={[
                            { required: true, message: t('Обязательное поле') },
                          ]}
                        >
                          <Select
                            options={investUnitOptions}
                            showSearch
                            filterOption={filterOption}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ width: '20%' }} justify="space-between">
                      {index > 0 && (
                        <Button danger onClick={() => remove(field.name)}>
                          {t('Отменить')}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        {t('Добавить')}
                      </Button>
                    </Row>
                  </>
                ))}
              </Col>
            )}
          </Form.List>
          <Divider orientationMargin={0} orientation="left">
            {t('Финансовые показатели')}
          </Divider>
          <Row justify="space-between">
            <Col span={6}>
              <Form.Item
                name="investment_amount"
                label={t('Сумма инвестиций по проекту')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>{t('тыс.$ США')}</span>
            </Col>
            <Col span={6}>
              <Form.Item
                name="npv"
                label={t('Чистая приведенная стоимость (NPV) по проекту')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>{t('тыс. $ США')}</span>
            </Col>
            <Col span={6}>
              <Form.Item
                name="irr"
                label={t('Внутренняя ставка доходности (IRR)')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>%</span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={6}>
              <Form.Item
                name="ebitda"
                label={t('EBITDA')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>{t('тыс.$ США')}</span>
            </Col>
            <Col span={6}>
              <Form.Item
                name="payback_period"
                label={t('Срок окупаемости')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>{t('лет')}</span>
            </Col>
            <Col span={6}>
              <Form.Item
                name="discounted_payback_period"
                label={t('Дисконтированный срок окупаемости')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <span style={{ position: 'relative', top: -20 }}>{t('лет')}</span>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={6}>
              <Form.Item
                name="workplaces"
                label={t('Кол-во создаваемых рабочих мест')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="deadline"
                label={t('Сроки реализации')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="status"
                label={t('Статус')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {type === 'create' && (
            <>
              <Form.Item
                name="plan"
                label={t('Бизнес-план')}
                valuePropName="file"
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Upload beforeUpload={() => false} maxCount={1}>
                  <Button
                    icon={<UploadOutlined />}
                    shape="round"
                    size="large"
                  />
                </Upload>
              </Form.Item>
              <Form.Item
                name="presentation"
                label={t('Презентация')}
                valuePropName="file"
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Upload beforeUpload={() => false} maxCount={1}>
                  <Button
                    icon={<UploadOutlined />}
                    shape="round"
                    size="large"
                  />
                </Upload>
              </Form.Item>
              <Form.Item
                name="fin"
                label={t('Фин. модель')}
                valuePropName="file"
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Upload beforeUpload={() => false} maxCount={1}>
                  <Button
                    icon={<UploadOutlined />}
                    shape="round"
                    size="large"
                  />
                </Upload>
              </Form.Item>
              <Form.Item
                name="files"
                label={t('Другие файлы')}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload beforeUpload={() => false} multiple>
                  <Button
                    icon={<UploadOutlined />}
                    shape="round"
                    size="large"
                  />
                </Upload>
              </Form.Item>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
