export const prepareEditPerms = (role) => {
  let perms;
  switch (role) {
    case 'admin':
      perms = {
        project: [
          'progress',
          'start_plan',
          'start_fact',
          'end_plan',
          'end_fact',
          'investments',
          'participants',
          'main_activity',
          'objects',
          'curators',
        ],
        stages: [
          'stage_name',
          'status',
          'start_plan',
          'start_fact',
          'end_plan',
          'end_fact',
          'description',
          'sum_plan',
          'sum_fact',
        ],
        // project: ['test'],
        // stages: ['test'],
      };
      break;
    case 'investor':
      perms = {
        project: [
          'progress',
          'start_plan',
          'start_fact',
          'end_plan',
          'end_fact',
          'investments',
          'participants',
          'main_activity',
          'objects',
        ],
        stages: [
          'stage_name',
          'status',
          'start_plan',
          'start_fact',
          'end_plan',
          'end_fact',
          'description',
          'sum_plan',
          'sum_fact',
        ],
      };
      break;
    case 'chamber':
      perms = {
        project: ['progress', 'start_fact', 'end_fact', 'objects'],
        stages: ['start_fact', 'end_fact', 'description'],
      };
      break;
    case 'gov':
      perms = {
        project: ['progress', 'start_fact', 'end_fact', 'curators'],
        stages: ['start_fact', 'end_fact', 'description'],
      };
      break;
    case 'institute':
      perms = {
        project: ['progress'],
        stages: ['description'],
      };
      break;
    default:
      perms = {
        project: ['unknown'],
        stages: ['unknown'],
      };
      break;
  }
  return perms;
};
