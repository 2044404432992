import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Typography,
} from 'antd';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  $regRequestSuccess,
  RegRequestGate,
  submitInvestorRegEv,
} from '../../models/authModel/index.js';
import { $regionsSelect } from '../../models/globalModel/index.js';
import {
  $countriesSelect,
  $createEditProjectUtils,
} from '../../models/dictionariesModel/index.js';
import styles from './RegRequest.module.css';
import logo from '../../assets/logo_2.png';
import logo2 from '../../assets/vtp_logo_main.png';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector.jsx';


export default function RegRequest() {
  const { t } = useTranslation();

  const regions = useUnit($regionsSelect);
  const regionsSelect = regions?.map((i) => ({
    label: t(`regions.${i.label}`),
    value: i.value,
  }));
  const { investActivityOptions } = useUnit($createEditProjectUtils);
  const countries = useUnit($countriesSelect);
  const onSubmit = useUnit(submitInvestorRegEv);
  const regSuccess = useUnit($regRequestSuccess);
  const [regForm] = Form.useForm();
  const selectedType = Form.useWatch('type', regForm);
  const selectedOrigin = Form.useWatch('origin', regForm);

  const [passValid, setPassValid] = useState(false);
  const validPass = new RegExp(
    '^(?=.*[a-zA-Z0-9_!@#$%^&?*])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&?*])[a-zA-Z0-9_!@#$%^&?*]{8,32}$'
  );

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" className={styles.logoMain} />
        <img src={logo2} alt="logo" className={styles.logoSecond} />
      </div>
      <RegRequestGate />
      <Card
        className="login-form"
        style={{ width: '80vw', maxHeight: '70vh', overflow: 'auto' }}
        extra={<LanguageSelector allLangs/>}
      >
        <Form
          form={regForm}
          layout="vertical"
          onFinish={(values) => {
            onSubmit(values);
          }}
        >
          <Form.Item name="type" label={t('Категория пользователя')}>
            <Select
              options={[
                { label: t('Хочу инвестировать'), value: 'potential_investor' },
                { label: t('Ищу инвестиции'), value: 'fundraiser' },
              ]}
            />
          </Form.Item>
          {selectedType === 'potential_investor' && (
            <Form.Item name="origin" label={t('Происхождение')}>
              <Select
                options={[
                  { label: t('Отечественный инвестор'), value: 'local' },
                  { label: t('Иностранный инвестор'), value: 'foreign' },
                ]}
              />
            </Form.Item>
          )}
          {(selectedOrigin || selectedType === 'fundraiser') && (
            <>
              <Form.Item
                name="gov_id"
                label={
                  selectedOrigin === 'local'
                    ? t('БИН/ИИН')
                    : t('Наименование компании')
                }
                rules={[
                  { required: true, message: t('Обязательное поле') },
                  {
                    validator: async (rule, value) => {
                      if (selectedType === 'local') {
                        if (value && value.length === 12) {
                          return Promise.resolve();
                        }
                        if (value && value.length !== 12) {
                          return Promise.reject(
                            new Error('БИН должен состоять из 12 цифр')
                          );
                        }
                      } else return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  showCount={selectedOrigin === 'local'}
                  maxLength={selectedOrigin === 'local' ? 12 : 256}
                />
              </Form.Item>
              {selectedOrigin === 'foreign' && (
                <Form.Item
                  name="country_id"
                  label={t('Страна')}
                  rules={[{ required: true, message: t('Обязательное поле') }]}
                >
                  <Select
                    options={countries}
                    filterOption={filterOption}
                    showSearch
                    allowClear
                  />
                </Form.Item>
              )}
              {selectedType === 'potential_investor' && (
                <>
                  <Form.Item
                    name="investment_amount"
                    label={`${t('Сумма инвестиций')} ${
                      selectedType === 'local'
                        ? t('(в млн. тенге)')
                        : t('(в тыс. долларов)')
                    }`}
                    rules={[{ required: true, message: t('Обязательное поле') }]}
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name="oked_ids"
                    label={t('Отрасль')}
                    rules={[{ required: true, message: t('Обязательное поле') }]}
                  >
                    <Select options={investActivityOptions} mode="multiple" />
                  </Form.Item>
                  <Form.Item
                    name="region_ids"
                    label={t('Регион')}
                  >
                    <Select
                      options={regionsSelect}
                      mode="multiple"
                      filterOption={filterOption}
                      showSearch
                      allowClear
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="email"
                label={t('Электронная почта')}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                name="password"
                label={t('Придумайте пароль')}
                rules={[
                  { required: true, message: t('Обязательное поле') },
                  {
                    pattern: validPass,
                    message:
                      t('Пароль должен содержать символы a-z, A-Z, 0-9, _!@#$%^&?*, длина 8-32'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                label={t('Подтвердите пароль')}
                dependencies={['password']}
                rules={[
                  { required: true, message: t('Подтвердите пароль') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('password') === value) {
                        setPassValid(true);
                        return Promise.resolve();
                      }
                      setPassValid(false);
                      return Promise.reject(new Error(t('Пароли не совпадают')));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
        </Form>
        <Popconfirm
          title={t('На указанный Вами адрес электронной почты будет отправлено письмо с подтверждением регистрации')}
          onConfirm={() => regForm.submit()}
          disabled={!passValid}
          okText={t('Продолжить')}
          cancelText={t('Отмена')}
        >
          <Button type="primary" disabled={!passValid}>
            {t('Подать заявку')}
          </Button>
        </Popconfirm>
      </Card>

      <Modal
        open={regSuccess}
        width="80vw"
        footer={null}
        closable={false}
        style={{ textAlign: 'center' }}
      >
        <Typography.Paragraph>
          {t('Регистрация завершена, проверьте свой почтовый ящик, подтвердите регистрацию и переходите к авторизации.')}
        </Typography.Paragraph>
        <Button type="primary" href={'/login'}>{t('Продолжить')}</Button>
      </Modal>
    </>
  );
}
