import React, { useEffect } from 'react';
import Map from '../../components/Map/Map.jsx';
import BottomBar from '../../components/BottomBar/BottomBar.jsx';
import RegionsLayer from '../../components/Layers/RegionsLayer.jsx';
import DotsLayer from '../../components/Layers/DotsLayer.jsx';
import BuildingsLayer from '../../components/Layers/BuildingsLayer.jsx';
import DistrictsLayer from '../../components/Layers/DistrictsLayer.jsx';
import style from './MapWork.module.css';
import Cities from '../../components/Cities/Cities.jsx';
import CitySelector from '../../components/CitySelector/CitySelector.jsx';
import GradientTypeSelector from '../../components/GradientTypeSelector/GradientTypeSelector.jsx';
import MapGradient from '../../components/MapGradient/MapGradient.jsx';
import MapControls from '../../components/MapControls/MapControls.jsx';
import DistrictSelector from '../../components/DistrictSelector/DistrictSelector.jsx';
import RegionSelector from '../../components/RegionSelector/RegionSelector.jsx';
import OkedSelector from '../../components/OkedSelector/OkedSelector.jsx';
import ProjectTypeSelector from '../../components/ProjectTypeSelector/ProjectTypeSelector.jsx';

function MapWork({ isInnerPage = false, isOverview = false }) {
  return (
    <div className={style.mapwork_wrapper}>
      <Map isInnerPage={isInnerPage} />
      {isInnerPage ? (
        <DotsLayer isInnerPage={isInnerPage} />
      ) : (
        <>
          <RegionsLayer />
          <DotsLayer isOverview={isOverview} />
          {/* {!isOverview ? <DistrictsLayer /> : ''} */}
          <DistrictsLayer isOverview={isOverview} />
          {/* <CitySelector /> */}
          {isOverview ? (
            <>
              <RegionSelector />
              <DistrictSelector />
              <div
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <OkedSelector />
                <ProjectTypeSelector />
              </div>
            </>
          ) : (
            ''
          )}
          {/* <GradientTypeSelector /> */}
          {/* <MapGradient /> */}
          <MapControls />
          {/* <Cities /> */}
        </>
      )}
      {/* <BuildingsLayer /> */}
      {/* <BottomBar /> */}
    </div>
  );
}

export default MapWork;
