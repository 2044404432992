import dayjs from 'dayjs';

function convertToTree(roots) {
  const treeData = [];

  for (const node of Object.values(roots)) {
    treeData.push({
      title: node.name_ru,
      value: node.id,
      children: convertToTree(node.children),
    });
  }

  return treeData;
}

export const prepareActivitiesSelect = (activities) => {
  const roots = [];
  const map = {};

  for (const [idx, node] of Object.entries(activities)) {
    node.children = [];
    map[node.id] = idx;
    if (node.root_id === -1) {
      roots.push(node);
    } else if (
      activities[map[node.root_id]] &&
      Object.hasOwn(activities[map[node.root_id]], 'children')
    ) {
      activities[map[node.root_id]].children.push(node);
    } else if (activities[map[node.root_id]]) {
      activities[map[node.root_id]].children = [node];
    }
  }

  return convertToTree(roots);
};

const budgetsDict = {
  own_budget: 'Собственные средства, в млн. тенге',
  own_budget_foreign:
    'Собственные средства (иностранный капитал), в млн. тенге',
  borrowed_budget: 'Заемные средства, в млн. тенге',
  borrowed_budget_foreign:
    'Заемные средства (иностранный капитал), в млн. тенге',
  gov_local: 'Бюджетные средства (местный бюджет), в млн. тенге',
  gov_republican: 'Бюджетные средства (республиканский бюджет), в млн. тенге',
  'Собственные средства, в млн. тенге': 'own_budget',
  'Собственные средства (иностранный капитал), в млн. тенге':
    'own_budget_foreign',
  'Заемные средства, в млн. тенге': 'borrowed_budget',
  'Заемные средства (иностранный капитал), в млн. тенге':
    'borrowed_budget_foreign',
  'Бюджетные средства (местный бюджет), в млн. тенге': 'gov_local',
  'Бюджетные средства (республиканский бюджет), в млн. тенге': 'gov_republican',
};

const effectsDict = {
  tax_fot: 'Налоги с ФОТ в год млн. тенге',
  tax: 'Прочие налоги в год, млн. тенге',
  workplaces: 'Количество рабочих мест единиц',
  total: 'Ожидаемая сумма выплачиваемых налогов в год млн. тенге',
  'Налоги с ФОТ в год млн. тенге': 'tax_fot',
  'Прочие налоги в год, млн. тенге': 'tax',
  'Количество рабочих мест единиц': 'workplaces',
  'Ожидаемая сумма выплачиваемых налогов в год млн. тенге': 'total',
};

const requirementsDict = {
  need_soil: 'Земля',
  need_water: 'Водоснабжение и КНС',
  need_electricity: 'Электроэнергия',
  need_heat: 'Теплоснабжение',
  need_gas: 'Газоснабжение',
  need_buildings: 'Здания и сооружения',
  Земля: 'need_soil',
  'Водоснабжение и КНС': 'need_water',
  Электроэнергия: 'need_electricity',
  Теплоснабжение: 'need_heat',
  Газоснабжение: 'need_gas',
  'Здания и сооружения': 'need_buildings',
};

const resourcesDict = {
  production: 'в т.ч. земли промышленности, га',
  farm: 'в т.ч. пашня, га',
  weatreap: 'в т.ч. сенокосы, га',
  feed: 'в т.ч. выпасы, га',
  other: 'в т.ч. прочие,га',
  manufacture: 'Производственные, тыс. кв. метров',
  utils: 'Вспомогательные, тыс. кв. метров',
  storage: 'Складские, тыс. кв. метров',
  technical: 'Промышленное техническое',
  irrigation: 'Ирригационное',
  drink: 'Питьевое',
  electricity: 'Мегаватт/час',
  gas: 'Кубометров/час',
  heat: 'Гкал/час',
  'в т.ч. земли промышленности, га': 'production',
  'в т.ч. пашня, га': 'farm',
  'в т.ч. сенокосы, га': 'weatreap',
  'в т.ч. выпасы, га': 'feed',
  'в т.ч. прочие,га': 'other',
  'Производственные, тыс. кв. метров': 'manufacture',
  'Вспомогательные, тыс. кв. метров': 'utils',
  'Складские, тыс. кв. метров': 'storage',
  'Промышленное техническое': 'technical',
  Ирригационное: 'irrigation',
  Питьевое: 'drink',
  'Мегаватт/час': 'electricity',
  'Кубометров/час': 'gas',
  'Гкал/час': 'heat',
};

const resourcesReqDict = {
  production: 'soil',
  farm: 'soil',
  weatreap: 'soil',
  feed: 'soil',
  other: 'soil',
  manufacture: 'buildings',
  utils: 'buildings',
  storage: 'buildings',
  technical: 'water',
  irrigation: 'water',
  drink: 'water',
};

export function prepareFormData(values, dictionaries) {
  const project_start = dayjs(values?.start_date_plan).toISOString();

  const budgets =
    Object?.entries(values?.budgets ?? {}).map(([type, value]) => ({
      budget_source_id: dictionaries.budgetsMap[budgetsDict[type]],
      sum_fact: +value,
      sum_plan: +value,
    })) ?? [];

  const capacities = values?.capacities ?? [{tru_type_id: null}];
  const participants = values?.participants ?? [];
  const requirements =
    Object?.entries(values?.resources ?? {})
      .filter(([key]) => key.includes('need'))
      .map(([type, value]) => {
        return {
          required: value,
          requirement_id:
            dictionaries.requirementsNameMap[requirementsDict[type]],
        };
      }) ?? [];

  const resourcesSimple =
    Object?.entries(values?.resources ?? {})
      .filter(([key]) => {
        return (
          !key.includes('need') && ['gas', 'electricity', 'heat'].includes(key)
        );
      })
      .filter(([key, value]) => {
        return !!value?.available_value && !!value?.requested_value;
      })
      .map(([type, value]) => {
        return {
          resource_id: dictionaries.projReqToResFieldsMap[resourcesDict[type]],
          available_value: value?.available_value,
          requested_value: value?.requested_value,
        };
      }) ?? [];

  const resourcesComplex =
    Object?.entries(values?.resources ?? {})
      .filter(
        ([key]) =>
          !key.includes('need') && !['gas', 'electricity', 'heat'].includes(key)
      )
      .flatMap(([_, value]) => {
        return Object?.entries(value ?? {})
          ?.filter(
            ([key, val]) => !!val?.available_value && !!val?.requested_value
          )
          ?.map(([key, val]) => {
            const result = {
              resource_id:
                dictionaries.projReqToResFieldsMap[resourcesDict[key]],
              available_value: val?.available_value,
              requested_value: val?.requested_value,
            };
            Object.hasOwn(val, 'is_provided') &&
              (result.is_provided = val?.is_provided);
            Object.hasOwn(val, 'provided_value') &&
              (result.provided_value = val?.provided_value);
            return result;
          });
      }) ?? [];

  const effects =
    Object?.entries(values?.effects ?? {})?.map(([type, value]) => ({
      effect_id: dictionaries.effectsMap[effectsDict[type]],
      investment_period: +value.investment_period,
      post_investment_period: +value.post_investment_period,
    })) ?? [];

  const contacts =
    Object?.entries(values?.contacts ?? {})?.map(([type, value]) => {
      return {
        full_name: value?.full_name ?? '',
        title: type === 'head' ? 'head' : value?.title ?? '',
        phone: value?.phone ?? '',
        email: value?.email ?? '',
      };
    }) ?? [];

  const {
    name,
    location,
    main_activity_id,
    description,
    region_id,
    district_id,
    project_type_id
  } = values;

  return {
    name,
    location,
    region_id,
    district_id,
    project_type_id,
    start_date_plan: project_start,
    main_activity_id,
    description,
    budgets,
    capacities,
    participants,
    requirements,
    resources: [...resourcesSimple, ...resourcesComplex],
    effects,
    contacts,
  };
}

export function parseProjectToForm(projectData, dicts) {
  const start_date_plan = projectData?.start_date_plan ? dayjs(projectData.start_date_plan) : null

  const budgets = Object.fromEntries(
    Object?.values(projectData?.budgets ?? {}).map((i) => [
      budgetsDict[dicts.budgetsIdToNameMap[i.budget_source_id]],
      i.sum_plan,
    ])
  );

  const effects = Object.fromEntries(
    Object?.values(projectData?.effects ?? {}).map((i) => [
      effectsDict[dicts.effectsIdToNameMap[i.effect_id]],
      i,
    ])
  );

  const reqirements = Object.fromEntries(
    Object?.values(projectData?.requirements ?? {}).map((i) => [
      requirementsDict[dicts.requirementsIdToNameMap[i.requirement_id]],
      i.required,
    ])
  );

  const resourcesData = Object.fromEntries(
    Object?.values(projectData?.resources ?? {}).map((i) => [
      resourcesDict[dicts.projReqToResNameMap[i.resource_id]],
      i,
    ])
  );

  const complexResources = {};
  const simpleResources = {};

  for (const key in resourcesData) {
    if (resourcesReqDict[key]) {
      complexResources[resourcesReqDict[key]] = {
        ...complexResources[resourcesReqDict[key]],
        [key]: resourcesData[key],
      };
    } else if (!resourcesReqDict[key]) {
      simpleResources[key] = resourcesData[key];
    }
  }

  const resources = { ...reqirements, ...complexResources, ...simpleResources };

  const contacts = Object.fromEntries(
    Object?.values(projectData?.contacts ?? {}).map((i) => [
      [i.title === 'head' ? 'head' : 'responsible'],
      i,
    ])
  );

  const stages = {};
  projectData.stages.forEach((stage) => {
    if (!stages[`stage_${stage.phase_id}`]) {
      stages[`stage_${stage.phase_id}`] = [
        {
          ...stage,
          start_date_plan: stage.start_date_plan
            ? dayjs(stage.start_date_plan)
            : null,
          start_date_fact: stage.start_date_fact
            ? dayjs(stage.start_date_fact)
            : null,
          finish_date_plan: stage.finish_date_plan
            ? dayjs(stage.finish_date_plan)
            : null,
          finish_date_fact: stage.finish_date_fact
            ? dayjs(stage.finish_date_fact)
            : null,
        },
      ];
    } else {
      stages[`stage_${stage.phase_id}`].push({
        ...stage,
        start_date_plan: stage.start_date_plan
          ? dayjs(stage.start_date_plan)
          : null,
        start_date_fact: stage.start_date_fact
          ? dayjs(stage.start_date_fact)
          : null,
        finish_date_plan: stage.finish_date_plan
          ? dayjs(stage.finish_date_plan)
          : null,
        finish_date_fact: stage.finish_date_fact
          ? dayjs(stage.finish_date_fact)
          : null,
      });
    }
  });

  const {
    id,
    name,
    location,
    main_activity_id,
    project_type_id,
    description,
    region_id,
    district_id,
    participants,
    capacities,
  } = projectData;

  const formData = {
    id,
    name,
    start_date_plan,
    project_type_id,
    location,
    main_activity_id,
    description,
    region_id,
    district_id,
    capacities,
    budgets,
    participants,
    effects,
    resources,
    contacts,
  };

  return { formData, stages };
}
