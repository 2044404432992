import { Col, Form, InputNumber, Row, Select } from 'antd';
import { useUnit } from 'effector-react';
import { $mgpSelects } from '../../../models/mgpModel/index.js';
import filterOption from '../../../utils/filterOption.js';

export default function FinForm() {
  const { targetSelect, operatorsSelect } = useUnit($mgpSelects);

  return (
    <>
      <Row justify="space-between">
        <Col span={7}>
          <Form.Item
            name="target_ids"
            label="Цель"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={targetSelect}
              filterOption={filterOption}
              mode="multiple"
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="operator"
            label="Оператор"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={operatorsSelect}
              filterOption={filterOption}
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={7} style={{ textAlign: 'center' }}>
          <span>Сумма (млн. тенге)</span>
          <Row justify="space-between">
            <Form.Item name="sum_from" label="От">
              <InputNumber min={0} style={{ width: 150 }} />
            </Form.Item>
            <Form.Item
              name="sum_to"
              label="До"
              // rules={[{ required: true, message: 'Обязательное поле' }]}
            >
              <InputNumber min={0} style={{ width: 150 }} />
            </Form.Item>
          </Row>
        </Col>
        <Col span={7} style={{ textAlign: 'center' }}>
          <span>Ставка (%)</span>
          <Row justify="space-between">
            <Form.Item name="rate_from" label="От">
              <InputNumber min={0} style={{ width: 150 }} />
            </Form.Item>
            <Form.Item
              name="rate_to"
              label="До"
              // rules={[{ required: true, message: 'Обязательное поле' }]}
            >
              <InputNumber min={0} style={{ width: 150 }} />
            </Form.Item>
          </Row>
        </Col>
        <Col span={7} style={{ textAlign: 'center' }}>
          <span>Сроки, мес.</span>
          <Row justify="space-between">
            <Form.Item name="deadline_from" label="От">
              <InputNumber min={0} style={{ width: 150 }} />
            </Form.Item>
            <Form.Item
              name="deadline_to"
              label="До"
              // rules={[{ required: true, message: 'Обязательное поле' }]}
            >
              <InputNumber min={0} style={{ width: 150 }} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </>
  );
}
