import Modal from 'antd/es/modal/Modal';
import {
    $bizPollsStatsModalData,
    $bizPollsStatsModalState,
    resetBizPollsStatsModalEv
} from '../../../../models/overviewModel/index.js';
import {useStoreMap, useUnit} from 'effector-react';
import {Bar, Column} from '@ant-design/charts';
import {Card, Col, Divider, Row, Tooltip, Typography} from 'antd';
import {$activitiesMap} from '../../../../models/globalModel/index.js';
import {useMemo, useState} from 'react';
import {RollbackOutlined} from '@ant-design/icons';

export default function BizPollsStatsModal() {
    const open = useUnit($bizPollsStatsModalState);
    const close = useUnit(resetBizPollsStatsModalEv);
    const okedMap = useUnit($activitiesMap)
    const {okedChart, okedDetailsChart, categoriesCharts} = useStoreMap($bizPollsStatsModalData, (data) => ({
        okedChart: Object?.entries(data?.oked_counts ?? [])?.map(([key, value]) => ({
            oked: okedMap[key],
            count: Object.values(value).reduce((a, b) => a + b, 0),
            id: key,
        })),
        okedDetailsChart: Object.fromEntries(Object?.entries(data?.oked_counts ?? [])
            ?.map(([key, value]) => (
                [
                    key,
                    Object.entries(value)?.map(([key, count]) => (
                        {
                            oked: okedMap[key],
                            count
                        }
                    ))]
            ))),
        categoriesCharts: Object?.entries(data?.category_percentages ?? [])?.map(([key, value]) => {
            return {
                header: key,
                chart: Object?.entries(value ?? {})?.map(([key, value]) => ({
                    category: key,
                    percent: value
                }))
            }
        })
    }));

    const [selectedOked, setSelectedOked] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const questionsCharts = useMemo(() => {
        return categoriesCharts?.map(({header, chart}) => (
            <Col span={12}>
                <Card size={'small'} style={{height: '100%'}}>
                    <Typography.Paragraph style={{textAlign: 'center'}}><strong>{header}</strong></Typography.Paragraph>
                    <Bar
                        data={chart}
                        height={150}
                        label={{position: 'left'}}
                        yAxis={{
                            label: {formatter: (v) => v.length > 17 ? `${v.slice(0, 17)}...` : v}
                        }}
                        meta={{
                            percent: {alias: 'Процент', formatter: (v) => `${Math.round(v)}%`},
                        }}
                        tooltip={{
                            formatter: ({_, percent}) => {
                                return {name: 'Показатель', value: `${Math.round(percent)}%`}
                            }
                        }}
                        xField={'percent'}
                        yField={'category'}
                    />
                </Card>
            </Col>
        ))
    }, [categoriesCharts])

    return <Modal title={'Детали опросов'}
                  open={open} width={'90vw'}
                  onCancel={() => close()}
                  rootClassName={'bizPollsStatsModal'}
                  footer={null}
                  destroyOnClose
                  styles={{header: {background: '#f3f6fe'}, body: {background: '#f3f6fe'}}}
    >
        <Card size={'small'}>
            {selectedTitle ? <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <div style={{width: '10%'}}/>
                <Typography.Title level={5} style={{textAlign: 'center', marginTop: 0}}>
                    {selectedTitle}
                </Typography.Title>
                <Tooltip title={'Назад'}>
                <RollbackOutlined style={{fontSize: 24}}
                               onClick={() => {
                                   setSelectedOked(null)
                                   setSelectedTitle(null)
                               }}/>
                </Tooltip>
            </div> : <Typography.Title level={5} style={{textAlign: 'center', marginTop: 0}}>
                Отраслевая структура респондентов
            </Typography.Title>
            }
            <Column
                data={selectedOked ? okedDetailsChart[selectedOked] : okedChart}
                seriesField={'oked'}
                legend={{position: 'left'}}
                tooltip={{
                    formatter: ({_, count}) => {
                        return {name: 'Количество ответов', value: count}
                    }
                }}
                xAxis={{
                    label: false
                }}
                xField={'oked'}
                yField={'count'}
                onReady={(plot) => {
                plot.on('plot:click', (evt) => {
                    const {x, y} = evt;
                    setSelectedOked(plot?.chart?.getTooltipItems({x, y})[0]?.data?.id ?? null)
                    setSelectedTitle(plot?.chart?.getTooltipItems({x, y})[0]?.data?.id
                        ? plot?.chart?.getTooltipItems({x, y})[0]?.data.oked
                        : null)
                })
            }}
        />
        </Card>
        <Divider />
        <Row justify={'space-between'} gutter={[48, 24]}>
        {
            ...questionsCharts
        }
        </Row>
    </Modal>
}