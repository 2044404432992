import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import { useUnit } from 'effector-react';
import { Link } from 'react-router-dom';
import {AreaChartOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import { regions_dictionary } from '../../dictionaries/regions_districts_dictionary.js';
import {
  $unpublishedList,
  approveProjectEv, getUnpublishedListFx,
  setRejectModalEv,
  UnpublishedGate,
} from '../../models/unpublishedProjectsModel';
import { $userInfo } from '../../models/authModel/index.js';
import RejectProjectModal from '../../components/RejectProjectModal/RejectProjectModal.jsx';

function isApproved(record, curUsr) {
  const npp = curUsr?.region_id === 2 && curUsr?.role === 'chamber';
  const rpp = curUsr?.region_id !== 2 && curUsr?.role === 'chamber';
  const mio = curUsr?.role === 'mio';
  if (npp && record?.npp_approve) {
    return true;
  }
  if (rpp && record?.rpp_approve) {
    return true;
  }
  if (mio && record?.mio_approve) {
    return true;
  }
  return false;
}

function isRejected(record, curUsr) {
  const npp = curUsr?.region_id === 2 && curUsr?.role === 'chamber';
  const rpp = curUsr?.region_id !== 2 && curUsr?.role === 'chamber';
  const mio = curUsr?.role === 'mio';
  if (npp && record?.npp_approve === false) {
    return true;
  }
  if (rpp && record?.rpp_approve === false) {
    return true;
  }
  if (mio && record?.mio_approve === false) {
    return true;
  }
  return false;
}

export default function UnpublishedProjects() {
  const curUsr = useUnit($userInfo);
  const data = useUnit($unpublishedList);
  const loading = useUnit(getUnpublishedListFx.pending);

  const approveProject = useUnit(approveProjectEv);
  const setRejectModal = useUnit(setRejectModalEv);

  const columns = [
    {
      title: '№',
      key: 'order',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      key: 'name',
      width: '40%',
      title: 'Наименование проекта',
      dataIndex: 'name',
    },
    {
      key: 'subject',
      width: '20%',
      title: 'Предприятие',
      dataIndex: 'organization_name',
    },
    {
      title: 'Регион',
      key: 'region',
      dataIndex: 'region_id',
      render: (region) => regions_dictionary[region],
    },
    {
      key: 'investments',
      width: '20%',
      title: 'Сумма инвестиций (млн. тенге)',
      dataIndex: 'investments_total',
      sorter: (a, b) => a.investments_total - b.investments_total,
      render: (record) =>
        record ? new Intl.NumberFormat('ru-RU').format(record) : 0,
    },
    {
      key: 'actions',
      width: '15%',
      title: 'Действия',
      align: 'center',
      render: (record) => (
        <div
          id="buttons_container"
          style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}
        >
          <Tooltip title="Просмотр проекта">
            <Link to={`/view-project/${record.id}`} target="_blank">
              <Button className="info-btn" icon={<AreaChartOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip
            title={
              isApproved(record, curUsr) ? 'Уже одобрен' : 'Одобрить проект'
            }
          >
            <Popconfirm
              disabled={isApproved(record, curUsr)}
              title="Вы уверены, что хотите одобрить данный проект?"
              placement="left"
              onConfirm={() => approveProject(record.id)}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                icon={<CheckCircleOutlined />}
                disabled={isApproved(record, curUsr)}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip
            title={
              isRejected(record, curUsr) ? 'Уже отклонен' : 'Отклонить проект'
            }
          >
            <Popconfirm
              disabled={isRejected(record, curUsr)}
              title="Вы уверены, что хотите отклонить данный проект?"
              placement="left"
              onConfirm={() => setRejectModal(record.id)}
              okButtonProps={{ danger: true }}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                icon={<CloseCircleOutlined />}
                disabled={isRejected(record, curUsr)}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <ContainerInner title="Проекты на рассмотрении">
      <UnpublishedGate />
      <RejectProjectModal />
      <Table dataSource={data} columns={columns} loading={loading} />
    </ContainerInner>
  );
}
