import { Divider, Layout, Tooltip } from 'antd';
import './ContentContainer.css';

function ContainerInner({ title, children, extra = null, showTitle = true }) {
  return (
    <Layout.Content className="container">
      {showTitle ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tooltip title={title}>
              <h2
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  color: '#2b3674',
                  fontSize: '28px',
                }}
              >
                {title}
              </h2>
            </Tooltip>
            {extra}
          </div>
          {/* <Divider */}
          {/*  orientationMargin={0} */}
          {/*  orientation="left" */}
          {/*  style={{ marginTop: 0 }} */}
          {/* /> */}
        </>
      ) : (
        ''
      )}
      {/* //For inner container padding */}
      <Layout.Content className="inner" style={{ padding: '0.5rem  1rem' }}>
        {children}
      </Layout.Content>
    </Layout.Content>
  );
}

export default ContainerInner;
