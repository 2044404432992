import raw from '../../data/activity.json';

export function prepareRegionsDistrictsTree(values) {
  const nodes = [];

  for (const [region, districts] of Object.entries(values)) {
    const total = Object.values(districts)?.reduce((a, b) => parseInt(a) + parseInt(b), 0)
    nodes.push({
      title: (
        <div>
          <span>{region} - </span>
          <span style={{ fontWeight: 700 }}>{total}</span>
        </div>
      ),
      value: region,
      key: `region##${region}`,
      total,
      children: Object.entries(districts)?.map(([name, count]) => ({
          title: (
            <div>
              <span>{name} - </span>
              <span style={{ fontWeight: 700 }}>{count}</span>
            </div>
          ),
          key: `district##${name}`,
          region,
          total: count,
          value: name,
      })).toSorted((a, b) => a.total < b.total ? 1 : -1),
    });
  }

  return nodes.toSorted((a, b) => a.total < b.total ? 1 : -1);
}




function getParents(map, item) {
  let result = [];
  if (map[item.root_id]) {
    result.push(item.root_id);
    result = result.concat(getParents(map, map[item.root_id]));
  }

  return result;
}

function filterUnavailable(map, actMap) {
  const check = Object.keys(actMap);
  const result = {};
  for (const [id, item] of Object.entries(map)) {
    if (item.activity_type_id === 5 && check.includes(id)) {
      result[id] = getParents(map, item) ?? [];
    }
  }
  return result;
}

function calculateLevelSum(node, values) {
  if (node.activity_type_id === 5) {
    node.count = values[node.name_ru] ? Number(values[node.name_ru]) : 0;
  } else {
    let sum = 0;
    if (node.children) {
      for (const child of node.children) {
        sum += calculateLevelSum(child, values);
      }
    }
    node['count'] = sum;
  }
  return node.count;
}

function convertToTree(roots, actMap, values) {
  const treeData = [];

  for (const node of Object.values(roots)) {
    const count = calculateLevelSum(node, values);

    treeData.push({
      title: (
        <div>
          <span>{node.name_ru} - </span>
          <span style={{ fontWeight: 700 }}>{count}</span>
        </div>
      ),
      value: node.name_ru,
      level: node.activity_type_id - 1,
      children: node.activity_type_id < 2 ? convertToTree(node.children, actMap, values) : [],
      disabled: !actMap[node.id],
    });
  }

  return treeData;
}

export function prepareActivitiesTreeSelectWithValues(activities, values) {
  const actMap = Object.fromEntries(activities.map((i) => [i.id, i]));
  const rawMap = Object.fromEntries(raw.map((i) => [i.id, i]));

  const filtered = filterUnavailable(rawMap, actMap);
  const availableIds = [];
  for (const [key, values] of Object.entries(filtered)) {
    availableIds.push(...values, parseInt(key));
  }

  const uniqueIds = Array.from(new Set(availableIds));

  const roots = [];
  const map = {};

  const sortedRaw = raw.filter((i) => uniqueIds.includes(i.id));

  for (const [idx, node] of Object.entries(sortedRaw)) {
    node.children = [];
    map[node.id] = idx;
    if (node.root_id === -1) {
      roots.push(node);
    } else if (
        sortedRaw[map[node.root_id]] &&
        Object.hasOwn(sortedRaw[map[node.root_id]], 'children')
    ) {
      sortedRaw[map[node.root_id]].children.push(node);
    } else if (sortedRaw[map[node.root_id]]) {
      sortedRaw[map[node.root_id]].children = [node];
    }
  }

  return convertToTree(roots, actMap, values);
}
