import {
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Row,
  Col,
  Tooltip,
  TreeSelect,
} from 'antd';
import { useEffect } from 'react';
import { useStore, useUnit } from 'effector-react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  $activitiesTreeSelect,
  $regionsSelect,
} from '../../../models/globalModel/index.js';
import {
  $createEditStatus,
  $mgpSelects,
  $mgpsModalState,
  $selectedMgp,
  createMGPEv,
  createMGPFx,
  editMGPEv,
  editMGPFx,
  EditMGPGate,
  getMGPByIdFx,
  resetMGPsModalEv,
} from '../../../models/mgpModel/index.js';
import FinForm from './FinForm.jsx';
import NonFinForm from './NonFinForm.jsx';
import filterOption from '../../../utils/filterOption.js';

const { SHOW_PARENT } = TreeSelect;

export default function MGPModal() {
  const { type, open, id } = useUnit($mgpsModalState);
  const { mgpSelect, kspSelect, typeSelect, kindSelect } = useUnit($mgpSelects);
  const okedTree = useUnit($activitiesTreeSelect);
  const submitCreate = useUnit(createMGPEv);
  const submitEdit = useUnit(editMGPEv);
  const close = useUnit(resetMGPsModalEv);
  const edit = useUnit($selectedMgp);
  const loading = useUnit(getMGPByIdFx.pending);
  const createLoading = useUnit(createMGPFx.pending);
  const editLoading = useUnit(editMGPFx.pending);
  const submitLoading = createLoading || editLoading;
  const status = useUnit($createEditStatus);
  const [mgpForm] = Form.useForm();

  const onActivitySelect = (value, label, extra) => {
    const payloadValue = [];

    const getOkedIds = (node) => {
      if (!payloadValue.includes(node.node.props.value)) {
        payloadValue.push(node.node.props.value);
      }
      if (node.children.length > 0) {
        node.children.forEach((child) => getOkedIds(child));
      }
    };

    if (extra.allCheckedNodes.length > 0) {
      extra.allCheckedNodes.forEach((checkedNode) => {
        getOkedIds(checkedNode);
      });
    }
    mgpForm.setFieldsValue({ activity_ids: payloadValue });
  };

  useEffect(() => {
    if (type === 'edit' && edit) {
      mgpForm.setFieldsValue(edit);
    }
  }, [type, edit]);

  const onClose = () => {
    mgpForm.resetFields();
    close();
  };

  const onFinish = (values) => {
    const mutatedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (!value && value !== 0) {
          return [key, null];
        }
        return [key, value];
      })
    );
    if (type === 'create') {
      submitCreate(mutatedValues);
    } else if (type === 'edit') {
      submitEdit({ id, payload: mutatedValues });
    }
  };

  useEffect(() => {
    if (status) {
      mgpForm.resetFields();
      onClose();
    }
  }, [status]);

  const regionsSelect = useStore($regionsSelect);

  const supType = Form.useWatch('type_id', mgpForm);

  return (
    <Modal
      open={open}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{ loading: submitLoading }}
      onOk={() => mgpForm.submit()}
      onCancel={onClose}
      width="80vw"
      title={type === 'create' ? 'Добавить МГП' : 'Изменить МГП'}
    >
      {type === 'edit' && id && <EditMGPGate id={id} />}
      {type === 'edit' && loading ? (
        <Skeleton />
      ) : (
        <Form
          name="mgpForm"
          form={mgpForm}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row justify="space-between">
            <Col span={7}>
              <Form.Item
                name="program_id"
                label="Наименование МГП (Программа)"
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Select
                  options={mgpSelect}
                  filterOption={filterOption}
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="region_ids"
                label="Регион"
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Select
                  options={regionsSelect}
                  filterOption={filterOption}
                  allowClear
                  showSearch
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="activity_ids"
                label="Вид деятельности"
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <TreeSelect
                  treeData={okedTree}
                  showSearch
                  treeNodeFilterProp="title"
                  allowClear
                  style={{
                    maxHeight: 32,
                    overflow: 'auto',
                    marginRight: '20px',
                  }}
                  dropdownStyle={{ width: 500 }}
                  listHeight={600}
                  treeCheckable
                  showCheckedStrategy={SHOW_PARENT}
                  onChange={onActivitySelect}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={7}>
              <Form.Item
                name="ksp_ids"
                label={
                  <Tooltip title="Категория субъекта предпринимательства">
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    >
                      <span>КСП</span>
                      <QuestionCircleOutlined />
                    </div>
                  </Tooltip>
                }
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Select
                  options={kspSelect}
                  filterOption={filterOption}
                  allowClear
                  mode="multiple"
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="type_id"
                label="Тип поддержки"
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Select
                  options={typeSelect}
                  filterOption={filterOption}
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="kind_id"
                label="Вид поддержки"
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Select
                  options={kindSelect}
                  filterOption={filterOption}
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
          {supType === 1 ? <FinForm /> : <NonFinForm />}
          <Form.Item
            name="description"
            label="Описание"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="requirements_for_organization"
            label="Требования к организациям"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="subsidy_amounts"
            label="Размеры субсидий в месяц"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="requirements_for_persons"
            label="Требования к лицам, трудоустраиваемым на субсидируемые рабочие места"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="link"
            label="Ссылка"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
