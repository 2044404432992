import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';
import { $userInfo } from '../../models/authModel/index.js';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import PasswordChange from './components/PasswordChange.jsx';
import InvProfile from './components/InvProfile.jsx';
import ProjectProfile from './components/ProjectProfile.jsx';

export default function Profile() {
  const curUsr = useUnit($userInfo);
  const { t } = useTranslation();
  return (
    <ContainerInner title={`${t('Личный кабинет')} ${curUsr.gov_id}`}>
      {curUsr.role === 'fundraiser' || curUsr.role === 'potential_investor' ? (
        <InvProfile curUsr={curUsr} />
      ) : (
        <ProjectProfile curUsr={curUsr} />
      )}
      <Divider />
      <PasswordChange />
    </ContainerInner>
  );
}
