import React, {useEffect, useState} from 'react';
import {Card, Typography, Button, DatePicker} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ru_RU.js";
import 'dayjs/locale/ru.js'
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import {date_format_short} from "../../constants/date_format.js";
const { RangePicker } = DatePicker

const { Title } = Typography;


const taxesData = {
    "2023-10-18": "18 октября Налоги",
    "2023-10-19": "19 октября Налоги",
    "2023-10-20": "20 октября Налоги",
    "2023-10-21": "21 октября Налоги",
};
const totalDebtData = {
    "2023-10-18": "18 октября общая Задолженность",
    "2023-10-19": "19 октября общая Задолженность",
    "2023-10-20": "20 октября общая Задолженность",
    "2023-10-21": "21 октября общая Задолженность",
};
const debtData = {
    "2023-10-18": "18 октября Задолженность",
    "2023-10-19": "19 октября Задолженность",
    "2023-10-20": "20 октября Задолженность",
    "2023-10-21": "21 октября Задолженность",
};
const paymentsData = {
    "2023-10-18": "18 октября Оплаты",
    "2023-10-19": "19 октября Оплаты",
    "2023-10-20": "20 октября Оплаты",
    "2023-10-21": "21 октября Оплаты",
};

const YearFilter = ({dates, changeDates, taxSum}) => {
    const [selectedDateIndex, setSelectedDateIndex] = useState(null);
    // const [dates, setDates] = useState([
    //     new Date(),
    //     new Date(new Date().setDate(new Date().getDate() + 1))
    // ]);


    // const handleDateClick = (index) => {
    //     setSelectedDateIndex(index);
    // };
    //
    // const changeDates = (days) => {
    //     const newDates = dates.map(date => new Date(date.setDate(date.getDate() + days)));
    //     setDates(newDates);
    // };
    //
    // const getInfoForTaxes = (date) => {
    //     const formattedDate = date.toISOString().split('T')[0];
    //     return taxesData[formattedDate] || "(Пусто)";
    // };
    // const getInfoForTotalDebt = (date) => {
    //     const formattedDate = date.toISOString().split('T')[0];
    //     return totalDebtData[formattedDate] || "(Пусто)";
    // };
    //
    // const getInfoForDebt = (date) => {
    //     const formattedDate = date.toISOString().split('T')[0];
    //     return debtData[formattedDate] || "(Пусто)";
    // };
    // const getInfoForPayments = (date) => {
    //     const formattedDate = date.toISOString().split('T')[0];
    //     return paymentsData[formattedDate] || "(Пусто)";
    // };


    return (
        <div>
        <Card size={"small"} style={{boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)'}} >
            <Title style={{textAlign:'center', marginTop:'0px'}} level={4}>Фильтр по годам</Title>

            <RangePicker picker={"month"} locale={locale} value={dates} onChange={changeDates}/>
            {/*<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>*/}
            {/*    <Button*/}
            {/*        icon={<LeftOutlined />}*/}
            {/*        onClick={() => changeDates(-1)}*/}
            {/*    />*/}
            {/*    {dates.map((date, index) => (*/}
            {/*        <Button*/}
            {/*            key={index}*/}
            {/*            style={{*/}
            {/*                border: selectedDateIndex === index ? '2px solid #1890ff' : '1px solid #d9d9d9',*/}
            {/*                width: '40%',*/}
            {/*                height: '40px'*/}
            {/*            }}*/}
            {/*            onClick={() => handleDateClick(index)}*/}
            {/*        >*/}
            {/*            {date.toDateString()}*/}
            {/*        </Button>*/}
            {/*    ))}*/}
            {/*    <Button*/}
            {/*        icon={<RightOutlined />}*/}
            {/*        onClick={() => changeDates(1)}*/}
            {/*    />*/}
            {/*</div>*/}
        </Card>

            <Card size={"small"} style={{ marginTop: '10px',boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)' }}>
                <p style={{margin: 0,textAlign: "center"}}>
                    {taxSum}
                </p>
                <Title style={{margin: 0, textAlign:'center'}} level={4}>Налоги</Title>
            </Card>
            {/*<Card size={"small"} style={{ marginTop: '10px',boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)' }}>*/}
            {/*    <p style={{margin: 0,textAlign: "center"}}>*/}
            {/*        {selectedDateIndex !== null ? getInfoForTotalDebt(dates[selectedDateIndex]) : "(Пусто)"}*/}
            {/*    </p>*/}
            {/*    <Title style={{margin: 0, textAlign:'center'}} level={4}>Общая задолженность</Title>*/}
            {/*</Card>*/}
            {/*<Card size={"small"} style={{ marginTop: '10px',boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)' }}>*/}
            {/*    <p style={{margin: 0,textAlign: "center"}}>*/}
            {/*        {selectedDateIndex !== null ? getInfoForDebt(dates[selectedDateIndex]) : "(Пусто)"}*/}
            {/*    </p>*/}
            {/*    <Title style={{margin: 0, textAlign:'center'}} level={4}>Задолженность</Title>*/}
            {/*</Card>*/}
            {/*<Card size={"small"} style={{ marginTop: '10px',boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)' }}>*/}
            {/*    <p style={{margin: 0,textAlign: "center"}}>*/}
            {/*        {selectedDateIndex !== null ? getInfoForPayments(dates[selectedDateIndex]) : "(Пусто)"}*/}
            {/*    </p>*/}
            {/*    <Title style={{margin: 0, textAlign:'center'}} level={4}>Оплаты</Title>*/}
            {/*</Card>*/}
        </div>


    );
}

export default YearFilter;
