import { useEffect } from 'react';
import { useEvent, useStore } from 'effector-react';
import {
  $chosenDot,
  $dotsLayerData,
  resetChosenDotEv,
  selectDotEv,
} from '../../models/dotsLayerModel/index.js';
import { $mapLoaded } from '../../models/globalModel/index.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import { $selectedDistrict } from '../../models/districtsModel/index.js';
import { $showProjectDots } from '../../models/overviewModel/index.js';

function DotsLayer({ isInnerPage, isOverview }) {
  const dotsLayerData = useStore($dotsLayerData);
  const chosenDot = useStore($chosenDot);

  const selectDot = useEvent(selectDotEv);
  const resetChosenDot = useEvent(resetChosenDotEv);
  const mapLoaded = useStore($mapLoaded);

  const selectedDistrict = useStore($selectedDistrict);

  const showProjectDots = useStore($showProjectDots);

  // FIXME Function to Init OR Update layer
  const createLayer = (update) => {
    const dotsData = {
      type: 'FeatureCollection',
      features: dotsLayerData,
    };

    if (update) {
      window.map.getSource('dots').setData(dotsData);
      if (isOverview) {
        if (showProjectDots) {
          changeLayerVisibility('dots_clusters', 'visible');
          changeLayerVisibility('dots_unclustered', 'visible');
          changeLayerVisibility('dots_cluster_count', 'visible');
        } else {
          changeLayerVisibility('dots_clusters', 'none');
          changeLayerVisibility('dots_unclustered', 'none');
          changeLayerVisibility('dots_cluster_count', 'none');
        }
      }
    } else {
      if (!window.map.getSource('dots')) {
        map.addSource('dots', {
          type: 'geojson',
          data: dotsData,
          cluster: true,
          clusterMaxZoom: 10,
          clusterRadius: 45,
        });
      }
      // FIXME for painting used feautre-state
      // hide field used to lower opacity of other regions when 1 is selected
      // selected field used to highlight selected region

      // FIXME for manipulations via zoom used interpolate expressions
      // ref - https://docs.mapbox.com/style-spec/reference/expressions/

      if (!window.map.getLayer('dots_clusters')) {
        // FIXME Clusters layer
        map.addLayer({
          id: 'dots_clusters',
          type: 'circle',
          source: 'dots',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#fff',
            'circle-radius': 15,
            'circle-stroke-width': 5,
            'circle-stroke-color': '#553DB4',
            // 'circle-stroke-opacity': [
            // 	'interpolate', ['linear'], ['zoom'],
            // 	5, 0,
            // 	6.5, 1
            // ],
            // 'circle-opacity': [
            // 	'interpolate', ['linear'], ['zoom'],
            // 	5, 0,
            // 	6.5, 1
            // ]
            'circle-stroke-opacity': 1,
            'circle-opacity': 1,
          },
        });

        // FIXME Dots UNclustered layer
        map.addLayer({
          id: 'dots_unclustered',
          type: 'circle',
          source: 'dots',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              '#fbb03b',
              '#553DB4',
            ],
            'circle-radius': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              5,
              3,
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
            // 'circle-stroke-opacity': [
            // 	'interpolate', ['linear'], ['zoom'],
            // 	5, 0,
            // 	6.5, 1
            // ],
            // 'circle-opacity': [
            // 	'interpolate', ['linear'], ['zoom'],
            // 	5, 0,
            // 	6.5, 1
            // ]
            'circle-stroke-opacity': 1,
            'circle-opacity': 1,
          },
        });

        // FIXME Layer to show dots count in cluster
        map.addLayer({
          id: 'dots_cluster_count',
          type: 'symbol',
          source: 'dots',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-size': 12,
          },
          paint: {
            // 'text-opacity': [
            // 	'interpolate', ['linear'], ['zoom'],
            // 	5, 0,
            // 	6.5, 1
            // ]
            'text-opacity': 1,
          },
        });
      }

      // FIXME Click on CLUSTER to zoom in
      map.on('click', 'dots_clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['dots_clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        map
          .getSource('dots')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      // FIXME used to save selected	feature
      map.on('click', 'dots_unclustered', (e) => {
        if (window.map.getZoom() >= 7 && !isInnerPage) {
          e.preventDefault();
          selectDot(e.features[0].id);

          const feature = e.features[0];
          //
          // const coordinates = feature.geometry.coordinates.slice()
          // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          // 	coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          // }

          // const textHTML = `
          // 	<div>
          // 		<p>Название проекта: ${feature.properties.name}</p>
          // 		<p>Название организации: ${feature.properties.organization_name}</p>
          // 		<p>Отрасль: ${feature.properties.main_activity_name}</p>
          // 		<p>Регион: ${regions_dictionary[feature.properties.region_id]}</p>
          // 	</div>
          // `

          // new mapboxgl.Popup()
          // 	.setLngLat(coordinates)
          // 	.setHTML(textHTML)
          // 	.addTo(map);
          window.open(
            `${window.location.origin}/view-project/${feature.properties.project_id}`,
            '_blank'
          );
        }
        // //FIXME if we click on dot zoom change color
        // map.flyTo({
        // 	center: e.features[0].geometry.coordinates,
        // 	zoom: 14,
        // 	speed: 1,
        // })
        //
        // if(dot_id){
        // 	//FIXME if we click on other dot while we have active one
        // 	// clear previous dot
        // 	map.removeFeatureState({
        // 		source: 'dots',
        // 		id: dot_id
        // 	})
        // }
        //
        // dot_id = e.features[0].id
        //
        // //FIXME set selected feature
        // map.setFeatureState(
        // 	{
        // 		source: 'dots',
        // 		id: dot_id
        // 	},
        // 	{
        // 		selected: true,
        // 	}
        // )
      });

      // FIXME to Remove selected feature click on empty space
      // map.on('click', (e) => {
      // 	if(e.defaultPrevented === false){
      // 		dotsData.features.forEach(item => {
      // 			resetChosenDot()
      // 			map.removeFeatureState({
      // 				source: 'dots',
      // 				id: item.id
      // 			})
      // 		})
      // 	}
      // })

      map.on('mouseenter', 'dots_clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'dots_clusters', () => {
        map.getCanvas().style.cursor = '';
      });
      map.on('mouseenter', 'dots_unclustered', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'dots_unclustered', () => {
        map.getCanvas().style.cursor = '';
      });

      if (isInnerPage) {
        map.flyTo({
          center: dotsLayerData[0].geometry.coordinates,
          zoom: 11,
          speed: 1,
        });
      }

      console.log('isOverview', isOverview);
      if (isOverview || window.location.href.includes('organizations')) {
        changeLayerVisibility('dots_clusters', 'none');
        changeLayerVisibility('dots_unclustered', 'none');
        changeLayerVisibility('dots_cluster_count', 'none');
      }
      // else {
      // 	changeLayerVisibility('dots_clusters', 'none')
      // 	changeLayerVisibility('dots_unclustered', 'none')
      // 	changeLayerVisibility('dots_cluster_count', 'none')
      // }
    }
  };

  const selectDotOnMap = (feature) => {
    if (feature) {
      map.flyTo({
        center: feature.geometry.coordinates,
        zoom: window.map.getZoom() < 7 ? 11 : window.map.getZoom(),
        speed: 2,
      });

      dotsLayerData.map((item) => {
        if (
          Object.keys(
            map.getFeatureState({
              source: 'dots',
              id: item.id,
            })
          ).length > 0
        ) {
          map.removeFeatureState({
            source: 'dots',
            id: item.id,
          });
        }
      });

      map.setFeatureState(
        {
          source: 'dots',
          id: chosenDot,
        },
        {
          selected: true,
        }
      );
    }
  };

  const clearDotOnMap = () => {
    map.flyTo({
      center: [68.71584352632227, 48.58775813682156],
      zoom: 3.1,
      speed: 2,
    });

    dotsLayerData.map((item) => {
      map.removeFeatureState({
        source: 'dots',
        id: item.id,
      });
    });
  };

  useEffect(() => {
    if (mapLoaded) {
      setTimeout(() => {
        createLayer(false);
      }, 500);
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('dots_unclustered')) {
      if (chosenDot > 0) {
        selectDotOnMap(dotsLayerData.find((item) => item.id === chosenDot));
      } else {
        clearDotOnMap();
      }
    }
  }, [chosenDot]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('dots')) {
      createLayer(true);
    }
  }, [dotsLayerData]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('dots')) {
      createLayer(true);
    }
  }, [showProjectDots]);

  // useEffect(() => {
  // 	if(window.map.getLayer('dots_clusters')){
  // 		if(selectedDistrict > 0){
  // 			changeLayerVisibility('dots_clusters', 'visible')
  // 			changeLayerVisibility('dots_unclustered', 'visible')
  // 			changeLayerVisibility('dots_cluster_count', 'visible')
  // 		} else {
  // 			changeLayerVisibility('dots_clusters', 'none')
  // 			changeLayerVisibility('dots_unclustered', 'none')
  // 			changeLayerVisibility('dots_cluster_count', 'none')
  // 		}
  // 	}
  // }, [selectedDistrict]);

  // useEffect(() => {
  // 	if(window.map.getLayer('dots_clusters')){
  // 		if(chosenDot > 0){
  // 			changeLayerVisibility('dots_clusters', 'visible')
  // 			changeLayerVisibility('dots_unclustered', 'visible')
  // 			changeLayerVisibility('dots_cluster_count', 'visible')
  // 		} else {
  // 			changeLayerVisibility('dots_clusters', 'none')
  // 			changeLayerVisibility('dots_unclustered', 'none')
  // 			changeLayerVisibility('dots_cluster_count', 'none')
  // 		}
  // 	}
  // }, [chosenDot]);

  return null;
}

export default DotsLayer;
