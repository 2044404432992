import {sample} from "effector";
import {setCuratorsStartDataEv} from "./events.js";
import {$curatorsStartStore, $curatorsStore} from "./stores.js";
import {prepareCuratorsData} from "../../counters/curators/index.js";

sample({
	clock: setCuratorsStartDataEv,
	fn: prepareCuratorsData,
	target: [$curatorsStore, $curatorsStartStore]
})
