import { sample } from 'effector';
import { $manufacturerData, $manufacturerInfo } from './stores.js';
import {
  getManufacturerDataFx,
  getManufacturerInfoFullFx,
  getManufacturerInfoGovFx
} from './effects.js';
import { ViewManufacturerGate } from './events.js';
import {prepareInvestorInfo} from '../viewProjectModel/utils.js';

$manufacturerData
  .on(getManufacturerDataFx.doneData, (_, payload) => payload)
  .reset(ViewManufacturerGate.close);

$manufacturerInfo
  .on(getManufacturerInfoFullFx.doneData, (_, payload) => prepareInvestorInfo(payload))
  .reset(ViewManufacturerGate.close);

sample({
  clock: ViewManufacturerGate.state,
  filter: (clock) => !!clock.id,
  fn: (clock) => clock.id,
  target: getManufacturerDataFx,
});

sample({
  clock: getManufacturerDataFx.doneData,
  filter: (clock) => !!clock.gov_id,
  fn: (clock) => clock.gov_id,
  target: getManufacturerInfoGovFx,
});

sample({
  clock: getManufacturerInfoGovFx.doneData,
  filter: (clock) => !!clock.id,
  fn: (clock) => clock.id,
  target: getManufacturerInfoFullFx,
})
