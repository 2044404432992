import {$investmentsFilteredStore, $investmentsStartStore, $investmentsStore} from "./stores.js";
import {resetInvestmentsFilteredStoreEv, setInvestmentsStartDataEv} from "./events.js";
import {sample} from "effector";
import {prepareFilteredInvestmentsData, prepareInvestmentsData} from "../../counters/investments/index.js";
import {$activeFilters, resetActiveFiltersEv} from "../activeFiltersModel/index.js";
import {hasActiveFilters, onlyRegionFilter} from "../../utils/active-filters-utils.js";
import {$globalStore, $projectsStore} from "../globalModel/index.js";

$investmentsFilteredStore.reset(resetInvestmentsFilteredStoreEv)

sample({
	clock: setInvestmentsStartDataEv,
	fn: prepareInvestmentsData,
	target: [$investmentsStore, $investmentsStartStore]
})

sample({
	source: [$globalStore, $investmentsStartStore, $activeFilters],
	clock: $projectsStore.updates,
	filter: ([global, startData, activeFilters], clock) => {
		return Object.values(startData).every(item => {
			if(Array.isArray(item)){
				return item.length > 0
			} else {
				return item > 0
			}
		})
	},
	fn: ([global, startData, activeFilters], clock) => {
		if(clock.length === global.projects.length){
			return startData
		} else {
			return prepareFilteredInvestmentsData(clock, global, activeFilters)
		}
	},
	target: $investmentsStore
})

// sample({
// 	source: [$investmentsStartStore, $activeFilters],
// 	clock: $investmentsFilteredStore.updates,
// 	filter: ([startStore, activeFilters], clock) => onlyRegionFilter(activeFilters),
// 	fn: ([startStore, activeFilters], clock) => {
// 		return {
// 			...clock,
// 			region_widget_data: startStore.region_widget_data
// 		}
// 	},
// 	target: $investmentsStore,
// })

// sample({
// 	source: [$investmentsStartStore, $activeFilters],
// 	clock: $investmentsFilteredStore.updates,
// 	filter: ([startStore, activeFilters], clock) => !onlyRegionFilter(activeFilters),
// 	fn: ([startStore, activeFilters], clock) => clock,
// 	target: $investmentsStore,
// })

sample({
	clock: $investmentsFilteredStore.updates,
	target: $investmentsStore
})

sample({
	source: $investmentsStartStore,
	clock: resetInvestmentsFilteredStoreEv,
	fn: (source) => source,
	target: $investmentsStore
})

sample({
	source: $investmentsStartStore,
	clock: resetActiveFiltersEv,
	fn: (source, clock) => source,
	target: [$investmentsStore, resetInvestmentsFilteredStoreEv]
})
