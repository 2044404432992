import {Select, TreeSelect} from "antd";
import './SearchSelect.css'

function SearchSelect({title, data, field, onChange, single = false, tree}) {

	const filterOption = (input, option) =>{
		let opt = option?.label
		let inp = input
		if(typeof option?.label !== 'string'){
			opt = opt.toString()
			inp = inp.toString()
		}
		return (opt ?? '').toLowerCase().includes(inp.toLowerCase());
	}

	return (
		<div className={'search-select'}>
			<span>{title}</span>
			{tree
				? <TreeSelect
					treeData={data}
					treeNodeFilterProp={'title'}
					showSearch={true}
					allowClear={true}
					multiple={true}
					onChange={(value) => onChange({field, value})}
				/>
				: <Select allowClear
					mode={single ? '' : 'multiple'}
					showSearch
					options={data}
					filterOption={filterOption}
					onChange={(value) => onChange({field, value})}
			/>}
		</div>
	);
}

export default SearchSelect;
