import { useEffect } from 'react';
import { useEvent, useStore, useStoreMap } from 'effector-react';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Skeleton,
  Table,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import locale from 'antd/locale/ru_RU';
import { InfoOutlined } from '@ant-design/icons';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import { resetActiveFiltersEv } from '../../models/activeFiltersModel/index.js';
import MapWork from '../MapWork/MapWork.jsx';
import {
  $activitiesSelect,
  $filters,
  $orgsTable,
  $tableControl,
  $regionsTree,
  changeFiltersEv,
  changeTableStateEv,
  getOrganizationsDataFx,
  getOrganizationsTableFx,
  OrgsDashboardGate,
} from '../../models/organizationsModel/index.js';
import styles from './OrganizationsDashboard.module.css';
import FiltersBlock from './FiltersBlock.jsx';
import TableFilter from './TableFilter.jsx';
import ReportsModal from './ReportsModal.jsx';

function OrganizationsDashboard() {
  const { tableData, count } = useStoreMap($orgsTable, ({ data, count }) => ({
    tableData: data,
    count,
  }));

  const tableLoading = useStore(getOrganizationsTableFx.pending);
  const dataLoading = useStore(getOrganizationsDataFx.pending);

  const filters = useStore($filters);
  const pagination = useStore($tableControl);
  const changeFilters = useEvent(changeFiltersEv);
  const changeTable = useEvent(changeTableStateEv);

  const onSelectOked = (node) => {
    if (node) {
      if (Object.keys(filters).some((field) => field.includes('oked_level'))) {
        for (const [key, value] of Object.entries(filters)) {
          if (key.includes('oked_level') && value !== '') {
            changeFilters({ [key]: null });
          }
        }
      }
      changeFilters({ [`oked_level_${node.level}`]: node.value });
    }
  };

  const onSelectRegion = (node) => {
    if (node) {
      const [type, value] = node.key.split('##');
      if (type === 'district') {
        changeFilters([{ region: node.region }, { [type]: value }]);
      } else if (type === 'region') {
        changeFilters([{ district: null }, { [type]: value }]);
      }
    }
  };

  const activitiesTree = useStore($activitiesSelect);
  const regionsTree = useStore($regionsTree);

  const resetActiveFilters = useEvent(resetActiveFiltersEv);

  useEffect(() => {
    setTimeout(() => resetActiveFilters(), 100);
  }, []);

  const columns = [
    {
      title: 'БИН',
      dataIndex: 'gov_id',
      key: 'bin',
      width: '10%',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      // ellipsis: true,
      render: (name) => (
        <Tooltip title={name} placement="topLeft">
          {name}
        </Tooltip>
      ),
      width: '28%',
    },
    {
      title: 'ОКЭД',
      dataIndex: 'oked_level_4',
      key: 'oked',
      ellipsis: true,
      render: (name) => (
        <Tooltip title={name} placement="topLeft">
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Область',
      dataIndex: 'region',
      key: 'region',
      ellipsis: true,
      render: (name) => (
        <Tooltip title={name} placement="topLeft">
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Район',
      dataIndex: 'district',
      key: 'district',
      ellipsis: true,
      render: (name) => (
        <Tooltip title={name} placement="topLeft">
          {name}
        </Tooltip>
      ),
    },
    // {
    //   title: 'Департамент',
    //   dataIndex: 'department',
    //   key: 'department',
    //   ellipsis: true,
    //   render: (name) => <Tooltip title={name} placement={'topLeft'}>{name}</Tooltip>,
    // },
    {
      title: 'Налоги',
      dataIndex: 'tax',
      key: 'tax',
      sorter: true,
      width: '11%',
      render: (value) =>
        value ? new Intl.NumberFormat('ru-RU').format(parseInt(value)) : '-',
    },
    {
      title: 'ЕНПФ',
      dataIndex: 'enpf',
      key: 'enpf',
      sorter: true,
      render: (value) =>
        value ? new Intl.NumberFormat('ru-RU').format(parseInt(value)) : '-',
    },
    {
      title: 'Кол-во сотрудников',
      dataIndex: 'emp',
      key: 'emp',
      sorter: true,
      render: (value) =>
        value ? new Intl.NumberFormat('ru-RU').format(parseInt(value)) : '-',
    },
    // {
    //   title: 'Итого',
    //   dataIndex: 'total',
    //   key: 'total',
    // },
    {
      title: 'ДМС',
      dataIndex: 'dms',
      width: '4%',
      key: 'dms',
      render: (value) =>
        value ? new Intl.NumberFormat('ru-RU').format(parseInt(value)) : '-',
    },
    {
      key: 'actions',
      width: '4%',
      align: 'center',
      render: (record) => (
        <Tooltip title="Просмотр предприятия">
          <Button
            type="primary"
            icon={<InfoOutlined />}
            href={`/view-organization/${record.gov_id}`}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <ContainerInner showTitle={false}>
      <ReportsModal orgs />
      <OrgsDashboardGate />
      <FiltersBlock />
      <Row justify="space-between" style={{ height: '40vh' }}>
        {/* <Col */}
        {/*  style={{ */}
        {/*    width: '62%', */}
        {/*    height: '55vh', */}
        {/*    boxShadow: '5px 5px 30px rgba(0,0,0,.1)', */}
        {/*  }} */}
        {/*  className="db-col-map" */}
        {/* > */}
        {/*  <MapWork /> */}
        {/* </Col> */}
        <Col
          style={{ width: '100%', boxShadow: '5px 5px 30px rgba(0,0,0,.1)' }}
          className="db-col1-top"
        >
          <div className={styles.treeCardContainer}>
            <Skeleton
              loading={activitiesTree.length === 0 || dataLoading}
              style={{ height: '50%' }}
            >
              <div style={{ width: '48%' }}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Кол-во предприятий по ОКЭД
                </Typography.Title>
                <Tree
                  treeData={activitiesTree}
                  className={styles.tree}
                  onSelect={(_, { selectedNodes }) =>
                    onSelectOked(selectedNodes[0])
                  }
                />
              </div>
            </Skeleton>
            <Skeleton loading={regionsTree.length === 0 || dataLoading}>
              <div style={{ width: '48%' }}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Кол-во предприятий по регионам
                </Typography.Title>
                <Tree
                  treeData={regionsTree}
                  className={styles.tree}
                  onSelect={(_, { selectedNodes }) =>
                    onSelectRegion(selectedNodes[0])
                  }
                />
              </div>
            </Skeleton>
          </div>
        </Col>
      </Row>

      <Col
        style={{ height: '65vh', maxHeight: '65vh' }}
        className="db-row-bottom"
      >
        <TableFilter />
        <ConfigProvider locale={locale}>
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={tableData}
            size="small"
            onChange={(pagin, filters, sorter) => {
              if (
                !!sorter?.order &&
                !!sorter?.field &&
                (sorter?.order !== pagination?.sort_order ||
                  sorter?.field !== pagination?.sort_field)
              ) {
                changeTable({
                  sort_order: sortOrderDict[sorter.order],
                  sort_field: sortFieldDict[sorter.field],
                });
              } else if (
                !sorter?.order &&
                !sorter?.column &&
                pagination.sort_order &&
                pagination.sort_field
              ) {
                changeTable({
                  sort_order: null,
                  sort_field: null,
                });
              }
            }}
            pagination={{
              total: count,
              onChange: (page, limit) => changeTable({ page, limit }),
              position: ['bottomCenter'],
              current: pagination?.page ?? 1,
              size: pagination?.limit ?? 100,
              defaultPageSize: 100,
              pageSizeOptions: [20, 50, 100],
            }}
            style={{ width: '100%' }}
          />
        </ConfigProvider>
      </Col>
    </ContainerInner>
  );
}
export default OrganizationsDashboard;

const sortFieldDict = {
  tax: 'amount_avg',
  enpf: 'enpf_sum_avg',
  emp: 'count_people_avg',
};

const sortOrderDict = {
  ascend: 'asc',
  descend: 'desc',
};
