function convertToTree(roots) {
    let treeData = [];

    for (const node of Object.values(roots)) {
        treeData.push({
            title: node.name_ru,
            value: node.id,
            children: convertToTree(node.children),
        });
    }

    return treeData;
}

export const prepareActivitiesSelect = (activities) => {
    const roots = []
    const map = {}

    for (const [idx, node] of Object.entries(activities)) {
        node.children = [];
        map[node.id] = idx;
        if (node.root_id === -1) {
            roots.push(node);
        } else {
            if (activities[map[node.root_id]] && Object.hasOwn(activities[map[node.root_id]], 'children')) {
                activities[map[node.root_id]].children.push(node)
            } else {
                if (activities[map[node.root_id]]) {
                    activities[map[node.root_id]]['children'] = [node];
                }
            }
        }
    }

    return convertToTree(roots)
}