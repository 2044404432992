import {createEffect} from "effector";
import {api} from "../../api/axiosInstance.js";

export const getForeignInvestmentsListFx = createEffect()
    .use(async () => (await api().get('/investor-form/')).data)

export const getForeignInvestmentsListEditorFx = createEffect()
    .use(async () => (await api().get('/investor-form/all')).data)

export const getForeignInvestByIdFx = createEffect()
    .use(async (id) => (await api().get(`/investor-form/${id}`)).data)

export const createForInvestFx = createEffect()
    .use(async (payload) => {
            const form = new FormData()
            for (const [key, value] of Object.entries(payload)) {
                    if (key === 'file' && value) {
                            form.append(key, value.file)
                    } else if (!value) {
                            form.append(key, '')
                    } else {
                            form.append(key, value)
                    }
            }

            return (await api().post('/investor-form/', form, {headers: {"Content-Type": "multipart/form-data"}})).data
    })

export const updateForInvestFx = createEffect()
    .use(async ({id, payload}) => {
            const form = new FormData()
            for (const [key, value] of Object.entries(payload)) {
                    if (key === 'file' && value) {
                            form.append(key, value.file)
                    } else if (!value) {
                            form.append(key, '')
                    } else {
                            form.append(key, value)
                    }
            }

            return (await api().put(`/investor-form/${id}`, form, {headers: {"Content-Type": "multipart/form-data"}})).data
    })

export const verifyForProjFx = createEffect()
    .use(async (id) => (await api().post(`/investor-form/verify/${id}`)).data)

export const downloadForProjFileFx = createEffect()
    .use(async (id) => {
        const file = await api().get(`/investor-form/download/${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })