import {Row} from "antd";
import {useCallback, useMemo} from "react";
import './StatsBlock.css'

const StatsBlock = ({data}) => {

    const formatValue = useCallback((v) => {
        return new Intl.NumberFormat('ru-RU', {notation: 'compact'}).format(v)
    }, [])

    const elements = useMemo(() => {
        return data.map((i) => <div className={'stats-item'}>
                <span style={{fontSize: '1.6rem'}}><strong>{formatValue(i.value)}</strong></span>
                <span>{i.label}</span>
            </div>
        )
    }, [data, formatValue])

    return <Row justify={'space-between'} className={'stats-container'}>
        {...elements}
    </Row>
}

export default StatsBlock