import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Descriptions from 'antd/es/descriptions';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { Tag } from 'antd';
import message from 'antd/es/message';
import {
  $editProfileStatus,
  editProfileEv,
  editProfileFx,
  resetEditProfileStatusEv,
} from '../../../models/authModel/index.js';
import {
  $regionsMap,
  $regionsSelect,
} from '../../../models/globalModel/index.js';
import {
  $createEditProjectUtils,
  $dictionariesMaps,
} from '../../../models/dictionariesModel/index.js';

export default function InvProfile({ curUsr }) {
  const { t } = useTranslation();
  const origin = curUsr.country_id ? 'foreign' : 'local';
  const [edit, setEdit] = useState(false);
  const editStatus = useUnit($editProfileStatus);
  const resetStatus = useUnit(resetEditProfileStatusEv);

  useEffect(() => {
    if (editStatus) {
      setEdit(false);
      resetStatus();
    }
  }, [editStatus]);

  return (
    <Card
      title={t('Данные профиля')}
      extra={
        curUsr.role !== 'fundraiser' && (
          <Button type="primary" onClick={() => setEdit(!edit)}>
            {edit ? t('Отмена') : t('Редактировать')}
          </Button>
        )
      }
    >
      {edit ? (
        <EditItems curUsr={curUsr} origin={origin} edit={edit} t={t} />
      ) : (
        <ViewItems curUsr={curUsr} origin={origin} t={t} />
      )}
    </Card>
  );
}

function ViewItems({ curUsr, origin, t }) {
  const { invActMap } = useUnit($dictionariesMaps);
  const regionsMap = useUnit($regionsMap);

  const items = [
    {
      key: 'category',
      label: t('Категория пользователя'),
      children:
        curUsr.role === 'fundraiser'
          ? t('Ищу инвестиции')
          : t('Хочу инвестировать'),
    },
    { key: 'role', label: t('Роль'), children: curUsr.role },
    // {key: 'access', label: t('Доступ'), children: curUsr.access},
  ];

  if (curUsr.role === 'potential_investor' && origin === 'local') {
    items.push(
      { key: 'gov_id', label: t('БИН'), children: curUsr.gov_id },
      {
        key: 'organization',
        label: t('Организация'),
        children: curUsr.organization,
      },
      {
        key: 'region_ids',
        label: t('Интересующие регионы'),
        children: (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              rowGap: 8,
            }}
          >
            {curUsr?.region_ids?.map((i) => (
              <Tag key={i}>{t(`regions.${regionsMap[i]}`)}</Tag>
            ))}
          </div>
        ),
      },
      {
        key: 'oked_ids',
        label: t('Интересующие отрасли'),
        children: (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              rowGap: 8,
            }}
          >
            {curUsr.oked_ids?.map((i) => (
              <Tag key={i}>{t(`industries.${invActMap[i]}`)}</Tag>
            ))}
          </div>
        ),
      },
      {
        key: 'investment_amount',
        label: t('Сумма инвестиций'),
        children: curUsr.investment_amount,
      }
    );
  } else if (curUsr.role === 'potential_investor' && origin === 'foreign') {
    items.push(
      { key: 'gov_id', label: t('Наименование'), children: curUsr.gov_id },
      { key: 'country_id', label: t('Страна'), children: curUsr.country_id },
      {
        key: 'investment_amount',
        label: t('Сумма инвестиций'),
        children: curUsr.investment_amount,
      },
      {
        key: 'region_ids',
        label: t('Интересующие регионы'),
        children: (
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            {curUsr.region_ids?.map((i) => (
              <Tag key={i}>{t(`regions.${regionsMap[i]}`)}</Tag>
            ))}
          </div>
        ),
      },
      {
        key: 'oked_ids',
        label: t('Интересующие отрасли'),
        children: (
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            {curUsr.oked_ids?.map((i) => (
              <Tag key={i}>{t(`industries.${invActMap[i]}`)}</Tag>
            ))}
          </div>
        ),
      }
    );
  } else if (curUsr.role === 'fundraiser') {
    items.push(
      { key: 'gov_id', label: t('БИН'), children: curUsr.gov_id },
      {
        key: 'organization',
        label: t('Организация'),
        children: curUsr.organization,
      }
    );
  }

  return (
    <Descriptions
      items={items}
      column={1}
      bordered
      labelStyle={{ width: '30%' }}
    />
  );
}

function EditItems({ curUsr, origin, edit, t }) {
  const [invProfForm] = Form.useForm();
  const loading = useUnit(editProfileFx.pending);

  const regions = useUnit($regionsSelect);
  const regionsSelect = regions?.map((i) => ({
    label: t(`regions.${i.label}`),
    value: i.value,
  }));
  const { investActivityOptions } = useUnit($createEditProjectUtils);

  const onSubmit = useUnit(editProfileEv);

  useEffect(() => {
    if (curUsr && edit) {
      invProfForm.setFieldsValue(curUsr);
    }
  }, [curUsr, edit]);

  useEffect(() => {
    if (!edit) {
      invProfForm.resetFields();
    }
  }, [edit]);

  const onFinish = (values) => {
    const payload = {};
    for (const [key, value] of Object.entries(values)) {
      if (Array.isArray(value)) {
        if (JSON.stringify(value) !== JSON.stringify(curUsr[key])) {
          payload[key] = value;
        }
      } else if (value !== curUsr[key]) {
        payload[key] = value;
      }
    }
    if (Object.keys(payload).length === 0) {
      return message.error(t('Изменения отсутствуют'));
    }
    onSubmit(payload);
  };

  return (
    <Form form={invProfForm} onFinish={onFinish} layout="vertical">
      {origin === 'foreign' && (
        <Form.Item label={t('Наименование компании')} name="gov_id">
          <Input />
        </Form.Item>
      )}
      <Form.Item label={t('Регион')} name="region_ids">
        <Select options={regionsSelect} mode="multiple" />
      </Form.Item>
      <Form.Item label={t('Отрасль')} name="oked_ids">
        <Select options={investActivityOptions} mode="multiple" />
      </Form.Item>
      <Form.Item label={t('Сумма инвестиций')} name="investment_amount">
        <InputNumber min={0} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item>
        <Popconfirm
          title={t('Вы уверены, что хотите изменить личные данные?')}
          onConfirm={() => invProfForm.submit()}
          okText={t('Да')}
          cancelText={t('Нет')}
        >
          <Button type="primary" loading={loading}>
            {t('Подтвердить')}
          </Button>
        </Popconfirm>
      </Form.Item>
    </Form>
  );
}
