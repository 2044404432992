import { sample } from 'effector';
import {
  $chosenDot,
  $dotsLayerData,
  $dotsLayerFilteredData,
  $dotsLayerStartData,
} from './stores.js';
import {
  resetChosenDotEv,
  resetDotsLayerFilteredDataEv,
  selectDotEv,
  updateDotsEv,
} from './events.js';
import {
  filterDots,
  prepareDotsFeatures,
} from '../../counters/dotsLayer/index.js';
import { $projectStore } from '../viewProjectModel/index.js';
import { $projectsStore, $globalStore } from '../globalModel/index.js';
import { $organizationStore } from '../viewSubjectModel/index.js';
import { $selectedDistrict } from '../districtsModel/index.js';
import { $selectedRegion } from '../regionsLayerModel/index.js';
import {
  $activeFilters,
  resetActiveFiltersEv,
} from '../activeFiltersModel/index.js';
import { hasActiveFilters, hasBin } from '../../utils/active-filters-utils.js';

$chosenDot.reset(resetChosenDotEv);
$dotsLayerFilteredData.reset(resetDotsLayerFilteredDataEv);

sample({
  source: $chosenDot,
  clock: selectDotEv,
  fn: (source, clock) => {
    if (source !== clock) {
      return clock;
    }
    return 0;
  },
  target: $chosenDot,
});

sample({
  clock: updateDotsEv,
  filter: () => !window.location.href.includes('view-project'),
  fn: prepareDotsFeatures,
  target: [$dotsLayerData, $dotsLayerStartData],
});

sample({
  clock: $projectStore.updates,
  fn: (clock) => {
    return [
      {
        type: 'Feature',
        geometry: {
          ...clock.location,
        },
      },
    ];
  },
  target: $dotsLayerData,
});

sample({
  clock: $organizationStore.updates,
  fn: (clock) => clock.dots,
  target: $dotsLayerData,
});

// sample({
// 	source: [$activeFilters, $globalStore],
// 	clock: $projectsStore.updates,
// 	filter: ([activeFilters, rawData], clock) => hasActiveFilters(activeFilters),
// 	fn: ([activeFilters, rawData], clock) => filterDots(clock, rawData),
// 	target: $dotsLayerFilteredData
// })
//
// sample({
// 	source: $globalStore,
// 	clock: $projectsStore.updates,
// 	filter: (source, clock) => source.projects.length === clock.length,
// 	target: resetDotsLayerFilteredDataEv
// })

sample({
  source: [$globalStore, $dotsLayerStartData],
  clock: $projectsStore.updates,
  filter: ([global, startData], clock) => startData.length > 0,
  fn: ([global, startData], clock) => {
    if (clock.length === global.projects.length) {
      return startData;
    }
    return filterDots(clock, global);
  },
  target: $dotsLayerData,
});

sample({
  clock: $dotsLayerFilteredData.updates,
  target: $dotsLayerData,
});

sample({
  source: $dotsLayerStartData,
  clock: resetDotsLayerFilteredDataEv,
  target: $dotsLayerData,
});

sample({
  source: $activeFilters,
  clock: $dotsLayerData.updates,
  fn: (source, clock) => {
    if (hasBin(source) && clock.length === 1) {
      return clock[0].id;
    }
    return 0;
  },
  target: $chosenDot,
});

sample({
  clock: resetActiveFiltersEv,
  fn: () => 0,
  target: $chosenDot,
});
