import {Divider, Form, Input} from "antd";

const ContactsForm = () => <>
    <Form.Item name={'contacts'}>
    <Divider orientationMargin={0} orientation={'left'}>Контактные данные ответственного за предоставление информации</Divider>
    <Form.Item name={['contacts', 'responsible', 'full_name']} label={'ФИО'}>
        <Input/>
    </Form.Item>
    <Form.Item name={['contacts', 'responsible', 'title']} label={'Должность'}>
        <Input/>
    </Form.Item>
    <Form.Item name={['contacts', 'responsible', 'phone']} label={'Телефон'}>
        <Input/>
    </Form.Item>
    <Form.Item name={['contacts', 'responsible', 'email']} label={'Электронная почта'} rules={[
        {type: 'email', message: 'Укажите действительный email'}
    ]}>
        <Input/>
    </Form.Item>
    <Divider orientationMargin={0} orientation={'left'}>Контактные данные руководителя</Divider>
    <Form.Item name={['contacts', 'head', 'full_name']} label={'ФИО'}>
        <Input/>
    </Form.Item>
    <Form.Item name={['contacts', 'head', 'phone']} label={'Телефон'}>
        <Input/>
    </Form.Item>
    <Form.Item name={['contacts', 'head', 'email']} label={'Электронная почта'} rules={[
        {type: 'email', message: 'Укажите действительный email'}
    ]}>
        <Input/>
    </Form.Item>
    </Form.Item>
</>

export default ContactsForm