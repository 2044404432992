import { createGate } from 'effector-react';
import { createEvent } from 'effector';

export const OkedsGate = createGate();
export const EditOkedGate = createGate();

export const setOkedsModalEv = createEvent();

export const resetOkedsModalEv = createEvent();

export const createOkedEv = createEvent();
export const editOkedEv = createEvent();
export const deleteOkedEv = createEvent();