import {createStore} from "effector";
import countries from '../../data/countries.json'
import activities from '../../data/activity.json'
import {prepareActivitiesSelect} from "./utils.js";

export const countriesSelect = Object.values(countries).map(i => ({label: i, value: i}))
export const activitiesSelect = prepareActivitiesSelect(activities)
export const activitiesDict = Object.fromEntries(activities.map(i => [i.id, i.name_ru]))

export const $foreignProjectsList = createStore([])

export const $selectedForeignProject = createStore(null)

export const $forProjModalState = createStore({visible: false, mode: undefined, id: null, submitStatus: null})