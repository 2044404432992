import { message } from 'antd';
import {
  districtIdFilter,
  okedFilter,
  problemProjectsFilter,
  projectTypeFilter,
  regionGradientFilter,
  regionIdFilter,
  searchOrgBinFilter,
  searchSelectActivityFilter,
  searchSelectNameFilter,
  searchSelectOrgFilter,
} from './filters.js';

export const filterProjects = (activeFilters, rawData) => {
  const filteredProjects = rawData.projects.filter((item) => {
    if (!regionIdFilter(activeFilters, item)) return false;
    if (!districtIdFilter(activeFilters, item)) return false;
    if (!searchSelectActivityFilter(activeFilters, item)) return false;
    if (!searchSelectOrgFilter(activeFilters, item)) return false;
    if (!searchOrgBinFilter(activeFilters, item)) return false;
    if (!searchSelectNameFilter(activeFilters, item)) return false;
    if (!regionGradientFilter(activeFilters, item)) return false;
    if (!problemProjectsFilter(activeFilters, item)) return false;
    if (!okedFilter(activeFilters, item)) return false;
    if (!projectTypeFilter(activeFilters, item)) return false;
    return true;
  });
  // if (filteredProjects.length === 0) {
  //   message.error('Проектов по данным критериям не найдено').then(() => true);
  // }

  console.log('filteredProjects', filteredProjects);

  return filteredProjects;
};

export const filterSelectOptions = (
  activeFilters,
  filteredProjects,
  rawData
) => {
  const filteredProjectsActivities = filteredProjects.map(
    (i) => i.main_activity_id
  );
  const filteredProjectsOrganizations = filteredProjects.map(
    (i) => i.organization_id
  );

  const activities = showRawSelect(activeFilters, 'main_activity_id')
    ? rawData.activities
    : rawData.activities.filter((i) =>
        filteredProjectsActivities.includes(i.id)
      );

  const organizations = showRawSelect(activeFilters, 'organization_id')
    ? rawData.organizations
    : rawData.organizations.filter((i) =>
        filteredProjectsOrganizations.includes(i.id)
      );

  return { activities, organizations };
};

function showRawSelect(activeFilters, filterName) {
  let otherFilters = 0;
  for (const [key, value] of Object.entries(activeFilters)) {
    if (
      key !== filterName &&
      ((typeof value === 'number' && value > 0) ||
        (Array.isArray(value) && value.length > 0))
    ) {
      otherFilters += 1;
    }
  }

  return otherFilters === 0;
}
