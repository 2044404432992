import {combine, createStore} from 'effector';
import {$dictionariesStore} from '../dictionariesModel/index.js';
import {prepareMGPMaps, prepareMGPSelects} from './utils.js';

export const $mgpsData = createStore([]);

export const $mgpPageList = createStore([]);
export const $mgpTypeFilter = createStore(null);
export const $mgpPageFiltered = combine(
  $mgpPageList,
  $mgpTypeFilter,
  (list, type) => {
    if (!type) {
      return list;
    }
    return list.filter(mgp => mgp.type_id === type)
  }
);
export const $mgpTypesCount = combine($mgpPageList, list => ({
    fin: list.filter(mgp => mgp.type_id === 1).length,
    non_fin: list.filter(mgp => mgp.type_id === 2).length,
    all: list.length
}))

export const $mgpListFilters = createStore({
    activity_ids: [],
    region_ids: [],
    ksp_ids: [],
    gov_id: null
})

export const $mgpsModalState = createStore({open: false, type: null, id: null});
export const $createEditStatus = createStore(false);

export const $selectedMgp = createStore(null);

export const $mgpSelects = combine($dictionariesStore, dicts => prepareMGPSelects(dicts))

export const $mgpMaps = combine($dictionariesStore, dicts => prepareMGPMaps(dicts))