import { sample } from 'effector';
import i18 from '../../i18n';
import notification from 'antd/es/notification';
import {
  $authStatus, $editProfileStatus, $language, $regRequestSuccess,
  $regSuccess,
  $signedCert,
  $userEditPerms,
  $userInfo, $verificationStatus,
} from './stores.js';
import {
  authorizeDSFx, changePasswordFx,
  completeRegFx, editProfileFx,
  getCurrentUserFx, getRegInfoFx,
  loginFx,
  logoutFx,
  submitInvestorRegFx, verifyEmailFx,
} from './effects.js';
import {
  authorizeDSEv, changeLanguageEv, changePasswordEv,
  completeRegEv, editProfileEv,
  loginEv,
  logoutEv,
  RegisterGate, RegRequestGate, resetEditProfileStatusEv,
  setAuthStatusEv,
  submitInvestorRegEv, VerifyGate,
} from './events.js';
import { cookies } from '../../api/axiosInstance.js';
import { prepareEditPerms } from '../../counters/auth/index.js';

$authStatus
  .on(loginFx.doneData, () => true)
  .on(setAuthStatusEv, (state, payload) => payload);

$signedCert
  .on(authorizeDSFx.doneData, (_, payload) =>
    payload.replace('/[\\n\\r]/gm', '').split('-----')[2].trim()
  )
  .reset(RegisterGate.close);

$regSuccess.on(completeRegFx.doneData, () => true).reset(RegisterGate.close);

$regRequestSuccess.on(submitInvestorRegFx.doneData, () => true)
  .reset(RegRequestGate.close);

$verificationStatus.on(verifyEmailFx.doneData, () => true)
  .reset(VerifyGate.close);

$editProfileStatus.on(editProfileFx.doneData, () => true)
  .reset(resetEditProfileStatusEv);

authorizeDSFx.doneData.watch(
  (ev) =>
    ev &&
    notification.success({
      message: 'Успешно',
      description: 'Авторизация ЭЦП завершена',
    })
);

completeRegFx.doneData.watch(
  (ev) =>
    ev &&
    notification.success({
      message: 'Успешно',
      description:
        'Регистрация завершена. Авторизуйтесь с помощью вашего БИН и пароля, указанного при регистрации.',
      duration: 5,
    })
);

sample({
  clock: loginEv,
  target: loginFx,
});

loginFx.doneData.watch((data) =>
  cookies.set('access_token', data.access_token)
);

sample({
  clock: logoutEv,
  target: logoutFx,
});

sample({
  clock: logoutFx.doneData,
  fn: () => {
    cookies.remove('access_token');
    window.location.href = '/login';
    return false;
  },
  target: setAuthStatusEv,
});

sample({
  clock: authorizeDSEv,
  target: authorizeDSFx,
});

sample({
  clock: completeRegEv,
  target: completeRegFx,
});

sample({
  clock: getCurrentUserFx.doneData,
  target: $userInfo,
});

sample({
  clock: getCurrentUserFx.doneData,
  fn: (clock) => prepareEditPerms(clock.role),
  target: $userEditPerms,
});

sample({
  clock: RegRequestGate.open,
  target: getRegInfoFx,
});

sample({
  clock: submitInvestorRegEv,
  target: submitInvestorRegFx,
});

sample({
  clock: changeLanguageEv,
  fn: (lang) => {
    localStorage.setItem('lang', lang);
    i18.changeLanguage(lang).then(() => true);
    return lang;
  },
  target: $language,
})

sample({
  clock: VerifyGate.state.updates,
  filter: (gate) => !!gate.token,
  fn: (gate) => gate.token,
  target: verifyEmailFx
})

sample({
  clock: changePasswordEv,
  target: changePasswordFx,
});

sample({
  clock: editProfileEv,
  target: editProfileFx,
});

sample({
  clock: [changePasswordFx.doneData, editProfileFx.doneData],
  target: getCurrentUserFx,
});

changePasswordFx.doneData.watch((ev) => ev && notification.success({message: 'Успешно!', description: 'Пароль изменен'}))

editProfileFx.doneData.watch((ev) => ev && notification.success({message: 'Успешно!', description: 'Личные данные обновлены'}))