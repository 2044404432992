import { sample } from 'effector';
import {
  $createEditStatus,
  $mgpListFilters,
  $mgpPageList,
  $mgpsData,
  $mgpsModalState, $mgpTypeFilter,
  $selectedMgp
} from './stores';
import {
  applyMgpFiltersEv,
  createMGPEv,
  deleteMGPEv,
  editMGPEv,
  EditMGPGate,
  MGPsGate, MGPsListGate, resetMgpFiltersEv,
  resetMGPsModalEv,
  setMGPsModalEv, setMgpTypeFilterEv,
} from './events';
import {
  createMGPFx,
  deleteMGPFx,
  editMGPFx,
  getMGPByIdFx, getMGPPageListFx,
  getMGPsListFx,
} from './effects';
// import {$activitiesMap, $regionsMap} from '../globalModel/index.js';

$mgpsData
  .on(getMGPsListFx.doneData, (state, payload) => payload)
  .reset(MGPsGate.close);

$selectedMgp
  .on(getMGPByIdFx.doneData, (state, payload) => payload)
  .reset(resetMGPsModalEv);

$mgpsModalState
  .on(setMGPsModalEv, (state, payload) => ({ ...state, ...payload }))
  .reset(resetMGPsModalEv);

$createEditStatus.on(createMGPFx.doneData, () => true)
  .on(editMGPFx.doneData, () => true)
  .reset(resetMGPsModalEv);

$mgpPageList.on(getMGPPageListFx.doneData, (state, payload) => payload)
  .reset(MGPsListGate.close);

$mgpListFilters.on(applyMgpFiltersEv, (state, payload) => ({...state, ...payload}))
  .reset(resetMgpFiltersEv);

$mgpTypeFilter.on(setMgpTypeFilterEv, (state, payload) => payload)
    .reset(MGPsListGate.close);

sample({
  clock: [
    MGPsGate.open,
    createMGPFx.doneData,
    editMGPFx.doneData,
    deleteMGPFx.doneData,
  ],
  target: getMGPsListFx,
});

sample({
  clock: EditMGPGate.state,
  filter: ({ id }) => !!id,
  fn: ({ id }) => id,
  target: getMGPByIdFx,
});

sample({
  // source: {activities: $activitiesMap, regions: $regionsMap},
  clock: createMGPEv,
  // fn: ({activities, regions, districts}, values) => ({
  //   ...values,
  //   activity: activities[values.activity_id],
  //   region: regions[values.region_id]
  // }),
  target: createMGPFx,
});

sample({
  // source: {activities: $activitiesMap, regions: $regionsMap},
  clock: editMGPEv,
  // fn: ({activities, regions, districts}, values) => ({
  //   ...values,
  //   activity: activities[values.activity_id],
  //   region: regions[values.region_id],
  // }),
  target: editMGPFx,
});

sample({
  clock: deleteMGPEv,
  target: deleteMGPFx,
});

sample({
  clock: MGPsListGate.open,
  target: getMGPPageListFx,
});

sample({
  clock: $mgpListFilters.updates,
  fn: (filters) => {
    const checked = {...filters}
    if (checked.gov_id && checked.gov_id.length > 0) {
      return {gov_id: checked.gov_id}
    } else {
      for (const [key, value] of Object.entries(checked)) {
        if (!value || value.length === 0) {
          delete checked[key];
        }
      }
      return checked;
    }
  },
  target: getMGPPageListFx,
});
