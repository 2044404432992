import { Col, Form, Input, Row, Select } from 'antd';
import { useUnit } from 'effector-react';
import { $mgpSelects } from '../../../models/mgpModel/index.js';
import filterOption from '../../../utils/filterOption.js';

export default function NonFinForm() {
  const { operatorsSelect } = useUnit($mgpSelects);

  return (
    <>
      <Row justify="space-between">
        <Col span={7}>
          <Form.Item
            name="service"
            label="Услуга"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="sub_service"
            label="Подуслуга"
            // rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="operator"
            label="Оператор"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Select
              options={operatorsSelect}
              filterOption={filterOption}
              allowClear
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={7}>
          <Form.Item
            name="service_cost"
            label="Стоимость"
            // rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="service_deadlines"
            label="Сроки оказания услуги"
            rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="service_limit"
            label="Лимит по получению услуг"
            // rules={[{ required: true, message: 'Обязательное поле' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
