import {Card} from "antd";
import {Column} from "@ant-design/charts";

const ColumnChart = ({title, data, xAlias, yAlias, style, scroll}) => {
    const [xField, yField] = Object.keys(data[0])

    const format = (v) => {
        return typeof v === 'number' ? new Intl.NumberFormat('ru-RU', {notation: 'compact'}).format(v) : v
    }

    return <Card title={title} style={{border: 0}}>
        <Column xField={xField} yField={yField} data={data}
                scrollbar={scroll ? { type: 'horizontal', width: 15 } : null}
                style={{maxHeight: '25vh', ...style}}
                label={{position: 'top', formatter: (v) => format(v[yField])}}
                yAxis={{
                    label: {
                        autoHide: true,
                        autoRotate: false,
                        formatter: (v) => format(v)
                    },
                }}
                meta={{
                    [xField]: {alias: xAlias},
                    [yField]: {alias: yAlias, formatter: (v) => format(Number(v))}
                }}

                color='#4328af'
                //FIXME Click on widget item
                onReady={(plot) => {
                  plot.on('plot:click', (evt) => {
                    const { x, y } = evt;
                    console.log(plot.chart.getTooltipItems({ x, y })[0]);
                  })
                }}

        />
    </Card>
}

export default ColumnChart
