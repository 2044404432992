import { createStore } from 'effector';
import { difference, intersect } from '@turf/turf';
import almaty_region from '../../Regions/almaty_region.json';
import akmola_region from '../../Regions/akmola_region.json';
import aktobe_region from '../../Regions/aktobe_region.json';
import abay_region from '../../Regions/abay_region.json';
import atyrau_region from '../../Regions/atyrau_region.json';
import eastkz_region from '../../Regions/eastkz_region.json';
import karagandy_region from '../../Regions/karagandy_region.json';
import kostanay_region from '../../Regions/kostanay_region.json';
import kyzylorda_region from '../../Regions/kyzylorda_region.json';
import mangystau_region from '../../Regions/mangystau_region.json';
import northkz_region from '../../Regions/northkz_region.json';
import pavlodar_region from '../../Regions/pavlodar_region.json';
import turkestan_region from '../../Regions/turkestan_region.json';
import ulytau_region from '../../Regions/ulytau_region.json';
import westkz_region from '../../Regions/westkz_region.json';
import zhambyl_region from '../../Regions/zhambyl_region.json';
import zhetisu_region from '../../Regions/zhetisu_region.json';

const initialRegionState = [
  {
    type: 'Feature',
    id: 9,
    properties: {
      sum: 100,
    },
    geometry: almaty_region,
  },
  {
    type: 'Feature',
    id: 7,
    properties: {
      sum: 100,
    },
    geometry: akmola_region,
  },
  {
    type: 'Feature',
    id: 8,
    properties: {
      sum: 100,
    },
    geometry: aktobe_region,
  },
  {
    type: 'Feature',
    id: 6,
    properties: {
      sum: 100,
    },
    geometry: abay_region,
  },
  {
    type: 'Feature',
    id: 10,
    properties: {
      sum: 100,
    },
    geometry: atyrau_region,
  },
  {
    type: 'Feature',
    id: 11,
    properties: {
      sum: 100,
    },
    geometry: eastkz_region,
  },
  {
    type: 'Feature',
    id: 15,
    properties: {
      sum: 100,
    },
    geometry: karagandy_region,
  },
  {
    type: 'Feature',
    id: 16,
    properties: {
      sum: 100,
    },
    geometry: kostanay_region,
  },
  {
    type: 'Feature',
    id: 17,
    properties: {
      sum: 100,
    },
    geometry: kyzylorda_region,
  },
  {
    type: 'Feature',
    id: 18,
    properties: {
      sum: 100,
    },
    geometry: mangystau_region,
  },
  {
    type: 'Feature',
    id: 20,
    properties: {
      sum: 100,
    },
    geometry: northkz_region,
  },
  {
    type: 'Feature',
    id: 19,
    properties: {
      sum: 100,
    },
    geometry: pavlodar_region,
  },
  {
    type: 'Feature',
    id: 21,
    properties: {
      sum: 100,
    },
    geometry: turkestan_region,
  },
  {
    type: 'Feature',
    id: 22,
    properties: {
      sum: 100,
    },
    geometry: ulytau_region,
  },
  {
    type: 'Feature',
    id: 14,
    properties: {
      sum: 100,
    },
    geometry: westkz_region,
  },
  {
    type: 'Feature',
    id: 12,
    properties: {
      sum: 100,
    },
    geometry: zhambyl_region,
  },
  {
    type: 'Feature',
    id: 13,
    properties: {
      sum: 100,
    },
    geometry: zhetisu_region,
  },
  {
    type: 'Feature',
    id: 23,
    properties: {
      sum: 100,
    },
    geometry: { type: 'Point', coordinates: [69.586942, 42.315521] },
  },
  {
    type: 'Feature',
    id: 24,
    properties: {
      sum: 100,
    },
    geometry: { type: 'Point', coordinates: [76.936843, 43.246356] },
  },
  {
    type: 'Feature',
    id: 25,
    properties: {
      sum: 100,
    },
    geometry: { type: 'Point', coordinates: [71.430429, 51.128201] },
  },
];

// FIXME Stores FEATURES for regions
export const $regionsLayerPolygons = createStore(initialRegionState);

// FIXME Stores selected region
export const $selectedRegion = createStore(0);

export const $regionsLayerPolygonsStart = createStore(initialRegionState);
export const $regionsLayerPolygonsFiltered = createStore([]);
