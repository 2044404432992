import { Button, Col, Dropdown, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEvent, useStore } from 'effector-react';
import {
  AreaChartOutlined,
  InfoOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import MapWork from '../MapWork/MapWork.jsx';
import SearchSelectWrapper from '../../components/SearchSelect/SearchSelectWrapper.jsx';
import StatsBlock from '../../components/StatsBlock/StatsBlock.jsx';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {
  $projRegistryFilteredStore,
  $projRegistryStore,
  resetProjRegFilteredStoreEv,
} from '../../models/projRegistryModel/index.js';
import {
  $regionsLayerPolygonsFiltered,
  resetRegionsLayerPolygonsFilteredEv,
} from '../../models/regionsLayerModel/index.js';
import {
  $activeFilters,
  resetActiveFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { selectDotEv } from '../../models/dotsLayerModel/index.js';
import { $binSelectOptions } from '../../models/globalModel/index.js';

function ProjRegistry() {
  const {
    projects_count,
    members_count,
    invest_count,
    activity_select,
    organizations_select,
    projects_table_data,
  } = useStore($projRegistryStore);
  const bin_select = useStore($binSelectOptions);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (record) => {
    setSelectedRow(record.key);
  };

  const selectDot = useEvent(selectDotEv);

  const dropdownItems = useCallback((proj_id, subj_id) => {
    return [
      {
        key: 'subject',
        label: (
          <Link to={`/view-subject/${subj_id}`} target="_blank">
            Просмотр предприятия
          </Link>
        ),
      },
      {
        key: 'project',
        label: (
          <Link to={`/view-project/${proj_id}`} target="_blank">
            Просмотр проекта
          </Link>
        ),
      },
    ];
  }, []);

  const DropDown = useCallback(({ field, record }) => {
    return (
      <Dropdown
        menu={{ items: dropdownItems(record.proj_id, record.subj_id) }}
        placement="topLeft"
        arrow
        // trigger={['click']}
        trigger={['']} // dont trigger dropdown
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => selectDot(record.proj_id)}
        >
          {field}
        </div>
        {/* <Tooltip arrow={false} placement="topLeft" title={field}>{field}</Tooltip> */}
      </Dropdown>
    );
  }, []);

  const columns = [
    {
      key: 'name',
      width: '40%',
      title: 'Наименование проекта',
      dataIndex: 'projectName',
      render: (name, record) => <DropDown field={name} record={record} />,
    },
    {
      key: 'subject',
      width: '20%',
      title: 'Предприятие',
      dataIndex: 'subject',
      render: (manufact, record) => (
        <DropDown field={manufact} record={record} />
      ),
    },
    {
      key: 'completion',
      width: '10%',
      title: '% завершения',
      dataIndex: 'completion',
    },
    {
      key: 'investments',
      width: '20%',
      title: 'Сумма инвестиций (тенге)',
      dataIndex: 'investments_total',
    },
    {
      key: 'actions',
      width: '15%',
      title: 'Действия',
      align: 'center',
      render: (record) => (
        <div
          id="buttons_container"
          style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}
        >
          <Tooltip title="Просмотр предприятия">
            <Link to={`/view-subject/${record.subj_id}`} target="_blank">
              <Button className="info-btn" icon={<ProjectOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip title="Просмотр проекта">
            <Link to={`/view-project/${record.proj_id}`} target="_blank">
              <Button className="info-btn" icon={<AreaChartOutlined />} />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const dataSource = Array(30).fill({
    projectName: 'Some project',
    subject: 'Test subject',
    completion: '50%',
    investments_total: 2000000,
  });

  const stats = [
    { label: 'Кол-во проектов', value: projects_count },
    { label: 'Кол-во участников', value: members_count },
    { label: 'Сумма инвестиций', value: invest_count },
  ];

  const activeFilters = useStore($activeFilters);
  const resetActiveFilters = useEvent(resetActiveFiltersEv);

  useEffect(() => {
    if (hasActiveFilters(activeFilters)) {
      setTimeout(() => {
        resetActiveFilters();
      }, 500);
    }
  }, []);

  return (
    <ContainerInner title="Реестр инвестиционных проектов">
      <Row justify="space-between" className="db-row-top">
        <Col style={{ width: '48%' }} className="db-col1-top">
          <StatsBlock data={stats} />
          <SearchSelectWrapper
            withSearch
            select_items={{
              activity_select,
              organizations_select,
              bin_select,
            }}
          />
        </Col>
        <Col
          style={{ width: '48%', boxShadow: '5px 5px 10px rgba(0,0,0,.4)' }}
          className="db-col-map"
        >
          <MapWork />
        </Col>
      </Row>

      <Row className="db-row-bottom">
        <Table
          columns={columns}
          dataSource={projects_table_data}
          size="small"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: record.key === selectedRow ? 'selectedRow' : '',
          })}
          pagination={{
            position: ['bottomCenter'],
            defaultPageSize: 20,
            pageSizeOptions: [20, 50, 100],
          }}
          style={{ width: '100%' }}
        />
      </Row>
    </ContainerInner>
  );
}

export default ProjRegistry;
