import { Button, Table, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import {
  CheckCircleOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import CreateEditRequestModal from './components/CreateEditRequestModal.jsx';
import {
  $requestsList,
  getReqListFx,
  setReqModalEv,
  RequestsGate,
  setViewModalEv,
} from '../../models/investorRequestsModel/index.js';
import { $userInfo } from '../../models/authModel/index.js';
import ViewRequestModal from './components/ViewRequestModal.jsx';
import styles from './InvestRequests.module.css';
import { $dictionariesMaps } from '../../models/dictionariesModel/index.js';
import { $regionsMap } from '../../models/globalModel/index.js';

export default function InvestRequests() {
  const { t } = useTranslation();
  const setModal = useUnit(setReqModalEv);
  const curUser = useUnit($userInfo);
  const viewReq = useUnit(setViewModalEv);
  const tableData = useUnit($requestsList);
  const loading = useUnit(getReqListFx.pending);

  const { invActMap } = useUnit($dictionariesMaps);
  const regionsMap = useUnit($regionsMap);

  const columns = [
    {
      title: t('Статус'),
      width: 24,
      align: 'center',
      render: (record) =>
        curUser.role === 'vtp' || curUser.role === 'fundraiser' ? (
          <Tooltip
            title={
              record.state === 'new'
                ? t('Ожидает рассмотрения')
                : record.state === 'approved'
                ? t('Одобрено')
                : t('Отклонено')
            }
          >
            <div className={styles[record.state]} />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              record.request_status ? t('Заявка получена') : t('Ожидает рассмотрения')
            }
          >
            <div className={styles[record.request_status ? 'approved' : 'new']} />
          </Tooltip>
        ),
    },
    {
      title: t('Наименование проекта'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Компания-инициатор'),
      dataIndex: 'initiator',
      key: 'initiator',
    },
    {
      title: t('Регион'),
      dataIndex: 'region_id',
      key: 'region',
      render: (region) => t(`regions.${regionsMap[region]}`),
    },
    {
      title: t('Отрасль'),
      dataIndex: 'activity_id',
      key: 'activity_id',
      render: (activity_id) => t(`industries.${invActMap[activity_id]}`),
    },
    {
      title: t('Стоимость (тыс. $)'),
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => new Intl.NumberFormat('ru-KZ').format(cost),
    },
    {
      align: 'center',
      width: 46,
      key: 'actions',
      dataIndex: 'id',
      render: (id, record) => (
        <Button.Group>
          <Tooltip title={t('Просмотр заявки')}>
            <Button icon={<FileTextOutlined />} onClick={() => viewReq(id)} />
          </Tooltip>
          {(curUser.role === 'potential_investor' && !record.request_status) && (
            <Tooltip title={t('Хочу инвестировать')}>
              <Button icon={<CheckCircleOutlined />} />
            </Tooltip>
          )}
          {curUser.role === 'fundraiser' && (
            <Tooltip title={t('Редактирование заявки')}>
              <Button
                icon={<EditOutlined />}
                onClick={() => setModal({ open: true, type: 'edit', id })}
              />
            </Tooltip>
          )}
        </Button.Group>
      ),
    },
  ];
  return (
    <ContainerInner
      title={t('Инвестиционные предложения')}
      extra={
        curUser.role === 'fundraiser' || curUser.role === 'admin' ? (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setModal({ open: true, type: 'create' })}
          >
            {t('Добавить')}
          </Button>
        ) : (
          ''
        )
      }
    >
      <RequestsGate />
      <CreateEditRequestModal />
      <ViewRequestModal />
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </ContainerInner>
  );
}
