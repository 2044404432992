import { Pie } from '@ant-design/charts';

const colorDict = {
    1: '#4318FF',
    2: '#9CBCE8',
    3: '#8367FF',
    4: '#55E7BB',
    5: '#6AD2FF',
    6: '#4995FF',
}

function PieChart({ data }) {
  return (
    <div style={{ height: '149px', marginTop: '16px' }}>
      <Pie
        angleField="value"
        colorField="source"
        data={data}
        radius={1}
        innerRadius={0.6}
        color={data.map((item) => colorDict[item.color])}
        interactions={[
          {
            type: 'element-active',
          },
        ]}
        statistic={{
          title: false,
          content: '',
        }}
        legend={false}
        label={false}
      />
    </div>
  );
}

export default PieChart;

// FIXME - backup
//<div style={{ height: '149px', marginTop: '16px' }}>
//       <Pie
//         angleField="value"
//         colorField="source"
//         data={data}
//         radius={1}
//         innerRadius={0.6}
//         color={data.map((item) => item.color)}
//         interactions={[
//           {
//             type: 'element-active',
//           },
//         ]}
//         statistic={{
//           title: false,
//           content: '',
//         }}
//         legend={false}
//         label={false}
//       />
//     </div>