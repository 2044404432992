import { createStore } from 'effector';

export const $pollOptions = createStore(null);
export const $binInfo = createStore(null);
export const $submitStatus = createStore(false);
export const $wrongTypeModalOpen = createStore(false);

export const $pollsResults = createStore([]);

export const $govCantVoteModalOpen = createStore(false);
