import Modal from 'antd/es/modal';
import { useEffect, useState } from 'react';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';
import { useUnit } from 'effector-react';
import {
  $rejectModal,
  $rejectStatus,
  rejectProjectEv,
  rejectProjectFx,
  setRejectModalEv,
} from '../../models/unpublishedProjectsModel/index.js';

export default function RejectProjectModal() {
  const id = useUnit($rejectModal);
  const reject = useUnit(rejectProjectEv);
  const setModal = useUnit(setRejectModalEv);
  const rejectLoading = useUnit(rejectProjectFx.pending);
  const rejectStatus = useUnit($rejectStatus);

  const [comment, setComment] = useState('');

  const onReject = () => {
    reject({ id, comment });
  };

  const onClose = () => {
    setModal(null);
    setComment('');
  };

  useEffect(() => {
    if (rejectStatus) {
      onClose();
    }
  }, [rejectStatus]);

  return (
    <Modal
      width="60vw"
      title="Отклонение проекта"
      open={!!id}
      destroyOnClose
      footer={null}
      onCancel={onClose}
    >
      <p>Причина отказа</p>
      <Input.TextArea
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
      <Popconfirm
        onConfirm={() => onReject()}
        title="Вы уверены, что хотите отклонить данный проект?"
        placement="left"
        okButtonProps={{ danger: true }}
        okText="Да"
        cancelText="Нет"
      >
        <Button
          type="primary"
          danger
          style={{ marginTop: 16 }}
          loading={rejectLoading}
        >
          Подтвердить
        </Button>
      </Popconfirm>
    </Modal>
  );
}
