import { useState } from 'react';
import {
  Button,
  Collapse,
  DatePicker, Divider,
  Form,
  Input,
  InputNumber,
  Select
} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU.js';
import 'dayjs/locale/ru.js';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { CloseOutlined } from '@ant-design/icons';
import { date_format_short } from '../../constants/date_format.js';
import { editProjectStagesFx } from '../../models/viewProjectModel/index.js';
import style from './EditProjectById.module.css';
import StageSelect from './StageSelect.jsx';
import {
  $dictionariesMaps,
  $dictionariesStore,
} from '../../models/dictionariesModel/index.js';
import { prepareProjectStagesDictionariesData } from '../../counters/viewProject/index.js';
import { $participantsMap } from '../../models/projectCreateEditModel/index.js';
import ResponsibleSelects from './ResponsibleSelects.jsx';


function EditSteps({ participantsTableData, stages }) {
  const { id } = useParams();
  const isLoading = useStore(editProjectStagesFx.pending);
  const [form] = Form.useForm();

  const dictionariesStore = useStore($dictionariesStore);
  const { rolesMap } = useStore($dictionariesMaps);
  const orgsMap = useStore($participantsMap);
  const { budgetOptions, stageStatusOptions, stagesData } =
    prepareProjectStagesDictionariesData(dictionariesStore);

  const [stagesOptions, setStagesOptions] = useState(stagesData);

  const onFinish = (values) => {
    const mutatedData = {
      stage_1: values.stage_1 || stages.stage_1,
      stage_2: values.stage_2 || stages.stage_2,
      stage_3: values.stage_3 || stages.stage_3,
      stage_4: values.stage_4 || stages.stage_4,
    };

    const payload = [];

    Object.values(mutatedData).forEach((stage) => {
      if (stage) {
        stage.forEach((item) => {
          payload.push({
            id: item.id || null,
            stage_id: item.stage_id || null,
            responsible_id: item.responsible_id || null,
            role_id: !!item?.responsible_id ? null : item.role_id ?? null,
            organization_id: !!item.responsible_id ? null : item.organization_id ?? null,
            status_id: item.status_id || null,
            start_date_plan: item.start_date_plan
              ? item.start_date_plan.format(date_format_short)
              : null,
            start_date_fact: item.start_date_fact
              ? item.start_date_fact.format(date_format_short)
              : null,
            finish_date_plan: item.finish_date_plan
              ? item.finish_date_plan.format(date_format_short)
              : null,
            finish_date_fact: item.finish_date_fact
              ? item.finish_date_fact.format(date_format_short)
              : null,
            note: item.note || '',
            sum_plan: typeof item.sum_plan === 'number' ? item.sum_plan : 0,
            sum_fact: typeof item.sum_fact === 'number' ? item.sum_fact : 0,
            budget_source_ids: item.budget_source_ids || null,
          });
        });
      }
    });

    console.log(payload);

    editProjectStagesFx({ id, changes: payload });
  };

  const initialValues = {
    stage_1: stages.stage_1 || [],
    stage_2: stages.stage_2 || [],
    stage_3: stages.stage_3 || [],
    stage_4: stages.stage_4 || [],
  };

  const disabledOptionIds = {
    1: initialValues.stage_1.map((item) => item.stage_id),
    2: initialValues.stage_2.map((item) => item.stage_id),
    3: initialValues.stage_3.map((item) => item.stage_id),
    4: initialValues.stage_4.map((item) => item.stage_id),
  };



  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


  // FIXME Waiting for func rewrite to make proper select options
  const executorOptions =
    participantsTableData?.map((item) => {
      return {
        label: `${rolesMap[item?.role_id] ?? ''} - ${
          orgsMap[item?.organization_id] ?? ''
        }`,
        value: item.id,
      };
    }) ?? [];

  const collapseItems = {
    1: {},
    2: {},
    3: {},
    4: {},
  };

  Object.keys(collapseItems).forEach((stage) => {
    collapseItems[stage] = [
      {
        key: stage,
        label: `Стадия ${stage}`,
        children: (
          <Form.Item>
            <Form.List name={`stage_${stage}`}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div className={style.stages_wrapper}>
                      {fields.map(({ key, name }) => (
                        <div className={style.stage_form} key={key}>
                          <Form.Item name={[name, 'id']} noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'stage_id']}
                            label="Этап"
                            rules={[
                              {
                                required: true,
                                message: 'Пожалуйста выберите этап',
                              },
                              {
                                validator: (_, value) =>
                                  value ? Promise.resolve() : Promise.reject(),
                              },
                            ]}
                          >
                            <StageSelect
                              filterOption={filterOption}
                              showSearch
                              options={stagesOptions[stage]}
                              // onChange={onSelectChange}
                            />
                          </Form.Item>
                          <Divider orientation={'left'} orientationMargin={0} plain>
                            Ответственный:
                          </Divider>
                          <ResponsibleSelects
                            name={name}
                            stage={`stage_${stage}`}
                            form={form}
                            filterOption={filterOption}
                            executorOptions={executorOptions}
                          />
                          <Divider/>
                          <Form.Item
                            name={[name, 'start_date_plan']}
                            label="Дата начала (план)"
                          >
                            <DatePicker locale={locale} />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'start_date_fact']}
                            label="Дата начала (факт)"
                          >
                            <DatePicker locale={locale} />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'finish_date_plan']}
                            label="Дата завершения (план)"
                          >
                            <DatePicker locale={locale} />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'finish_date_fact']}
                            label="Дата завершения (факт)"
                          >
                            <DatePicker locale={locale} />
                          </Form.Item>
                          <Form.Item name={[name, 'status_id']} label="Статус">
                            <Select
                              filterOption={filterOption}
                              showSearch
                              options={stageStatusOptions}
                            />
                          </Form.Item>
                          <Form.Item name={[name, 'note']} label="Примечание">
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'sum_plan']}
                            label="Бюджет (план)"
                          >
                            <InputNumber type="number" controls={false} />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'sum_fact']}
                            label="Бюджет (факт)"
                          >
                            <InputNumber type="number" controls={false} />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'budget_source_ids']}
                            label="Источник финансирования"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Пожалуйста выберите источник финансирования',
                              },
                            ]}
                          >
                            <Select
                              filterOption={filterOption}
                              showSearch
                              options={budgetOptions}
                              mode="multiple"
                              dropdownStyle={{width: 500}}
                            />
                          </Form.Item>
                          <Button
                            icon={
                              <CloseOutlined
                                style={{ fontSize: '14px' }}
                                onClick={() => {
                                  remove(name);
                                }}
                              />
                            }
                            shape="circle"
                            className={style.delete_button}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      type="dashed"
                      onClick={() => {
                        // if (fields.length !== stagesOptions[stage].length) {
                        add();
                        // }
                      }}
                      block
                    >
                      + Этап
                    </Button>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        ),
      },
    ];
  });

  const onFieldsChange = (field) => {
    if (field[0].name.length === 1 && /stage_\d{1}/.test(field[0].name[0])) {
      const stage = field[0].name[0].split('_')[1];
      const existingStages = field[0].value
        .filter((item) => item !== undefined && item.stage_id !== undefined)
        .map((item) => item.stage_id);
      setStagesOptions({
        ...stagesOptions,
        [stage]: stagesOptions[stage].map((item) => {
          if (existingStages.includes(item.value)) {
            return {
              ...item,
              disabled: true,
            };
          }
          return {
            ...item,
            disabled: false,
          };
        }),
      });
    }
  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={initialValues}
      form={form}
      // onFieldsChange={onFieldsChange}
    >
      <Collapse items={collapseItems[1]} className={style.collapse} />
      <Collapse items={collapseItems[2]} className={style.collapse} />
      <Collapse items={collapseItems[3]} className={style.collapse} />
      <Collapse items={collapseItems[4]} className={style.collapse} />
      <Button htmlType="submit" type="primary" loading={isLoading}>
        Подтвердить
      </Button>
    </Form>
  );
}

export default EditSteps;
