import { createStore } from 'effector';
import { difference, union, intersect } from '@turf/turf';
import booleanIntersects from '@turf/boolean-intersects';
import { regions_districts_id_dictionary } from '../../dictionaries/regions_districts_dictionary.js';

const initialState = [];
Object.entries(regions_districts_id_dictionary).forEach(
  ([region_id, districts]) => {
    Object.entries(districts).forEach(([key, value]) => {
      if (value.length > 0 && Object.keys(value[0]).length > 0) {
        initialState.push({
          type: 'Feature',
          properties: {
            district_id: +key,
            region_id: +region_id,
          },
          geometry: {
            ...value[0],
          },
          id: +key,
        });
      }
    });
  }
);

// const test = new_districts.features.map((item) => {
//   const intersects = [];
//   initialRegionState.forEach((region) => {
//     if (booleanIntersects(region, item)) {
//       intersects.push(region.id);
//     }
//   });
//   return {
//     ...item,
//     properties: {
//       ...item.properties,
//       intersects,
//     },
//   };
// });
//
// const kokp = test.filter(
//   (item) => item.properties.NAME_2 === 'Kokpektinskiy'
// )[0];
//
// const dist_327 = initialState.filter((item) => item.id === 327)[0];
//
// const int = difference(kokp, dist_327);
// console.log('MANIPULATIONS::', int);
//
// debugger;

// const unifiedRegions = {
//   type: 'Feature',
//   geometry: kazakhstan,
// };
//
// let unifiedDistricts;
// initialState.forEach((district) => {
//   if (!unifiedDistricts) {
//     unifiedDistricts = district;
//   } else {
//     unifiedDistricts = union(unifiedDistricts, district);
//   }
// });
//
// const emptyDistrictsPolygon = difference(unifiedRegions, unifiedDistricts);
//
// console.log('emptyDistrictsPolygon', emptyDistrictsPolygon);

// FIXME Algorithm to find missing polygon
// const first_dist_int = {
//   type: 'Feature',
//   geometry: {
//     ...regions_districts_id_dictionary[21][432][0],
//   },
// };
// const sec_dist_int = {
//   type: 'Feature',
//   geometry: {
//     ...regions_districts_id_dictionary[21][422][0],
//   },
// };
// const thir_dist_int = {
//   type: 'Feature',
//   geometry: {
//     ...regions_districts_id_dictionary[21][423][0],
//   },
// };
//
// let unionpolygon = union(first_dist_int, sec_dist_int);
// unionpolygon = union(unionpolygon, thir_dist_int);
//
// const dif = difference(
//   {
//     type: 'Feature',
//     geometry: turkestan_region,
//   },
//   unionpolygon
// );
//
// console.log('dif', JSON.stringify(dif.geometry));

export const $districtsLayerPolygons = createStore(initialState);

export const $selectedDistrict = createStore(0);

export const $districtsLayerPolygonsStart = createStore(initialState);
export const $districtsLayerPolygonsFiltered = createStore(initialState);
