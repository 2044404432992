import { combine, createStore } from 'effector';
import {prepareDictionariesData, prepareDictsMaps} from '../../counters/viewProject/index.js';
import {$language} from '../authModel/index.js';
import i18 from '../../i18n';

const initialStore = {
  budget_source: {},
  foreign_investors: {},
  kpi: {},
  organizations: {},
  project_statuses: {},
  role: {},
  tru_types: {},
  tnveds: {},
  project_effects: {},
  project_requirements: {},
  project_resources: {},
  project_types: {},
  units: {},
  stage_statuses: {},
  phases: {},
  stages: {},
  countries: {},
  mgp_program: {},
  mgp_ksp: {},
  mgp_type: {},
  mgp_kind: {},
  mgp_target: {},
  mgp_operator: {},
  investment_proposal_units: {},
  investment_proposal_statuses: {},
  investment_proposal_file_types: {},
  investment_proposal_activities: {},
};
export const $dictionariesStore = createStore(initialStore);
export const $createEditProjectUtils = combine($dictionariesStore, $language, (dicts) =>
  prepareDictionariesData(dicts)
);

export const $dictionariesMaps = combine($dictionariesStore, (dicts) => prepareDictsMaps(dicts))
export const $countriesSelect = combine($dictionariesStore, $language, (dicts) => Object.entries(dicts.countries).map(([id, name]) => ({label: i18.t(`countries.${name}`), value: +id})))
