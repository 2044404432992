import { Table } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import style from './ViewProjectComponents.module.css';
import {$projectStore} from "../../models/viewProjectModel/index.js";
import {useStore} from "effector-react";

const columnTitleDict = {
  available_value: 'Собственные',
  requested_value: 'Запрашиваемые',
  provided_value: 'Предоставлено',
  total: 'Итого',
  name: 'Тип'
}

function ProjectResourcesInfo() {
  const {requirements} = useStore($projectStore)

  return (
    <div>
      {/*<div className={style.block_title}>*/}
      {/*  Потребность в ресурсах для реализации проекта*/}
      {/*</div>*/}
      {Object?.keys(requirements)?.length > 0 ? Object.entries(requirements)
          ?.map(([title, data], idx) =>
              <ResourceTable
                  data={data}
                  title={title}
                  marginTop={idx === 0 ? 0 : ''}
              />
          )
          : <div className={style.project_view_card_subtitle}>Отсутствуют</div>}
    </div>
  );
}

export default ProjectResourcesInfo;

function ResourceTable({ data, title, marginTop }) {
  const [expanded, setExpanded] = useState(false);

  const columns = Object.entries(data?.filter(i => i.name !== 'Всего')[0] ?? {})?.map(([key, value]) => {
        if (columnTitleDict[key]) {
          return {
            title: columnTitleDict[key],
            dataIndex: key,
            key: key,
          }
        }
      }
  )?.filter(i => i !== undefined);
  const typeIdx = columns.findIndex(i => i.title === 'Тип')
  const typeCol = {...columns.find(i => i.title === 'Тип')}
  columns?.splice(typeIdx, 1)

  const summaryIdx = columns.findIndex(i => i.title === 'Итого')
  const summaryCol = {...columns.find(i => i.title === 'Итого')}
  columns?.splice(summaryIdx, 1)

  columns?.unshift(typeCol, summaryCol)

  const total = data.filter((item) => item.name === 'Всего');

  const [tableData, setTableData] = useState(total);

  const changeExpand = () => {
    if (expanded) {
      setTableData(total);
    } else {
      setTableData(data);
    }
    setExpanded(!expanded);
  };

  return (
    <div
      className={style.project_view_card}
      style={{ marginTop: (marginTop || marginTop === 0) ? marginTop : 16 }}
    >
      <div className={style.project_view_card_title}>{title}</div>
      {data.length > 0
          ? <>
            <Table
                dataSource={tableData}
                columns={columns}
                size="small"
                pagination={false}
                style={{ marginTop: '16px' }}
            />
            {expanded ? (
                <div className={style.resource_table_control} onClick={changeExpand}>
                  Скрыть <UpOutlined />
                </div>
            ) : (
                <div className={style.resource_table_control} onClick={changeExpand}>
                  Подробнее <DownOutlined />
                </div>
            )}
          </>
          : 'Показатели потребности отсутствуют'
      }
    </div>
  );
}
