import { useStore } from 'effector-react';
import { getOverviewDataFx } from '../models/overviewModel/index.js';

export const hasActiveFilters = (active_filters) => {
  if (
    active_filters.region_id !== 0 ||
    active_filters.organization_id?.length > 0 ||
    active_filters.name?.length > 0 ||
    active_filters.district_id !== 0 ||
    active_filters.organization_bin !== 0 ||
    active_filters.regionIdByGradient.length > 0 ||
    active_filters.subCategory !== '' ||
    active_filters.main_activity_id.length > 0 ||
    active_filters.project_type_id.length > 0
  ) {
    return true;
  }
  return false;
};

export const onlyRegionFilter = (active_filters) => {
  const { region_id } = active_filters;
  const other_filters = Object.keys(active_filters).filter(
    (key) => key !== 'region_id'
  );

  if (
    region_id !== 0 &&
    other_filters.every(
      (key) => active_filters[key] === 0 || active_filters[key]?.length === 0
    )
  ) {
    return true;
  }
  return false;
};

export const hasBin = (active_filters) => {
  return active_filters.organization_bin !== 0;
};

export const isOverviewFilters = (activeFilters) => {
  // const workingCategories = ['mal', 'sred'];
  // const workingIndexes = ['people', 'taxes', 'companies'];
  if (
    window.location.href.includes('dashboard') &&
    (activeFilters.region_id !== 0 || activeFilters.district_id !== 0)
    // ||
    // (workingCategories.includes(activeFilters.category) &&
    //   workingIndexes.includes(activeFilters.subCategory))
  ) {
    return true;
  }
  return false;
};
