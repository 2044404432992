import { Select } from 'antd';
import { useState } from 'react';

function StageSelect({ onChange, ...props }) {
  const [stateValue, setStateValue] = useState(props.value);
  const onSelectChange = (value, option) => {
    onChange(value, stateValue, option.stage);
    setStateValue(value);
  };

  return <Select onChange={onSelectChange} {...props} value={stateValue} dropdownStyle={{width: 500}}/>;
}

export default StageSelect;
