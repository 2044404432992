import {Button, Modal, Table, Tooltip} from 'antd';
import { useUnit } from 'effector-react';
import {InfoOutlined} from '@ant-design/icons';
import {
  $bizRegionModalData,
  $bizRegionModalState,
  resetBizModalEv,
  setDistrictModalEv,
} from '../../../../models/overviewModel/index.js';
import BizDistrictModal from './BizDistrictModal.jsx';

export default function BizRegionModal() {
  const { open, type, granularity } = useUnit($bizRegionModalState);
  const close = useUnit(resetBizModalEv);
  const setDistrictModal = useUnit(setDistrictModalEv);

  const tableData = useUnit($bizRegionModalData);
  const childrenCols = tableData[0] && tableData[0]?.quarters_avg
      && Object.entries(tableData[0]?.quarters_avg)?.map(([quarter, value]) => ({
      title: granularity === 'quarter' ? `${quarter.split('_')[1]} кв. ${quarter.split('_')[0]}` : quarter.split('_')[0],
      dataIndex: ['quarters_avg', quarter],
      key: quarter,
      sorter: (a, b) => a['quarters_avg'][quarter] - b['quarters_avg'][quarter],
    }));

  const columns = [
    {
      title: '№',
      key: 'number',
      render: (_, __, idx) => idx + 1,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (region, record) => <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <span>{region}</span>
          <Tooltip title={'Детали по районам'}>
            <Button
              size={'small'}
              onClick={() => setDistrictModal({open: true, type, region_id: record.id, granularity})}
              icon={<InfoOutlined />}
            />
          </Tooltip>
      </div>
    },
    {
      title: 'Общий рейтинг',
      key: 'quarter_avg',
      children: childrenCols,
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={() => close()}
      width="75vw"
      footer={null}
      title={type === 'business_inspections' ? 'Малый бизнес' : type === 'mio_performance' ? 'Средний бизнес' : ''}
    >
      <BizDistrictModal />
      <Table dataSource={tableData} columns={columns} pagination={false} bordered style={{width: '100%', overflow: 'auto'}}/>
    </Modal>
  );
}
