import {message} from "antd";
import {
	registryRegionIdFilter,
	registrySearchProjNameFilter,
	registrySearchSelectActivityFilter,
	registrySearchSelectOrgFilter
} from "./counters.js";
import {filterProjects, filterSelectOptions} from "../projects/index.js";
import {prepareTreeSelectData} from "../utils/activityTreeSelectPreparator.js";

export const prepareProjRegistryData = (data) => {
	const projects_count = data.projects.length
	const members_count = (new Set(data.projects.map(item => item.organization_name))).size
	const invest_count = data?.projects?.length > 0
		? data.projects?.map(item => item.budget.sum_plan)?.reduce((a, b) => a + b, 0)
		: 0

	const activity_select = prepareTreeSelectData(data.activities)

	const organizations_select = data.organizations.map(item => {
		return {
			label: item.name,
			value: item.id
		}
	})

	const projects_table_data = data.projects.map(item => {
		return {
			key: `projRegTable${item.id}`,
			projectName: item.name,
			subject: item.organization_name,
			completion: item.realization_status + '%',
			investments_total: new Intl.NumberFormat('ru-RU', {notation: 'compact'}).format(item.budget.sum_plan),
			proj_id: item.id,
			subj_id: item.organization_id
		}
	})

	return {
		projects_count,
		members_count,
		invest_count,
		activity_select,
		organizations_select,
		projects_table_data,
	}
}

export const prepareFilteredRegistryData = (projects, rawData, activeFilters) => {
	const {activities, organizations} = filterSelectOptions(activeFilters, projects, rawData)

	if (projects.length === 0) {
		message.error('Проектов по данным критериям не найдено').then(() => true)
	}

	const filteredData = {
		...rawData,
		projects,
		activities,
		organizations
	}

	return prepareProjRegistryData(filteredData)
}
