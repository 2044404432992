import {resetChosenBuildingEv, selectBuildingEv} from "./events.js";
import {$selectedBuilding} from "./stores.js";
import {sample} from "effector";

$selectedBuilding.reset(resetChosenBuildingEv)

sample({
	clock: selectBuildingEv,
	target: $selectedBuilding,
})
