import { createStore } from 'effector';

const initialState = {
  polygons: [],
  points: [],
};

//FIXME stores created objects by map controls or form
export const $createdObjects = createStore(initialState);
$createdObjects.watch(state => console.log('$createdObjects', state));
