import { Select } from 'antd';
import { useEvent, useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $districtsSelect } from '../../models/globalModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { $selectedDistrict } from '../../models/districtsModel/index.js';

function DistrictSelector() {
  const districts = useStore($districtsSelect);
  const changeActiveFilter = useEvent(changeActiveFilterEv);
  const activeFilters = useStore($activeFilters);
  const selectedDistrict = useStore($selectedDistrict);
  const [selectValue, setSelectValue] = useState(null);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(districts);
  }, [districts]);

  useEffect(() => {
    if (activeFilters.region_id > 0) {
      setOptions(
        districts.filter((item) => item.region_id === activeFilters.region_id)
      );
    } else {
      setOptions(districts);
    }
  }, [activeFilters]);

  const onDistrictSelect = (value) => {
    changeActiveFilter({
      field: 'district_id',
      value: value || 0,
    });
  };

  useEffect(() => {
    if (selectedDistrict === 0) {
      setSelectValue(null);
    } else {
      setSelectValue(selectedDistrict);
    }
  }, [selectedDistrict]);

  if (activeFilters.region_id > 0) {
    return (
      <Select
        style={{
          width: '220px',
          position: 'absolute',
          left: '10px',
          top: '50px',
          color: 'black',
        }}
        options={options}
        onChange={onDistrictSelect}
        value={selectValue}
        allowClear
        placeholder="Выберите район"
      />
    );
  }
  return null;
}

export default DistrictSelector;
