import { createStore } from 'effector';

const initialState = {
  medium_companies: {
    last_month_data: {
      change: 0,
      month: 0,
      value: 0,
      year: 0,
    },
  },
  small_companies: {
    monthly_data: [],
  },
  people: {
    last_month_data: {
      change: 0,
      month: 0,
      value: 0,
      year: 0,
    },
    monthly_data: {
      2022: [],
      2023: [],
    },
  },
  taxes: {
    last_month_data: {
      change: 0,
      month: 0,
      value: 0,
      year: 0,
    },
  },
  monthly_data: [],
  business_climate_data: {
    business_inspections: 0,
    mio_performance: 0,
  },
  mio_performance: {
    last_month_data: {
      value: 0,
    },
  },
  business_inspections: {
    last_month_data: {
      value: 0,
    },
  },
  problems: {
    count: 0,
    budget: 0,
  },
  projects: {
    count: 0,
    budget: 0,
  },
};

export const $overviewStore = createStore(initialState);

export const $otpStore = createStore({
  companies: 0,
  map_data: {},
  oked_chart: null,
  avg_workload: 0
})

export const $problemProjects = createStore([]);

export const $showProjectDots = createStore(false);
export const $activeProblemResp = createStore(1);
export const $isModalOpen = createStore({visible: false, modalType: null});
export const $dashboardModalOpen = createStore(false);
export const $dashboardModalData = createStore([]);

export const $otpModalState = createStore({
      open: false,
      type: null,
      region_id: null,
      district_id: null,
      activity_id: null
    }
);
export const $otpModalData = createStore([]);

export const $bizModalRawData = createStore([]);

export const $bizRegionModalState = createStore({
  open: false,
  type: null,
  year: new Date().getFullYear(),
  granularity: 'quarter',
});

export const $bizDistrictModalState = createStore({
  open: false,
  region_id: null,
  granularity: 'quarter',
  selectedYear: new Date().getFullYear(),
  selectedQuarter: 2 // Math.floor((new Date().getMonth() + 3) / 3),
});

export const $bizRegionModalData = createStore([]);
export const $bizDistrictModalData = createStore({
  title: '',
  table: [],
  years: [],
  charts: { bar: [], column: [] },
});

export const $bizPollsStatsModalState = createStore(false);
export const $bizPollsStatsModalData = createStore({
  gov_id_count: 0,
  oked_counts: {},
  category_percentages: {},
});