import {Card, Skeleton} from "antd";
import {Area} from "@ant-design/charts";

const Title = ({title, desc}) => <div>
    <h4 style={{marginBottom: 0}}>{title}</h4>
    <span style={{color: '#888', fontWeight: 400, fontSize: 14}}>{desc}</span>
</div>

const DynamicsChart = ({title, description, data, xAlias, yAlias}) => {
    if(data.length > 0){
      const [xField, yField] = Object.keys(data[0])

      const format = (v) => {
        return typeof v === 'number' ? new Intl.NumberFormat('ru-RU', {notation: 'compact'}).format(v) : v
      }

      return <Card title={<Title title={title} desc={description}/>} style={{border: 0, width: '100%', maxHeight: '100%',boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)'}} >
        <Area data={data}
              style={{maxHeight: '25vh'}}
              xField={xField}
              yField={yField}
              xAxis={{tickCount: 5}}
              slider={{start: 0, end: 1, trendCfg: {isArea: true}}}
              animation={false}
              yAxis={{
                label: {
                  autoHide: true,
                  autoRotate: false,
                  formatter: (v) => format(parseInt(v))
                },
              }}
              meta={{
                [xField]: {alias: xAlias},
                [yField]: {alias: yAlias}
              }}
        />
      </Card>
    } else {
      return <Skeleton />
    }
}

export default DynamicsChart
