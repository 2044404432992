import Modal from 'antd/es/modal/Modal';
import Paragraph from 'antd/es/typography/Paragraph';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import {
  $wrongTypeModalOpen,
  resetWrongTypeModalEv,
} from '../../models/bizPollsModel/index.js';

export default function WrongTypeModal() {
  const { t } = useTranslation();
  const { type } = useParams();
  const open = useUnit($wrongTypeModalOpen);
  const resetModal = useUnit(resetWrongTypeModalEv);

  return (
    <Modal width="80vw" closable={false} open={open} footer={null}>
      <Paragraph>
        {type === 'small'
          ? t('biz-polls.wrong-type-not-small')
          : t('biz-polls.wrong-type-not-medium')}
      </Paragraph>
      <Button
        type="primary"
        onClick={resetModal}
        href={`/polls/${type === 'medium' ? 'small' : 'medium'}`}
      >
        {t('biz-polls.wrong-type-link')}
      </Button>
    </Modal>
  );
}
