import {Button, Col, Dropdown, Row, Table, Tooltip} from "antd";
import StatsBlock from "../../components/StatsBlock/StatsBlock.jsx";
import MapWork from "../MapWork/MapWork.jsx";
import ContainerInner from "../../components/ContentContainer/ContainerInner.jsx";
import {Link} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {$curatorsStore} from "../../models/curatorsModel/index.js";
import {useEvent, useStore} from "effector-react";
import {
    $regionsLayerPolygonsFiltered,
    resetRegionsLayerPolygonsFilteredEv
} from "../../models/regionsLayerModel/index.js";
import {$activeFilters, resetActiveFiltersEv} from "../../models/activeFiltersModel/index.js";
import {hasActiveFilters} from "../../utils/active-filters-utils.js";
import {selectDotEv} from "../../models/dotsLayerModel/index.js";
import {AreaChartOutlined, ProjectOutlined} from "@ant-design/icons";

const Curators = () => {

    const {
        curatorsTableData,
        projectsTableData,
        projects_count,
        total_count
    } = useStore($curatorsStore)

    const selectDot = useEvent(selectDotEv)

    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (record) => {
        setSelectedRow(record.key);
    };

    const columnsTop = [
        {
            key: 'name',
            title: 'Наименование',
            dataIndex: 'name',
            ellipsis: true,
            render: (text) => <Tooltip arrow={false} placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            key: 'role',
            title: 'Роль',
            dataIndex: 'role',
            ellipsis: true,
            render: (text) => <Tooltip arrow={false} placement="topLeft" title={text}>{text}</Tooltip>
        },

    ]

    const dropdownItems = useCallback((proj_id, subj_id) => {
        return [
            {
                key: 'subject',
                label: <Link to={`/view-subject/${subj_id}`} target={'_blank'}>Просмотр предприятия</Link>
            },
            {
                key: 'project',
                label: <Link to={`/view-project/${proj_id}`} target={'_blank'}>Просмотр проекта</Link>
            },
        ]
    }, [])

    const DropDown = useCallback(({field, record}) => {
        return <Dropdown
            menu={{items: dropdownItems(record.proj_id, record.subj_id)}}
            placement={'topLeft'}
            arrow
            //trigger={['click']}
            trigger={['']} // dont trigger dropdown
        >
            <div style={{cursor: 'pointer'}} onClick={() => selectDot(record.proj_id)}>{field}</div>
            {/*<Tooltip arrow={false} placement="topLeft" title={field}>{field}</Tooltip>*/}
        </Dropdown>
    }, [])

    const columnsBottom = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Наименование проекта',
            width: '40%',
            ellipsis: true,
            render: (name, record) => <DropDown field={name} record={record} />
        },
        {
            key: 'subject',
            dataIndex: 'subject',
            width: '20%',
            title: 'Предприятие',
            render: (prod, record) => <DropDown field={prod} record={record}/>
        },
        {
            key: 'investments',
            dataIndex: 'investments',
            width: '10%',
            title: 'Сумма инвестиций (тенге)',
            render: (sum, record) => <DropDown field={sum} record={record}/>
        },
        {

            key: 'actions',
            width: '10%',
            title: 'Действия',
            align: 'center',
            render: (record) => (
                <div id={'buttons_container'} style={{display:"flex", gap: '20px', justifyContent:'center'}}>
                    <Tooltip title="Просмотр предприятия">
                        <Link to={`/view-subject/${record.subj_id}`} target={'_blank'}>
                            <Button className="info-btn" icon={<ProjectOutlined />} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Просмотр проекта">
                        <Link to={`/view-project/${record.proj_id}`} target={'_blank'}>
                            <Button className="info-btn" icon={<AreaChartOutlined />} />
                        </Link>
                    </Tooltip>
                </div>
            )
        },
    ]

    const stats = [
        {label: 'Кол-во проектов', value: projects_count},
        {label: 'Сумма инвестиций', value: total_count}
    ]

    // const tableData = [
    //     {
    //         id: 1,
    //         name: 'test',
    //         subject: 'some subj',
    //         investments: 40000000,
    //     },
    //     {
    //         id: 2,
    //         name: 'test 2',
    //         subject: 'some subj 2',
    //         investments: 60000000,
    //     }
    // ]

    const activeFilters = useStore($activeFilters)
    const resetActiveFilters = useEvent(resetActiveFiltersEv)

    useEffect(() => {
        if(hasActiveFilters(activeFilters)){
            setTimeout(() => {
                resetActiveFilters()
            }, 500)
        }
    }, []);

    return <ContainerInner title={'Кураторы проектов'}>
        <Row justify={'space-between'} className={'db-row-top'}>
            <Col span={11} className={'db-col1-top'}>
                   <Table columns={columnsTop} dataSource={curatorsTableData} scroll={{ y: '40vh' }} pagination={false}/>
            </Col>
            <Col style={{display: "flex", flexDirection:'column', padding: 'unset'}} span={11} className={'db-col2-top'}>
                <div style={{padding: '0.6rem 1.2rem'}}>
                    <StatsBlock data={stats}/>
                    <p>Карта сумм инвестиций в проекты по областям</p>
                </div>
                <MapWork />
            </Col>
        </Row>
        <Row className={'db-row-bottom'}>
            <Table
                dataSource={projectsTableData}
                columns={columnsBottom}
                style={{width: '100%'}}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                    className: record.key === selectedRow ? 'selectedRow' : '',
                })}
            />
        </Row>
    </ContainerInner>
}

export default Curators
