import {krp_dictionary} from "../../dictionaries/krp_dictionary.js";

export const prepareOrganizationData = (data) => {

	const statgov = data.statgov[0]

	const subjectInfoProps = {
		bin: data.gov_id,
		krp: statgov?.krp_name_ru,
		oked: statgov?.main_oked,
		activity: statgov?.activity_ru,
		address: statgov?.legal_address,
		oked_secondary: statgov?.secondary_oked,
		supervisor: statgov?.head_fio,
		addition: 0, //FIXME Didn't understand
		krp_count: krp_dictionary[statgov?.krp],
		reg_date: statgov?.registration_date,
		name: '', //FIXME Didn't understand
		reg: statgov?.kato_level_0,
	}

	const taxesChartData = data.taxes.map(item => {
		return {
			year: item.date,
			amount: item.amount
		}
	})

	const dots =  data.location ? [{
		type: 'Feature',
		geometry: {
			...data.location
		}
	}] : []

	const projectsData = data.projects.map((item, index) => {
		return {
			key: `${index}${item.id}`,
			proj_name: item.name,
			proj_cost: item.invest,
		}
	})

	const orgName = data.name

	const taxesDates = [data.taxes[0].date, data.taxes[data.taxes.length - 1].date]
	return {
		subjectInfoProps,
		taxesChartData,
		projectsData,
		dots,
		orgName,
		taxesDates
	}
}
