import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import logo from '../../assets/logo_2.png';
import styles from '../RegRequest/RegRequest.module.css';
import {
  $verificationStatus,
  verifyEmailFx,
  VerifyGate,
} from '../../models/authModel/index.js';

export default function EmailVerification() {
  const { t } = useTranslation();
  const { token } = useParams();
  const status = useUnit($verificationStatus);
  const loading = useUnit(verifyEmailFx.pending);

  return (
    <>
      <VerifyGate token={token} />
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" className={styles.logoMain} />
      </div>
      <Card
        className="login-form"
        title={t('Подтверждение электронной почты')}
        loading={loading}
        style={{ width: '60vw' }}
        styles={{ body: { display: 'flex', flexDirection: 'column' } }}
      >
        {status
          ? t('Ваша электронная почта подтверждена')
          : t('Произошла ошибка')}
        {status && (
          <Button type="primary" href="/login">
            {t('Продолжить')}
          </Button>
        )}
      </Card>
    </>
  );
}
