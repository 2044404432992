import { createEffect } from 'effector/effector.umd';
import { api } from '../../api/axiosInstance.js';

export const getReqListFx = createEffect().use(
  async () => (await api().get('/investment-proposals/')).data
);

export const getReqByIdFx = createEffect().use(
  async (id) => (await api().get(`/investment-proposals/${id}`)).data
);

export const createReqFx = createEffect().use(async (payload) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const fileList = new FormData();
  fileList.append('plan', payload.plan.file);
  fileList.append('presentation', payload.presentation.file);
  fileList.append('fin', payload.fin.file);
  if (payload.files && payload.files.length > 0) {
    for (const file of payload.files) {
      fileList.append('files', file.originFileObj);
    }
    delete payload.files;
  }
  delete payload.plan;
  delete payload.presentation;
  delete payload.fin;
  payload.costs = [];

  return api()
    .post('/investment-proposals/', payload)
    .then((res) => res.data.id)
    .then((id) =>
      api().post(`/investment-proposals/upload/${id}`, fileList, config)
    )
    .then((res) => res.data);
});

export const editReqFx = createEffect().use(async ({id, payload}) => {
  payload.costs = [];
  return (await api().put(`/investment-proposals/${id}`, payload)).data;
});

export const confirmReqFx = createEffect().use(
  async (id) => (await api().post(`/investment-proposals/approve/${id}`)).data
);

export const declineReqFx = createEffect().use(
  async (id) => (await api().post(`/investment-proposals/reject/${id}`)).data
);

export const downloadReqFileFx = createEffect().use(async (id) => {
  const file = await api().get(`/investment-proposals/download/${id}`, {responseType: 'blob'});
  const link = document.createElement('a');
  const url = URL.createObjectURL(file.data);
  link.href = url;
  link.target = '_blank';
  link.download = file.headers['content-disposition']
    .split('filename=')[1]
    .replace(/"/gm, '');
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
});

export const sendRequestFx = createEffect()
    .use(async (id) => (await api().post(`/investment-proposals/send/${id}`)).data)