import { sample } from 'effector';
import {
  $filters,
  $orgsData,
  $orgsTable,
  $reportsList,
  $reportsModal,
  $tableControl
} from './stores.js';
import {
  downloadReportFx,
  exportToExcelFx,
  getOrganizationsDataFx,
  getOrganizationsTableFx,
  getReportsListFx
} from './effects.js';
import {
  changeFiltersEv,
  changeTableStateEv, downloadReportEv, exportExcelEv, getReportsListEv, openReportsModalEv,
  OrgsDashboardGate, ReportsModalGate,
  resetFiltersEv, resetReportsModalEv,
  sendOrgsCallEv
} from './events.js';
import notification from 'antd/es/notification';

$orgsData
  .on(getOrganizationsDataFx.doneData, (state, payload) => payload)
  .reset(OrgsDashboardGate.close);

$orgsTable
  .on(getOrganizationsTableFx.doneData, (state, payload) => payload)
  .reset(OrgsDashboardGate.close);

$filters.on(changeFiltersEv, (state, payload) => {
    if (Array.isArray(payload)) {
      const newState = Object.fromEntries(
        payload.flatMap((obj) => Object.entries(obj))
      )
      return {...state, ...newState}
    } else {
      return {...state, ...payload}
    }
}).reset([resetFiltersEv, OrgsDashboardGate.close])

$tableControl.on(changeTableStateEv, (state, payload) => {
    return { ...state, ...payload };
  })
  .reset([OrgsDashboardGate.close, $filters.updates]);

$reportsModal.on(openReportsModalEv, () => true)
    .reset(resetReportsModalEv)

$reportsList.on(getReportsListFx.doneData, (state, payload) => payload.toSorted((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))
    .reset(resetReportsModalEv)

sample({
  clock: OrgsDashboardGate.open,
  target: [getOrganizationsDataFx, getOrganizationsTableFx],
});

const triggerKeys = [
  'show_all',
  'have_dvc',
  'is_gov',
  'have_contacts',
  'region',
  'district',
  'krp',
  'org_type',
  'legal_type',
  'department',
  'priority',
  'oked_level_0',
  'oked_level_1',
  'oked_level_2',
  'oked_level_3',
  'oked_level_4',
  'activity_category',
];
sample({
  source: $filters,
  clock: sendOrgsCallEv,
  filter: (filters) => window.location.pathname.includes('organizations') &&
  Object.keys(filters).some(key => triggerKeys.includes(key)),
  fn: (filters) => {
    const current = Object.fromEntries(Object.entries(filters)
        .filter(([key, value]) => triggerKeys.includes(key) && !!value))
    return current
  },
  target: getOrganizationsDataFx,
})

const triggerKeysTable = [...triggerKeys, 'bins'];
sample({
  source: {filters: $filters, tableState: $tableControl},
  clock: [sendOrgsCallEv, $tableControl.updates],
  filter: ({filters, tableState}) => window.location.pathname.includes('organizations') &&
    Object.keys(filters).some(key => triggerKeysTable.includes(key)),
  fn: ({filters, tableState}) => {
    const current = Object.fromEntries(Object.entries(filters)
        .filter(([key, value]) => triggerKeysTable.includes(key) && !!value))
    return {...current, ...tableState}
  },
  target: getOrganizationsTableFx,
})

sample({
  source: $filters,
  clock: exportExcelEv,
  fn: (filters) => Object.fromEntries(Object.entries(filters)
      .filter(([key, value]) => !!value)),
  target: exportToExcelFx
})

sample({
  clock: resetFiltersEv,
  target: [getOrganizationsDataFx, getOrganizationsTableFx],
})

sample({
  clock: downloadReportEv,
  target: downloadReportFx
})

sample({
  clock: ReportsModalGate.state,
  filter: (gate) => Object.hasOwn(gate, 'type'),
  fn: (gate) => gate.type ?? undefined,
  target: getReportsListEv,
});

sample({
  clock: exportToExcelFx.doneData,
  fn: () => undefined,
  target: getReportsListEv,
});

sample({
  clock: getReportsListEv,
  target: getReportsListFx,
});

exportToExcelFx.done.watch(() => notification.info({message: 'Отчет принят в работу'}))
