import {
  districtSumWidgetDataCounter,
  regionProjectsWidgetCounter,
  regionSumWidgetDataCounter,
} from '../investments/counters.js';
import {
  districts_dictionary,
  regions_dictionary,
  regions_districts_id_dictionary,
} from '../../dictionaries/regions_districts_dictionary.js';
import { prepareTreeSelectData } from '../utils/activityTreeSelectPreparator.js';
import { filterSelectOptions } from '../projects/index.js';

export const prepareProjectsMapData = (data) => {
  const counters = {
    regionSumWidgetData: regionSumWidgetDataCounter,
    regionProjectsWidgetData: regionProjectsWidgetCounter,
    districtSumWidgetData: districtSumWidgetDataCounter,
  };

  const summary = Object.fromEntries(
    Object.keys(counters).map((prop) => [prop, {}])
  );

  data.projects.forEach((item) => {
    Object.keys(counters).map(
      (prop) => (summary[prop] = counters[prop](item, summary[prop]))
    );
  });

  const region_widget_data = Object.entries(summary.regionSumWidgetData)
    .map(([key, value]) => {
      return {
        area: regions_dictionary[key] || `unknown ${key}`,
        amount: value,
        area_id: parseInt(key),
      };
    })
    .sort((a, b) => b.amount - a.amount);

  const dict = Object.entries(regions_districts_id_dictionary).map(
    ([region_id, districts]) => {
      return {
        [region_id]: Object.keys(districts),
      };
    }
  );

  const district_widget_data = Object.entries(summary.districtSumWidgetData)
    .filter(([key, value]) => key !== 'null')
    .map(([key, value]) => {
      const region = Object.entries(dict).find((item) =>
        Object.values(item[1])[0].includes(key)
      );

      const region_id = region ? +Object.keys(region[1])[0] : null;

      return {
        area: districts_dictionary[key] || `unknown ${key}`,
        amount: value,
        area_id: parseInt(key),
        region_id,
      };
    })
    .sort((a, b) => b.amount - a.amount);

  let projects_table_data = data.projects
    .filter((item) => item.id !== 1816)
    .map((item) => {
      return {
        key: `projRegTable${item.id}`,
        projectName: item.name,
        subject: item.organization_name,
        completion: `${item.realization_status}%`,
        // investments_total: new Intl.NumberFormat('ru-RU', {
        //   notation: 'compact',
        // }).format(item.budget.sum_plan),
        investments_total: item.budget.sum_plan,
        proj_id: item.id,
        subj_id: item.organization_id,
        curators: '',
        district_id: item.district_id,
        region_id: item.region_id,
        overdue: Math.round(Math.random() * 10),
        // showButton: item.id >= 1797,
        delay: item.delay,
      };
    });

  const sortedArray = Object.entries(projects_table_data).sort(
    (a, b) => b[1].proj_id - a[1].proj_id
  );

  projects_table_data = sortedArray.map((item) => item[1]);
  // projects_table_data = projects_table_data.map((item) => {
  //   return {
  //     ...item,
  //     investments_total: new Intl.NumberFormat('ru-RU', {
  //       notation: 'compact',
  //     }).format(item.investments_total),
  //   };
  // });

  const activity_select = prepareTreeSelectData(data.activities);

  const organizations_select = data.organizations.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  return {
    region_widget_data,
    district_widget_data,
    projects_table_data,
    activity_select,
    organizations_select,
  };
};

export const prepareFilteredProjectsMap = (
  projects,
  rawData,
  activeFilters
) => {
  const { activities, organizations } = filterSelectOptions(
    activeFilters,
    projects,
    rawData
  );

  const filteredData = {
    ...rawData,
    projects,
    activities,
    organizations,
  };

  return prepareProjectsMapData(filteredData);
};
