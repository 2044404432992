import { useEvent, useUnit } from 'effector-react';
import { Button, Divider, Modal, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
  BulbOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExportOutlined,
  LoadingOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import {
    $reportsList,
    $reportsModal,
    downloadReportEv,
    exportExcelEv,
    exportToExcelFx,
    getReportsListEv,
    getReportsListFx, ReportsModalGate,
    resetReportsModalEv,
} from '../../models/organizationsModel/index.js';

function getStatusIcon(status) {
  if (status === 'success') {
    return (
      <Tooltip title="Готово">
        <CheckCircleOutlined style={{ color: 'green', fontSize: 24 }} />
      </Tooltip>
    );
  }
  if (status === 'fail') {
    return (
      <Tooltip title="Ошибка">
        <CloseCircleOutlined style={{ color: 'red', fontSize: 24 }} />
      </Tooltip>
    );
  }
  if (status === 'process') {
    return (
      <Tooltip title="В работе">
        <LoadingOutlined style={{ fontSize: 24 }} />
      </Tooltip>
    );
  }
  if (status === 'new') {
    return (
      <Tooltip title="Ожидает очереди">
        <BulbOutlined style={{ color: 'lightblue', fontSize: 24 }} />
      </Tooltip>
    );
  }
}

export default function ReportsModal({ orgs, type }) {
  const open = useUnit($reportsModal);
  const data = useUnit($reportsList);
  const close = useUnit(resetReportsModalEv);
  const download = useUnit(downloadReportEv);
  const loading = useUnit(getReportsListFx.pending);
  const refresh = useUnit(getReportsListEv);
  const exportExcel = useEvent(exportExcelEv);
  const exportLoading = useUnit(exportToExcelFx.pending);

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'created_at',
      render: (date) => dayjs(date).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      align: 'center',
      render: (status) => getStatusIcon(status),
    },
    {
      title: (
        <Tooltip title="Обновить">
          <Button
            icon={<RedoOutlined />}
            onClick={() => refresh(type)}
            type="primary"
          />
        </Tooltip>
      ),
      width: '5%',
      align: 'center',
      render: ({ id, status }) =>
        status === 'success' ? (
          <Tooltip title="Скачать">
            <Button
              type="primary"
              ghost
              onClick={() => download(id)}
              icon={<DownloadOutlined />}
            />
          </Tooltip>
        ) : null,
    },
  ];

  return (
    <Modal
      title="Результаты экспорта"
      open={open}
      footer={null}
      onCancel={() => close()}
      width="80vh"
      destroyOnClose
    >
      <ReportsModalGate type={type} />
      {orgs && (
        <>
          <Tooltip
            title="Экспортировать текущую страницу в Excel"
            placement="right"
          >
            <Button
              type="primary"
              icon={<ExportOutlined />}
              loading={exportLoading}
              onClick={() => exportExcel()}
              style={{ width: 128 }}
              size="large"
            />
          </Tooltip>
          <Divider />
        </>
      )}
      <Table dataSource={data} columns={columns} loading={loading} />
    </Modal>
  );
}
