import locale from 'antd/locale/ru_RU'
import {Button, Modal, Table, Tooltip, Dropdown, Select, ConfigProvider, TreeSelect} from 'antd';
import { Link } from 'react-router-dom';
import { AreaChartOutlined } from '@ant-design/icons';
import { useUnit, useStoreMap } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectDotEv } from '../../../models/dotsLayerModel/index.js';
import {
  $districtsSelect, $globalStore,
  $regionsSelect, $activitiesTreeSelect
} from '../../../models/globalModel/index.js';
import {
  districtIdFilter, okedFilter, projectTypeFilter,
  regionIdFilter,
} from '../../../counters/projects/filters.js';
import {
  $activeProblemResp,
  $problemProjects,
  changeActiveProblemRespEv, downloadProjectsEv, downloadProjectsFx, getProblemProjectsEv,
} from '../../../models/overviewModel/index.js';
import {$activeFilters} from "../../../models/activeFiltersModel/index.js";
import {regions_dictionary} from "../../../dictionaries/regions_districts_dictionary.js";
// import activities from '../../data/activity.json';
import {$createEditProjectUtils} from '../../../models/dictionariesModel/index.js';
import {$userInfo} from '../../../models/authModel/index.js';

const { SHOW_PARENT } = TreeSelect;


// const activitiesSelect = activities
//     .filter((i) => i.activity_type_id === 2)
//     .map((i) => ({ label: i.name_ru, value: i.id }));

function OverviewModal({ isOpen, setIsOpen, type }) {
  const activeFiltersGlobal = useUnit($activeFilters)
  const projTypesSelect = useStoreMap($createEditProjectUtils, utils => utils.projTypeOptions)
  const curUsr = useUnit($userInfo);

  const allProjects = useStoreMap($globalStore, global => global.projects)
  const problemProjects = useUnit($problemProjects);
  const activeProblemResp = useUnit($activeProblemResp);
  const changeActiveProblemResp = useUnit(changeActiveProblemRespEv);
  const regions = useUnit($regionsSelect);
  const districts = useUnit($districtsSelect);
  const okedTree = useUnit($activitiesTreeSelect)
  const selectDot = useUnit(selectDotEv);
  const getProblemProjects = useUnit(getProblemProjectsEv);

  const downloadProjects = useUnit(downloadProjectsEv);
  const downloadProjectsLoading = useUnit(downloadProjectsFx.pending);

  const [projects_data, setProjectsData] = useState([]);

  useEffect(() => {
    if (type === 'problemProjects' && problemProjects?.length > 0) {
      setProjectsData(
          problemProjects.map((item) => {
            return {
              projectName: item.name,
              subject: item.organization,
              overdue: item.delay,
              investments_total: item.budget,
              districts_id: item.district_id,
              region_id: item.region_id,
              proj_id: item.id,
            };
          })
      );
    } else if (type === 'projectCount' && allProjects?.length > 0) {
      setProjectsData(
          allProjects.map((item) => {
          return {
              projectName: item.name,
              subject: item.organization_name,
              investments_total: item.budget?.sum_fact,
              districts_id: item.district_id,
              region_id: item.region_id,
              proj_id: item.id,
              overdue: item.delay
            };
          })
      );

    }
  }, [type, problemProjects]);

  const dropdownItems = useCallback((proj_id, subj_id) => {
    return [
      {
        key: 'subject',
        label: (
          <Link to={`/view-subject/${subj_id}`} target="_blank">
            Просмотр предприятия
          </Link>
        ),
      },
      {
        key: 'project',
        label: (
          <Link to={`/view-project/${proj_id}`} target="_blank">
            Просмотр проекта
          </Link>
        ),
      },
    ];
  }, []);

  const DropDown = useCallback(({ field, record }) => {
    return (
      <Dropdown
        menu={{ items: dropdownItems(record.proj_id, record.subj_id) }}
        placement="topLeft"
        arrow
        // trigger={['click']}
        trigger={['']} // dont trigger dropdown
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => selectDot(record.proj_id)}
        >
          {field}
        </div>
        {/* <Tooltip arrow={false} placement="topLeft" title={field}>{field}</Tooltip> */}
      </Dropdown>
    );
  }, []);

  const columns = useMemo(() => {
    const cols = [
      {
        title: '№',
        width: '3%',
        align: 'center',
        key: 'number',
        render: (_, __, idx) => idx + 1,
      },
      {
        key: 'name',
        width: '40%',
        title: 'Наименование проекта',
        dataIndex: 'projectName',
        render: (name, record) => <DropDown field={name} record={record} />,
      },
      {
        key: 'subject',
        width: '20%',
        title: 'Предприятие',
        dataIndex: 'subject',
        render: (manufact, record) => (
            <DropDown field={manufact} record={record} />
        ),
      },
      {
        title: 'Регион',
        key: 'region',
        dataIndex: 'region_id',
        render: (region) => regions_dictionary[region],
      },
      {
        key: 'overdue',
        width: '10%',
        title: 'Просрочка, дней',
        dataIndex: 'overdue',
        // defaultSortOrder: 'descend',
        sorter: (a, b) => {
          if (a.overdue > b.overdue) {
            return 1
          } else if (a.overdue < b.overdue) {
            return -1
          } else {
            return 0
          }
        },
        render: (record) => record == null ? 0 : record
      },
      {
        key: 'investments',
        width: '20%',
        title: 'Сумма инвестиций (млн тенге)',
        dataIndex: 'investments_total',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          if (a.investments_total > b.investments_total) {
            return 1
          } else if (a.investments_total < b.investments_total) {
            return -1
          } else {
            return 0
          }
        },
        render: (record) => record ? new Intl.NumberFormat('ru-RU').format(record) : 0
      },
      {
        key: 'actions',
        width: '15%',
        title: 'Действия',
        align: 'center',
        render: (record) => (
            <div
                id="buttons_container"
                style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}
            >
              {/* <Tooltip title="Просмотр предприятия"> */}
              {/*  <Link to={`/view-subject/${record.subj_id}`} target="_blank"> */}
              {/*    <Button className="info-btn" icon={<ProjectOutlined />} /> */}
              {/*  </Link> */}
              {/* </Tooltip> */}
              <Tooltip title="Просмотр проекта">
                <Link to={`/view-project/${record.proj_id}`} target="_blank">
                  <Button className="info-btn" icon={<AreaChartOutlined />} />
                </Link>
              </Tooltip>
            </div>
        ),
      },
    ];

    if (type === 'problemProjects') {
      const idx = cols.findIndex(i => i.key === 'overdue')
      cols[idx] = { ...cols[idx], defaultSortOrder: 'descend' };
      delete cols[idx + 1].defaultSortOrder
    }
    return cols
  }, [type])

  const [activeRegion, setActiveRegion] = useState(null);
  const [activeDistrict, setActiveDistrict] = useState(null);
  const [activeOkeds, setActiveOkeds] = useState([]);
  const [activeTypes, setActiveTypes] = useState([]);

  useEffect(() => {
    if (isOpen) {
      if (activeFiltersGlobal.region_id && !activeFiltersGlobal.district_id) {
        setActiveRegion(activeFiltersGlobal.region_id)
      } else if (activeFiltersGlobal.region_id && activeFiltersGlobal.district_id) {
        setActiveRegion(activeFiltersGlobal.region_id)
        setActiveDistrict(activeFiltersGlobal.district_id)
      }
      if (activeFiltersGlobal?.main_activity_id && activeFiltersGlobal?.main_activity_id?.length > 0) {
        setActiveOkeds(activeFiltersGlobal.main_activity_id)
      }
      if (activeFiltersGlobal?.project_type_id && activeFiltersGlobal?.project_type_id?.length > 0) {
        setActiveTypes(activeFiltersGlobal.project_type_id)
      }
    }
  }, [activeFiltersGlobal, isOpen]);

  const [districtsSelect, setDistrictSelect] = useState([])
  useEffect(() => {
    if (districts?.length > 0) {
      if (activeRegion) {
        setDistrictSelect(districts.filter(i => i.region_id === activeRegion)?.map((item) => {
          return {
            label: item.label,
            value: item.value
          }
        }))
      } else {
        setDistrictSelect(districts.map((item) => {
          return {
            label: item.label,
            value: item.value
          }
        }))
      }
    }
  }, [districts, activeRegion]);

  useEffect(() => {
    const activeFilters = {
      region_id: activeRegion,
      district_id: activeDistrict,
      main_activity_id: activeOkeds,
      project_type_id: activeTypes
    };

    let filtered_projects = []
    if (type === 'problemProjects') {
      filtered_projects = [...problemProjects]
      //     .filter((item) => {
      //   if (!regionIdFilter(activeFilters, item)) return false;
      //   if (!districtIdFilter(activeFilters, item)) return false;
      //   return true;
      // });
    } else if (type === 'projectCount') {
      filtered_projects = allProjects.filter((item) => {
        if (!regionIdFilter(activeFilters, item)) return false;
        if (!districtIdFilter(activeFilters, item)) return false;
        if(!okedFilter(activeFilters, item)) return false;
        if(!projectTypeFilter(activeFilters, item)) return false;
        if(item?.published === false) return false;
        return true;
      });
    }

    setProjectsData(
      filtered_projects?.map((item) => {
        return {
          projectName: item.name,
          subject: type === 'problemProjects' ? item.organization : item.organization_name,
          overdue: item.delay,
          investments_total: type === 'problemProjects' ? item.budget : item.budget?.sum_fact,
          districts_id: item.district_id,
          region_id: item.region_id,
          proj_id: item.id,
        };
      })
    );
  }, [activeProblemResp, activeDistrict, activeRegion, problemProjects, activeTypes, activeOkeds]);

  useEffect(() => {
    if (type === 'problemProjects') {
      getProblemProjects({
        region_id: activeRegion,
        district_id: activeDistrict,
        activity_type_id: activeOkeds,
        project_type_id: activeTypes
      })
    }
  }, [type, activeRegion, activeDistrict, activeOkeds, activeTypes]);

  const handleCancel = () => {
    setIsOpen({visible: false, type: null});
    setActiveRegion(null)
    setActiveDistrict(null)
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onActivitySelect = (value, label, extra) => {
    const payloadValue = [];

    const getOkedIds = (node) => {
      if (!payloadValue.includes(node.node.props.value)) {
        payloadValue.push(node.node.props.value);
      }
      if (node.children.length > 0) {
        node.children.forEach((child) => getOkedIds(child));
      }
    };

    if (extra.allCheckedNodes.length > 0) {
      extra.allCheckedNodes.forEach((checkedNode) => {
        getOkedIds(checkedNode);
      });
    }
    setActiveOkeds(payloadValue)
  }

  return (
    <Modal open={isOpen} onCancel={handleCancel} width="80%" footer={[]} destroyOnClose={true}>
      <div
          style={{
            display: 'flex',
          }}
      >
        {/*<div*/}
        {/*  style={{*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'column',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div>Исполнитель:</div>*/}
        {/*  <Select*/}
        {/*    options={selectOptions}*/}
        {/*    value={activeProblemResp}*/}
        {/*    onChange={changeActiveProblemResp}*/}
        {/*    style={{ width: '200px', marginRight: '20px' }}*/}
        {/*  />*/}
        {/*</div>*/}
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          <div>Область:</div>
          <Select
              options={regions}
              value={activeRegion}
              onChange={setActiveRegion}
              allowClear
              style={{width: '200px', marginRight: '20px'}}
              showSearch
              filterOption={filterOption}
          />
        </div>
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          <div>Район:</div>
          <Select
              options={districtsSelect}
              value={activeDistrict}
              onChange={setActiveDistrict}
              allowClear
              style={{width: '200px', marginRight: '20px'}}
              showSearch
              filterOption={filterOption}
          />
        </div>
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          <div>ОКЭД:</div>
          <TreeSelect
              value={activeOkeds}
              treeData={okedTree}
              showSearch
              treeNodeFilterProp={'title'}
              allowClear
              style={{width: '200px', maxHeight: 32, overflow: 'auto', marginRight: '20px'}}
              dropdownStyle={{width: 500}}
              listHeight={600}
              onChange={onActivitySelect}
              treeCheckable
              showCheckedStrategy={SHOW_PARENT}
          />
          {/*<Select*/}
          {/*    options={activitiesSelect}*/}
          {/*    value={activeOkeds}*/}
          {/*    onChange={setActiveOkeds}*/}
          {/*    mode={'multiple'}*/}
          {/*    allowClear*/}
          {/*    style={{width: '200px', marginRight: '20px'}}*/}
          {/*    showSearch*/}
          {/*    dropdownStyle={{width: 500}}*/}
          {/*    filterOption={filterOption}*/}
          {/*/>*/}
        </div>
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          <div>Тип проекта:</div>
          <Select
              options={projTypesSelect}
              value={activeTypes}
              onChange={setActiveTypes}
              mode={'multiple'}
              allowClear
              style={{width: '200px', marginRight: '20px'}}
              showSearch
              dropdownStyle={{width: 400}}
              filterOption={filterOption}
          />
        </div>
        {type === 'projectCount' && curUsr.role !== 'guest' && (
          <Button
            type="primary"
            onClick={() => downloadProjects()}
            style={{ alignSelf: 'flex-end' }}
            loading={downloadProjectsLoading}
          >
            Выгрузка проектов
          </Button>
        )}
      </div>
      <ConfigProvider locale={locale}>
        <Table
            columns={columns}
            dataSource={projects_data}
            size="small"
            pagination={false}
            style={{width: '100%', marginTop: '20px'}}
        />
      </ConfigProvider>
    </Modal>
  );
}

export default OverviewModal;
