import {$foreignProjectsList, $forProjModalState, $selectedForeignProject} from "./stores.js";
import {
    downloadFormFileEv,
    ForeignProjectsGate, getForeignInvestmentsListEv,
    resetForProjModalStateEv,
    setForProjModalStateEv, submitCreateFormEv, submitUpdateFormEv,
    verifyFormEv
} from "./events.js";
import {sample} from "effector";
import {
    createForInvestFx,
    downloadForProjFileFx,
    getForeignInvestByIdFx, getForeignInvestmentsListEditorFx,
    getForeignInvestmentsListFx, updateForInvestFx,
    verifyForProjFx
} from "./effects.js";
import notification from "antd/es/notification";
import {$userInfo} from "../authModel/index.js";

$forProjModalState
    .on(setForProjModalStateEv, (state, payload) => ({...state, ...payload}))
    .on([createForInvestFx.doneData, updateForInvestFx.doneData], (state) => ({...state, submitStatus: true}))
    .on([createForInvestFx.doneData, updateForInvestFx.doneData], (state) => ({...state, submitStatus: true}))
    .reset(resetForProjModalStateEv)

$foreignProjectsList.on([getForeignInvestmentsListFx.doneData, getForeignInvestmentsListEditorFx.doneData], (state, payload) => payload)
    .reset(ForeignProjectsGate.close)

$selectedForeignProject.on(getForeignInvestByIdFx.doneData, (state, payload) => payload)
    .reset(resetForProjModalStateEv)

sample({
    source: $userInfo,
    clock: [ForeignProjectsGate.open, verifyForProjFx.doneData, createForInvestFx.doneData, updateForInvestFx.doneData, $userInfo.updates],
    filter: () => window.location.pathname.includes('foreign-investments'),
    fn: (user) => ({logged: !!user?.id, role: user?.role}),
    target: getForeignInvestmentsListEv
})

sample({
    clock: submitCreateFormEv,
    target: createForInvestFx
})

sample({
    source: $forProjModalState,
    clock: submitUpdateFormEv,
    fn: (modal, form) => ({id: modal.id, payload: form}),
    target: updateForInvestFx
})

sample({
    clock: $forProjModalState.updates,
    filter: modal => !!(modal.mode === 'edit' && modal.id),
    fn: modal => modal.id,
    target: getForeignInvestByIdFx
})

sample({
    clock: verifyFormEv,
    target: verifyForProjFx
})

sample({
    clock: downloadFormFileEv,
    target: downloadForProjFileFx
})

sample({
    clock: getForeignInvestmentsListEv,
    filter: (user) => !user.logged || (user.role !== 'editor' && user.role !== 'admin'),
    target: getForeignInvestmentsListFx
})

sample({
    clock: getForeignInvestmentsListEv,
    filter: (user) => user.logged && (user.role === 'editor' || user.role === 'admin'),
    target: getForeignInvestmentsListEditorFx
})

downloadFormFileEv.watch(ev => ev && notification.info({message: 'Скачивание файла...', duration: 2}))