import style from './ViewProjectComponents.module.css';
import {Tooltip} from "antd";

function ProjectOverdue({ overdue, title, setOverdue }) {
  return overdue > 0 ? (
    <Tooltip title={'Показать просроченные этапы'}>
      <div
          className={style.overdue_card}
          onClick={() => {
            window.scroll({top: 800, behavior: 'smooth'})
            setOverdue(true)
          }}
      >
        <div className={style.project_view_card_text}>{title}:</div>
        <div className={style.overdue}>{overdue}</div>
      </div>
    </Tooltip>
  ) : (
    <div className={style.no_overdue_card}>
      <div className={style.project_view_card_text}>{title}:</div>
      <div>0</div>
    </div>
  );
}

export default ProjectOverdue;
