import i18 from '../../i18n';

export const prepareActivitiesSelectData = (activities) => {
  const activity_select = activities.map((item) => {
    return {
      label: item.name_ru,
      value: item.id,
    };
  });

  return {
    activity_select,
  };
};

export const prepareDictionariesData = (dictionaries) => {
  const budgetOptions = Object.values(dictionaries.budget_source).map(
    (item) => {
      return {
        label: item.name_ru,
        value: item.id,
      };
    }
  );

  const kpiOptions = Object.values(dictionaries.kpi).map((item) => {
    return {
      label: item.name_ru,
      value: item.id,
    };
  });

  const roleOptions = Object.values(dictionaries.role).map((item) => {
    return {
      label: item.name_ru,
      value: item.id,
    };
  });

  // const organizationOptions = Object.values(dictionaries.organizations).map(
  //   (item) => {
  //     return {
  //       label: item.name,
  //       value: item.id,
  //     };
  //   }
  // );

  const projStatusOptions = Object.values(dictionaries.project_statuses).map(
    (item) => {
      return {
        label: item.name,
        value: item.id,
      };
    }
  );

  const projTypeOptions = Object.values(dictionaries.project_types).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const truOptions = Object.values(dictionaries.tru_types).map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const tnvedOptions = Object.values(dictionaries.tnveds).map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const unitOptions = Object.values(dictionaries.units).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const investUnitOptions = Object.values(dictionaries.investment_proposal_units).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const investActivityOptions = Object.values(dictionaries.investment_proposal_activities).map((item) => ({
    label: i18.t(`industries.${item.name}`),
    value: item.id,
  }));

  const orgsMapByBin = Object.fromEntries(
    Object.values(dictionaries.organizations).map((org) => [org.gov_id, org])
  );

  return {
    budgetOptions,
    kpiOptions,
    roleOptions,
    // organizationOptions,
    projStatusOptions,
    projTypeOptions,
    orgsMapByBin,
    truOptions,
    tnvedOptions,
    unitOptions,
    investUnitOptions,
    investActivityOptions,
  };
};

export const prepareDictsMaps = (dictionaries) => {
  const truIdToNameMap = Object.fromEntries(
    Object.values(dictionaries.tru_types).map((item) => [item.id, item.name])
  )
  const unitsIdToNameMap = Object.fromEntries(
    Object.values(dictionaries.units).map((item) => [item.id, item.name])
  )

  const reqIdToResIdsMap = {}
  for (const res of Object.values(dictionaries.project_resources)) {
    if (reqIdToResIdsMap[res.requirement_id]) {
      reqIdToResIdsMap[res.requirement_id].push(res.id)
    } else {
      reqIdToResIdsMap[res.requirement_id] = [res.id]
    }
  }

  const projReqToResFieldsMap = Object.fromEntries(Object.values(dictionaries.project_resources)?.map(i => [i.name, i.id]));
  const projReqToResNameMap = Object.fromEntries(Object.values(dictionaries.project_resources)?.map(i => [i.id, i.name]));

  const requirementsNameMap = Object.fromEntries(Object.values(dictionaries.project_requirements)?.map((req) => [req.name, req.id]))
  const requirementsIdToNameMap = Object.fromEntries(Object.values(dictionaries.project_requirements)?.map((req) => [req.id, req.name]))
  const budgetsMap = Object.fromEntries(Object.values(dictionaries.budget_source)?.map((item) => [item.name_ru, item.id]));
  const budgetsIdToNameMap = Object.fromEntries(Object.values(dictionaries.budget_source)?.map((item) => [item.id, item.name_ru]));
  const effectsMap = Object.fromEntries(Object.values(dictionaries.project_effects)?.map((item) => [item.name, item.id]));
  const effectsIdToNameMap = Object.fromEntries(Object.values(dictionaries.project_effects)?.map((item) => [item.id, item.name]));

  const rolesMap = Object.fromEntries(Object.values(dictionaries.role)?.map((item) => [item.id, item.name_ru]));
  // const orgsMap = Object.fromEntries(Object.values(dictionaries.organizations)?.map((item) => [item.id, item.name]));

  const phasesMap = Object.fromEntries(Object.values(dictionaries.phases)?.map((item) => [item.id, item.name]));
  const stagesIdToNameMap = Object.fromEntries(Object.values(dictionaries.stages)?.map((item) => [item.id, item.name]));
  const stageStatusMap = Object.fromEntries(Object.values(dictionaries.stage_statuses)?.map((item) => [item.id, item.name]));

  const invActMap = Object.fromEntries(Object.values(dictionaries.investment_proposal_activities)?.map((item) => [item.id, item.name]));
  const invUnitMap = Object.fromEntries(Object.values(dictionaries.investment_proposal_units)?.map((item) => [item.id, item.name]));
  const invFileTypeMap = Object.fromEntries(Object.values(dictionaries.investment_proposal_file_types)?.map((item) => [item.id, item.name]));
  const countriesMap = dictionaries.countries;

  return {
    projReqToResFieldsMap,
    projReqToResNameMap,
    requirementsNameMap,
    requirementsIdToNameMap,
    budgetsMap,
    budgetsIdToNameMap,
    effectsMap,
    effectsIdToNameMap,
    rolesMap,
    // orgsMap,
    phasesMap,
    stageStatusMap,
    truIdToNameMap,
    unitsIdToNameMap,
    reqIdToResIdsMap,
    stagesIdToNameMap,
    invActMap,
    invUnitMap,
    invFileTypeMap,
    countriesMap,
  };
}

export const prepareProjectStagesDictionariesData = (dictionaries) => {
  const budgetOptions = Object.values(dictionaries.budget_source).map(
    (item) => {
      return {
        label: item.name_ru,
        value: item.id,
      };
    }
  );
  const stageStatusOptions = Object.values(dictionaries.stage_statuses).map(
    (item) => {
      return {
        label: item.name,
        value: item.id,
      };
    }
  );
  const stagesData = {};
  Object.values(dictionaries.stages).forEach((stage) => {
    if (!stagesData[stage.phase_id]) {
      stagesData[stage.phase_id] = [
        {
          label: stage.name,
          value: stage.id,
          stage: stage.phase_id,
        },
      ];
    } else {
      stagesData[stage.phase_id].push({
        label: stage.name,
        value: stage.id,
        stage: stage.phase_id,
      });
    }
  });
  return {
    budgetOptions,
    stageStatusOptions,
    stagesData,
  };
};
