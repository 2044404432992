import PieChart from './PieChart.jsx';
import style from './ViewProjectComponents.module.css';

const colorDict = {
  1: '#4318FF',
  2: '#9CBCE8',
  3: '#8367FF',
  4: '#55E7BB',
  5: '#6AD2FF',
  6: '#4995FF',
}

function ProjectCost({data, total}) {
  return (
    <div className={style.project_view_card} style={{ minHeight: 640 }}>
      <h1 className={style.project_view_card_title}>Стоимость проекта</h1>
      <div className={style.project_view_card_subtitle}>{`${new Intl.NumberFormat('ru-RU').format(total)} млн тенге`}</div>
      <PieChart data={data} />
      <div
        className={style.project_view_card_text}
        style={{ fontWeight: '600', marginTop: '16px' }}
      >
        Источники финансирования
      </div>
      <div className={style.flex_column}>
        {data.map((item, index) => {
          return (
            <div className={style.budget_source_item} key={item.source + index}>
              <div
                className={[
                  style.project_view_card_text,
                  style.source_badge,
                ].join(' ')}
                style={{ background: colorDict[item?.color] }}
              >
                {item.value}
              </div>
              <div className={style.project_view_card_text}>{item.source}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectCost;
// FIXME - backup with hardcode
// import PieChart from './PieChart.jsx';
// import style from './ViewProjectComponents.module.css';
//
// const data = [
//   {
//     source: 'Собственные средства',
//     value: 130.56,
//     color: '#4318FF',
//   },
//   {
//     source: 'Собственные средства (иностранный капитал)',
//     value: 130.56,
//     color: '#9CBCE8',
//   },
//   {
//     source: 'Заемные средства',
//     value: 130.56,
//     color: '#8367FF',
//   },
//   {
//     source: 'Заемные средства (иностранный капитал)',
//     value: 130.56,
//     color: '#55E7BB',
//   },
//   {
//     source: 'Бюджетные средства (местный бюджет)',
//     value: 130.56,
//     color: '#6AD2FF',
//   },
//   {
//     source: 'Бюджетные средства (республиканский бюджет)',
//     value: 130.56,
//     color: '#4995FF',
//   },
// ];
// function ProjectCost() {
//   return (
//     <div className={style.project_view_card} style={{ minHeight: '550px' }}>
//       <h1 className={style.project_view_card_title}>Стоимость проекта</h1>
//       <div className={style.project_view_card_subtitle}>457.8 млн тенге</div>
//       <PieChart data={data} />
//       <div
//         className={style.project_view_card_text}
//         style={{ fontWeight: '600', marginTop: '16px' }}
//       >
//         Источники финансирования
//       </div>
//       <div className={style.flex_column}>
//         {data.map((item, index) => {
//           return (
//             <div className={style.budget_source_item} key={item.source + index}>
//               <div
//                 className={[
//                   style.project_view_card_text,
//                   style.source_badge,
//                 ].join(' ')}
//                 style={{ background: item.color }}
//               >
//                 {item.value}
//               </div>
//               <div className={style.project_view_card_text}>{item.source}</div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
//
// export default ProjectCost;