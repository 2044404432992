import { Divider } from 'antd';
import style from './ViewProjectComponents.module.css';

// const data = [
//   {
//     inv_period: 10,
//     postinv_period: 100,
//     name: 'Количество рабочих мест, единиц',
//   },
//   {
//     inv_period: 0.3,
//     postinv_period: 720.5,
//     name: 'Ожидаемая сумма выплачиваемых налогов в год млн тенге',
//   },
//   {
//     inv_period: 0.2,
//     postinv_period: 220.5,
//     name: 'Налоги с ФОТ в год млн тенге',
//   },
//   {
//     inv_period: 0.1,
//     postinv_period: 500.0,
//     name: 'Прочие налоги в год, млн тенге',
//   },
// ];

function ProjectRealisationEffect({data}) {
  return (
    <div
      className={style.project_view_card}
      style={{ minHeight: '640px', padding: '15px' }}
    >
      <div className={style.project_view_card_title}>
        Эффект от реализации проекта
      </div>
      <div className={style.flex_column}>
        {data.map((item, index) => {
          return (
            <div key={item.name + index}>
              <div className={style.project_view_card_text}>{item.name}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                }}
              >
                <div
                  style={{ color: '#6AD2FF' }}
                  className={style.effect_number}
                >
                  {item.inv_period}
                </div>
                <div
                  style={{ color: '#4318FF' }}
                  className={style.effect_number}
                >
                  {item.postinv_period}
                </div>
              </div>
              <Divider style={{ margin: '20px 0px' }} />
            </div>
          );
        })}
      </div>
      <div
        style={{ display: 'flex', marginBottom: '8px' }}
        className={style.project_view_card_text}
      >
        <div className={style.effect_badge} style={{ background: '#6AD2FF' }} />
        Инвестиционный период (строительство)
      </div>
      <div style={{ display: 'flex' }} className={style.project_view_card_text}>
        <div className={style.effect_badge} style={{ background: '#4318FF' }} />
        Постинвестиционный (эксплуатационный) период
      </div>
    </div>
  );
}

export default ProjectRealisationEffect;
