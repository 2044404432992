import { sample } from 'effector';
import {getInvestorInfoFx, getParticipantsInfoFx, getProjectFx} from './effects.js';
import {$projectStore, $projInvestorInfo, $projParticipantsInfo} from './stores.js';
import {prepareInvestorInfo, prepareProjectData} from './utils.js';
import {ViewProjectGate} from './events.js';
import {$dictionariesMaps} from "../dictionariesModel/index.js";

$projectStore.reset(ViewProjectGate.close);

$projInvestorInfo.on(getInvestorInfoFx.doneData, (_, data) => prepareInvestorInfo(data))
    .reset(ViewProjectGate.close)

$projParticipantsInfo.on(getParticipantsInfoFx.doneData, (state, payload) => {
      const newState = {...state}
      payload.forEach((org) => {
          newState[org.id] = org
        })
      return newState
  })
    .reset(ViewProjectGate.close)

sample({
  clock: $projectStore.updates,
  filter: (data) => !!data.participants && data.participants.every(p => !!p.organization_id),
  fn: (data) => data.participants.map(p => p.organization_id),
  target: getParticipantsInfoFx
})

sample({
  clock: ViewProjectGate.open,
  filter: (gate) => !!gate.id,
  fn: (gate) => gate.id,
  target: getProjectFx
})

sample({
  source: $dictionariesMaps,
  clock: getProjectFx.doneData,
  fn: (dicts, project) => prepareProjectData(project, dicts),
  target: $projectStore,
});

sample({
  clock: getProjectFx.doneData,
  filter: project => project?.participants?.length > 0,
  fn: ({participants}) => participants.find(p => p.role_id === 1)?.organization_id ?? null,
  target: getInvestorInfoFx
})