import { createStore } from 'effector';

const projectInitialState = {
  name: '',
  main_activity_id: null,
  updated_at: null,
  start_date_plan: null,
  stages: [],
  region_id: null,
  district_id: null,
  location: {},
  description: '',
  status: '',
  capacities: [],
  budgets: [],
  budgets_total: null,
  budgets_sum: [],
  participants: [],
  requirements: [],
  resources: [],
  effects: [],
  contacts: [],
};

export const $projectStore = createStore(projectInitialState);

export const $projInvestorInfo = createStore(null);

export const $projParticipantsInfo = createStore({})
