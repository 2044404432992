import { months_dictionary } from '../../dictionaries/months_dictionary.js';
import {
  districts_dictionary,
  regions_dictionary
} from '../../dictionaries/regions_districts_dictionary.js';

export const prepareDashboardModalData = (data) => {
  const result = [];
  Object.entries(data).forEach(([year, values]) => {
    if (year !== 'None') {
      values
        ?.toSorted((a, b) => a.month - b.month)
        ?.forEach((i) => {
          result.push({
            month: `${months_dictionary[i.month]} ${year}`,
            value: i.value,
            year,
          });
        });
    }
    if (year === 'None') {
      values?.forEach((i) => {
        result.unshift({
          month: `Без даты`,
          value: i.value,
          year,
        });
      });
    }
  });
  return result;
};

export function prepareBizRegionModalData(data, granularity) {
  return Object.entries(data)
      .map(([region_id, districtsData]) => (
          { id: region_id, name: regions_dictionary[region_id], quarters_avg: extractRegionAvg(districtsData, granularity) }
      ));
}

function extractRegionAvg(districts, granularity = 'quarter') {
  const avgPerQuarter = {};
  for (const district of Object.values(districts)) {
    for (const quarter of district) {
      if (granularity === 'quarter') {
        if (quarter.quarter) {
          avgPerQuarter[`${quarter.year}_${quarter.quarter}`]
              ? avgPerQuarter[`${quarter.year}_${quarter.quarter}`].push(...Object.values(quarter.categories))
              : (avgPerQuarter[`${quarter.year}_${quarter.quarter}`] = Object.values(quarter.categories));
        }
      } else  if (granularity === 'year') {
        avgPerQuarter[`${quarter.year}`]
            ? avgPerQuarter[`${quarter.year}`].push(...Object.values(quarter.categories))
            : (avgPerQuarter[`${quarter.year}`] = Object.values(quarter.categories));
      }
    }
  }
  for (const [quarter, values] of Object.entries(avgPerQuarter)) {
    avgPerQuarter[quarter] = (values.reduce((a, b) => a + b, 0) / values.length).toFixed(2);
  }
  return avgPerQuarter;
};


export function prepareBizDistrictModalData(data, region, granularity, quarter, year) {
  const title = regions_dictionary[region];
  const districtsData = data[region];
  const years = Array.from(new Set(Object.values(districtsData).flatMap((i) => i.map(j => j.year))));
  const table = []
  const charts = {column: [], bar: []}
  charts.column = Object.entries(extractRegionAvg(districtsData)).map(([q, avg]) => (
    {quarter: `${q.split('_')[1]} кв. ${q.split('_')[0]}`, value: +avg,}));

  if (granularity === 'quarter') {
    const quartersAll = Object.values(districtsData).flatMap(i => i.filter(q => q.year === year && q.quarter === quarter))
    const perCategories = extractQuarterStatsPerCatAvg(quartersAll);
    charts.bar = Object.entries(perCategories).map(([category, avg]) => (
        {category, value: +avg,})
    )
  }

  for (const [district_id, quarters] of Object.entries(districtsData)) {
    if (granularity === 'quarter') {
      const perCategories = extractQuarterStatsPerCatAvg(quarters.filter(i => i.year === year && i.quarter === quarter));
      table.push({
        id: district_id,
        name: districts_dictionary[district_id],
        avg: Object.values(perCategories).reduce((a, b) => a + Number(b), 0) / Object.values(perCategories).length,
        categories: perCategories,
      });
    } else {
      const perCategories = extractQuarterStatsPerCatAvg(quarters);
      charts.bar = Object.entries(perCategories).map(([category, avg]) => (
          {category, value: +avg,})
      )
      table.push({
        id: district_id,
        name: districts_dictionary[district_id],
        avg: Object.values(perCategories).reduce((a, b) => a + Number(b), 0) / Object.values(perCategories).length,
        categories: perCategories,
      });
    }
  }
  return { title, table, years, charts };
}

function extractQuarterStatsPerCatAvg(quarters) {
  const perCat = {}
  for (const quarter of quarters) {
    for (const [category, value] of Object.entries(quarter.categories)) {
      perCat[category] ? perCat[category].push(value) : (perCat[category] = [value]);
    }
  }
  for (const [category, values] of Object.entries(perCat)) {
    perCat[category] = (values.reduce((a, b) => a + b, 0) / values.length).toFixed(2);
  }
  return perCat;
}