import { sample } from 'effector';
import { splitMap } from 'patronum';
import booleanIntersects from '@turf/boolean-intersects';
import {
  $binDictionary,
  $binSelectOptions,
  $globalStore,
  $mapLoaded,
  $projectsStore,
} from './stores.js';
import { getRawDataFx } from './effects.js';
import { setInvestmentsStartDataEv } from '../investmentsModel/index.js';
import { setCuratorsStartDataEv } from '../curatorsModel/index.js';
import { updateRegionsEv } from '../regionsLayerModel/index.js';
import { updateDotsEv } from '../dotsLayerModel/index.js';
import { setProjRegistryDataEv } from '../projRegistryModel/index.js';
import { resetMapLoadedEv, setMapLoadedEv } from './events.js';
import { regions_districts_id_dictionary } from '../../dictionaries/regions_districts_dictionary.js';
import { updateDistrictsEv } from '../districtsModel/index.js';
import {
  prepareBinDict,
  prepareBinOptions,
} from '../../counters/globalStore/index.js';
import { $activeFilters } from '../activeFiltersModel/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { filterProjects } from '../../counters/projects/index.js';
import { $activeGradient } from '../gradientModel/index.js';
import { setProjectsMapStartDataEv } from '../projectsMapModel/index.js';
import { deleteProjectFx } from '../projectCreateEditModel/index.js';
import {getRegInfoFx} from '../authModel/index.js';

$globalStore.on(getRawDataFx.doneData, (_, payload) => payload)
  .on(getRegInfoFx.doneData, (_, payload) => payload);
$projectsStore.on(getRawDataFx.doneData, (_, payload) => payload.projects);

$mapLoaded.on(setMapLoadedEv, (_, payload) => payload).reset(resetMapLoadedEv);

// FIXME Function to match projects points with districts
// $globalStore.on(getRawDataFx.doneData, (_, payload) => {
// 	const districts_features = []
//
// 	const district_projects = {}
//
// 	Object.values(regions_districts_id_dictionary).forEach(region_districts => {
// 		Object.entries(region_districts).forEach(([id, district]) => {
// 			const dist = district[0]
// 			const feature = {
// 				type: 'Feature',
// 				properties: {
// 					district_id: id
// 				},
// 				geometry: {
// 					...dist
// 				}
// 			}
// 			if(Object.keys(feature.geometry).length > 0){
// 				districts_features.push(feature)
// 			}
// 		})
// 	})
//
// 	const projects_with_coords = payload.projects.filter(item => item.objects_to_be.length > 0)
//
// 	const objects_points = new Set()
// 	projects_with_coords.forEach(project => {
// 		project.objects_to_be.forEach(object => {
// 			const object_feature = {
// 				type: 'Feature',
// 				geometry: {
// 					...object.geo_territories.geometries[0]
// 				},
// 				properties: {
// 					project_id: project.id,
// 					project_name: project.name
// 				}
// 			}
// 			objects_points.add(object_feature)
// 		})
// 	})
//
// 	let iterations = 0
//
// 	districts_features.forEach(feature => {
// 		objects_points.forEach(object => {
// 			if(booleanIntersects(feature, object)){
// 				if(district_projects[feature.properties.district_id]){
// 					if(!district_projects[feature.properties.district_id].includes(object.properties.project_id)){
// 						district_projects[feature.properties.district_id].push(object.properties.project_id)
// 					}
// 				} else {
// 					district_projects[feature.properties.district_id] = [object.properties.project_id]
// 				}
// 				objects_points.delete(object)
// 			}
// 			iterations++
// 		})
// 	})
//
// 	console.log(JSON.stringify(district_projects))
//
// 	const test_object = {}
//
// 	payload.projects.forEach(project => {
// 		if(test_object[project.district_id]){
// 			test_object[project.district_id].push(project.id)
// 		} else {
// 			test_object[project.district_id] = [project.id]
// 		}
// 	})
//
// 	const new_dict = {}
//
// 	payload.projects.forEach(project => {
// 		new_dict[project.id] = {
// 			region_id: project.region_id,
// 			district_id: +Object.entries(district_projects).filter(([dist_id, proj_ids]) => {
// 				return proj_ids.includes(project.id)
// 			}).map(([dist_id, proj_ids]) => dist_id)[0] || null
// 		}
// 	})
//
// 	debugger
// })

// sample({
//   clock: getRawDataFx.doneData,
//   fn: (clock) => {
//     return {
//       projects: clock.projects,
//       activities: clock.activities,
//       organizations: clock.organizations,
//     };
//   },
//   target: setInvestmentsStartDataEv,
// });

// sample({
//   clock: getRawDataFx.doneData,
//   fn: (clock) => {
//     return {
//       projects: clock.projects,
//       curators: clock.curators,
//     };
//   },
//   target: setCuratorsStartDataEv,
// });

// sample({
//   clock: getRawDataFx.doneData,
//   fn: (clock) => {
//     return {
//       projects: clock.projects,
//       activities: clock.activities,
//       organizations: clock.organizations,
//     };
//   },
//   target: setProjRegistryDataEv,
// });

sample({
  clock: getRawDataFx.doneData,
  fn: (clock) => {
    return {
      projects: clock.projects,
      activities: clock.activities,
      organizations: clock.organizations,
    };
  },
  target: setProjectsMapStartDataEv,
});

sample({
  clock: getRawDataFx.doneData,
  fn: (clock) => clock.projects,
  target: updateRegionsEv,
});

sample({
  clock: getRawDataFx.doneData,
  fn: (clock) => clock.projects,
  target: updateDistrictsEv,
});

sample({
  clock: getRawDataFx.doneData,
  fn: (clock) => ({ projects: clock.projects, activities: clock.activities }),
  target: updateDotsEv,
});

// sample({
// 	clock: setMapLoadedEv,
// 	target: $mapLoaded
// })

sample({
  clock: getRawDataFx.doneData,
  fn: (clock) => prepareBinDict(clock.projects),
  target: $binDictionary,
});

sample({
  clock: $projectsStore.updates,
  fn: (clock) => prepareBinDict(clock),
  target: $binDictionary,
});

sample({
  clock: $binDictionary.updates,
  fn: (clock) => prepareBinOptions(clock),
  target: $binSelectOptions,
});

sample({
  source: $globalStore,
  clock: $activeFilters.updates,
  fn: (source, clock) => {
    if (hasActiveFilters(clock)) {
      return filterProjects(clock, source);
    }
    return source.projects;
  },
  target: $projectsStore,
});

sample({
  clock: deleteProjectFx.doneData,
  target: getRawDataFx,
});
