import { Select } from 'antd';
import { useEvent, useStore, useStoreMap } from 'effector-react';
import { useState } from 'react';
import { $createEditProjectUtils } from '../../models/dictionariesModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';

export default function ProjectTypeSelector() {
  const projTypesSelect = useStoreMap(
    $createEditProjectUtils,
    (utils) => utils.projTypeOptions
  );

  const activeProjectType = useStore($activeFilters).project_type_id;
  const changeActiveFilter = useEvent(changeActiveFilterEv);

  const onProjectTypeSelect = (value) => {
    changeActiveFilter({
      field: 'project_type_id',
      value: value || [],
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <Select
      style={{
        width: '220px',
        color: 'black',
      }}
      options={projTypesSelect}
      value={activeProjectType}
      onChange={onProjectTypeSelect}
      mode="multiple"
      allowClear
      showSearch
      dropdownStyle={{ width: 400 }}
      filterOption={filterOption}
      placeholder="Выберите тип проекта"
    />
  );
}
