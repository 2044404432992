import React from 'react';
import { Card, Typography } from 'antd';
import dict from '../../../src/data/activity.json'
const { Title } = Typography;

const SubjectInfo = (props) => {
    const {
        bin,
        krp,
        reg,
        oked,
        activity,
        address,
        oked_secondary,
        supervisor,
        addition,
        krp_count,
        reg_date,
        name
    } = props

    function getOkedById(id) {
        const item = dict.find(item => item.id === id);
        return item ? item.name_ru : id;
    }
    return (
        <Card style={{boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)',height: '100%'}}>
            <Title level={4} style={{margin: '0 0 10px 0',}}>Свойства организации</Title>
            <div style={{display:'flex', flexDirection:"column", gap:'7px'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>БИН</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{bin || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Наименование КРП</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{krp || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Регион</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{reg || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>ОКЭД Основной</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{getOkedById(oked) || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Основной вид деятельности</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{activity || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Юридический адрес</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{address || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Втор.ОКЭД</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{oked_secondary || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>ФИО Руководителя</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{supervisor || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Примечание</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{addition || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Количество КРП</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{krp_count || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Дата регистрации</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{reg_date || '(Пусто)'}</span>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <span><strong>Секция наименование</strong></span>
                    <span style={{fontSize:'14px',fontFamily:'sans-serif'}}>{name || '(Пусто)'}</span>
                </div>
            </div>

        </Card>
    );
}

export default SubjectInfo;
