import {createEffect} from "effector";
import {api} from "../../api/axiosInstance.js";

export const createProjectFx = createEffect()
    .use(async (payload) => (await api().post('/project/', payload)).data)

export const getProjectByIdFx = createEffect().use(async (id) => {
    return (await api().get(`/project/${id}`)).data;
});

export const getParticipantsFx = createEffect()
    .use(async (bin) => {
        return (await Promise.all([...bin.map(id => api().get(`/organization/gov/${id}`))])).map(res => res.data)
    })

export const updateProjectFx = createEffect()
    .use(async ({id, values}) => (await api().put(`/project/${id}`, values)).data)

export const deleteProjectFx = createEffect()
    .use(async (id) => (await api().delete(`/project/${id}`)).data)

export const publishProjectFx = createEffect()
    .use(async (id) => (await api().post(`/project/publish/${id}`)).data)

export const rollbackProjectFx = createEffect()
    .use(async (id) => (await api().post(`/project/rollback/${id}`)).data)