import { useCallback, useMemo, useState } from 'react';
import {useStoreMap, useUnit} from 'effector-react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  Input,
  Popconfirm, Popover,
  Row,
  Table,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined, RollbackOutlined, StopOutlined,
  ToTopOutlined,
} from '@ant-design/icons';
import ContainerInner from '../ContentContainer/ContainerInner.jsx';
import { $userInfo } from '../../models/authModel/index.js';
import { $globalStore } from '../../models/globalModel/index.js';
import {
  deleteProjectEv,
  publishProjectEv,
  rollbackProjectEv
} from '../../models/projectCreateEditModel/index.js';

function getStatusColor(npp, rpp, mio, publish_on_process, published) {
  if (!publish_on_process && !published && npp !== false && rpp !== false && mio !== false) {
    return '#ffe017';
  } else if (!publish_on_process && !published && (npp === false || rpp === false || mio === false)) {
    return '#ff0000';
  } else if (published && npp && rpp && mio) {
    return '#7dea3d';
  } else if (publish_on_process && (!npp || !rpp || !mio)) {
    return '#2B3674FF';
  }
}

function StatusText({npp, rpp, mio, record}) {
    if (!record?.publish_on_process && !record.published && npp !== false && rpp !== false && mio !== false) {
      return <span>Не опубликован</span>;
    } else if (!record?.publish_on_process && !record.published && (npp === false || rpp === false || mio === false)) {
      return <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <span><strong>Отказано</strong></span>
        {record?.npp_comment && <span><strong>НПП:</strong> {record?.npp_comment}</span>}
        {record?.rpp_comment && <span><strong>РПП:</strong> {record?.rpp_comment}</span>}
        {record?.mio_comment && <span><strong>МИО:</strong> {record?.mio_comment}</span>}
      </div>
    } else {
      return <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <span><strong>РПП:</strong> {rpp ? 'Одобрено' : 'На рассмотрении'}</span>
        <span><strong>НПП:</strong> {npp ? 'Одобрено' : 'На рассмотрении'}</span>
        <span><strong>МИО:</strong> {mio ? 'Одобрено' : 'На рассмотрении'}</span>
      </div>
    }
}

function isUnpublished(record) {
  if (!record?.publish_on_process && !record.published && (record?.npp_approve === false || record?.rpp_approve === false || record?.mio_approve === false)) {
      return false
  } else if (!record.publish_on_process && !record.published) {
    return true
  }
}

function EditList() {
  const projects = useStoreMap($globalStore, (globalStore) =>
    globalStore?.projects?.map((item) => {
      if (item.gov_id.toString().length < 12) {
        item.gov_id =
          '0'.repeat(12 - item.gov_id.length) + item.gov_id.toString();
        return item;
      }
      return item;
    }) ?? []
  );

  const location = useLocation();
  const projView = location.pathname === '/edit-project';
  const userInfo = useUnit($userInfo);

  const deleteProject = useUnit(deleteProjectEv);
  const publishProject = useUnit(publishProjectEv);
  const rollbackProject = useUnit(rollbackProjectEv);

  const projectsTableDataInit = projects.map((item) => {
    return {
      key: `${item.name}${item.id}`,
      proj_id: item.id,
      projectName: item.name,
      gov_id: item.gov_id,
      publish_on_process: item.publish_on_process,
      published: item.published,
      npp_approve: item.npp_approve,
      rpp_approve: item.rpp_approve,
      mio_approve: item.mio_approve,
      npp_comment: item.npp_comment,
      rpp_comment: item.rpp_comment,
      mio_comment: item.mio_comment,
    };
  });

  const [nameSearch, setNameSearch] = useState('');
  const [binSearch, setBinSearch] = useState('');

  const projectsTableDataFiltered = useMemo(() => {
    let projectsTableData = projectsTableDataInit;
    if (nameSearch.length > 0) {
      projectsTableData = projectsTableData.filter((item) =>
        item?.projectName?.toLowerCase()?.includes(nameSearch.toLowerCase())
      );
    }
    if (binSearch.length > 0) {
      projectsTableData = projectsTableData.filter((item) =>
        item?.gov_id?.toString()?.includes(binSearch)
      );
    }
    return projectsTableData;
  }, [projectsTableDataInit, nameSearch, binSearch, userInfo.role]);

  const dropdownItems = useCallback((proj_id, subj_id) => {
    return [
      {
        key: 'project',
        label: (
          <Link
            to={
              projView ? `/view-project/${proj_id}` : `/view-project/${subj_id}`
            }
            target="_blank"
          >
            Просмотр проекта
          </Link>
        ),
      },
    ];
  }, []);

  const DropDown = useCallback(({ field, record }) => {
    return (
      <Dropdown
        menu={{ items: dropdownItems(record.proj_id, record.subj_id) }}
        placement="topLeft"
        arrow
        trigger={['click']}
      >
        <div style={{ cursor: 'pointer' }}>{field}</div>
        {/* <Tooltip arrow={false} placement="topLeft" title={field}>{field}</Tooltip> */}
      </Dropdown>
    );
  }, []);

  const columns = useMemo(() => {
    const cols = [
      {
        key: 'name',
        title: projView ? 'Наименование проекта' : 'Наименование предприятия',
        dataIndex: projView ? 'projectName' : 'subject',
        render: (name, record) => <DropDown field={name} record={record} />,
      },
      {
        key: 'bin',
        title: 'БИН',
        dataIndex: 'gov_id',
      },
      {
        key: 'edit',
        title: 'Действия',
        width: '15%',
        render: (record) =>
            ['admin', 'chamber', 'investor'].includes(userInfo.role) ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tooltip
                      title={(isUnpublished(record) || ['admin', 'npp', 'rpp', 'chamber'].includes(userInfo.role)) ? 'Редактировать проект' : 'Проект уже опубликован'}
                  >
                    <Link
                        aria-disabled={!isUnpublished(record) && userInfo.role === 'investor'}
                        to={isUnpublished(record) || ['admin', 'npp', 'rpp', 'chamber'].includes(userInfo.role) ? `/edit-project/${record.proj_id}` : '#'}
                    >
                      <Button className="info-btn" icon={<EditOutlined />} disabled={!isUnpublished(record) && userInfo.role === 'investor'}/>
                    </Link>
                  </Tooltip>
                  {['investor', 'chamber'].includes(userInfo.role) && isUnpublished(record) && (
                      <Tooltip title="Опубликовать проект">
                        <Popconfirm
                            title="Вы уверены, что хотите опубликовать проект?"
                            onConfirm={() => publishProject(record.proj_id)}
                            okText="Да"
                            cancelText="Нет"
                        >
                          <Button icon={<ToTopOutlined />} />
                        </Popconfirm>
                      </Tooltip>
                  )}
                  {['investor', 'chamber'].includes(userInfo.role) && !isUnpublished(record) && (
                      <Tooltip title="Отозвать с рассмотрения">
                        <Popconfirm
                            title="Вы уверены, что хотите отозвать проект с рассмотрения?"
                            onConfirm={() => rollbackProject(record.proj_id)}
                            okText="Да"
                            cancelText="Нет"
                        >
                          <Button icon={<StopOutlined />} />
                        </Popconfirm>
                      </Tooltip>
                  )}
                  <Tooltip title="Удалить проект">
                    <Popconfirm
                        title="Вы уверены, что хотите удалить проект?"
                        onConfirm={() => deleteProject(record.proj_id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                      <Button danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
            ) : null,
        align: 'center',
      },
    ]
    if (userInfo.role === 'investor') {
      cols.splice(2, 0, {
        key: 'status',
        title: 'Статус',
        width: '20%',
        align: 'center',
        render: (record) => <Popover
            content={<StatusText npp={record.npp_approve} rpp={record.rpp_approve} mio={record.mio_approve} record={record}/>}
        >
          <div style={{display: 'inline-block', width: 24, height: 24, borderRadius: '50%', backgroundColor: getStatusColor(record.npp_approve, record.rpp_approve, record.mio_approve, record.publish_on_process, record.published)}}/>
        </Popover>
      })
    }
    return cols
  }, [userInfo, projectsTableDataFiltered]);
  // const extra = <Tooltip title={projView ? 'Создать проект' : 'Создать предприятие'} placement='left'>
  //     <Link to={projView ? '/create-project' : 'create-subject'}>
  //         <Button className='info-btn' icon={<PlusCircleOutlined />}/>
  //     </Link>
  // </Tooltip>
  return (
    <ContainerInner title="Список проектов" /* extra={extra} */>
      <Row style={{ marginBottom: 24 }}>
        <Card style={{ width: '100%' }}>
          <Row justify="space-between">
            <Input.Search
              placeholder="Поиск по наименованию"
              allowClear
              onSearch={(value) => setNameSearch(value)}
              style={{ width: '40%' }}
            />
            <Input.Search
              placeholder="Поиск по БИН"
              allowClear
              onSearch={(value) => setBinSearch(value)}
              style={{ width: '40%' }}
            />
          </Row>
        </Card>
      </Row>
      <Row style={{ minHeight: '40%' }}>
        <Table
          columns={columns}
          dataSource={projectsTableDataFiltered}
          size="large"
          pagination={{
            position: ['bottomCenter'],
            defaultPageSize: 20,
            pageSizeOptions: [20, 50, 100],
          }}
          style={{ width: '100%' }}
        />
      </Row>
    </ContainerInner>
  );
}

export default EditList;
