import { Col, Row } from 'antd';
import style from './ViewProjectComponents.module.css';
import MapWork from '../../pages/MapWork/MapWork.jsx';
import {useStore} from "effector-react";
import {$projectStore} from "../../models/viewProjectModel/index.js";
import {$activitiesMap, $districtsMap, $regionsMap} from "../../models/globalModel/index.js";
import {$dictionariesMaps} from "../../models/dictionariesModel/index.js";

const data = [
  {
    title: 'Вид ТРУ',
    value: 'Товар',
  },
  {
    title: 'Наименование ТНВЭД',
    value: '7907000001',
  },
  {
    title: 'Количество',
    value: 150000,
  },
  {
    title: 'Единица измерения',
    value: 'Метр',
  },
  {
    title: 'Экспорт продукции за границу РК',
    value: 'нет',
  },
];

const nameDict = {
  tru_type_id: 'Вид ТРУ',
  tnved: 'Наименование ТНВЭД',
  unit_id: 'Единица измерения',
  export: 'Экспорт продукции за границу РК',
  value: 'Количество'
}

function ProjectCommonInfo() {
  const {name, district_id, region_id, main_activity_id, description, capacities} = useStore($projectStore)

  const activitiesMap = useStore($activitiesMap)
  const regionsMap = useStore($regionsMap)
  const districtsMap = useStore($districtsMap)


  const {truIdToNameMap, unitsIdToNameMap} = useStore($dictionariesMaps)

  const capacitiesForMapping = Object.entries(capacities)
      ?.filter(([key]) => Object.keys(nameDict).includes(key))
      ?.map(([key, value]) => (
          {
            title: nameDict[key],
            value: key === 'export'
                ? (value ? 'Да' : 'Нет')
                : key === 'tru_type_id'
                    ? truIdToNameMap[value]
                    : key === 'unit_id'
                        ? unitsIdToNameMap[value] : value,
          }
      ))


  return (
    <div>
      {/*<div className={style.block_title}>Общие сведения</div>*/}
      <div className={style.block_subtitle}>
        {name}
      </div>
      <Row gutter={[0, 20]} style={{ marginTop: '32px' }}>
        <Col
          span={8}
          style={{
            height: '286px',
            borderRadius: '20px',
            overflow: 'hidden',
          }}
        >
          <MapWork isInnerPage />
        </Col>
        <Col span={16} style={{ paddingLeft: '20px' }}>
          <Row gutter={[20, 20]}>
            <Col span={9}>
              <div
                className={style.project_view_card}
                style={{
                  minHeight: '150px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div className={style.common_text_small}>Регион</div>
                <div className={style.common_text_big}>
                  {regionsMap[region_id] ?? ''}
                </div>
                <div className={style.common_text_small}>Район</div>
                <div className={style.common_text_big}>
                  {districtsMap[district_id] ?? ''}
                </div>
              </div>
            </Col>
            <Col span={15}>
              <div
                className={style.project_view_card}
                style={{ padding: '20px 16px', minHeight: '150px' }}
              >
                <div className={style.common_text_small}>Вид деятельности</div>
                <div
                  className={style.common_text_big}
                  style={{ marginTop: '8px' }}
                >
                  {activitiesMap[main_activity_id] ?? ''}
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div
                className={style.project_view_card}
                style={{ minHeight: '116px' }}
              >
                <div className={style.common_text_big}>Описание проекта</div>
                <div
                  className={style.common_text_small}
                  style={{ marginTop: '8px' }}
                >
                  {description}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className={style.common_data_wrapper}>
            {capacitiesForMapping.map((item, index) => {
              return (
                <div
                  key={`${item.title}-${index}`}
                  className={style.project_view_card}
                  style={{ minWidth: '243px', minHeight: '91px' }}
                >
                  <div className={style.common_text_small}>{item.title}</div>
                  <div
                    className={style.common_text_big}
                    style={{ marginTop: '8px' }}
                  >
                    {item.value}
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col span={24}>
          <div
            className={style.project_view_card}
            style={{ minHeight: '91px' }}
          >
            <div className={style.common_text_big}>Описание</div>
            <div
              className={style.common_text_small}
              style={{ marginTop: '8px' }}
            >
              {capacities?.description ?? ''}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ProjectCommonInfo;
