import { createStore } from 'effector';

const initialState = {
  region_widget_data: [],
  district_widget_data: [],
  projects_table_data: [],
  activity_select: [],
  organizations_select: [],
};

export const $projectsMapStore = createStore(initialState);

export const $projectsMapStartStore = createStore(initialState);
export const $projectsMapFilteredStore = createStore(initialState);
