import {
  Button,
  Col,
  Collapse,
  ConfigProvider,
  Dropdown,
  Row,
  Skeleton,
  Table,
  Tooltip
} from 'antd';
import locale from 'antd/locale/ru_RU';
import { Line } from '@ant-design/charts';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import style from '../../components/ViewProjectComponents/ViewProjectComponents.module.css';
import {
  $manufacturerData,
  $manufacturerInfo, getManufacturerInfoFullFx,
  ViewManufacturerGate,
} from '../../models/viewManufacturerModel/index.js';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {CameraOutlined} from '@ant-design/icons';

const formatText = (v) => {
  return v.length > 30 ? (
    <Tooltip title={v}>{`${v.slice(0, 36)}...`}</Tooltip>
  ) : (
    v
  );
};

function getCameras(links) {
  return links && links?.length > 0
    ? links.map((link, idx) => ({
        key: idx,
        label: (
          <Tooltip title={`Камера ${idx + 1}`} placement="left">
            <Button href={link} icon={<CameraOutlined />} />
          </Tooltip>
        ),
      }))
    : [];
}

export default function ManufacturerInfo() {
  const manufacturer = useStore($manufacturerInfo);
  const productsData = useStore($manufacturerData);
  const loading = useStore(getManufacturerInfoFullFx.pending);
  const { id } = useParams();

  const columns = [
    {
      title: 'Наименование товара',
      key: 'name',
      dataIndex: 'product_name',
    },
    {
      title: 'Код ТН ВЭД',
      key: 'tnved',
      dataIndex: 'tnved',
    },
    {
      title: 'Код КП ВЭД',
      key: 'kpved',
      dataIndex: 'kpved',
    },
    {
      title: 'Вид деятельности согласно ОКЭД (перв, вторич.)',
      dataIndex: 'oked',
      key: 'oked',
    },
    {
      title: 'Производственная мощность, кол-во единиц в год',
      dataIndex: 'power',
      key: 'power',
      render: (power) =>
        power ? new Intl.NumberFormat('ru-RU').format(parseInt(power)) : '-',
    },
    {
      title: 'единица измерения',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];

  return (
    <ContainerInner
      title={productsData.name}
      extra={
        <Tooltip title={'Камеры на предприятии'} placement={'left'}>
          <Dropdown menu={{ items: getCameras(productsData?.streams) }} placement={'bottom'}>
            <Button icon={<CameraOutlined />} type={'primary'} />
          </Dropdown>
        </Tooltip>
      }
    >
      <ViewManufacturerGate id={id} />
      <ConfigProvider locale={locale} theme={{
        components: {
          Collapse: {
            contentBg: '#f3f6fe',
            contentPadding: '32px 16px'
          }
        }
      }}>
      <Collapse defaultActiveKey={'common'}>
        <Collapse.Panel key={'common'} header={'Общие сведения'}>
          <Row gutter={[20, 20]}>
            {loading && <Skeleton loading={loading}/>}
            {manufacturer?.blocks?.map((item, index) => {
              return (
                  <Col span={6} key={`${item.title}-${index}`}>
                    <div className={style.project_view_card}>
                      <div className={style.project_view_card_title}>
                        {item.title}
                      </div>
                      <div className={style.project_view_card_text} style={{ marginTop: '8px' }}>
                        {formatText(item.value)}
                      </div>
                    </div>
                  </Col>
              );
            })}
            {manufacturer?.graphs?.map((item, index) => {
              return (
                  <Col span={8} key={`${item.title}-${index}`}>
                    <div
                        className={style.project_view_card}
                        style={{ maxHeight: '247px' }}
                    >
                      <div className={style.project_view_card_title}>
                        {item.title}
                      </div>
                      <LineGraph data={item.values} />
                    </div>
                  </Col>
              );
            })}
          </Row>
        </Collapse.Panel>
        <Collapse.Panel key={'products'} header={'Производимые товары'}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Table dataSource={productsData.manufacturers} columns={columns} />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      </ConfigProvider>
    </ContainerInner>
  );
}

function LineGraph({ data }) {
  const format = (v) => {
    return typeof v === 'number'
      ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
      : v;
  };

  const formatYLabel = (v) => {
    return v.length > 10 ? `${v.slice(0, 10)}...` : v;
  };

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    height: 150,
    color: '#4318FF',
    // label: { position: 'right', formatter: (v) => format(v.value), autoHide: true },
    yAxis: { label: { formatter: (v) => formatYLabel(v), autoHide: true } },
    xAxis: {
      label: {
        autoHide: true,
        formatter: (v) => {
          return new Intl.DateTimeFormat('ru-RU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }).format(new Date(v));
        },
      },
    },
    meta: {
      date: { alias: 'Дата' },
      value: { alias: 'Сумма', formatter: (v) => format(parseInt(v)) },
    },
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {data?.length > 0 ? <Line {...config} /> : 'Нет данных'}
    </div>
  );
}
