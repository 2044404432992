import raw from '../../data/activity.json';

function getParents(map, item) {
  let result = [];
  if (map[item.root_id]) {
    result.push(item.root_id);
    result = result.concat(getParents(map, map[item.root_id]));
  }

  return result;
}

function filterUnavailable(map, actMap) {
  const check = Object.keys(actMap);
  const result = {};
  for (const [id, item] of Object.entries(map)) {
    if (item.activity_type_id === 5 && check.includes(id)) {
      result[id] = getParents(map, item) ?? [];
    }
  }
  return result;
}

function convertToTree(roots, actMap) {
  const treeData = [];

  for (const node of Object.values(roots)) {
    treeData.push({
      title: node.name_ru,
      value: node.id,
      level: node.activity_type_id,
      children: convertToTree(node.children, actMap),
      disabled: !actMap[node.id],
    });
  }

  return treeData;
}

export function prepareTreeSelectData(activities) {
  const actMap = Object.fromEntries(activities.map((i) => [i.id, i]));
  const rawMap = Object.fromEntries(raw.map((i) => [i.id, i]));

  const filtered = filterUnavailable(rawMap, actMap);
  const availableIds = [];
  for (const [key, values] of Object.entries(filtered)) {
    availableIds.push(...values, parseInt(key));
  }

  const uniqueIds = Array.from(new Set(availableIds));

  const roots = [];
  const map = {};

  const sortedRaw = raw.filter((i) => uniqueIds.includes(i.id));

  for (const [idx, node] of Object.entries(sortedRaw)) {
    node.children = [];
    map[node.id] = idx;
    if (node.root_id === -1) {
      roots.push(node);
    } else if (
      sortedRaw[map[node.root_id]] &&
      Object.hasOwn(sortedRaw[map[node.root_id]], 'children')
    ) {
      sortedRaw[map[node.root_id]].children.push(node);
    } else if (sortedRaw[map[node.root_id]]) {
      sortedRaw[map[node.root_id]].children = [node];
    }
  }

  return convertToTree(roots, actMap);
}
