import React, { useEffect } from 'react';
import { useEvent, useStore } from 'effector-react';
import { center } from '@turf/turf';
import { useLocation } from 'react-router-dom';
import {
  $districtsLayerPolygons,
  $selectedDistrict,
} from '../../models/districtsModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { $mapLoaded } from '../../models/globalModel/index.js';
import { $selectedRegion } from '../../models/regionsLayerModel/index.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import {
  $activeGradient,
  $gradientBuckets,
} from '../../models/gradientModel/index.js';

const mag1 = ['<', ['get', 'projects_count'], 25];
const mag2 = [
  'all',
  ['>=', ['get', 'projects_count'], 25],
  ['<', ['get', 'projects_count'], 50],
];

function handleOtpZoomVis() {
  if (
    window.map.getZoom() < 4.3 &&
    window.location.href.includes('dashboard')
  ) {
    if (
      window.map.getLayer('districts_count_count')?.visibility === 'visible' ||
      !window.map.getLayer('districts_count_count')?.visibility
    ) {
      changeLayerVisibility('districts_count_count', 'none');
    }
  } else if (
    window.map.getLayer('districts_count_count')?.visibility === 'none'
  ) {
    changeLayerVisibility('districts_count_count', 'visible');
  }
}

// const mag3 = ['all', ['>=', ['get', 'projects_count'], 25], ['<', ['get', 'projects_count'], 50]];
function DistrictsLayer({ isOverview = false }) {
  const districtsLayerPolygons = useStore($districtsLayerPolygons);
  const selectedDistrict = useStore($selectedDistrict);

  const changeActiveFilter = useEvent(changeActiveFilterEv);
  const mapLoaded = useStore($mapLoaded);

  const selectedRegion = useStore($selectedRegion);
  const activeCategory = useStore($activeFilters).category;
  const activeSubCategory = useStore($activeFilters).subCategory;
  const location = useLocation();
  const gradientBuckets = useStore($gradientBuckets);
  const gradientType = useStore($activeGradient);
  const { buckets, colors } = gradientBuckets;

  const createLayer = (update) => {
    const districtsData = {
      type: 'FeatureCollection',
      features: districtsLayerPolygons,
    };
    const districtsCenters = {
      type: 'FeatureCollection',
      features: districtsLayerPolygons.map((item) => {
        return {
          type: 'Feature',
          properties: {
            count: location.pathname.includes('organizations')
              ? item.properties.orgsCount
              : item.properties.otp_count,
          },
          geometry:
            item.geometry.type === 'Point'
              ? item.geometry
              : center(item).geometry,
        };
      }),
    };

    let fillColorProp = [];
    if (location.pathname.includes('dashboard')) {
      switch (activeSubCategory) {
        case 'projectCount': {
          fillColorProp = '#05cd99';
          break;
        }
        case 'problemProjects': {
          fillColorProp = '#ee5d50';
          break;
        }
        case 'otp_count':
        case 'otp_avg_workload': {
          fillColorProp = '#05cd99';
          break;
        }
        default: {
          fillColorProp = [
            'case',
            ['==', ['get', 'change'], false],
            '#ee5d50',
            '#05cd99',
          ];
          break;
        }
      }
    } else {
      // fillColorProp = [
      //   'case',
      //   ['<', ['get', gradientType], buckets[0]],
      //   colors[0],
      //   [
      //     'all',
      //     ['>=', ['get', gradientType], buckets[0]],
      //     ['<', ['get', gradientType], buckets[1]],
      //   ],
      //   colors[1],
      //   colors[2],
      // ];

      fillColorProp = '#05cd99';
    }

    if (update) {
      window.map.getSource('districts').setData(districtsData);
      window.map.getSource('districts_centers').setData(districtsCenters);
      window.map.setPaintProperty(
        'districts_layer',
        'fill-color',
        fillColorProp
      );
      if (isOverview) {
        // if (
        //   activeSubCategory !== 'mio_performance' &&
        //   activeCategory !== 'business_inspections'
        // ) {
        //   changeLayerVisibility('districts_layer', 'visible');
        //   changeLayerVisibility('districts_outline', 'visible');
        // } else {
        //   changeLayerVisibility('districts_layer', 'none');
        //   changeLayerVisibility('districts_outline', 'none');
        // }
      }
    } else {
      if (!window.map.getSource('districts')) {
        map.addSource('districts', {
          type: 'geojson',
          data: districtsData,
        });
        map.addSource('districts_centers', {
          type: 'geojson',
          data: districtsCenters,
        });
      }

      if (!window.map.getLayer('districts_layer')) {
        map.addLayer({
          id: 'districts_layer',
          type: 'fill',
          source: 'districts',
          paint: {
            'fill-color': fillColorProp,
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              // 3.4, 0,
              // 3.5, 0.8
              4.2,
              0,
              4.3,
              0.8,
              11,
              0,
            ],

            // 'fill-opacity': 0.8
          },
        });

        map.addLayer({
          id: 'districts_outline',
          type: 'line',
          source: 'districts',
          paint: {
            'line-color': 'black',
            'line-width': 1,
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              // 3.4, 0,
              // 3.5, 0.8
              4.2,
              0,
              4.3,
              0.8,
              11,
              0,
            ],

            // 'line-opacity': 0.8
          },
        });
      }

      if (!window.map.getLayer('districts_count_circles')) {
        map.addLayer({
          id: 'districts_count_circles',
          type: 'circle',
          source: 'districts_centers',
          filter: ['has', 'count'],
          paint: {
            'circle-color': '#fff',
            'circle-radius': 15,
            'circle-stroke-width': 5,
            'circle-stroke-color': '#553DB4',
            'circle-stroke-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              4.2,
              0,
              4.3,
              0.8,
              11,
              0,
            ],

            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              4.2,
              0,
              4.3,
              0.8,
              11,
              0,
            ],
          },
        });

        map.addLayer({
          id: 'districts_count_count',
          type: 'symbol',
          source: 'districts_centers',
          layout: {
            'text-field': ['get', 'count'],
          },
          paint: {
            'text-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              4.2,
              0,
              4.3,
              0.8,
              11,
              0,
            ],
          },
        });

        if (!location.pathname.includes('organizations')) {
          changeLayerVisibility('districts_count_circles', 'none');
          changeLayerVisibility('districts_count_count', 'none');
        }

        // window.map.on('render', handleOtpZoomVis);
      }

      window.map.on('click', 'districts_layer', (e) => {
        if (window.map.getZoom() >= 5 && window.map.getZoom() < 8.5) {
          if (e.features[0].id !== 425) {
            changeActiveFilter({
              field: 'district_id',
              value: e.features[0].id,
            });
          }
        }
      });

      // if (isOverview) {
      //   changeLayerVisibility('districts_layer', 'none');
      //   changeLayerVisibility('districts_outline', 'none');
      // }
    }
  };

  const selectDistrictOnMap = (feature) => {
    if ([23, 24, 25].includes(selectedRegion)) {
      map.flyTo({
        center: center(feature).geometry.coordinates,
        zoom: 9,
        speed: 1,
      });
    } else {
      map.flyTo({
        center: center(feature).geometry.coordinates,
        zoom: 7,
        speed: 1,
      });
    }

    window.map.setPaintProperty('districts_layer', 'fill-opacity', 0.2);
    window.map.setPaintProperty('districts_outline', 'line-opacity', 0.2);
  };

  const clearDistrictOnMap = () => {
    const region_feature = window.map
      .getSource('regions')
      ._data.features.find((item) => item.id === selectedRegion);
    const flyToConf = {
      center: region_feature
        ? center(region_feature).geometry.coordinates
        : [66.71584352632227, 48.58775813682156],
      zoom: region_feature ? 5 : 3.4,
      speed: 1,
    };
    map.flyTo(flyToConf);

    window.map.setPaintProperty('districts_layer', 'fill-opacity', [
      'interpolate',
      ['linear'],
      ['zoom'],
      // 3.4, 0,
      // 3.5, 0.8
      4.2,
      0,
      4.3,
      0.8,
      11,
      0,
    ]);
    window.map.setPaintProperty('districts_outline', 'line-opacity', [
      'interpolate',
      ['linear'],
      ['zoom'],
      // 3.4, 0,
      // 3.5, 0.8
      4.2,
      0,
      4.3,
      0.8,
      11,
      0,
    ]);
  };

  useEffect(() => {
    if (mapLoaded) {
      setTimeout(() => {
        createLayer(false);
      }, 500);
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('districts_layer')) {
      if (selectedDistrict > 0) {
        selectDistrictOnMap(
          districtsLayerPolygons.find((item) => item.id === selectedDistrict)
        );
      } else {
        clearDistrictOnMap();
      }
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('districts')) {
      createLayer(true);
    }
  }, [districtsLayerPolygons]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('districts')) {
      createLayer(true);
    }
  }, [districtsLayerPolygons, gradientBuckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('districts')) {
      createLayer(true);

      if (
        location.pathname.includes('dashboard') &&
        (activeSubCategory.includes('otp') ||
          activeSubCategory === 'count' ||
          activeSubCategory === 'avg_workload')
      ) {
        changeLayerVisibility('districts_count_circles', 'visible');
        changeLayerVisibility('districts_count_count', 'visible');
        window.map.on('render', handleOtpZoomVis);
      } else {
        changeLayerVisibility('districts_count_circles', 'none');
        changeLayerVisibility('districts_count_count', 'none');
        window.map.off('render', handleOtpZoomVis);
      }
    }
  }, [activeCategory, activeSubCategory]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('districts_centers')) {
      if (location.pathname.includes('organizations')) {
        changeLayerVisibility('districts_count_circles', 'visible');
        changeLayerVisibility('districts_count_count', 'visible');
      } else {
        changeLayerVisibility('districts_count_circles', 'none');
        changeLayerVisibility('districts_count_count', 'none');
      }
    }
  }, [location.pathname]);

  // useEffect(() => {
  // 	if(window.map.getLayer('districts_layer')){
  // 		if(selectedRegion > 0){
  // 			changeLayerVisibility('districts_layer', 'visible')
  // 			changeLayerVisibility('districts_outline', 'visible')
  // 		} else {
  // 			changeLayerVisibility('districts_layer', 'none')
  // 			changeLayerVisibility('districts_outline', 'none')
  // 		}
  // 	}
  // }, [selectedRegion]);

  return null;
}

export default DistrictsLayer;
