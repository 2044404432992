import {useEvent, useStore} from "effector-react";
import {
    $dashboardModalData,
    $dashboardModalOpen,
    getDashboardsDataEv,
    setDashboardModalOpenEv
} from "../../../models/overviewModel/index.js";
import {Modal, Select} from "antd";
import {Column} from "@ant-design/charts";
import {$districtsSelect, $regionsSelect} from "../../../models/globalModel/index.js";
import {useEffect, useState} from "react";
import {$activeFilters} from "../../../models/activeFiltersModel/index.js";

const OverviewDashboardModal = () => {
    const activeFiltersGlobal = useStore($activeFilters)
    const open = useStore($dashboardModalOpen)
    const data = useStore($dashboardModalData)
    const setModal = useEvent(setDashboardModalOpenEv)
    const regions = useStore($regionsSelect);
    const districts = useStore($districtsSelect);

    const getData = useEvent(getDashboardsDataEv)

    const [activeRegion, setActiveRegion] = useState(null);
    const [activeDistrict, setActiveDistrict] = useState(null);

    useEffect(() => {
        if (open) {
            if (activeFiltersGlobal.region_id && !activeFiltersGlobal.district_id) {
                setActiveRegion(activeFiltersGlobal.region_id)
            } else if (activeFiltersGlobal.region_id && activeFiltersGlobal.district_id) {
                setActiveRegion(activeFiltersGlobal.region_id)
                setActiveDistrict(activeFiltersGlobal.district_id)
            }
        }
    }, [activeFiltersGlobal, open]);

    const [districtsSelect, setDistrictSelect] = useState([])

    useEffect(() => {
        if (districts?.length > 0) {
            if (activeRegion) {
                setDistrictSelect(districts.filter(i => i.region_id === activeRegion)?.map((item) => {
                    return {
                        label: item.label,
                        value: item.value
                    }
                }))
            } else {
                setDistrictSelect(districts.map((item) => {
                    return {
                        label: item.label,
                        value: item.value
                    }
                }))
            }
        }
    }, [districts, activeRegion]);

    useEffect(() => {
        const activeFilters = {
            region_id: activeRegion ?? null,
            district_id: activeDistrict ?? null,
        };
        getData(activeFilters)
    }, [activeRegion, activeDistrict]);

    const onClose = () => {
        setActiveRegion(null)
        setActiveDistrict(null)
        setModal(false)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal open={open} onCancel={onClose} width="80%" footer={[]} destroyOnClose={true}>
        <div style={{display: 'flex', gap: 16}}>
        <div style={{display: 'flex', flexDirection: 'column',}}>
            <div>Область:</div>
            <Select
                options={regions}
                value={activeRegion}
                onChange={setActiveRegion}
                allowClear
                style={{width: '200px', marginRight: '20px'}}
                showSearch
                filterOption={filterOption}
            />
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>Район:</div>
            <Select
                options={districtsSelect}
                value={activeDistrict}
                onChange={setActiveDistrict}
                allowClear
                style={{width: '200px', marginRight: '20px'}}
                showSearch
                filterOption={filterOption}
            />
        </div>
        </div>
    <Column
        style={{marginTop: '30px'}}
        height={215}
        xField="month"
        yField="value"
        data={data}
        // yAxis={{label: {formatter: (v) => v}}}
        columnStyle={{
            radius: [20, 20, 0, 0],
            fill: 'l(90) 0:#4318FF 1:#4318FF47',
        }}
        minColumnWidth={14}
        maxColumnWidth={14}
        meta={{
            value: {alias: 'Количество', formatter: (v) => v},
        }}
    />
</Modal>
}

export default OverviewDashboardModal