import { Bar, Column, Line } from '@ant-design/charts';
import { useEvent, useStore, useStoreMap } from 'effector-react';
import { useEffect, useState } from 'react';
import { Divider } from 'antd';
import {
  $overviewStore,
  changeActiveProblemRespEv,
  setIsModalOpenEv,
} from '../../models/overviewModel/index.js';
import { months_dictionary } from '../../dictionaries/months_dictionary.js';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import { formatNumber } from '../../utils/number-manipulation.js';
import style from '../ViewProjectComponents/ViewProjectComponents.module.css';
import { $dictionariesStore } from '../../models/dictionariesModel/index.js';
import { $activitiesMap } from '../../models/globalModel/index.js';

const rome_dict = {
  0: 'I',
  1: 'II',
  2: 'III',
  3: 'IV',
};

const title_dict = {
  people: 'Численность занятых',
  taxes: 'Сумма уплаченных налогов',
  medium_companies: 'Действующие компании',
  problemProjects: 'Проекты по стадиям',
  projectCount: 'Проекты по стадиям',
  mio_performance: 'Средний бизнес',
  business_inspections: 'Малый и микро бизнес',
};

function DashboardCharts() {
  const overviewStore = useStore($overviewStore);
  const { activeSubCategory, yearly } = useStoreMap(
    $activeFilters,
    (actFilts) => ({
      activeSubCategory: actFilts.subCategory,
      yearly: actFilts.business_climate_yearly,
    })
  );
  const [title, setTitle] = useState(title_dict[activeSubCategory]);
  const [data, setData] = useState([]);
  const [smallCompData, setSmallCompData] = useState([]);
  const [stagesChartData, setStagesChartData] = useState([]);
  const [problemStagesChartData, setProblemStagesChartData] = useState([]);
  const [bizInspData, setBizInspData] = useState([]);
  const workingIndexes = ['people', 'taxes', 'medium_companies'];
  const activitiesMap = useStore($activitiesMap);

  useEffect(() => {
    if (
      activeSubCategory !== '' &&
      workingIndexes.includes(activeSubCategory)
    ) {
      const chartsData = [];
      if (
        overviewStore[activeSubCategory]?.monthly_data[
          new Date().getFullYear() - 1
        ]
      ) {
        overviewStore[activeSubCategory]?.monthly_data[
          new Date().getFullYear() - 1
        ]?.forEach((item) => {
          chartsData.push({
            ...item,
            year: (new Date().getFullYear() - 1).toString(),
          });
        });
      }
      if (
        overviewStore[activeSubCategory]?.monthly_data[new Date().getFullYear()]
      ) {
        overviewStore[activeSubCategory]?.monthly_data[
          new Date().getFullYear()
        ]?.forEach((item) => {
          chartsData.push({
            ...item,
            year: new Date().getFullYear().toString(),
          });
        });
      }
      // const chartsData = [
      //   ...overviewStore[activeSubCategory]?.monthly_data[2022]?.map((item) => {
      //     return {
      //       ...item,
      //       year: '2022',
      //     };
      //   }),
      //   ...overviewStore[activeSubCategory]?.monthly_data[2023]?.map((item) => {
      //     return {
      //       ...item,
      //       year: '2023',
      //     };
      //   }),
      // ];

      setData(
        chartsData
          .map((item) => {
            return {
              month: `${months_dictionary[item.month]} ${item.year}`,
              value: item.value,
              change: Math.round(item.change),
              year: item.year,
            };
          })
          .filter((item) => item.change !== 0)
      );
      setTitle(title_dict[activeSubCategory]);

      if (activeSubCategory === 'medium_companies') {
        const smallCompChartData = [];
        if (
          overviewStore?.small_companies?.monthly_data[
            new Date().getFullYear() - 1
          ]
        ) {
          overviewStore?.small_companies?.monthly_data[
            new Date().getFullYear() - 1
          ]?.forEach((item) => {
            smallCompChartData.push({
              ...item,
              year: (new Date().getFullYear() - 1).toString(),
            });
          });
        }
        if (
          overviewStore?.small_companies?.monthly_data[new Date().getFullYear()]
        ) {
          overviewStore?.small_companies?.monthly_data[
            new Date().getFullYear()
          ]?.forEach((item) => {
            smallCompChartData.push({
              ...item,
              year: new Date().getFullYear().toString(),
            });
          });
        }

        setSmallCompData(
          smallCompChartData.map((item) => {
            return {
              month: `${months_dictionary[item.month]} ${item.year}`,
              value: item.value,
              change: Math.round(item.change),
              year: item.year,
            };
          })
        );
      }
    } else if (
      activeSubCategory !== '' &&
      activeSubCategory === 'problemProjects'
    ) {
      setData([
        ...Object.values(overviewStore.problems.oked_chart).map((item) => {
          return {
            name: item.id,
            type: 'План',
            value: item.stage_count,
          };
        }),
        ...Object.values(overviewStore.problems.oked_chart).map((item) => {
          return {
            name: item.id,
            type: 'Факт',
            value: item.stage_problems,
          };
        }),
      ]);
      setTitle(title_dict[activeSubCategory]);
      setProblemStagesChartData(
        Object.entries(overviewStore.problems.stage_chart).map(
          ([key, value], idx) => {
            return {
              stage: `${rome_dict[idx]} Стадия`,
              count: value,
            };
          }
        )
      );
    } else if (
      activeSubCategory !== '' &&
      activeSubCategory === 'projectCount'
    ) {
      setTitle(title_dict[activeSubCategory]);
      setData(
        Object.entries(overviewStore?.projects?.oked_chart)
          ?.map(([key, value]) => {
            return {
              oked: activitiesMap[key],
              value:
                typeof value === 'number'
                  ? value * 1000000
                  : parseInt(value) * 1000000,
            };
          })
          .filter((item) => item.value > 0)
          .sort((a, b) => b.value - a.value)
      );
      setStagesChartData(
        Object.entries(overviewStore.projects.stage_chart).map(
          ([key, value], idx) => {
            return {
              stage: `${rome_dict[idx]} Стадия`,
              count: value,
            };
          }
        )
      );
    } else if (activeSubCategory === 'business_inspections') {
      const prep = [];
      if (
        overviewStore?.business_inspections?.monthly_data?.[
          new Date().getFullYear() - 1
        ]
      ) {
        overviewStore?.business_inspections?.monthly_data[
          new Date().getFullYear() - 1
        ]
          ?.toSorted((a, b) => a.month - b.month)
          ?.forEach((item) => {
            prep.push({
              ...item,
              year: (new Date().getFullYear() - 1).toString(),
            });
          });
      }
      if (
        overviewStore?.business_inspections?.monthly_data?.[
          new Date().getFullYear()
        ]
      ) {
        overviewStore?.business_inspections?.monthly_data[
          new Date().getFullYear()
        ]
          ?.toSorted((a, b) => a.month - b.month)
          ?.forEach((item) => {
            prep.push({
              ...item,
              year: new Date().getFullYear().toString(),
            });
          });
      }

      setData(
        prep?.map((item) => {
          return {
            month: `${item?.month} квартал ${item?.year}`,
            value:
              Object.values(item.categories).reduce(
                (a, b) => a + Number(b),
                0
              ) / Object.values(item.categories).length,
            change: Math.round(item?.change),
            year: item?.year,
          };
        })
      );

      if (yearly) {
        setBizInspData(
          Object.entries(
            overviewStore?.business_inspections?.category_data ?? {}
          )
            ?.map(([key, value]) => {
              return {
                title: key,
                count: value,
              };
            })
            .toSorted((a, b) => a.title.localeCompare(b.title))
        );
      } else {
        setBizInspData(
          Object.entries(
            overviewStore?.business_inspections?.category_data ?? {}
          )
            ?.map(([key, value]) => {
              return {
                title: key,
                count: value,
              };
            })
            .toSorted((a, b) => a.title.localeCompare(b.title))
        );
      }
      setTitle(title_dict[activeSubCategory]);
    } else if (activeSubCategory === 'mio_performance') {
      const prep = [];
      if (
        overviewStore?.mio_performance?.monthly_data?.[
          new Date().getFullYear() - 1
        ]
      ) {
        overviewStore?.mio_performance?.monthly_data[
          new Date().getFullYear() - 1
        ]
          ?.toSorted((a, b) => a.month - b.month)
          ?.forEach((item) => {
            prep.push({
              ...item,
              year: (new Date().getFullYear() - 1).toString(),
            });
          });
      }
      if (
        overviewStore?.mio_performance?.monthly_data?.[new Date().getFullYear()]
      ) {
        overviewStore?.mio_performance?.monthly_data[new Date().getFullYear()]
          ?.toSorted((a, b) => a.month - b.month)
          ?.forEach((item) => {
            prep.push({
              ...item,
              year: new Date().getFullYear().toString(),
            });
          });
      }

      setData(
        prep?.map((item) => {
          return {
            month: `${item?.month} квартал ${item?.year}`,
            value:
              Object.values(item.categories).reduce(
                (a, b) => a + Number(b),
                0
              ) / Object.values(item.categories).length,
            change: Math.round(item?.change),
            year: item?.year,
          };
        })
      );

      if (yearly) {
        setBizInspData(
          Object.entries(overviewStore?.mio_performance?.category_data ?? {})
            ?.map(([key, value]) => {
              return {
                title: key,
                count: value,
              };
            })
            .toSorted((a, b) => a.title.localeCompare(b.title))
        );
      } else {
        setBizInspData(
          Object.entries(overviewStore?.mio_performance?.category_data ?? {})
            ?.map(([key, value]) => {
              return {
                title: key,
                count: value,
              };
            })
            .toSorted((a, b) => a.title.localeCompare(b.title))
        );
      }

      setTitle(title_dict[activeSubCategory]);
    }
  }, [activeSubCategory, overviewStore]);

  return (
    <div
      style={{
        background: '#fff',
        borderRadius: '12px',
        padding: '18px',
        // height: '85%',
        width: '100%',
      }}
    >
      <div
        style={{
          margin: '0',
          fontSize: '24px',
          fontWeight: '700',
          color: '#2b3674',
          marginBottom: '20px',
        }}
      >
        {title}
      </div>
      {/* <div style={{ width: '370px', margin: '0 auto' }}> */}
      {workingIndexes.includes(activeSubCategory) ? (
        <>
          <LineGraph data={data} />
          {activeSubCategory === 'people' && (
            <div
              style={{
                margin: '20px 0',
                fontSize: '24px',
                fontWeight: '700',
                color: '#2b3674',
              }}
            >
              Сумма оплаченных пенсионных взносов
            </div>
          )}
          {(activeSubCategory === 'medium_companies' ||
            activeSubCategory === 'taxes') && (
            <div
              style={{
                margin: '20px 0',
                fontSize: '24px',
                fontWeight: '700',
                color: '#2b3674',
              }}
            >
              Сумма оплаченных налогов
            </div>
          )}
          <ColumnChart data={data} />
          {/* {activeSubCategory === 'medium_companies' ? ( */}
          {/*  <ColumnChart data={smallCompData} /> */}
          {/* ) : ( */}
          {/*  '' */}
          {/* )} */}
        </>
      ) : activeSubCategory === 'problemProjects' ? (
        <>
          <BarChart
            data={problemStagesChartData}
            height={131}
            xField="count"
            yField="stage"
          />
          <div
            style={{
              margin: '0',
              fontSize: '24px',
              fontWeight: '700',
              color: '#2b3674',
              marginTop: 24,
            }}
          >
            Сквозной контроль
          </div>
          <DoubleColumnChart data={data} marginTop={24} />
        </>
      ) : activeSubCategory === 'projectCount' ? (
        <>
          <BarChart
            data={stagesChartData}
            height={131}
            xField="count"
            yField="stage"
          />
          <div
            style={{
              margin: '0',
              fontSize: '24px',
              fontWeight: '700',
              color: '#2b3674',
              marginTop: 24,
            }}
          >
            Инвестиции по видам деятельности
          </div>
          <BarChart data={data} xField="value" yField="oked" marginTop={24} />
        </>
      ) : activeSubCategory === 'business_inspections' && !yearly ? (
        <>
          <ColumnChart
            data={data}
            xField="month"
            yField="value"
            labelAlias="Баллы"
          />
          <Divider />
          <BarChart
            data={bizInspData}
            xField="count"
            yField="title"
            xAlias="Баллы"
            notFormatLabel
            xLabelPosition="left"
          />
        </>
      ) : activeSubCategory === 'business_inspections' && yearly ? (
        <BarChart
          data={bizInspData}
          xField="count"
          yField="title"
          xAlias="Баллы"
          notFormatLabel
          xLabelPosition="left"
        />
      ) : activeSubCategory === 'mio_performance' && !yearly ? (
        <>
          <ColumnChart
            data={data}
            xField="month"
            yField="value"
            labelAlias="Баллы"
          />
          <Divider />
          <BarChart
            data={bizInspData}
            xField="count"
            yField="title"
            xAlias="Баллы"
            notFormatLabel
            xLabelPosition="left"
          />
        </>
      ) : activeSubCategory === 'mio_performance' && yearly ? (
        <BarChart
          data={bizInspData}
          xField="count"
          yField="title"
          xAlias="Баллы"
          notFormatLabel
          xLabelPosition="left"
        />
      ) : null}
      {/* </div> */}
    </div>
  );
}

export default DashboardCharts;

function ColumnChart({ data, labelAlias }) {
  const formatYLabel = (v) => {
    if (!isNaN(parseInt(v))) {
      return new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
        typeof v === 'number' ? v : parseInt(v)
      );
    }
    return v;
  };

  return (
    <Column
      style={{ marginTop: '30px' }}
      height={215}
      xField="month"
      yField="value"
      data={data}
      yAxis={{ label: { formatter: (v) => v } }}
      columnStyle={{
        radius: [20, 20, 0, 0],
        fill: 'l(90) 0:#4318FF 1:#4318FF47',
      }}
      minColumnWidth={14}
      maxColumnWidth={14}
      meta={{
        value: {
          alias: labelAlias || 'Количество',
          formatter: (v) => formatYLabel(v),
        },
      }}
      // scrollbar={{
      //   type: 'horizontal',
      //   animate: false,
      // }}
    />
  );
}

function LineGraph({ data, xField, yField, alias }) {
  const formatYLabel = (v) => {
    return `${v}`;
  };

  const formatLineLabel = (yField, v) => {
    if (yField) {
      return !Number.isNaN(v[yField]) ? v[yField].toFixed(2) : v[yField];
    }
    return `${v.change}%`;
  };

  const config = {
    data: data.filter((item) => item.year !== '2022'),
    xField: xField ?? 'month',
    yField: yField ?? 'change',
    height: 131,
    color: '#4318FF',
    label: { position: 'right', formatter: (v) => formatLineLabel(yField, v) },
    yAxis: { label: { formatter: (v) => formatYLabel(v) } },
    meta: {
      change: { alias: alias ?? 'Процент', formatter: (v) => `${v}%` },
      value: { alias, formatter: (v) => `${v.toFixed(2)}` },
    },
    animate: false,
  };
  return <Line {...config} />;
}

function BarChart({
  xField,
  yField,
  data,
  height,
  marginTop,
  xAlias,
  notFormatLabel,
  xLabelPosition = 'right',
}) {
  const formatYLabel = (v) => {
    return v.length > 10 ? `${v.slice(0, 10)}...` : v;
  };

  const format = (v) => {
    return typeof v === 'number'
      ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
      : v;
  };

  const config = {
    xField,
    yField,
    style: { height: height || 215, marginTop },
    barStyle: {
      radius: [20, 20, 0, 0],
      fill: 'l(180) 0:#4318FF 1:#4318FF47',
    },
    label: { position: xLabelPosition, formatter: (v) => format(v[xField]) },
    yAxis: {
      label: { formatter: (v) => (notFormatLabel ? v : formatYLabel(v)) },
    },
    meta: {
      [xField]: {
        alias: xAlias ?? 'Сумма',
        formatter: (v) => format(xAlias ? v : parseInt(v)),
      },
    },
    data,
  };
  return <Bar {...config} />;
}

function DoubleColumnChart({ data, marginTop }) {
  const dictionariesStore = useStore($dictionariesStore);
  const changeActiveProblemResp = useEvent(changeActiveProblemRespEv);
  const changeIsModalOpen = useEvent(setIsModalOpenEv);

  const roles_dict = dictionariesStore.role;

  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (Object.keys(roles_dict).length !== 0) {
      setColumnData(
        data.map((item) => {
          return {
            ...item,
            name: roles_dict[item.name]?.name_ru,
          };
        })
      );
    }
  }, [roles_dict, data]);

  const selectOptions = {
    Инициатор: 0,
    МИО: 1,
    ЦГО: 2,
    ИР: 3,
  };

  const config = {
    style: {
      marginTop,
    },
    data: columnData,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    color: ['l(90) 0:#4318FF 1:#4318FF47', 'l(90) 0:#6AD2FF 1:#6AD2FF47'],
    minColumnWidth: 8,
    maxColumnWidth: 16,
    legend: {
      position: 'top-right',
    },
    meta: {
      value: { alias: 'Количество этапов', formatter: (v) => v },
      // stage_problems: {
      //   alias: 'Количество проблемных этапов',
      //   formatter: (v) => v,
      // },
    },
    height: 215,
    onReady: (plot) => {
      plot.on('plot:click', (evt) => {
        const { x, y } = evt;
        const chosen_item = plot.chart.getTooltipItems({ x, y })[0].data;
        let resp_id = 0;
        Object.keys(selectOptions).forEach((item) => {
          if (chosen_item.name.includes(item)) {
            resp_id = selectOptions[item];
          }
        });
        changeActiveProblemResp(resp_id);
        changeIsModalOpen(true);
      });
    },
    xAxis: {
      label: {
        formatter: (v) => {
          let label = '';
          Object.keys(selectOptions).forEach((item) => {
            if (v.includes(item)) {
              label = item;
            }
          });
          return label;
        },
      },
    },
  };

  return (
    // <div className={style.project_view_card}>
    <Column {...config} />
    // </div>
  );
}
