import {Button, Popover, Tooltip} from 'antd';
import {AreaChartOutlined, InfoCircleOutlined} from '@ant-design/icons';
import style from '../../Overview.module.css';
import {
    actualityDictionary,
    infoSourceDictionary
} from '../../../../dictionaries/info_source_dictionary.js';

export default function OverviewCardOtp(props) {
    const {
        title,
        cards,
        cardClick,
        field,
        activeCategory,
        activeSubCategory,
        setModal,
    } = props;

    const openModal = (type) => {
        setModal({ open: true, type });
    };

    return (
        <div
            className={style.overview_card}
            onClick={cardClick}
            data-category={field}
            data-subcategory={cards[0].subCategory}
            data-type="card"
        >
            <h1 className={[style.overview_card_title, style.center].join(' ')}>{title}</h1>
            <div className={style.cards_wrapper}>
                {cards.map((item) => {
                    return (
                        <div
                            className={[
                                style.overview_subcard,
                                activeSubCategory.length > 0 &&
                                activeSubCategory !== item.subCategory
                                    ? style.not_active
                                    : '',
                            ].join(' ')}
                            style={{
                                background:
                                    item.type === 'neutral'
                                        ? '#eedc501a'
                                        : item.type === 'positive'
                                            ? '#05CD991A'
                                            : '#EE5D501A',
                                width:
                                    cards.length === 1 ? '100%' : `${100 / cards.length - 1.5}%`,
                                border:
                                    activeSubCategory.length > 0 &&
                                    activeSubCategory === item.subCategory
                                        ? item.type === 'neutral'
                                            ? '2px solid #eedc50'
                                            : item.type === 'positive'
                                                ? '2px solid #05CD99'
                                                : '2px solid #EE5D50'
                                        : '',
                                padding:
                                    activeSubCategory.length > 0 &&
                                    activeSubCategory === item.subCategory
                                        ? '8px'
                                        : '12px',
                            }}
                            data-category={field}
                            data-subcategory={item.subCategory}
                            data-type="subcard"
                        >
                            <div
                                className={
                                    item.breakWord
                                        ? style.title_break
                                        : style.overview_subcard_title
                                }
                            >
                                {item.title}
                            </div>
                            <div>
                                <Popover title={<div
                                    style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                    <span>{`Источник данных: ${infoSourceDictionary[item.subCategory]}`}</span>
                                    <span>{`Актуализация: ${actualityDictionary[item.subCategory]}`}</span>
                                </div>
                                }>
                                    <InfoCircleOutlined style={{height: 16}}/>
                                </Popover>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className={style.overview_value}>
                                    {parseInt(item.value)}
                                </div>
                                <div
                                    className={style.overview_value}
                                    style={{fontSize: '22px', marginLeft: '10px'}}
                                >
                                    {item.measure}
                                </div>
                            </div>

                            <div className={style.overview_subcard_footer}>
                                <div className={style.overview_subcard_date}>
                                    {item?.date === 'Нет данных ' ? '' : item?.date}
                                </div>
                                <div style={{display: 'flex', gap: 4}}>
                                    {item.otpModal === true ? (
                                        <Tooltip
                                            title="Детали по предприятиям"
                                            placement="bottom"
                                        >
                                            <Button
                                                icon={<AreaChartOutlined/>}
                                                onClick={() => openModal('organizations')}
                                            />
                                        </Tooltip>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}