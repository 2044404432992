import {createStore} from "effector";

const initial_state = {
	projects_count: 0,
	region_widget_data: [],
	total_count: 0,
	members_count: 0,
	activity_select: [],
	organizations_select: [],
	region_projects_data: [],
	district_widget_data: [],
}

export const $investmentsStore = createStore(initial_state)

export const $investmentsStartStore = createStore(initial_state)

export const $investmentsFilteredStore = createStore({})
