import { useUnit } from 'effector-react';
import {Button, Modal, Popover, Select, Table, Typography} from 'antd';
import {
  $bizDistrictModalData,
  $bizDistrictModalState, $bizPollsStatsModalData, BizPollsStatsModalGate,
  resetDistrictModalEv, setBizPollsStatsModalEv, setDistrictModalEv,
} from '../../../../models/overviewModel/index.js';
import BarChart from '../../../../components/BarChart/BarChart.jsx';
import ColumnChart from '../../../../components/ColumnChart/ColumnChart.jsx';
import {InfoCircleOutlined} from '@ant-design/icons';
import BizPollsStatsModal from './BizPollsStatsModal.jsx';

const infoDict = {
  'Деятельность МИО в привл инв': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка деятельности акимата по привлечению инвестиций и сопровождению инвестиционных
        проектов
        в населенном пункте</strong>
    </Typography.Paragraph>
    <ol>
      <li>не заинтересован в привлечении инвестиций и никак не сопровождает инвестпроекты</li>
      <li>частично заинтересован в привлечении инвестиций, чаще не прилагает усилий к
        сопровождению инвестпроектов
      </li>
      <li>заинтересован в привлечении инвестиций, но не всегда прилагает усилия к сопровождению
        инвестпроектов
      </li>
      <li>заинтересован в привлечении инвестиций и максимально прилагает усилия к сопровождению
        инвестпроектов
      </li>
    </ol>
  </div>,
  'Привлечение инвестиций': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка деятельности акимата по привлечению инвестиций и сопровождению инвестиционных
        проектов
        в населенном пункте</strong>
    </Typography.Paragraph>
    <ol>
      <li>не заинтересован в привлечении инвестиций и никак не сопровождает инвестпроекты</li>
      <li>частично заинтересован в привлечении инвестиций, чаще не прилагает усилий к
        сопровождению инвестпроектов
      </li>
      <li>заинтересован в привлечении инвестиций, но не всегда прилагает усилия к сопровождению
        инвестпроектов
      </li>
      <li>заинтересован в привлечении инвестиций и максимально прилагает усилия к сопровождению
        инвестпроектов
      </li>
    </ol>
  </div>,
  'Открытость акимата': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка насколько акимат населенного пункта (района/города) открыт для обсуждения
        бизнеса:</strong>
    </Typography.Paragraph>
    <ol>
      <li>закрыт для обсуждения проблем и не приходил</li>
      <li>частично открыт для обсуждения проблем, приходил для формальности</li>
      <li>открыт для обсуждения проблем, частично рассказал о мерах поддержки и получения госуслуг
      </li>
      <li>открыт для обсуждения проблем и приходил, рассказал о мерах поддержки и получения
        госуслуг
      </li>
    </ol>
  </div>,
  'Эффективность акимата': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка насколько акимат населенного пункта (района/города) содействует решению проблем бизнеса:</strong>
    </Typography.Paragraph>
    <ol>
      <li>не отвечает на мои запросы</li>
      <li>редко прилагает усилия решить мои проблемы</li>
      <li>чаще прилагает усилия решить мои проблемы</li>
      <li>максимально прилагает усилия решить мои проблемы</li>
    </ol>
  </div>,
  'Уровень коррупции': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка уровеня распространенности коррупции или неформальных способов налаживания взаимоотношений с представителями госорганов в населенном пункте:</strong>
    </Typography.Paragraph>
    <ol>
      <li>Всегда</li>
      <li>Часто</li>
      <li>Редко</li>
      <li>Нет коррупции</li>
    </ol>
  </div>,
  'Доступность мер господдержки': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка доступности (наличие информации) и эффективности (полезность) мер государственной поддержки для бизнеса (финансовые и нефинансовые меры поддержки), реализуемых в населенном пункте</strong>
    </Typography.Paragraph>
    <ol>
      <li>Низкая</li>
      <li>Средняя</li>
      <li>Выше среднего</li>
      <li>Высокая</li>
    </ol>
  </div>,
  'Доступность мер ГП': <div style={{display: 'inline-block'}}>
    <Typography.Paragraph>
      <strong>Оценка доступности (наличие информации) и эффективности (полезность) мер государственной поддержки для бизнеса (финансовые и нефинансовые меры поддержки), реализуемых в населенном пункте</strong>
    </Typography.Paragraph>
    <ol>
      <li>Низкая</li>
      <li>Средняя</li>
      <li>Выше среднего</li>
      <li>Высокая</li>
    </ol>
  </div>,
}

export default function BizDistrictModal() {
  const changeModalState = useUnit(setDistrictModalEv);
  const {open, type, granularity, region_id, selectedQuarter, selectedYear} = useUnit(
      $bizDistrictModalState
  );
  const {title, table, charts, years} = useUnit($bizDistrictModalData);

  const yearsSelect = years.map((y) => ({value: y, label: y}));

  const setPollsStatsModal = useUnit(setBizPollsStatsModalEv)

  const pollsData = useUnit($bizPollsStatsModalData);

  const close = useUnit(resetDistrictModalEv);

  const childrenCols = table[0] && table[0]?.categories &&
      Object.entries(table[0]?.categories)?.map(([category, value]) => ({
        title: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
          {category}
          <Popover content={infoDict[category]} style={{maxWidth: 300, maxHeight: 300, overflow: 'auto'}}>
            <InfoCircleOutlined/>
          </Popover>
      </div>,
      dataIndex: ['categories', category],
      key: category,
      sorter: (a, b) => a['categories'][category] - b['categories'][category],
    }));

  const columns = [
    {
      title: '№',
      key: 'number',
      render: (_, __, idx) => idx + 1,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Общий рейтинг',
      dataIndex: 'avg',
      sorter: (a, b) => a.avg - b.avg,
      key: 'avg',
      render: (avg, idx) => {
        const sum = Object.values(idx.categories).reduce((total, num) => total + parseFloat(num), 0);
        const result = sum / 4;
        return result.toFixed(2);
        // isNaN(avg) ? 'Нет данных': +avg.toFixed(2),
      }
    },
    {
      title: 'В том числе',
      key: 'categories',
      children: childrenCols,
    },
  ];

  return (
    <Modal
      open={open}
      title={title}
      footer={null}
      onCancel={() => close()}
      width="90vw"
    >
      <BizPollsStatsModalGate type={type === 'business_inspections' ? 'малый' : 'средний'} region_id={region_id} quarter={selectedQuarter} year={selectedYear}/>
      <BizPollsStatsModal />
      <div style={{display: 'flex', gap: 16}}>
        {/*<div style={{display: 'flex', flexDirection: 'column', gap: 4}}>*/}
        {/*  <span>Периодичность</span>*/}
        {/*  <Select options={[{value: 'year', label: 'Год'}, {value: 'quarter', label: 'Квартал'}]} value={granularity}*/}
        {/*          onChange={(v) => changeModalState({granularity: v})}/>*/}
        {/*</div>*/}
        {/*<div style={{display: 'flex', flexDirection: 'column', gap: 4}}>*/}
        {/*  <span>Год</span>*/}
        {/*  <Select options={yearsSelect} value={selectedYear}*/}
        {/*          onChange={(v) => changeModalState({selectedYear: v})}/>*/}
        {/*</div>*/}
        {granularity === 'quarter' && <>
          <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
            <span>Год</span>
            <Select options={yearsSelect} value={selectedYear}
                    onChange={(v) => changeModalState({selectedYear: v})}/>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
            <span>Квартал</span>
            <Select options={[{value: 1, label: 'I'}, {value: 2, label: 'II'}, {
              value: 3,
              label: 'III'
            }, {value: 4, label: 'IV'}]}
                    value={selectedQuarter}
                    onChange={(v) => changeModalState({selectedQuarter: v})}
            />
          </div>
        </>}
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', maxHeight: '35vh'}}>
        {granularity === 'quarter' && <>
          <ColumnChart
              style={{width: 400}}
              data={charts.column}
              xAlias={'Квартал'}
            yAlias={'Сред. значение'}
            title={'Период'}
            scroll={!!charts.column.length > 8}
        />
        </>
        }
        <div style={{maxWidth: '40%', maxHeight: '25vh', overflow: 'auto'}}>
          <Typography.Paragraph><strong>О независимом рейтинге «Деловой климат»</strong></Typography.Paragraph>
          <Typography.Paragraph>Согласно пункту 2) статьи 10 Закона РК от 4 июля 2013 года № 129-V «О Национальной палате предпринимателей Республики Казахстан» на регулярной основе <strong>проводится социологическое исследование «Независимый рейтинг «Деловой климат».</strong></Typography.Paragraph>
          <Typography.Paragraph>Основные результаты <strong>исследования отражаются в ежегодном Национальном докладе</strong> о состоянии предпринимательской активности в Республике Казахстан, также размещаются на официальном интернет-ресурсе НПП.</Typography.Paragraph>
          <Typography.Paragraph><strong>Целью исследования</strong> является оценка и сопоставления результатов работы центральных государственных и местных исполнительных органов по созданию условий ведения предпринимательской деятельности в приоритетных отраслях экономики каждого отдельного региона/района.</Typography.Paragraph>
          <Typography.Paragraph>Исследование основано на результатах онлайн-опроса бизнеса.</Typography.Paragraph>
          <Typography.Paragraph><strong>Репрезентативная выборка</strong> респондентов определена путем научно-обоснованных расчетов в зависимости от количества действующих субъектов бизнеса в населеннном пункте.</Typography.Paragraph>
          <Typography.Paragraph><strong>География опроса</strong> – все населенные пункты 17 регионов и 3 городов областного значения.</Typography.Paragraph>
          <Typography.Paragraph>При описании рейтинга населенные пункты распределяются в зависимости от значения интегрального индекса, определяющего позиции регионов, где «4» -  максимальное значение/положительный полюс, а «1» - минимальное/отрицательный полюс.</Typography.Paragraph>
          <Typography.Paragraph>Для оценки опросных данных в анкете используется шкала от 1 до 4, где «1» означает минимальное значение/отрицательный полюс, а «4» - максимально значение/положительный полюс.</Typography.Paragraph>
          <Typography.Paragraph>Все исследовательские работы проводятся с соблюдением конфиденциальности полученной от участников исследования личной и контактной информации.</Typography.Paragraph>
        </div>
        <BarChart data={charts.bar} xAlias={'Категория'} yAlias={'Сред. значение'} title={'Показатели'} style={{width: 400}} concatLabel={false} scroll={false}/>
      </div>
      <div style={{display: 'flex', gap: 8, alignItems: 'center', marginBottom: 16}}>
      <Typography.Title level={4} style={{margin: 0, display: 'inline-block'}}>Количество респондентов - {pollsData.gov_id_count}</Typography.Title>
        <Button type="primary" onClick={() => setPollsStatsModal()}>Детали</Button>
      </div>
      <Table dataSource={table} pagination={false} columns={columns} bordered style={{width: '100%', overflow: 'auto'}}/>
    </Modal>
  );
}
