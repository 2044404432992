import {createEffect} from "effector/effector.mjs";
import raw_data from '../../data/raw_data.json';
import {api} from "../../api/axiosInstance.js";

export const getRawDataFx = createEffect()
	.use(async () => {
		// return new Promise (resolve => setTimeout(() => {
		// 	return resolve(raw_data)
		// }, 1000))

		return (await api().get('/info/')).data
	})
