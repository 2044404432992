import {createEvent} from "effector";
import {createGate} from "effector-react";

export const EditProjectGate = createGate()

export const addPartisipantBinEv = createEvent()
export const deletePartisipantBinEv = createEvent()

export const submitCreateFormEv = createEvent()
export const submitUpdateFormEv = createEvent()

export const deleteProjectEv = createEvent()

export const publishProjectEv = createEvent()
export const rollbackProjectEv = createEvent()

export const resetCreateStatusEv = createEvent()