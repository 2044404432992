import {
    addPartisipantBinEv, deletePartisipantBinEv, deleteProjectEv,
    EditProjectGate, publishProjectEv,
    resetCreateStatusEv, rollbackProjectEv,
    submitCreateFormEv,
    submitUpdateFormEv
} from './events.js';
import {sample} from "effector";
import {
    createProjectFx,
    deleteProjectFx,
    getParticipantsFx,
    getProjectByIdFx,
    publishProjectFx, rollbackProjectFx,
    updateProjectFx
} from './effects.js';
import {parseProjectToForm, prepareFormData} from "./utils.js";
import {$dictionariesMaps} from "../dictionariesModel/index.js";
import {$createProjectStatus, $participantsBins, $participantsSelect, $projectForEdit} from "./stores.js";
import notification from "antd/es/notification";
import {getRawDataFx} from '../globalModel/index.js';

$createProjectStatus.on(createProjectFx.doneData, () => true)
    .reset(resetCreateStatusEv)

$projectForEdit.reset(EditProjectGate.close)

$participantsBins.on(addPartisipantBinEv, (state, bins) => {
    const newState = {...state}
    bins.forEach(bin => {
        newState[bin] = null
    })
    return newState
})
    .on(deletePartisipantBinEv, (state, bin) => {
        const newState = {...state}
        delete newState[bin]
        return newState
    })
    .on(getParticipantsFx.doneData, (state, payload) => {
        const newState = {...state}
        payload.forEach((org) => {
            newState[org.gov_id] = org.id
        })
        return newState
    })
    .on(getParticipantsFx.failData, (state, payload) => {
        const newState = {...state}
        newState[payload.config.url.split('/')[3]] = false
        notification.error({message: 'Ошибка', description: 'Организация не найдена'})
        return newState
    })
    .reset([resetCreateStatusEv, EditProjectGate.close])

$participantsSelect.on(getParticipantsFx.doneData, (state, payload) => {
    const newState = [...state]
    payload.forEach((org) => {
        if (!newState.some(opt => opt.value === org.id)) {
            newState.push({label: org.name, value: org.id, bin: org.gov_id})
        }
    })
    return newState
})
    .on(deletePartisipantBinEv, (state, bin) => {
        const newState = [...state]
        newState.forEach((opt, i) => {
            if (opt.bin === bin) {
                newState.splice(i, 1)
            }
        })
        return newState
    })
    .reset([resetCreateStatusEv, EditProjectGate.close])

sample({
    clock: EditProjectGate.open,
    filter: (gate) => !!gate.id,
    fn: (gate) => gate.id,
    target: getProjectByIdFx
})

sample({
    clock: updateProjectFx.doneData,
    fn: (data) => data.id,
    target: getProjectByIdFx
})

sample({
    source: $dictionariesMaps,
    clock: getProjectByIdFx.doneData,
    fn: (dicts, project) => parseProjectToForm(project, dicts),
    target: $projectForEdit
})

sample({
    source: $dictionariesMaps,
    clock: submitCreateFormEv,
    fn: (dicts, form) => prepareFormData(form, dicts),
    target: createProjectFx
})

sample({
    source: $dictionariesMaps,
    clock: submitUpdateFormEv,
    fn: (dicts, {id, values}) => ({id, values: prepareFormData(values, dicts)}),
    target: updateProjectFx
})

sample({
    clock: $participantsBins.updates,
    filter: (bins) => Object.values(bins).some(v => v === null),
    fn: (bins) => Object.keys(bins).filter(bin => bins[bin] === null),
    target: getParticipantsFx
})

sample({
    clock: deleteProjectEv,
    target: deleteProjectFx
})

sample({
    clock: publishProjectEv,
    target: publishProjectFx
})

sample({
    clock: rollbackProjectEv,
    target: rollbackProjectFx
})

sample({
    clock: [deleteProjectFx.doneData, publishProjectFx.doneData, rollbackProjectFx.doneData],
    target: getRawDataFx
})

createProjectFx.doneData.watch(ev => ev && notification.success({message: 'Успешно!', description: 'Проект создан'}))
updateProjectFx.doneData.watch(ev => ev && notification.success({message: 'Успешно!', description: 'Проект обновлен'}))