import { sample } from 'effector';
import notification from 'antd/es/notification';
import {
  approveProjectEv,
  rejectProjectEv,
  setRejectModalEv,
  UnpublishedGate,
} from './events.js';
import {
  approveProjectFx,
  getUnpublishedListFx,
  rejectProjectFx,
} from './effects.js';
import { $rejectModal, $rejectStatus, $unpublishedList } from './stores.js';

$unpublishedList
  .on(getUnpublishedListFx.doneData, (state, payload) => payload)
  .reset(UnpublishedGate.close);

$rejectModal
  .on(setRejectModalEv, (_, id) => id)
  .reset(rejectProjectFx.doneData);

$rejectStatus
  .on(rejectProjectFx.doneData, () => true)
  .on(setRejectModalEv, (state, id) => id === null && false);

sample({
  clock: [UnpublishedGate.open, approveProjectFx.doneData, rejectProjectFx.doneData],
  target: getUnpublishedListFx,
});

sample({
  clock: approveProjectEv,
  target: approveProjectFx,
});

sample({
  clock: rejectProjectEv,
  target: rejectProjectFx,
});

rejectProjectFx.doneData.watch(() =>
  notification.success({ message: 'Успешно', description: 'Проект отклонен' })
);
approveProjectFx.doneData.watch(() =>
  notification.success({ message: 'Успешно', description: 'Проект одобрен' })
);
