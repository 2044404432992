import { sample } from 'effector';
import { $activeFilters } from './stores.js';
import { changeActiveFilterEv, resetActiveFiltersEv } from './events.js';

$activeFilters.reset(resetActiveFiltersEv);

sample({
  source: $activeFilters,
  clock: changeActiveFilterEv,
  fn: (source, clock) => {
    if (clock.field !== 'gradient') {
      if (
        clock.field === 'region_id' &&
        clock.value === 0 &&
        source.district_id !== 0
      ) {
        return {
          ...source,
          region_id: 0,
          district_id: 0,
        };
      }
      if (clock.value && source[clock.field] !== clock.value) {
        return {
          ...source,
          [clock.field]: clock.value,
        };
      }
      return {
        ...source,
        [clock.field]: typeof clock.value === 'number' ? 0 : typeof clock.value === 'boolean' ? clock.value : '',
      };
    }
    if (
      source.gradient.min !== clock.value.min ||
      source.gradient.max !== clock.value.max
    ) {
      return {
        ...source,
        gradient: {
          min: clock.value.min,
          max: clock.value.max,
        },
      };
    }
    return {
      ...source,
      gradient: {
        min: 0,
        max: 0,
      },
    };
  },
  target: $activeFilters,
});
