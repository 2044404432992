import { combine, createStore } from 'effector';
import { $globalStore } from '../globalModel/index.js';

export const $createProjectStatus = createStore(false);
export const $projectForEdit = createStore({ formData: null, stages: [] });

export const $participantsBins = createStore({});
export const $participantsSelect = createStore([]);
export const $participantsMap = combine($participantsSelect, (parts) =>
  Object.fromEntries(Object.values(parts).map((i) => [i.value, i.label]))
);

export const $organizationsSelect = combine(
  $globalStore,
  ({ organizations }) =>
    organizations?.map((i) => ({
      label: i.name,
      value: i.id,
      region_id: i.region_id,
      role_id: i.role_id,
      gov_id: i.gov_id
    })) ?? []
);

export const $rolesSelect = combine($globalStore, ({ roles }) =>
  roles?.filter(i => ![1, 4].includes(i.id))?.map((i) => ({label: i.name_ru, value: i.id,})))
