import {createStore} from 'effector';

export const $requestsList = createStore([])

export const $selectedRequest = createStore(null)

export const $createEditModal = createStore({open: false, type: null, id: null})

export const $createStatus = createStore(false)

export const $viewModal = createStore({ open: false, id: null });
