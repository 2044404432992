import {Button, Card, Form, Input} from "antd";
import {useEvent, useStore} from "effector-react";
import {$authStatus, loginEv, loginFx} from "../../models/authModel/index.js";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {cookies} from "../../api/axiosInstance.js";

const Login = () => {
	const onLogin = useEvent(loginEv)
	const loading = useStore(loginFx.pending)
	const authStatus = useStore($authStatus)

	const navigate = useNavigate()

	useEffect(() => {
		if (authStatus) {
			const token = cookies.get('access_token')
			if (token) {
				navigate('/')
			}
		}
	}, [authStatus]);

	return <Card className={'login-form'} id='login-form'>
		<Form name={'loginForm'} id={'loginForm'}
					onFinish={onLogin}
					layout={'vertical'}
		>
			<Form.Item name={'username'} label={'Логин'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'password'} label={'Пароль'}>
				<Input.Password allowClear={true}/>
			</Form.Item>
			<Button htmlType={'submit'} form={'loginForm'} type={'primary'} loading={loading} className="login-btn">
				Войти
			</Button>
      <Button type={'link'} href={'/register'}>Регистрация через ЭЦП</Button>
      <Button type={'link'} href={'/reg-request'}>Регистрация для инвестора</Button>
		</Form>
	</Card>
}

export default Login;
