import {Col, Divider, Form, InputNumber, Row, Switch, Typography} from "antd";
import {useState} from "react";
import {numberParser} from "../../utils.js";

const SoilTemplate = ({namespace, title, total, resetVal, form}) => {
    const [providedByGov, setProvidedByGov] = useState(form.getFieldValue([...namespace, 'is_provided'] ?? false))

    return <>
        <Divider orientation={'left'} orientationMargin={0}>{`В т.ч. ${title}, га`}</Divider>
        <Typography.Title level={5}>{`Итого: ${total}`}</Typography.Title>
                    <Row style={{alignItems: 'center'}}>
                        <Col span={4}>
                            <Form.Item name={[...namespace, 'available_value']} label={'Из них собственные'} key={`${namespace}-1`}
                                       trigger={'onBlur'}
                                       getValueFromEvent={(e) => numberParser(e.target.value)}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={[...namespace, 'requested_value']} label={'Из них запрашиваемые у государства'} key={`${namespace}-2`}
                                       trigger={'onBlur'}
                                       getValueFromEvent={(e) => numberParser(e.target.value)}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={[...namespace, 'is_provided']}
                                       label={'Предоставлено государством'}
                                       valuePropName={'checked'}
                                       key={`${namespace}-3`}
                                       initialValue={form.getFieldValue([...namespace, 'is_provided'] ?? false)}
                            >
                                <Switch onChange={(checked) => {
                                    if (checked) {
                                        setProvidedByGov(checked)
                                    } else {
                                        resetVal([...namespace, 'provided_value'])
                                        setProvidedByGov(checked)
                                    }
                                }}/>
                            </Form.Item>
                        </Col>
                        {providedByGov && <Col span={4}>
                            <Form.Item name={[...namespace, 'provided_value']} label={'В каком объеме'} key={`${namespace}-4`}
                                       trigger={'onBlur'}
                                       getValueFromEvent={(e) => numberParser(e.target.value)}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>}
                    </Row>
    </>
}

export default SoilTemplate