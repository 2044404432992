import style from './ViewProjectComponents.module.css';
import dayjs from "dayjs";

function ProjectDate({ date, title }) {
  return (
    <div className={style.project_view_card}>
      <div className={style.project_view_card_text}>{title}</div>
      <div className={style.date}>{date ? dayjs(date).format('DD.MM.YY') : '-'}</div>
    </div>
  );
}

export default ProjectDate;
