import {Input} from "antd";
import './SearchSelect.css'
import SearchSelect from "./SearchSelect.jsx";
import {useEvent} from "effector-react";
import {changeActiveFilterEv} from "../../models/activeFiltersModel/index.js";

const SearchSelectWrapper = ({withSearch, select_items}) => {
    const onApply = useEvent(changeActiveFilterEv)

    return <div className={'search-container'}>
        <SearchSelect title={'Отрасли'} data={select_items.activity_select} field={'main_activity_id'} onChange={onApply} tree={true}/>
        <SearchSelect title={'Предприятия'} data={select_items.organizations_select} field={'organization_id'} onChange={onApply}/>
        {withSearch
            && <Input.Search placeholder={"Поиск по наименованию проекта"}
                             allowClear={true}
                             onSearch={(value) => onApply({field: 'name', value})}
            />
        }
        <SearchSelect title={'БИН'} data={select_items.bin_select} field={'organization_bin'} onChange={onApply} single={true}/>
    </div>
}

export default SearchSelectWrapper
