import dayjs from 'dayjs';

function isOverdue(status, plan_date, fact_date) {
  if (status === 2 && plan_date && fact_date) {
    return new Date(fact_date).getTime() > new Date(plan_date).getTime();
  } else if ([1, 5, null].includes(status) && plan_date) {
    return new Date().getTime() > new Date(plan_date).getTime();
  }
}

export const prepareProjectData = (data, dicts) => {
  const {
    id,
    name,
    main_activity_id,
    region_id,
    district_id,
    location,
    description,
    capacities,
    participants,
    updated_at,
    start_date_plan,
    npp_approved,
    rpp_approved,
    mio_approved,
    publish_on_process,
  } = data;

  const budgets =
    data.budgets.length > 0
      ? data.budgets
            .filter(i => i.sum_plan > 0)
            .map((item) => {
              return {
                source: dicts?.budgetsIdToNameMap[item.budget_source_id] ?? null,
                value: item.sum_plan,
                color: item.budget_source_id,
              };
            })
      : [];
  const budgets_total =
    data.budgets.length > 0
      ? data.budgets.map((i) => i.sum_plan).reduce((acc = 0, cur) => acc + cur)
      : 0;

  const effects =
    data?.effects?.map((item) => {
      return {
        name: dicts?.effectsIdToNameMap[item?.effect_id],
        inv_period: item?.investment_period,
        postinv_period: item?.post_investment_period,
      };
    }) ?? [];

  const start_date =
    data?.stages?.toSorted(
      (a, b) =>
        new Date(a?.start_date_plan).getTime() -
        new Date(b?.start_date_plan).getTime()
    )[0]?.start_date_plan ?? null;

  const finish_date =
    data?.stages
      ?.toSorted(
        (a, b) =>
          new Date(a?.finish_date_plan).getTime() -
          new Date(b?.finish_date_plan).getTime()
      )
      ?.at(-1)?.finish_date_plan ?? null;

  const overdue_count = data?.stages
      ?.filter((stage) => [1, 5, null].includes(stage.status_id))
      ?.filter(
        (stage) => !!stage?.finish_date_plan &&
          new Date(stage?.finish_date_plan).getTime() < new Date().getTime()
      )?.length;

  const overdue_count_complete = data?.stages
    ?.filter((stage) => stage.status_id === 2)
    ?.filter(
      (stage) => !!(stage?.finish_date_plan && stage?.finish_date_fact) &&
        new Date(stage?.finish_date_plan).getTime() < new Date(stage?.finish_date_fact).getTime()
    )?.length

  const overdue_count_total = overdue_count + overdue_count_complete

  let current_phase = 0

  if (data?.stages?.length > 0) {
    if (data?.stages?.every(i => [2, 3, 4].includes(i.status_id) && i?.finish_date_fact)) {
      current_phase = 5
    } else {
      const stagesInWork = data?.stages?.filter((i) => i.status_id === 1 || i.status_id === 5)
      if (stagesInWork?.length > 0) {
        current_phase = stagesInWork
          ?.toSorted((a, b) => a.phase_id < b.phase_id)
          ?.at(-1)?.phase_id ?? 1
      }
    }
  } else {
    current_phase = 0
  }
  // const current_phase = data?.stages?.every(i => i.status_id === 2)
  //     ? 5
  //     : data?.stages.filter((i) => i.status_id === 1)
  //       ?.toSorted((a, b) => a.phase_id < b.phase_id)
  //       ?.at(-1)?.phase_id ?? 1;

  const stagesMapByRole = {
    [dicts.rolesMap[1]]: {
      count: 0,
      completed: 0,
    },
    [dicts.rolesMap[2]]: {
      count: 0,
      completed: 0,
    },
    [dicts.rolesMap[3]]: {
      count: 0,
      completed: 0,
    },
    [dicts.rolesMap[5]]: {
      count: 0,
      completed: 0,
    },
  };

  for (const participant of Object.values(participants)) {
    if (!stagesMapByRole[dicts.rolesMap[participant?.role_id]]) {
      stagesMapByRole[dicts.rolesMap[participant?.role_id]] = {
        count: participant.stage_count,
        completed: participant.stage_completed,
      };
    } else {
      stagesMapByRole[dicts.rolesMap[participant?.role_id]].count +=
        participant?.stage_count;
      stagesMapByRole[dicts.rolesMap[participant?.role_id]].completed +=
        participant?.stage_completed;
    }
  }

  const stages_chart = Object.entries(stagesMapByRole)?.flatMap(
    ([role, values]) => [
      { category: role, type: 'План', value: values.count },
      { category: role, type: 'Факт', value: values.completed },
    ]
  );

  const stagesCountTotal = Object.values(stagesMapByRole).reduce(
    (acc = 0, cur) => acc + cur?.count,
    0
  );
  const stagesCountComplete = Object.values(stagesMapByRole).reduce(
    (acc = 0, cur) => acc + cur?.completed,
    0
  );
  const completion_progress = Math.floor(
    (stagesCountComplete / stagesCountTotal) * 100
  );

  const stages_tables = {};
  for (const stage of data?.stages) {
    if (!stages_tables[stage?.phase_id]) {
      stages_tables[stage?.phase_id] = [];
    }
    stages_tables[stage?.phase_id].push({
      ...stage,
      stage_name: dicts?.stagesIdToNameMap[stage?.stage_id],
      budget_source: stage.budget_source_ids?.map((id) => dicts?.budgetsIdToNameMap[id]).join(', '),
      responsible: stage?.organization_id,
      status: dicts?.stageStatusMap[stage?.status_id] ?? 'Не указан',
      overdue: isOverdue(stage.status_id, stage?.finish_date_plan, stage?.finish_date_fact),
    });
  }

  const requirements = Object.fromEntries(
    data?.requirements?.map((req) => [
      dicts.requirementsIdToNameMap[req.requirement_id],
      data?.resources
        ?.filter((res) =>
          dicts.reqIdToResIdsMap[req.requirement_id]?.includes(res.resource_id)
        )
        ?.map((res) => ({
          ...res,
          name: dicts.projReqToResNameMap[res.resource_id],
          total:
            (res?.available_value ?? 0) +
            (res?.requested_value ?? 0) +
            (res?.provided_value ?? 0),
        })),
    ])
  );

  const requirements_summary = Object.fromEntries(
    Object.entries(requirements)?.map(([key, values]) => [
      key,
      {
        available_value: values.reduce(
          (acc = 0, cur) => acc + cur?.available_value,
          0
        ),
        requested_value: values.reduce(
          (acc = 0, cur) => acc + cur?.requested_value,
          0
        ),
        provided_value: values.reduce(
          (acc = 0, cur) => acc + cur?.provided_value,
          0
        ),
        total: values.reduce((acc = 0, cur) => acc + cur?.total, 0),
      },
    ])
  );

  for (const [key, values] of Object.entries(requirements_summary)) {
    if (Object.values(values).every((i) => i === 0)) {
      continue;
    }
    requirements[key]?.unshift({ ...values, name: 'Всего' });
  }

  return {
    project_id: id,
    name,
    main_activity_id,
    updated_at,
    start_date_plan,
    region_id,
    district_id,
    location,
    description,
    capacities: { ...capacities[0] },
    budgets,
    budgets_total,
    effects,
    start_date,
    finish_date,
    overdue_count,
    overdue_count_total,
    current_phase,
    stages_chart,
    stagesCountTotal,
    stagesCountComplete,
    completion_progress,
    stages_tables,
    participants,
    requirements,
    rpp_approved,
    npp_approved,
    mio_approved,
    publish_on_process,
  };
};

export const prepareInvestorInfo = (data) => {
  const statgov = { ...data?.statgov[0] } ?? null;

  const blocks = [
    {
      title: 'Наименование',
      value: data.name,
    },
    {
      title: 'КРП',
      value: statgov?.krp_name_ru ?? '',
    },
    {
      title: 'Регион',
      value: statgov?.kato_level_0 ?? '',
    },
    {
      title: 'Дата регистрации',
      value: dayjs(data?.reg_date ?? statgov?.registration_date).format('DD.MM.YYYY'),
    },
    {
      title: 'Юридический адрес',
      value: statgov?.legal_adress ?? data.address ?? '',
    },
    {
      title: 'Вид деятельности',
      value: data?.main_activity?.activity_name_ru ?? '',
    },
    {
      title: 'Руководитель',
      value: data.full_name ?? '',
    },
    {
      title: 'Контактное лицо',
      value: data?.full_name ?? '',
    },
  ];

  const graphs = [
    {
      title: 'Динамика оплаченных налогов',
      values: data?.taxes
        ?.toSorted(
          (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
        )
        ?.filter((t) => typeof t?.amount === 'number')
        ?.map((t) => ({ date: t?.date, value: t?.amount })),
    },
    {
      title: 'Динамика Фонда оплаты труда',
      values: data?.enpf
        ?.toSorted(
          (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
        )
        ?.filter((t) => typeof t?.enpf_sum === 'number')
        ?.map((t) => ({ date: t?.date, value: t?.enpf_sum })),
    },
    {
      title: 'Динамика кол-ва сотрудников',
      values: data?.enpf
        ?.toSorted(
          (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
        )
        ?.filter((t) => typeof t?.count_people === 'number')
        ?.map((t) => ({ date: t?.date, value: t?.count_people })),
    },
  ];

  return { blocks, graphs };
};
