import { useEvent, useStore } from 'effector-react';
import { Button, Space } from 'antd';
import style from './MapControls.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';

function MapControls() {
  const activeFilters = useStore($activeFilters);
  const changeActiveFilter = useEvent(changeActiveFilterEv);
  const clearFilter = (filter) => {
    // if (
    //   filter === 'region_id' &&
    //   activeFilters.region_id &&
    //   activeFilters.region_id !== 0 &&
    //   activeFilters.district_id !== 0
    // ) {
    //   changeActiveFilter({
    //     field: 'district_id',
    //     value: 0,
    //   });
    // }

    changeActiveFilter({
      field: filter,
      value: 0,
    });
  };

  return (
    <Space direction="vertical" className={style.controls_wrapper}>
      {activeFilters.region_id > 0 ? (
        <Button onClick={() => clearFilter('region_id')}>
          Очистить выбранный регион
        </Button>
      ) : (
        ''
      )}
      {activeFilters.district_id > 0 ? (
        <Button onClick={() => clearFilter('district_id')}>
          Очистить выбранный район
        </Button>
      ) : (
        ''
      )}
    </Space>
  );
}

export default MapControls;
