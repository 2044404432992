import { Button, Card, Form, Input, Popconfirm } from 'antd';
import { useState } from 'react';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import {
  changePasswordEv,
  changePasswordFx,
} from '../../../models/authModel/index.js';

export default function PasswordChange() {
  const { t } = useTranslation();
  const [passForm] = Form.useForm();
  const [passValid, setPassValid] = useState(false);
  const validPass = new RegExp(
    '^(?=.*[a-zA-Z0-9_!@#$%^&?*])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&?*])[a-zA-Z0-9_!@#$%^&?*]{8,32}$'
  );
  const onConfirm = useUnit(changePasswordEv);
  const loading = useUnit(changePasswordFx.pending);

  const oldPass = Form.useWatch('current_password', passForm);
  console.log("=>(PasswordChange.jsx:21) oldPass", oldPass);

  return (
    <Card title={t('Изменить пароль')}>
      <Form
        form={passForm}
        layout="vertical"
        onFinish={({current_password, new_password}) => onConfirm({current_password, new_password})}
      >
          <Form.Item
              name="current_password"
              label={t('Текущий пароль')}
              rules={[{ required: true, message: t('Обязательное поле') }]}
          >
              <Input.Password />
          </Form.Item>
        <Form.Item
          name="new_password"
          label={t('Новый пароль')}
          rules={[
            { required: true, message: t('Обязательное поле') },
            {
              pattern: validPass,
              message: t(
                'Пароль должен содержать символы a-z, A-Z, 0-9, _!@#$%^&?*, длина 8-32'
              ),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label={t('Подтвердите пароль')}
          dependencies={['new_password']}
          rules={[
            { required: true, message: t('Подтвердите пароль') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('new_password') === value) {
                  setPassValid(true);
                  return Promise.resolve();
                }
                setPassValid(false);
                return Promise.reject(new Error(t('Пароли не совпадают')));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <Popconfirm
        title={t('Вы уверены, что хотите изменить пароль?')}
        onConfirm={() => passForm.submit()}
        disabled={!passValid || !oldPass}
      >
        <Button type="primary" disabled={!passValid || !oldPass} loading={loading}>
          {t('Подтвердить')}
        </Button>
      </Popconfirm>
    </Card>
  );
}
