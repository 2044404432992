import queryString from 'query-string';
import { regions_dictionary } from '../../dictionaries/regions_districts_dictionary.js';

export function prepareResults(results) {
  if (results.length === 0) {
    return [];
  }
  const res = {};
  results.forEach((i) => {
    res[i.region] = {
      region: i.region,
      value: i.value,
    };
  });

  Object.values(regions_dictionary).forEach((region) => {
    if (region !== '(Пусто)' && !res[region]) {
      res[region] = { region, value: 0 };
    }
  });
  // results.forEach((i) => {
  //   if (res[i.region]) {
  //     res[i.region].categories[i.category] = i.value;
  //   } else {
  //     res[i.region] = {
  //       region: i.region,
  //       categories: { [i.category]: i.value },
  //     };
  //   }
  // });

  // Object.values(regions_dictionary).forEach((region) => {
  //   if (region !== '(Пусто)' && !res[region]) {
  //     const { categories } = Object.values(res)[0];
  //     res[region] = {
  //       region,
  //       categories: Object.fromEntries(
  //         Object.entries(categories).map(([key]) => [key, 0])
  //       ),
  //     };
  //   }
  // });

  return Object.values(res);
}

export function checkValue(val) {
  if (val.value.length > 1 && val.value.includes('custom')) {
    return [...val.value, val.custom].filter((i) => i !== 'custom');
  }
  if (val.value.includes('custom') && val.value.length === 1) {
    return [val.custom];
  }
  return val.value;
}

export const formQuery = (data) => {
  const payload = { ...data };
  if (!payload.region_id) delete payload.region_id;
  if (!payload.activeQuarter) {
    delete payload.activeQuarter;
  } else {
    const [year, quarter] = payload.activeQuarter.split('_');
    payload.year = year;
    payload.quarter = quarter;
    delete payload.activeQuarter;
  }
  const result = queryString
    .stringify(payload)
    .replaceAll(/small|medium/g, (match) => {
      if (match === 'small') return 'малый';
      if (match === 'medium') return 'средний';
    });

  return result;
};
