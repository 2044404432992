import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/locales/en/translation.json';
import ru from './assets/locales/ru/translation.json';
import kz from './assets/locales/kz/translation.json';
import cn from './assets/locales/cn/translation.json';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  kz: {
    translation: kz,
  },
  cn: {
    translation: cn,
  },
};

await i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang'),
  debug: false,
  fallbackLng: 'ru',
  interpolation: { escapeValue: false },
  ns: 'translation',
  defaultNS: 'translation',
});

export default i18next;
