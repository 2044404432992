import { sample } from 'effector';
import {
  $createEditModal,
  $createStatus,
  $requestsList,
  $selectedRequest,
  $viewModal
} from './stores.js';
import {
  confirmReqFx,
  createReqFx,
  declineReqFx, downloadReqFileFx, editReqFx,
  getReqByIdFx,
  getReqListFx, sendRequestFx,
} from './effects.js';
import {
  closeReqModalEv,
  confirmReqEv,
  declineReqEv, downloadReqFileEv,
  getReqByIdEv, setReqModalEv,
  RequestsGate,
  resetSelectedEv,
  submitRequestEv, setViewModalEv, sendRequestEv,
} from './events.js';
import notification from 'antd/es/notification';

$requestsList
  .on(getReqListFx.doneData, (_, payload) => payload)
  .reset(RequestsGate.close);

$selectedRequest
  .on(getReqByIdFx.doneData, (_, payload) => payload)
  .reset([resetSelectedEv, closeReqModalEv]);

$createEditModal
  .on(setReqModalEv, (state, payload) => ({ ...state, ...payload }))
  .reset(closeReqModalEv);

$createStatus
  .on([createReqFx.doneData, editReqFx.doneData], () => true)
  .reset(closeReqModalEv);

$viewModal
  .on(setViewModalEv, (_, payload) => ({ open: true, id: payload }))
  .reset(resetSelectedEv);

sample({
  clock: [
    RequestsGate.open,
    confirmReqFx.doneData,
    editReqFx.doneData,
    declineReqFx.doneData,
    createReqFx.doneData,
    sendRequestFx.doneData,
  ],
  target: getReqListFx,
});

sample({
  clock: getReqByIdEv,
  target: getReqByIdFx,
});

sample({
  clock: $viewModal.updates,
  filter: (modal) => !!modal.open && !!modal.id,
  fn: (modal) => modal.id,
  target: getReqByIdEv,
});

sample({
  source: $createEditModal,
  clock: submitRequestEv,
  filter: (modal) => modal.type === 'create',
  fn: (_, payload) => payload,
  target: createReqFx,
});

sample({
  source: $createEditModal,
  clock: submitRequestEv,
  filter: (modal) => modal.type === 'edit',
  fn: (modal, payload) => ({id: modal.id, payload}),
  target: editReqFx,
});

sample({
  clock: $createEditModal.updates,
  filter: (modal) => modal.type === 'edit' && !!modal.id,
  fn: (modal) => modal.id,
  target: getReqByIdEv,
});

sample({
  clock: confirmReqEv,
  target: confirmReqFx,
});

sample({
  clock: declineReqEv,
  target: declineReqFx,
});

sample({
  clock: downloadReqFileEv,
  target: downloadReqFileFx,
});

sample({
  clock: sendRequestEv,
  target: sendRequestFx,
});

confirmReqFx.doneData.watch(() => notification.success({message: 'Успешно', description: 'Заявка подтверждена'}))
declineReqFx.doneData.watch(() => notification.warning({message: 'Успешно', description: 'Заявка отклонена'}))
sendRequestFx.doneData.watch(() => notification.success({message: 'Успешно', description: 'Заявка на участие отправлена'}))
