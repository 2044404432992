import { Select } from 'antd';
import { useEvent, useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $regionsSelect } from '../../models/globalModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { $selectedRegion } from '../../models/regionsLayerModel/index.js';

function RegionSelector() {
  const regions = useStore($regionsSelect);
  const activeFilters = useStore($activeFilters);
  const changeActiveFilter = useEvent(changeActiveFilterEv);
  const selectedRegion = useStore($selectedRegion);
  const [selectValue, setSelectValue] = useState(null);

  const onRegionSelect = (value) => {
    // if (!value && activeFilters.district_id && activeFilters.district_id !== 0) {
    //     changeActiveFilter({
    //         field: 'district_id',
    //         value: 0,
    //     });
    // }
    changeActiveFilter({
      field: 'region_id',
      value: value ?? 0,
    });
  };

  useEffect(() => {
    if (selectedRegion === 0) {
      setSelectValue(null);
    } else {
      setSelectValue(selectedRegion);
    }
  }, [selectedRegion]);

  return (
    <Select
      style={{
        width: '220px',
        position: 'absolute',
        left: '10px',
        top: '10px',
        color: 'black',
      }}
      options={regions}
      onChange={onRegionSelect}
      value={selectValue}
      allowClear
      placeholder="Выберите регион"
    />
  );
}

export default RegionSelector;
