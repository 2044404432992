import {Col, Progress, Row, Tooltip} from "antd";
import ProjectCost from "./ProjectCost.jsx";
import ProjectRealisationEffect from "./ProjectRealisationEffect.jsx";
import ProjectStatus from "./ProjectStatus.jsx";
import ProjectOverdue from "./ProjectOverdue.jsx";
import ProjectDate from "./ProjectDate.jsx";
import ProjectChart from "./ProjectChart.jsx";
import React from "react";
import {useStore} from "effector-react";
import {$projectStore} from "../../models/viewProjectModel/index.js";
import style from "./ViewProjectComponents.module.css";

const ProjectSummary = ({setShowOverdue}) => {
    const {
        budgets,
        budgets_total,
        effects,
        start_date,
        finish_date,
        overdue_count,
        overdue_count_total,
        current_phase,
        stages_chart,
        completion_progress,
        stagesCountTotal,
        stagesCountComplete,
        project_start_date,
        updated_at
    } = useStore($projectStore);

    return <Row gutter={[20, 20]}>
        <Col span={6}>
            <ProjectCost data={budgets} total={budgets_total}/>
        </Col>
        <Col span={6}>
            <ProjectRealisationEffect data={effects}/>
        </Col>
        <Col span={12}>
            <Row gutter={[20, 28]}>
                <Col span={12}>
                    <ProjectStatus activeStatus={current_phase} />
                </Col>
                <Col span={12}>
                    <Row gutter={[20, 5]}>
                        <Col span={12}>
                            <ProjectOverdue overdue={overdue_count} title={'Текущие просрочки'} setOverdue={setShowOverdue}/>
                        </Col>
                        <Col span={12}>
                            <ProjectOverdue overdue={overdue_count_total} title={'Всего просрочек'} setOverdue={setShowOverdue}/>
                        </Col>
                        <Col span={12}>
                            <ProjectDate title="Начало" date={start_date} />
                        </Col>
                        <Col span={12}>
                            <ProjectDate title="Завершение" date={finish_date} />
                        </Col>
                        <Col span={12}>
                            <ProjectDate title="Проект начат" date={project_start_date} />
                        </Col>
                        <Col span={12}>
                            <ProjectDate title="Проект обновлен" date={updated_at} />
                        </Col>
                    </Row>
                </Col>
              <Col span={18}>
                <ProjectChart data={stages_chart}/>
              </Col>
              <Col span={6}>
                <div className={style.project_view_card} style={{padding: '24px', minHeight: 315}}>
                            <h1 className={style.project_view_card_title}>Прогресс проекта</h1>
                            <Tooltip title={`${stagesCountComplete} из ${stagesCountTotal} стадий завершено.`}>
                            <div className={style.flex_column} style={{marginTop: '44px', alignItems: 'center'}}>
                                <Progress percent={completion_progress}
                                          size={120}
                                          type={'circle'}
                                          strokeWidth={16}
                                          showInfo={false}
                                          style={{position: "relative"}}
                                    // status={completion_progress < 100 ? 'active' : 'success'}
                                          success={{percent: completion_progress, strokeColor: 'rgb(85, 231, 187)'}}
                                />
                                <div className={style.project_view_card_subtitle}
                                     style={{position: 'absolute', top: '50%', fontSize: 32}}
                                >
                                    {stagesCountComplete + '/' + stagesCountTotal}
                                </div>
                            </div>
                            </Tooltip>
                            </div>
                </Col>
            </Row>
        </Col>
    </Row>
}

export default ProjectSummary