import { months_dictionary } from '../../dictionaries/months_dictionary.js';

export const initData = {
    mal: {
        title: 'Малый бизнес',
        cards: [
            {
                title: 'Численность занятых',
                value: 485.3,
                measure: ' человек',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 10,
                type: 'positive',
                subCategory: 'people',
            },
            {
                title: 'Сумма уплаченных налогов',
                value: 1563.5,
                measure: ' тенге',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 5,
                type: 'positive',
                subCategory: 'taxes',
            },
        ],
    },
    sred: {
        title: 'Средний бизнес',
        cards: [
            {
                title: 'Количество действующих компаний',
                value: 560,
                measure: '',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 10,
                type: 'negative',
                subCategory: 'medium_companies',
                breakWord: true
            },
        ],
    },
    businessClimate: {
        title: 'Рейтинг НПП "Деловой климат"',
        cards: [
            {
                header: 'Малый и микро бизнес',
                title: 'Оценка эффективности деятельности МИО',
                value: 0,
                measure: 'баллов',
                date: '1 кв. 2024',
                percent: 0,
                type: 'positive',
                subCategory: 'business_inspections',
            },
            {
                header: 'Средний бизнес',
                title: 'Оценка эффективности деятельности МИО',
                value: 0,
                measure: 'баллов',
                date: '1 кв. 2024',
                percent: 0,
                type: 'positive',
                subCategory: 'mio_performance',
            }
            // {
            //   title: 'Количество проверок бизнеса',
            //   value: 3.5,
            //   measure: 'баллов',
            //   date: '3 кв. 2023',
            //   percent: '',
            //   type: 'positive',
            //   subCategory: 'business_inspections',
            // },
        ],
    },
    projectsRealisation: {
        title: 'Инвестиционные проекты',
        cards: [
            {
                title: 'Количество проектов',
                value: 800,
                measure: '',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 1,
                type: 'positive',
                subCategory: 'projectCount',
                projectsButton: true,
                dashboardButton: true
            },
            // {
            //   title: 'Количество проектов без отставаний',
            //   value: 440,
            //   measure: 'единиц',
            //   date: 'ноябрь 2023',
            //   percent: 1,
            //   type: 'positive',
            //   subCategory: 'normalProjSum',
            // },
            // {
            //   title: 'Количество проектов с отставаниями',
            //   value: 100,
            //   measure: 'единиц',
            //   date: 'ноябрь 2023',
            //   percent: 1,
            //   type: 'negative',
            //   subCategory: 'delayedProjSum',
            // },
            {
                title: 'Количество проблемных проектов',
                value: 260,
                measure: '',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 10,
                type: 'negative',
                subCategory: 'problemProjects',
                projectsButton: true,
            },
        ],
    },
    otp: {
        title: 'Предприятия обрабатывающей промышленности',
        cards: [
            {
                title: 'Количество предприятий',
                value: 485.3,
                measure: '',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 10,
                type: 'positive',
                subCategory: 'otp_count',
                otpModal: true,
            },
            {
                title: 'Средняя загруженность предприятий',
                value: 0,
                measure: '%',
                date: `${months_dictionary[new Date().getMonth() + 1]} ${new Date().getFullYear()}`,
                percent: 5,
                type: 'positive',
                subCategory: 'otp_avg_workload',
            },
        ],
    },
};