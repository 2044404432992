import {Col, Row, Tooltip} from 'antd';
import { Line } from '@ant-design/charts';
import style from './ViewProjectComponents.module.css';
import {useStore} from "effector-react";
import {$projInvestorInfo} from "../../models/viewProjectModel/index.js";


const formatText = (v) => {
  return v.length > 30 ? <Tooltip title={v}>{`${v.slice(0, 36)}...`}</Tooltip> : v;
};

function ProjectInvestorInfo() {
  const investor = useStore($projInvestorInfo)

  return (
    <div>
      {/*<div className={style.block_title}>Об инвесторе</div>*/}
      <Row gutter={[20, 20]}>
        {investor?.blocks?.map((item, index) => {
          return (
            <Col span={6} key={`${item.title}-${index}`}>
              <div className={style.project_view_card}>
                <div className={style.project_view_card_title}>
                  {item.title}
                </div>
                <div
                  className={style.project_view_card_text}
                  style={{ marginTop: '8px' }}
                >
                  {formatText(item.value)}
                </div>
              </div>
            </Col>
          );
        })}
        {investor?.graphs?.map((item, index) => {
          return (
            <Col span={8} key={`${item.title}-${index}`}>
              <div
                className={style.project_view_card}
                style={{ maxHeight: '247px' }}
              >
                <div className={style.project_view_card_title}>
                  {item.title}
                </div>
                <LineGraph data={item.values} />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default ProjectInvestorInfo;

function LineGraph({ data }) {
  const format = (v) => {
    return typeof v === 'number'
        ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
        : v;
  };

  const formatYLabel = (v) => {
    return v.length > 10 ? `${v.slice(0, 10)}...` : v;
  };

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    height: 150,
    color: '#4318FF',
    label: { position: 'right', formatter: (v) => format(v['value']) },
    yAxis: { label: { formatter: (v) => formatYLabel(v) }},
    meta:{
      date: { alias: 'Дата'},
      value: { alias: 'Сумма', formatter: (v) => format(parseInt(v))},
    }
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {data?.length > 0 ? <Line {...config} /> : 'Нет данных'}
    </div>
  );
}
