import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {useEvent, useStore, useStoreMap} from 'effector-react';
import {Col, Row, Skeleton, Tabs} from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import EditSteps from './EditSteps.jsx';
import 'dayjs/locale/ru.js';
import { $mapLoaded } from '../../models/globalModel/index.js';
import {
  $createdObjects,
  createObjectEv,
  resetCreatedObjectsEv,
} from '../../models/createObjectModel/index.js';
import { $userEditPerms } from '../../models/authModel/index.js';
import style from './EditProjectById.module.css';
import ProjectCreateEditForm from '../../components/ProjectCreateEditForm/ProjectCreateEditForm.jsx';
import {
  $projectForEdit,
  EditProjectGate,
} from '../../models/projectCreateEditModel/index.js';
import {$dictionariesMaps} from '../../models/dictionariesModel/index.js';

dayjs.extend(weekday);
dayjs.extend(localeData);
const { TabPane } = Tabs;

function EditProjectById() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('1');

  const infoLoading = useStoreMap($dictionariesMaps, (maps) => Object.values(Object.values(maps)[0]).length === 0)

  const { formData, stages } = useStore($projectForEdit);

  const mapLoaded = useStore($mapLoaded);
  const createdPoints = useStore($createdObjects).points;

  const createObject = useEvent(createObjectEv);

  const resetCreatedObjects = useEvent(resetCreatedObjectsEv);

  const perms = useStore($userEditPerms);

  const projectPerms = perms.project;
  const stagesPerms = perms.stages;

  const project_dots = {
    type: 'Feature',
    geometry: {
      ...formData?.location,
    },
  };

  useEffect(() => {
    if (mapLoaded) {
      createObject(project_dots);
    }
  }, [mapLoaded]);

  useEffect(() => {
    return () => {
      if (window.draw && createdPoints.length > 0) {
        window.draw.set({
          type: 'FeatureCollection',
          features: [],
        });
      }
      resetCreatedObjects();
    };
  }, []);

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (mapLoaded && Object.values(perms).some((item) => item.length > 0)) {
      if (!projectPerms.includes('objects')) {
        const controls = document.getElementsByClassName(
          'mapboxgl-ctrl-group mapboxgl-ctrl'
        );
        for (const item of controls) {
          item.classList.add(style.disabled);
        }
      }
    }
  }, [mapLoaded, perms]);

  return (
    <ContainerInner title={`Редактирование ${formData?.name ?? ''}`}>
      {!infoLoading && <EditProjectGate id={id} />}
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" onChange={onTabChange} centered>
            <TabPane tab="Основная" key="1">
              {formData?.id
                  ? <ProjectCreateEditForm editMode project={formData}/>
                  : <Skeleton loading={true}/>
              }
            </TabPane>

            <TabPane tab="Стадии проекта" key="2">
              <EditSteps
                perms={stagesPerms}
                participantsTableData={formData?.participants}
                stages={stages}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </ContainerInner>
  );
}

export default EditProjectById;
