import {
  regionProjectsWidgetCounter,
  regionSumWidgetDataCounter,
} from '../investments/counters.js';
import { filterProjects } from '../projects/index.js';
import { regions_dictionary } from '../../dictionaries/regions_districts_dictionary.js';

export const prepareRegionsFeatures = (features, data) => {
  const counters = {
    regionSumWidgetData: regionSumWidgetDataCounter,
    regionProjectsWidget: regionProjectsWidgetCounter,
  };

  const summary = Object.fromEntries(
    Object.keys(counters).map((prop) => [prop, {}])
  );

  data.forEach((item) => {
    summary.regionSumWidgetData = counters.regionSumWidgetData(
      item,
      summary.regionSumWidgetData
    );
    summary.regionProjectsWidget = counters.regionProjectsWidget(
      item,
      summary.regionProjectsWidget
    );
  });

  return features.map((item, index) => {
    return {
      ...item,
      properties: {
        ...item.properties,
        sum: summary.regionSumWidgetData[item.id],
        projects_count: summary.regionProjectsWidget[item.id],
        mal: index % 2 === 0 ? 0 : 1,
        sred: index % 2 === 0 ? 1 : 0,
        businessClimate: index % 2 === 0 ? 0 : 1,
        projectsRealisation: index % 2 === 0 ? 1 : 0,
      },
    };
  });
};

export const prepareRegionsFilteredFeatures = (projects, startData) => {
  const project_region_ids = new Set(projects.map((item) => item.region_id));
  return startData.filter((item) => project_region_ids.has(item.id));
};

export const filterRegionsByGradient = (
  regions,
  activeFilters,
  activeGradient
) => {
  return regions
    .filter((item) => {
      return (
        item.properties[activeGradient] >= activeFilters.value.min &&
        item.properties[activeGradient] < activeFilters.value.max
      );
    })
    .map((item) => item.id);
};

export const prepareRegionsOverview = (filteredData, startData) => {
  return startData.map((item) => {
    const region_name = regions_dictionary[item.id];
    return {
      ...item,
      properties: {
        ...item.properties,
        change: filteredData[region_name]?.value,
      },
    };
  });
};

export const prepareRegionsOtp = (filteredData, startData) => {
  return startData.map((item) => {
    const region_name = regions_dictionary[item.id];
    return {
      ...item,
      properties: {
        ...item.properties,
        otp_count: filteredData[region_name]?.value,
      },
    };
  });
};

export const prepareRegionsOrgs = (startData, orgsData) => {
  return startData.map((item) => {
    const region_name = regions_dictionary[item.id];
    const orgsCount = orgsData.region_data[region_name]
      ? Object.values(orgsData.region_data[region_name]).reduce((a, b) => {
          return a + parseInt(b);
        }, 0)
      : 0;
    return {
      ...item,
      properties: {
        ...item.properties,
        orgsCount,
      },
    };
  });
};
