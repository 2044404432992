import { Button, Card, Form, InputNumber, Space } from 'antd';
import { useStore } from 'effector-react';
import { useState } from 'react';
import style from './BottomBar.module.css';
import { $createdObjects } from '../../models/createObjectModel/index.js';

function EditBottomBar() {
  const { points } = useStore($createdObjects);

  console.log('points', points);

  return (
    <Space
      size="large"
      className={[style.bottom_bar_wrapper, style.edit].join(' ')}
    >
      {points.map((point, index) => (
        <PointForm
          key={`${point.properties.id}${index}`}
          id={point.properties.id}
          coords={point.geometry.coordinates}
        />
      ))}
    </Space>
  );
}

export default EditBottomBar;

function PointForm(props) {
  const { coords, id } = props;

  const [longitude, setLongitude] = useState(coords[0]);
  const [latitude, setLatitude] = useState(coords[1]);

  if (window.map) {
    window.map.on('draw.update', (e) => {
      const feature = e.features.find((item) => item.properties.id === id);

      if (feature) {
        setLongitude(feature.geometry.coordinates[0]);
        setLatitude(feature.geometry.coordinates[1]);
      }
    });
  }

  const changeLongtitude = (value) => {
    setLongitude(value);
  };

  const changeLatitude = (value) => {
    setLatitude(value);
  };

  const onFinish = () => {
    const newCoords = [longitude, latitude];
    const { features } = window.draw.getAll();
    const featureIndex = features.findIndex(
      (feature) => feature.properties.id === id
    );

    const featureToChange = {
      ...features[featureIndex],
      geometry: {
        ...features[featureIndex].geometry,
        coordinates: newCoords,
      },
    };

    window.draw.set({
      type: 'FeatureCollection',
      features: [
        ...features.slice(0, featureIndex),
        featureToChange,
        ...features.slice(featureIndex + 1),
      ],
    });
  };

  return (
    <Card>
      <div className={style.point_form}>
        <InputNumber
          style={{
            marginBottom: '20px',
          }}
          controls={false}
          addonBefore="Longitude"
          value={longitude}
          onChange={changeLongtitude}
          stringMode={false}
          type="number"
        />
        <InputNumber
          style={{
            marginBottom: '20px',
          }}
          controls={false}
          addonBefore="Latitude"
          value={latitude}
          onChange={changeLatitude}
          stringMode={false}
          type="number"
        />
        <Button type="primary" onClick={onFinish}>
          Change
        </Button>
      </div>
    </Card>
  );
}
