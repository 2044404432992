import {Button, Col, Divider, Form, Input, InputNumber, message, Row, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useStoreMap, useUnit} from 'effector-react';
import {$createEditProjectUtils} from "../../../models/dictionariesModel/index.js";
import {countriesSelect} from "../../../models/foreignInvestmentsModel/index.js";
import {useEffect} from "react";
import {
    $participantsBins, $participantsSelect,
    addPartisipantBinEv,
    deletePartisipantBinEv
} from "../../../models/projectCreateEditModel/index.js";
import {$userInfo} from '../../../models/authModel/index.js';

const ParticipantsForm = ({form, edit}) => {
    const roleOptions = useStoreMap($createEditProjectUtils, (utils) => utils.roleOptions)
    const organizationOptions = useUnit($participantsSelect)
    const curUsr = useUnit($userInfo)

    const addBin = useUnit(addPartisipantBinEv)
    const removeBin = useUnit(deletePartisipantBinEv)

    const orgs = useUnit($participantsBins)
    const participantsFields = Form.useWatch('participants', form)

    useEffect(() => {
        const formBins = participantsFields?.map(p => p?.gov_id) ?? []
        if (formBins.length > 0 && Object.keys(orgs).some(bin => !formBins.includes(bin))) {
            Object.keys(orgs).forEach(bin => {
                if (!formBins.includes(bin)) {
                    removeBin(bin)
                }
            })
        } else if (edit && Object.keys(orgs)?.length === 0
            && formBins.length > 0
            && formBins.every(bin => !!bin)
            && formBins.every(bin => !orgs[bin])
            && formBins.every(bin => bin.length === 12)
        ) {
            addBin(formBins)
        }
    }, [orgs, participantsFields, edit]);

    useEffect(() => {
        const formData = form.getFieldValue('participants')
        if (formData && formData?.some(p => p?.gov_id && orgs[p?.gov_id] && (!p?.organization_id || p.organization_id !== orgs[p.gov_id]))) {
            formData.forEach(p => {
                if (p.gov_id && orgs[p.gov_id] && (!p?.organization_id || p.organization_id !== orgs[p.gov_id])) {
                    p.organization_id = orgs[p.gov_id]
                }
            })
            form.setFieldsValue({participants: formData})
        }

    }, [orgs]);

    const initiatorRoleId = roleOptions.find(r => r?.label === 'Инициатор проекта')?.value


    useEffect(() => {
        if (!edit && initiatorRoleId) {
                if (curUsr.role === 'investor') {
                    form.setFieldsValue({participants: [{role_id: initiatorRoleId, gov_id: curUsr.gov_id}]})
                    addBin([curUsr.gov_id])
                } else {
                    form.setFieldsValue({participants: [{role_id: initiatorRoleId}]})
                }
        }
    }, [initiatorRoleId, edit, curUsr]);

    const onEnterPartBin = (value) => {
        if (value && value.length === 12 && !orgs[value]) {
            addBin([value])
        }
        // if (value && orgsMapByBin[value]) {
        //     const fields = [...form.getFieldValue('participants')]
        //     const replaceableIdx = fields.findIndex(f => f.gov_id === value)
        //     const org = orgsMapByBin[value]
        //     const selectValue = organizationOptions.find(opt => opt.value === org.id)?.value
        //     fields[replaceableIdx]['organization_id'] = selectValue ?? null
        //     form.setFieldsValue({participants: fields})
        // }
    }

    return <>
        <Form.List name={'participants'} rules={[{
            validator: (async (rule, values) => {
                const initiatorRoleTitle = roleOptions.find(r => r.value === values[0].role_id)
                if (initiatorRoleTitle.label !== 'Инициатор проекта') {
                    return Promise.reject('Первый участник должен иметь роль "Инициатор проекта"');
                }
                const orgsIds = Object.values(orgs).map(i => i)
                if (values.some(v => !orgsIds.includes(v.organization_id))) {
                    message.error('Одна или несколько организаций не найдены')
                    return Promise.reject('Одна или несколько организаций не найдены');
                }
                return Promise.resolve();
            })
        }]}>
            {(fields, {add, remove}, {errors}) => (
                <Col span={24}>
                    {fields.map((field, idx) => (
                        <Row style={{alignItems: 'center'}}>
                            {
                                idx === 0
                                    ? <Divider orientationMargin={0} orientation={'left'}>{`Инициатор проекта`}</Divider>
                                    : <Divider orientationMargin={0} orientation={'left'}>{`Участник ${idx+1}`}</Divider>
                            }
                            <div style={{width: '80%'}}>
                                <Row style={{gap: 16}}>
                                    <Col span={7}>
                                        <Form.Item name={[field.name, 'gov_id']} label={'БИН (12 символов)'}
                                                   rules={[{required: true, message: 'Поле обязательно для заполнения'},
                                                       {validator: async (rule, value) => {
                                                               if (value && value.length === 12) {
                                                                   return Promise.resolve();
                                                                   // if (orgsMapByBin[value]) {
                                                                   //     return Promise.resolve();
                                                                   // } else {
                                                                   //     return Promise.reject(new Error('Организация не найдена'));
                                                                   // }
                                                               } else if (value && value.length !== 12) {
                                                                   return Promise.reject(new Error('БИН должен состоять из 12 цифр'));
                                                               }
                                                           }}
                                                   ]}
                                        >
                                            <Input showCount
                                                   maxLength={12}
                                                   onBlur={({target}) => onEnterPartBin(target.value)}
                                                   disabled={idx === 0 && curUsr.role === 'investor'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name={[field.name, 'organization_id']}
                                                   label={'Наименование организации'}
                                        >
                                            <Select disabled={true} options={organizationOptions} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name={[field.name, 'part']} label={'Доля участия (в %)'}>
                                            <InputNumber min={0} max={100}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{gap: 16}}>
                                    <Col span={7}>
                                        <Form.Item name={[field.name, 'country']}
                                                   label={'Страна инвестора'}>
                                            <Select options={countriesSelect} showSearch={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name={[field.name, 'role_id']} label={'Роль'}
                                                   rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
                                        >
                                            <Select options={roleOptions} disabled={idx === 0}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            {idx > 0 && <Button danger onClick={() => remove(field.name)}>
                                Отменить
                            </Button>}
                        </Row>
                    ))}
                    <Button type={'primary'} onClick={() => add()} icon={<PlusOutlined/>}>
                        Добавить участника
                    </Button>
                </Col>
            )}
        </Form.List>
    </>
}

export default ParticipantsForm