import { sample } from 'effector';
import {
  prepareFilteredProjectsMap,
  prepareProjectsMapData,
} from '../../counters/projectsMap/index.js';
import { $globalStore, $projectsStore } from '../globalModel/index.js';
import {
  $activeFilters,
  resetActiveFiltersEv,
} from '../activeFiltersModel/index.js';
import {
  resetProjectsMapFilteredStoreEv,
  setProjectsMapStartDataEv,
} from './events.js';
import {
  $projectsMapFilteredStore,
  $projectsMapStartStore,
  $projectsMapStore,
} from './stores.js';

$projectsMapFilteredStore.reset(resetProjectsMapFilteredStoreEv);

sample({
  clock: setProjectsMapStartDataEv,
  fn: prepareProjectsMapData,
  target: [$projectsMapStore, $projectsMapStartStore],
});

sample({
  source: [$globalStore, $projectsMapStartStore, $activeFilters],
  clock: $projectsStore.updates,
  filter: ([global, startData, activeFilters], clock) => {
    return Object.values(startData).some((item) => {
      if (Array.isArray(item)) {
        return item.length > 0;
      }
      return item > 0;
    });
  },
  fn: ([global, startData, activeFilters], clock) => {
    if (clock.length === global.projects.length) {
      return startData;
    }
    return prepareFilteredProjectsMap(clock, global, activeFilters);
  },
  target: $projectsMapStore,
});

sample({
  clock: $projectsMapFilteredStore.updates,
  target: $projectsMapStore,
});

sample({
  source: $projectsMapStartStore,
  clock: resetProjectsMapFilteredStoreEv,
  fn: (source) => source,
  target: $projectsMapStore,
});

sample({
  source: $projectsMapStartStore,
  clock: resetActiveFiltersEv,
  fn: (source) => source,
  target: [$projectsMapStore, resetProjectsMapFilteredStoreEv],
});
