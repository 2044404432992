import { Dropdown } from 'antd';
import { useUnit } from 'effector-react';
import ru from '../../assets/64px-Flag_of_Russia.svg.png';
import kz from '../../assets/64px-Flag_of_Kazakhstan.svg.png';
import cn from "../../assets/64px-Flag_of_the_People's_Republic_of_China.svg.webp";
import en from '../../assets/64px-Flag_of_the_United_Kingdom_(3-5).svg.png';
import { $language, changeLanguageEv } from '../../models/authModel/index.js';

const langDict = {
  ru: 'Русский',
  kz: 'Қазақ',
  cn: '中文',
  en: 'English',
};

const imgDict = {
  ru,
  kz,
  cn,
  en,
};

export default function LanguageSelector({allLangs}) {
  const language = useUnit($language);
  const changeLang = useUnit(changeLanguageEv);
  const items = [
      {
          key: 'ru',
          label: (
              <div>
                  <img src={ru} alt="ru" style={{ width: 16, marginRight: 4 }} />
                  Русский
              </div>
          ),
      },
      {
          key: 'kz',
          label: (
              <div>
                  <img src={kz} alt="kz" style={{ width: 16, marginRight: 4 }} />
                  Қазақ
              </div>
          ),
      },
  ]
    if (allLangs) {
        items.push({
            key: 'cn',
            label: (
                <div>
                    <img src={cn} alt="cn" style={{ width: 16, marginRight: 4 }} />
                    中文
                </div>
            ),
        })
        items.push({
            key: 'en',
            label: (
                <div>
                    <img src={en} alt="en" style={{ width: 16, marginRight: 4 }} />
                    English
                </div>
            ),
        })
    }

  return (
    <Dropdown
      placement="bottom"
      menu={{items, onClick: ({ key }) => changeLang(key)}}
    >
      <div className="language_box">
        <img
          src={imgDict[language]}
          alt={language}
          style={{ width: 16, marginRight: 4 }}
        />
        {langDict[language]}
      </div>
    </Dropdown>
  );
}
