import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  TreeSelect,
} from 'antd';
import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { useStore, useUnit } from 'effector-react';
import {
  $activitiesTreeSelect,
  $regionsSelect,
} from '../../../models/globalModel/index.js';
import { krpSelect } from '../../../models/organizationsModel/index.js';
import {
  applyMgpFiltersEv,
  resetMgpFiltersEv,
} from '../../../models/mgpModel/index.js';

const { SHOW_PARENT } = TreeSelect;

export default function MGPFilters() {
  const [filterForm] = Form.useForm();
  const regions = useStore($regionsSelect);
  const okedTree = useUnit($activitiesTreeSelect);

  const apply = useUnit(applyMgpFiltersEv);
  const reset = useUnit(resetMgpFiltersEv);

  const onActivitySelect = (value, label, extra) => {
    const payloadValue = [];

    const getOkedIds = (node) => {
      if (!payloadValue.includes(node.node.props.value)) {
        payloadValue.push(node.node.props.value);
      }
      if (node.children.length > 0) {
        node.children.forEach((child) => getOkedIds(child));
      }
    };

    if (extra.allCheckedNodes.length > 0) {
      extra.allCheckedNodes.forEach((checkedNode) => {
        getOkedIds(checkedNode);
      });
    }
    filterForm.setFieldValue('activity_ids', payloadValue);
  };

  const onFinish = (values) => {
    apply(values);
  };

  const onCancel = () => {
    filterForm.resetFields();
    reset();
  };

  return (
    <Form form={filterForm} onFinish={onFinish} layout="vertical">
      <Row justify="space-between" style={{ alignItems: 'center' }}>
        <Col span={4}>
          <Form.Item name="activity_ids" label="ОКЭД">
            <TreeSelect
              treeData={okedTree}
              showSearch
              treeNodeFilterProp="title"
              allowClear
              style={{
                width: '200px',
                maxHeight: 32,
                overflow: 'auto',
                marginRight: '20px',
              }}
              dropdownStyle={{ width: 500 }}
              listHeight={600}
              onChange={onActivitySelect}
              treeCheckable
              showCheckedStrategy={SHOW_PARENT}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="region_ids" label="Регион">
            <Select
              options={regions}
              dropdownStyle={{ width: 300 }}
              allowClear
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="ksp_ids" label="Размерность">
            <Select
              options={krpSelect}
              dropdownStyle={{ width: 300 }}
              allowClear
              mode={'multiple'}
            />
          </Form.Item>
        </Col>
        <Divider type="vertical" style={{ height: 64 }} />
        <Col span={6}>
          <Form.Item name="gov_id" label="БИН">
            <Input allowClear/>
          </Form.Item>
        </Col>
        <Button.Group>
          <Tooltip title="Применить фильтры">
            <Button
              onClick={() => filterForm.submit()}
              icon={<FilterOutlined />}
              type="primary"
              style={{ boxShadow: 'none' }}
            />
          </Tooltip>
          <Tooltip title="Сбросить фильтры">
            <Button onClick={onCancel} icon={<ClearOutlined />} danger />
          </Tooltip>
        </Button.Group>
      </Row>
    </Form>
  );
}
