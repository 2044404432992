import React from 'react';
import { Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph.js';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react/effector-react.mjs';
import { $govCantVoteModalOpen } from '../../models/bizPollsModel/index.js';

function GovCantVoteModal() {
  const { t } = useTranslation();
  const open = useUnit($govCantVoteModalOpen);

  return (
    <Modal
      width="80vw"
      closable={false}
      open={open}
      footer={null}
      className="gov-cant-vote"
    >
      <Paragraph>{t('biz-polls.gov-cant-vote')}</Paragraph>
    </Modal>
  );
}

export default GovCantVoteModal;
