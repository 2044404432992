import {Button, Popover, Select, Tooltip} from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined, InfoCircleOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from '../../Overview.module.css';
import { setBizModalEv } from '../../../../models/overviewModel/index.js';
import {
  actualityDictionary,
  infoSourceDictionary
} from '../../../../dictionaries/info_source_dictionary.js';

export default function OverviewBizCard(props) {
  const {
    title,
    cards,
    cardClick,
    field,
    activeSubCategory,
    granularity,
    changeFilters,
  } = props;
  const setModal = useUnit(setBizModalEv);

  return (
    <div
      className={style.overview_card}
      onClick={cardClick}
      data-category={field}
      data-subcategory={cards[0].subCategory}
      data-type="card"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          className={[
            style.overview_card_title,
            field === 'projectsRealisation' ? style.center : '',
          ].join(' ')}
          style={{ display: 'inline-block' }}
        >
          {title}
        </div>
        <Select
          style={{ width: 150 }}
          options={[
            { label: 'Ежеквартально', value: false },
            { label: 'Годовой', value: true },
          ]}
          value={granularity}
          onChange={(v) => changeFilters({ field: 'business_climate_yearly', value: v })}
        />
      </div>
      <div className={style.cards_wrapper}>
        {cards.map((item) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width:
                  cards.length === 1 ? '100%' : `${100 / cards.length - 1.5}%`,
              }}
            >
              {item?.header ? (
                <h1
                  className={style.overview_card_title}
                  style={{ textAlign: 'center' }}
                >
                  {item.header}
                </h1>
              ) : null}
              <div
                  className={[
                    style.overview_subcard,
                    activeSubCategory.length > 0 &&
                    activeSubCategory !== item.subCategory
                        ? style.not_active
                        : '',
                  ].join(' ')}
                  style={{
                    background:
                        item.type === 'neutral'
                            ? '#eedc501a'
                            : item.type === 'positive'
                                ? '#05CD991A'
                                : '#EE5D501A',
                    border:
                        activeSubCategory.length > 0 &&
                        activeSubCategory === item.subCategory
                            ? item.type === 'neutral'
                                ? '2px solid #eedc50'
                                : item.type === 'positive'
                                    ? '2px solid #05CD99'
                                    : '2px solid #EE5D50'
                            : '',
                    padding:
                        activeSubCategory.length > 0 &&
                        activeSubCategory === item.subCategory
                            ? '8px'
                            : '12px',
                  }}
                  data-category={field}
                  data-subcategory={item.subCategory}
                  data-type="subcard"
              >
                <div
                    className={
                      item.breakWord
                          ? style.title_break
                          : style.overview_subcard_title
                    }
                >
                  {item.title}
                </div>
                <div>
                  <Popover title={<div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{`Источник данных: ${infoSourceDictionary[item.subCategory]}`}</span>
                    <span>{`Актуализация: ${actualityDictionary[item.subCategory]}`}</span>
                  </div>
                  }>
                    <InfoCircleOutlined style={{height: 16}}/>
                  </Popover>
                </div>
                <div className={style.overview_value}>{item.value} <span style={{fontSize: 24}}>из 4</span></div>
                <div
                    className={style.overview_value}
                    style={{fontSize: '28px', marginTop: '0px'}}
                >
                  {item.measure}
                </div>
                <div className={style.overview_subcard_footer}>
                  <div className={style.overview_subcard_date}>
                    {granularity ? item.date.split(' ').at(-1) : item.date}
                  </div>
                  <div style={{display: 'flex', gap: 4}}>
                    <Tooltip title="Просмотр по районам" placement="bottom">
                      <Button
                          className={style.modal_button}
                          style={{top: -48}}
                          icon={<PieChartOutlined/>}
                          onClick={() =>
                              setModal({
                                open: true,
                                type: item.subCategory,
                                granularity: granularity ? 'year' : 'quarter'
                              })
                          }
                      />
                    </Tooltip>
                  </div>
                  <div
                      className={style.overview_subcard_percent}
                      style={{
                        color:
                            item.type === 'neutral'
                                ? '#eedc50'
                                : item.type === 'positive'
                                    ? '#05CD99'
                                    : '#EE5D50',
                      }}
                  >
                    {item.percent ?? ''}%
                    {item.type !== 'neutral' ? (
                        item.type === 'positive' ? (
                            <ArrowUpOutlined/>
                        ) : (
                            <ArrowDownOutlined/>
                        )
                    ) : (
                        ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
