import { createStore } from 'effector';

const colors = [
  'rgba(0, 102, 255, 0.5)',
  'rgba(240, 216, 30, 0.5)',
  'rgba(255, 0, 0, 0.7)',
];

const buckets = [25, 50];

export const $activeGradient = createStore('projects_count');
$activeGradient.watch((state) => console.log('$activeGradient', state));
export const $gradientBuckets = createStore({ buckets, colors });
$gradientBuckets.watch((state) => console.log('$gradientBuckets', state));
