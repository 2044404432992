import {Col, Row} from "antd";
import ContainerInner from "../../components/ContentContainer/ContainerInner.jsx";
import DynamicsChart from "../../components/DynamicsChart/DynamicsChart.jsx";
import YearFilter from "../../components/ViewOrganization/YearFilter.jsx";
import SubjectInfo from "../../components/ViewOrganization/SubjectInfo.jsx";
import ProjectInfo from "../../components/ViewOrganization/ProjectInfo.jsx";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useStore} from "effector-react";
import MapWork from "../MapWork/MapWork.jsx";
import {$organizationStore, getOrganizationFx} from "../../models/viewSubjectModel/index.js";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween)

const ViewSubject = () => {

    const {
        subjectInfoProps,
        projectsData,
        taxesChartData,
        orgName,
        taxesDates
    } = useStore($organizationStore)
    /*
    FIXME - 1) Create left <Col /> with subject info (V)
    FIXME - 2) Create middle <Col /> with 2 <Row /> inside - Map and TaxDynamicsChart (M)
    FIXME - 3) Create right <Col /> with YearFilter, Stats, ProjectCard with link to 'view-project/:id' (V)
     */

    let { id } = useParams()

    const dynamicsChartData = [
        {year: 2016, amount: 120000000},
        {year: 2017, amount: 160000000},
        {year: 2018, amount: 240000000},
        {year: 2019, amount: 260000000},
        {year: 2020, amount: 180000000},
        {year: 2021, amount: 200000000},
        {year: 2022, amount: 120000000},
        {year: 2023, amount: 6000000},
    ]

    useEffect(() => {
        getOrganizationFx(id)
    }, [])

    const [dates, setDates] = useState([
        dayjs(),
        dayjs()
    ])

    useEffect(() => {
        setDates(taxesDates.map(date => dayjs(date)))
    }, [taxesDates]);

    const [taxesData, setTaxesData] = useState(taxesChartData)

    useEffect(() => {
        const filteredDates = taxesChartData.filter(item => {
            return dayjs(item.year).isBetween(dates[0], dates[1])
        })
        const tax_sum = new Intl.NumberFormat('ru-RU', {notation: 'compact'}).format(filteredDates.reduce((acc, item) => acc + item.amount, 0))
        setTaxSum(tax_sum)
        setTaxesData(filteredDates)
    }, [dates])

    const changeDates = (days) => {
        setDates(days)
    }

    const [taxSum, setTaxSum] = useState('')

    console.log('taxesData', taxesData)

    return <ContainerInner title={orgName}>
        <Row justify={'space-between'}>
            <Col span={5}>
                {/* Subject Info */}
                <SubjectInfo
                  {...subjectInfoProps}
                />

            </Col>
            <Col span={11}>
                <Row style={{height: '40vh', overflow: "hidden",boxShadow: '5px 5px 10px rgba(0, 0, 0, .4)', borderRadius:'8px'}}><MapWork isInnerPage={true} /></Row>
                <Row style={{ marginTop: '2.4rem'}}>
                    <DynamicsChart title={'Динамика налоговых выплат'}
                                   description={'Сумма налогов за период 2016-2023 г.г. в динамике'}
                                   data={taxesData}
                                   xAlias={'Год'}
                                   yAlias={'Сумма'}
                    />
                </Row>
            </Col>
            <Col span={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {/* Year Filter */}
                <YearFilter
                  dates={dates}
                  changeDates={changeDates}
                  taxSum={taxSum}
                />
                {/* Stats */}
                {/* Card with project link */}
                <ProjectInfo projects={projectsData}/>

            </Col>
        </Row>
    </ContainerInner>
}

export default ViewSubject
