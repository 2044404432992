import locale from 'antd/locale/ru_RU'
import {Button, Col, Collapse, ConfigProvider, Divider, Form, message} from 'antd';
import {useUnit} from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import FinanceForm from './components/FinanceForm.jsx';
import ParticipantsForm from './components/ParticipantsForm.jsx';
import ContactsForm from './components/ContactsForm.jsx';
import TaxesForm from './components/TaxesForm.jsx';
import CommonInfoForm from './components/CommonInfoForm.jsx';
import ResourcesForm from './components/ResourcesRequired/ResourcesForm.jsx';
import {
  $createProjectStatus, createProjectFx,
  resetCreateStatusEv,
  submitCreateFormEv,
  submitUpdateFormEv, updateProjectFx,
} from '../../models/projectCreateEditModel/index.js';
import {$userInfo} from '../../models/authModel/index.js';

function ProjectCreateEditForm({ editMode, project }) {
  const createStatus = useUnit($createProjectStatus);
  const resetCreateStatus = useUnit(resetCreateStatusEv);
  const navigate = useNavigate();
  const curUser = useUnit($userInfo);

  const createLoading = useUnit(createProjectFx.pending)
  const updateLoading = useUnit(updateProjectFx.pending)

  useEffect(() => {
    if (createStatus) {
      resetCreateStatus();
      navigate('/edit-project');
    }
  }, [createStatus]);

  const [projectForm] = Form.useForm();

  useEffect(() => {
    if (editMode && project?.id) {
      projectForm.setFieldsValue(project);
    }
  }, [editMode, project]);

  const submitCreate = useUnit(submitCreateFormEv);
  const submitEdit = useUnit(submitUpdateFormEv);

  const totalCostFields = Form.useWatch(['budgets'], projectForm);
  const totalCostValue =
    totalCostFields && Object?.values(totalCostFields)?.length > 0
      ? Object?.values(totalCostFields)?.reduce(
          (acc = 0, curr) => +acc + (+curr ?? 0)
        )
      : 0;

  const calcTaxes = useCallback((value, namespace) => {
    const result = Object?.entries(value).filter(
      ([key]) => key !== 'workplaces'
    );
    if (result.length > 0) {
      return result
        .map(([field, value]) =>
          (field === 'tax_fot' && value[namespace]) ||
          (field === 'tax' && value[namespace])
            ? +value[namespace]
            : 0
        )
        .reduce((acc = 0, curr) => {
          return acc + (curr ?? 0);
        });
    }
    return 0;
  }, []);

  const totalTaxFields = Form.useWatch('effects', projectForm);
  const totalTaxBeforeValue =
    totalTaxFields && Object?.keys(totalTaxFields)?.length > 0
      ? calcTaxes(totalTaxFields, 'investment_period')
      : 0;

  const totalTaxAfterValue =
    totalTaxFields && Object?.values(totalTaxFields)?.length > 0
      ? calcTaxes(totalTaxFields, 'post_investment_period')
      : 0;

  const onFinish = (values) => {
    const location = window.draw.getAll().features[0]?.geometry;
    if (location) {
      values.location = {
        type: 'Point',
        coordinates: location.coordinates.sort((a, b) => a - b),
      };
      if (
        values?.effects &&
        totalTaxBeforeValue > 0 &&
        totalTaxAfterValue > 0
      ) {
        values.effects.total = {
          investment_period: totalTaxBeforeValue,
          post_investment_period: totalTaxAfterValue,
        };
      }
      editMode ? submitEdit({ id: project.id, values }) : submitCreate(values);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      message.error('Укажите координаты проекта на карте', 3).then(() => true);
    }
  };

  return (
      <ConfigProvider locale={locale}>
        <Form
            name="projectForm"
            form={projectForm}
            layout="vertical"
            validateTrigger="onBlur"
            scrollToFirstError
            onFinishFailed={({_, errorFields}) => {
              if (errorFields && errorFields.length > 0) {
                message
                    .error('Необходимо заполнить все обязательные поля', 3)
                    .then(() => true);
              }
            }}
            onFinish={onFinish}
        >
          <Collapse
              destroyInactivePanel={false}
              accordion={false}
              defaultActiveKey={
                editMode
                    ? [
                      'common',
                      'finance',
                      'participants',
                      'resources',
                      'outcome',
                      'taxes',
                      'contacts',
                    ]
                    : ['common', 'participants']
              }
          >
            <Collapse.Panel key="common" header="Общие сведения">
              <CommonInfoForm form={projectForm}/>
            </Collapse.Panel>
            <Collapse.Panel
                key="finance"
                header="Стоимость проекта и источники финансирования"
            >
              <Col span={24}>
                <Divider orientationMargin={0} orientation="left">
                  {`Общая стоимоть ${Intl.NumberFormat('ru-RU').format(
                      totalCostValue
                  )} млн тг.`}{' '}
                </Divider>
                <FinanceForm/>
              </Col>
            </Collapse.Panel>
            <Collapse.Panel key="participants" header="Участники проекта">
              <ParticipantsForm form={projectForm} edit={editMode}/>
            </Collapse.Panel>
            <Collapse.Panel
                key="resources"
                header="Потребность в ресурсах для реализации проекта"
            >
              <ResourcesForm
                  form={projectForm}
                  editMode={editMode}
                  project={project}
              />
            </Collapse.Panel>
            <Collapse.Panel key="outcome" header="Эффект от реализации проекта">
              <TaxesForm
                  totalTaxBeforeValue={totalTaxBeforeValue}
                  totalTaxAfterValue={totalTaxAfterValue}
              />
            </Collapse.Panel>
            <Collapse.Panel key="contacts" header="Контактные данные">
              <ContactsForm/>
            </Collapse.Panel>
          </Collapse>
          {curUser.role !== 'guest' && <Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                form="projectForm"
                style={{marginTop: 24}}
                loading={createLoading || updateLoading}
            >
              Подтвердить
            </Button>
          </Form.Item>}
        </Form>
      </ConfigProvider>
  );
}

export default ProjectCreateEditForm;
