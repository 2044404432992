//FIXME Счетчик который подсчитывает сумму инвестиций по регионам
// принимает в себя итерируемый item и объект результат summary
export const regionSumWidgetDataCounter = (item, summary) => {
	return {
		//FIXME деструктурируем имеющийся результат
		...summary,
		//FIXME добавляем ключ в этом случае region_id ИЛИ перезаписываем значние если он уже есть
		[item.region_id]:
			//FIXME Складываем sum_plan (или fact) в уже имеющееся значение ИЛИ добавляем его
			(summary[item.region_id] || 0) + item.budget.sum_plan
	}
}

export const regionProjectsWidgetCounter = (item, summary) => {
	return {
		...summary,
		[item.region_id]:
			(summary[item.region_id] || 0) + 1
	}
}

export const districtSumWidgetDataCounter = (item, summary) => {
	return {
		...summary,
		[item.district_id]:
			(summary[item.district_id] || 0) + item.budget.sum_plan
	}
}

export const districtProjectsCounter = (item, summary) => {
	return {
		...summary,
		[item.district_id]:
			(summary[item.district_id] || 0) + 1
	}
}
