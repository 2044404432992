import { useEvent, useStore } from 'effector-react';
import { useEffect } from 'react';
import { center } from '@turf/turf';
import { useLocation } from 'react-router-dom';
import {
  $regionsLayerPolygons,
  $selectedRegion,
  resetSelectedRegionEv,
  selectRegionEv,
} from '../../models/regionsLayerModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { $mapLoaded } from '../../models/globalModel/index.js';
import { $selectedDistrict } from '../../models/districtsModel/index.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import {
  $activeGradient,
  $gradientBuckets,
} from '../../models/gradientModel/index.js';

const mag1 = ['<', ['get', 'projects_count'], 25];
const mag2 = [
  'all',
  ['>=', ['get', 'projects_count'], 25],
  ['<', ['get', 'projects_count'], 50],
];
// const mag3 = ['all', ['>=', ['get', 'projects_count'], 25], ['<', ['get', 'projects_count'], 50]];

function RegionsLayer() {
  const regionsLayerPolygons = useStore($regionsLayerPolygons);
  const selectedRegion = useStore($selectedRegion);
  const mapLoaded = useStore($mapLoaded);
  const gradientBuckets = useStore($gradientBuckets);
  const gradientType = useStore($activeGradient);
  const activeCategory = useStore($activeFilters).category;
  const activeSubCategory = useStore($activeFilters).subCategory;

  const { buckets, colors } = gradientBuckets;

  const changeActiveFilter = useEvent(changeActiveFilterEv);

  const selectedDistrict = useStore($selectedDistrict);
  const location = useLocation();

  // FIXME Function to Init OR Update layer
  const createLayer = (update) => {
    // console.log('regions createLayer update', update, regionsLayerPolygons);
    const regionsData = {
      type: 'FeatureCollection',
      features: regionsLayerPolygons,
    };
    const regionsCenters = {
      type: 'FeatureCollection',
      features: regionsLayerPolygons.map((item) => {
        return {
          type: 'Feature',
          properties: {
            count: location.pathname.includes('organizations')
              ? item.properties.orgsCount
              : item.properties.otp_count,
          },
          geometry: center(item).geometry,
        };
      }),
    };
    let fillColorProp = [];
    let fillOpacityProp = [];
    if (location.pathname.includes('dashboard')) {
      switch (activeSubCategory) {
        case 'people':
        case 'taxes':
        case 'medium_companies': {
          fillColorProp = [
            'case',
            ['==', ['get', 'change'], false],
            '#ee5d50',
            '#05cd99',
          ];
          break;
        }
        case 'projectCount': {
          fillColorProp = '#05cd99';
          break;
        }
        case 'problemProjects': {
          fillColorProp = '#ee5d50';
          break;
        }
        case 'otp_count':
        case 'otp_avg_workload': {
          fillColorProp = '#05cd99';
          break;
        }
        default: {
          fillColorProp = [
            'case',
            ['==', ['get', 'change'], false],
            '#ee5d50',
            '#05cd99',
          ];
          break;
        }
      }
      fillOpacityProp = [
        'interpolate',
        ['linear'],
        ['zoom'],
        // 5, [ 'case', ['boolean', ['feature-state', 'hide'], false], 0.1, 0.8],
        // 6.5, [ 'case', ['boolean', ['feature-state', 'hide'], false], 0.1, 0],

        4.2,
        0.8,
        5,
        0,

        // 5, 0.8,
        // 6.5, 0,
      ];
    } else {
      // fillColorProp = [
      //   'case',
      //   ['<', ['get', gradientType], buckets[0]],
      //   colors[0],
      //   [
      //     'all',
      //     ['>=', ['get', gradientType], buckets[0]],
      //     ['<', ['get', gradientType], buckets[1]],
      //   ],
      //   colors[1],
      //   colors[2],
      // ];

      fillColorProp = '#05cd99';

      fillOpacityProp = [
        'interpolate',
        ['linear'],
        ['zoom'],
        // 5, [ 'case', ['boolean', ['feature-state', 'hide'], false], 0.1, 0.8],
        // 6.5, [ 'case', ['boolean', ['feature-state', 'hide'], false], 0.1, 0],

        4.2,
        0.8,
        5,
        0,

        // 5, 0.8,
        // 6.5, 0,
      ];
    }

    if (update) {
      window.map.getSource('regions').setData(regionsData);
      window.map.getSource('regions_centers').setData(regionsCenters);
      window.map.setPaintProperty('regions_layer', 'fill-color', fillColorProp);
      window.map.setPaintProperty(
        'regions_layer',
        'fill-opacity',
        fillOpacityProp
      );
    } else {
      if (!window.map.getSource('regions')) {
        map.addSource('regions', {
          type: 'geojson',
          data: regionsData,
        });
        map.addSource('regions_centers', {
          type: 'geojson',
          data: regionsCenters,
        });
      }

      // FIXME for painting used feautre-state
      // hide field used to lower opacity of other regions when 1 is selected
      // selected field used to highlight selected region

      // FIXME for manipulations via zoom used interpolate expressions
      // ref - https://docs.mapbox.com/style-spec/reference/expressions/

      if (!window.map.getLayer('regions_layer')) {
        // FIXME Main region polygons layer
        map.addLayer({
          id: 'regions_layer',
          type: 'fill',
          source: 'regions',
          paint: {
            'fill-color': fillColorProp,

            // 'fill-color': [
            // 	'interpolate', ['linear'], ['zoom'],
            // 	3.4, '#353535',
            // 	5, [ 'case', ['boolean', ['feature-state', 'hide'], false], '#353535', '#434343'],
            // ],

            // FIXME hide/show polygon depending on zoom
            'fill-opacity': fillOpacityProp,
          },
        });

        // FIXME outline layer for polygons
        map.addLayer({
          id: 'regions_outline',
          type: 'line',
          source: 'regions',
          paint: {
            // 'line-color': [
            // 	'case',
            // 	['boolean', ['feature-state', 'selected'], false],
            // 	'#ffad00',
            // 	'black',
            // ],
            'line-color': 'black',
            // 'line-width': [
            // 	'case',
            // 	['boolean', ['feature-state', 'selected'], false],
            // 	3,
            // 	1,
            // ],
            'line-width': 1,
            // FIXME hide/show polygon outline depending on zoom
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],

              3.4,
              0.5,
              5,
              0,

              // 5, 0.5,
              // 6.5, 0,
            ],
          },
        });

        if (!window.map.getLayer('regions_count_circles')) {
          map.addLayer({
            id: 'regions_count_circles',
            type: 'circle',
            source: 'regions_centers',
            filter: ['has', 'count'],
            paint: {
              'circle-color': '#fff',
              'circle-radius': 15,
              'circle-stroke-width': 5,
              'circle-stroke-color': '#553DB4',
              'circle-stroke-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                4.2,
                1,
                5,
                0,
              ],

              'circle-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                4.2,
                1,
                5,
                0,
              ],
            },
          });

          map.addLayer({
            id: 'regions_count_count',
            type: 'symbol',
            source: 'regions_centers',
            layout: {
              'text-field': ['get', 'count'],
            },
            paint: {
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                4.2,
                1,
                5,
                0,
              ],
            },
          });

          if (!location.pathname.includes('organizations')) {
            changeLayerVisibility('regions_count_circles', 'none');
            changeLayerVisibility('regions_count_count', 'none');
          }
        }
      }

      // FIXME used to save selected	feature
      map.on('click', 'regions_layer', (e) => {
        // selectRegion(e.features[0].id)
        if (window.map.getZoom() < 5) {
          changeActiveFilter({
            field: 'region_id',
            value: e.features[0].id,
          });
        }
      });
      map.on('mouseenter', 'regions_layer', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
    }
  };

  const selectRegionOnMap = (feature, selectedRegion) => {
    // FIXME if we click on region zoom change color and low opacity on others

    if (feature) {
      map.flyTo({
        center: center(feature).geometry.coordinates,
        zoom: 5,
        speed: 1,
      });
    } else {
      const city_center_coords = {
        23: [69.586942, 42.315521],
        24: [76.936843, 43.246356],
        25: [71.430429, 51.128201],
      };
      map.flyTo({
        center: city_center_coords[selectedRegion],
        zoom: 8.5,
        speed: 1,
      });
    }

    // FIXME set selected feature
    // map.setFeatureState(
    // 	{
    // 		source: 'regions',
    // 		id: selectedRegion
    // 	},
    // 	{
    // 		selected: true,
    // 		hide: false
    // 	})

    // FIXME set other features state to hide them
    // regionsLayerPolygons.map(item => {
    // 	if(item.id !== feature.id){
    // 		map.setFeatureState(
    // 			{
    // 				source: 'regions',
    // 				id: item.id
    // 			},
    // 			{
    // 				selected: false,
    // 				hide: true,
    // 			}
    // 		)
    // 	}
    // })
  };

  const clearRegionOnMap = () => {
    map.flyTo({
      center: [68.71584352632227, 48.58775813682156],
      zoom: 3.1,
      speed: 1,
    });

    // regionsLayerPolygons.map(item => {
    // 	//FIXME Removes feature state
    // 	map.removeFeatureState({
    // 		source: 'regions',
    // 		id: item.id
    // 	})
    // })
  };

  useEffect(() => {
    if (mapLoaded) {
      setTimeout(() => {
        createLayer(false);
      }, 500);
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('regions_layer')) {
      if (selectedRegion > 0) {
        selectRegionOnMap(
          regionsLayerPolygons.find((item) => item.id === selectedRegion),
          selectedRegion
        );
      } else {
        clearRegionOnMap();
      }
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('regions')) {
      createLayer(true);
    }
  }, [regionsLayerPolygons, gradientBuckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('regions')) {
      createLayer(true);

      if (
        location.pathname.includes('dashboard') &&
        (activeSubCategory.includes('otp') ||
          activeSubCategory === 'count' ||
          activeSubCategory === 'avg_workload')
      ) {
        changeLayerVisibility('regions_count_circles', 'visible');
        changeLayerVisibility('regions_count_count', 'visible');
      } else {
        changeLayerVisibility('regions_count_circles', 'none');
        changeLayerVisibility('regions_count_count', 'none');
      }
    }
  }, [activeCategory, activeSubCategory]);

  useEffect(() => {
    if (window.map.getLayer('regions_layer')) {
      if (selectedDistrict > 0) {
        changeLayerVisibility('regions_layer', 'none');
        changeLayerVisibility('regions_outline', 'none');
      } else {
        changeLayerVisibility('regions_layer', 'visible');
        changeLayerVisibility('regions_outline', 'visible');
      }
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (mapLoaded && window.map.getSource('regions_centers')) {
      if (location.pathname.includes('organizations')) {
        changeLayerVisibility('regions_count_circles', 'visible');
        changeLayerVisibility('regions_count_count', 'visible');
      } else {
        changeLayerVisibility('regions_count_circles', 'none');
        changeLayerVisibility('regions_count_count', 'none');
      }
    }
  }, [location.pathname]);

  return null;
}

export default RegionsLayer;
