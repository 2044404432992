import {krp_dictionary} from "../../dictionaries/krp_dictionary.js";

export const prepareCuratorsData = (data) => {
	const curatorsTableData = data.curators.map(item  => {
		return {
			key: `${item.id}${item.name}`,
			id: item.id,
			name: item.name,
			role: item.role_name,
		}
	})

	const projectsTableData = data.projects.map(item => {
		return {
			key: `${item.id}${item.name}`,
			proj_id: item.id,
			subj_id: item.organization_id,
			name: item.name,
			subject: item.organization_name,
			investments: new Intl.NumberFormat('ru-RU', {notation: 'compact'}).format(item.budget.sum_plan)
		}
	})

	const projects_count = data.projects.length

	const total_count = data.projects.reduce((a, b) => a + b.budget.sum_plan, 0)

	return {
		curatorsTableData,
		projectsTableData,
		projects_count,
		total_count
	}
}
