import ContainerInner from "../../components/ContentContainer/ContainerInner.jsx";
import {Button, Popconfirm, Row, Table, Tooltip, Typography} from "antd";
import ProjectCreateEditModal from "./components/ProjectCreateEditModal.jsx";
import {useEvent, useStore, useStoreMap} from "effector-react";
import {
    $foreignProjectsList, downloadFormFileEv,
    ForeignProjectsGate,
    setForProjModalStateEv, verifyFormEv, activitiesDict
} from "../../models/foreignInvestmentsModel/index.js";
import {$userInfo} from "../../models/authModel/index.js";
import {CheckOutlined, DownloadOutlined, EditOutlined, FormOutlined} from "@ant-design/icons";


const ForeignInvestments = () => {
    const approve = useEvent(verifyFormEv)
    const download = useEvent(downloadFormFileEv)
    const setModal = useEvent(setForProjModalStateEv)
    const curUsrRole = useStoreMap($userInfo, state => state.role)

    const tableData = useStore($foreignProjectsList)

    const onCreate = () => {
        setModal({visible: true, mode: 'create'})
    }

    const onUpdate = (id) => {
        setModal({visible: true, mode: 'edit', id})
    }

    const onApprove = (id) => {
        approve(id)
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            width: '5%',
            align: 'center',
        },
        {
            title: 'Наименование компании',
            dataIndex: 'name',
            width: '10%',
            render: (data) => <Typography.Paragraph>{data}</Typography.Paragraph>
        },
        {
            title: 'Вид деятельности компании',
            dataIndex: 'activity_id',
            width: '20%',
            render: (data) => <Typography.Paragraph>{activitiesDict[data] ?? '-'}</Typography.Paragraph>
        },
        {
            title: 'Контакты компании',
            dataIndex: 'phone_number',
            width: '10%',
        },
        {
            title: 'Почта',
            dataIndex: 'email',
            width: '10%',
            render: (data) => <Typography.Paragraph>{data}</Typography.Paragraph>
        },
        {
            title: 'Страна',
            dataIndex: 'country',
            width: '10%',
            render: (data) => <Typography.Paragraph>{data}</Typography.Paragraph>
        },
        {
            title: 'Цель интереса в Казахстане',
            dataIndex: 'purpose_of_interest',
            width: '10%',
            render: (data) => <Typography.Paragraph>{data}</Typography.Paragraph>
        },
        {
            title: 'Доп. детали целей',
            dataIndex: 'additional_goal_details',
            width: '10%',
            render: (data) => <Typography.Paragraph>{data}</Typography.Paragraph>
        }
    ]

    if (curUsrRole === 'admin' || curUsrRole === 'editor') {
        columns.push(
            {
                title: 'Документ',
                align: 'center',
                render: record => record.file_path ? <Tooltip title={'Загрузить документ'}>
                    <Button className={'btn-primary'} icon={<DownloadOutlined/>}
                            onClick={() => download(record.id)}
                    />
                </Tooltip> : 'Без документа'
            },
            {
                align: 'center',
                width: '5%',
                render: (record) => (curUsrRole === 'admin' || curUsrRole === 'editor') && !record.verified
                    ? <Button.Group>
                        <Tooltip title={'Редактировать'}>
                            <Button className={'info-btn'} onClick={() => onUpdate(record.id)} icon={<EditOutlined/>}/>
                        </Tooltip>
                        <Popconfirm title={'Вы уверены, что хотите одобрить данную заявку?'}
                                    onConfirm={() => onApprove(record.id)}
                                    okText={'Да'}
                                    cancelText={'Нет'}
                                    okButtonProps={{className: 'btn-primary'}}
                                    placement={'left'}
                        >
                            <Tooltip title={'Одобрить'}>
                                <Button className={'btn-primary'} icon={<CheckOutlined/>}/>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                    : <Tooltip title={'Одобрено'}><CheckOutlined /></Tooltip>
            },
        )
    }

    return <ContainerInner title={"Иностранные инвестиции"}>
        <ForeignProjectsGate/>
        <ProjectCreateEditModal/>
        <Row>
            <Row justify={'end'} style={{marginBottom: 24, width: '100%'}}>
                <Button className={'btn-primary'} onClick={() => onCreate()} icon={<FormOutlined/>}>
                    Подать заявку
                </Button>
            </Row>
            <Table columns={columns} dataSource={tableData} style={{width: '100%'}}/>
        </Row>
    </ContainerInner>
}

export default ForeignInvestments