import {createStore} from "effector";

const initialBuildingsState = [
	{
		'type': 'Feature',
		id: 1,
		properties: {
			test: 1
		},
		'geometry': {
			'type': 'Polygon',
			'coordinates': [
				[
					[63.88185915132007, 50.641678332069944],
					[61.72853883882149, 49.2565126939501],
					[67.13381224148023, 48.78622985045277],
					[63.88185915132007, 50.641678332069944],
				]
			]
		}
	},
	{
		type: 'Feature',
		id: 2,
		properties: {
			test: 2
		},
		'geometry': {
			'type': 'Polygon',
			'coordinates': [
				[
					[71.48439817897963, 51.23562241954497],
					[69.77053099148003, 49.46091323301934],
					[72.31935911647932, 49.51916275858434],
					[71.48439817897963, 51.23562241954497]
				]
			]
		}
	}
]

//FIXME Stores FEATURES for buildings
export const $buildingsLayerPolygons = createStore(initialBuildingsState)

//FIXME Stores selected building
export const $selectedBuilding = createStore({})
$selectedBuilding.watch(state => console.log('$selectedBuilding', state))
