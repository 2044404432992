import {
	districtSumWidgetDataCounter,
	regionProjectsWidgetCounter,
	regionSumWidgetDataCounter
} from "./counters.js";
import {
	districts_dictionary,
	regions_dictionary,
	regions_districts_id_dictionary
} from "../../dictionaries/regions_districts_dictionary.js";
import {filterProjects, filterSelectOptions} from "../projects/index.js";
import {prepareTreeSelectData} from "../utils/activityTreeSelectPreparator.js";

//FIXME This function fully inits investments data
export const prepareInvestmentsData = (data) => {

	//FIXME Counters stuff start эту часть напишу на русском чтобы было максимально понятно

	//FIXME Смысл в следующем
	// Есть массив данных который нужно просчитать
	// В этом случае projects в его item-ах есть region_id и sum_plan, sum_fact
	// Нужно просчитать сумму sum_plan (или fact) для каждого региона

	//FIXME Объект который хранит каунтеры (счетчики)
	const counters = {
		//FIXME этот счетчик подсчитывает сумму инвестиций по регионам подробнее в комментах самой функции
		regionSumWidgetData: regionSumWidgetDataCounter,
		regionProjectsWidgetData: regionProjectsWidgetCounter,
		districtSumWidgetData: districtSumWidgetDataCounter,
	}

	//FIXME Объект который в итоге будет хранить результат
	// Изначально он имеет вид:
	// {
	// 	[Ключ из counters]: {} пустой объект
	// }
	const summary = Object.fromEntries(Object.keys(counters).map(prop => [prop, {}]))

	//FIXME Итерируем источник данных из которого нужно просчитать
	// в каждой итерации мы вызываем функицю счетчик и результат присваиваем summary
	// data.projects.forEach(item => {
	// 	summary.regionSumWidgetData = counters.regionSumWidgetData(item, summary.regionSumWidgetData)
	// })

	data.projects.forEach(item => {
		Object.keys(counters).map(prop => summary[prop] = counters[prop](item, summary[prop]))
	})

	//FIXME Получается так что
	// Мы пробегаемся по всему массиву projects и берем объект (summary)
	// в каждом item-е смотрим его region_id и sum_plan (fact)
	// Если в summary такой region_id есть то суммируем его
	// Если нет то добавляем

	//FIXME Counters stuff end
	//FIXME Total amount of projects (Стр. Инвестиции Кол-во проектов)
	const total_count = Object.values(summary.regionSumWidgetData).reduce((a, b) => a + b)

	//FIXME Prepare data for REGION widget
	const region_widget_data = Object.entries(summary.regionSumWidgetData).map(([key, value]) => {
		return {
			area: regions_dictionary[key] || `unknown ${key}`,
			amount: value,
			area_id: parseInt(key),
		}
	}).sort((a, b) => b.amount - a.amount)

	const region_projects_data = Object.entries(summary.regionProjectsWidgetData).map(([key, value]) => {
		return {
			area: regions_dictionary[key] || `unknown ${key}`,
			amount: value,
			area_id: parseInt(key),
		}
	}).sort((a, b) => b.amount - a.amount)

	const dict = Object.entries(regions_districts_id_dictionary).map(([region_id, districts]) => {
		return {
			[region_id]: Object.keys(districts)
		}
	})

	const district_widget_data = Object.entries(summary.districtSumWidgetData).filter(([key, value]) => key !== 'null').map(([key, value]) => {
		const region = Object.entries(dict).find((item) => Object.values(item[1])[0].includes(key))

		const region_id = region ? +Object.keys(region[1])[0] : null

		return {
			area: districts_dictionary[key] || `unknown ${key}`,
			amount: value,
			area_id: parseInt(key),
			region_id,
		}
	}).sort((a, b) => b.amount - a.amount)

	//FIXME Total amount of members (Стр. Инвестиции Кол-во участников) (NOT 100% RIGHT)
	const members_count = (new Set(data.projects.map(item => item.organization_name))).size

	//FIXME These two consts store prepared data for selects
	const activity_select = prepareTreeSelectData(data.activities)
	// const activity_select = data.activities.map(item => {
	// 	return {
	// 		label: item.name_ru,
	// 		value: item.id
	// 	}
	// })
	const organizations_select = data.organizations.map(item => {
		return {
			label: item.name,
			value: item.id
		}
	})
	// debugger
	return {
		projects_count: data.projects.length,
		region_widget_data,
		total_count,
		members_count,
		activity_select,
		organizations_select,
		region_projects_data,
		district_widget_data
	}
}

export const prepareFilteredInvestmentsData = (projects, rawData, activeFilters) => {
	const {activities, organizations} = filterSelectOptions(activeFilters, projects, rawData)

	const filteredData = {
		...rawData,
		projects,
		activities,
		organizations
	}

	return prepareInvestmentsData(filteredData)
}
