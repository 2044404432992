import {Collapse, Popover, Table, Tooltip} from 'antd';
import {useEffect, useState} from 'react';
import style from './ViewProjectComponents.module.css';
import {$projectStore, $projParticipantsInfo} from "../../models/viewProjectModel/index.js";
import {useStore, useStoreMap} from "effector-react";
import {$dictionariesMaps} from "../../models/dictionariesModel/index.js";

const getStatusText = (status, overdue, only_title) => {
  if (only_title) {
    return (status === 'В работе' || status === 'Приостановлен') ? status : overdue ? `${status} с просрочкой` : status
  } else {
    return overdue ? `${status} с просрочкой` : status
  }
}

const rowClass = {
  'Завершен с просрочкой': 'project_view_stage_table_reject_light',
  'Подан с просрочкой': 'project_view_stage_table_reject',
  'В работе с просрочкой': 'project_view_stage_table_reject',
  'Не указан с просрочкой': 'project_view_stage_table_reject',
  'Отказано с просрочкой': 'project_view_stage_table_reject_dark',
  'Отказано': 'project_view_stage_table_reject_dark'
}

const status_colors = {
  'Завершен': '#05CD99',
  'В работе': '#FBD655',
  'Подан': '#6AD2FF',
  'Отказано': '#EE5D50',
  'Приостановлен': '#D9DEE6',
  'Не указан': '#f0f0f0',
  'Завершен с просрочкой': '#EE5D5066',
  'Не указан с просрочкой': '#EE5D50BF',
  'В работе с просрочкой': '#EE5D50BF',
  'Подан с просрочкой': '#EE5D50BF',
  'Отказано с просрочкой': '#EE5D50E6'
};

const toFixedData = (stagesData) => {
  const step = 100 / stagesData.length;

  const statusCount = {};
  stagesData.forEach((stage) => {
    if (statusCount[getStatusText(stage.status, stage.overdue)]) {
      statusCount[getStatusText(stage.status, stage.overdue)] += 1;
    } else {
      statusCount[getStatusText(stage.status, stage.overdue)] = 1;
    }
  });

  let backgroundProp = `linear-gradient(90deg, `;

  if (Object.keys(statusCount).length === 1) {
    const color = status_colors[Object.keys(statusCount)[0]];
    backgroundProp += `${color} 0%, ${color} 100%)`;
  } else {
    Object.entries(statusCount).forEach(([stage, count], index) => {
      const color = status_colors[stage];
      let percent = count * step;
      let prevPercent = 0;
      if (index > 0) {
        const prevCount = Object.values(statusCount)
            .slice(0, index)
            .reduce((a, b) => a + b);
        prevPercent = prevCount * step;
        percent = prevPercent + percent;
        if (index === Object.keys(statusCount).length - 1) {
          backgroundProp += `${color} ${prevPercent}%, ${color} ${percent}%`;
        } else {
          backgroundProp += `${color} ${prevPercent}%, ${color} ${percent}%, `;
        }
      } else {
        backgroundProp += `${color} ${percent}%, `;
      }
      // backgroundProp += `${color} ${percent}%, `;
      // linear-gradient(90deg, #05CD99 20%, #FBD655 20%, #FBD655 40%, #6AD2FF 40%, #6AD2FF 60%, #EE5D50 60%, #EE5D50 80%, #D9DEE6 80%, #d9dee6 100%)
    });
    backgroundProp += ')';
  }

  return [
    {
      start_date_plan: stagesData[0].start_date_plan,
      start_date_fact: stagesData[0].start_date_fact,
      finish_date_plan: stagesData[0].finish_date_plan,
      finish_date_fact: stagesData[0].finish_date_fact,
      status: backgroundProp,
    },
  ];
};

const getColumns = (orgsMap) => {
  return [
    {
      title: 'Наименование этапа',
      dataIndex: 'stage_name',
      key: 'stage_name',
    },
    {
      title: 'Ответственный',
      dataIndex: 'responsible',
      key: 'responsible',
      render: (resp) => orgsMap[resp]?.name ?? ''
    },
    {
      title: 'Дата начала',
      children: [
        {
          title: 'План',
          dataIndex: 'start_date_plan',
          key: 'start_date_plan',
        },
        {
          title: 'Факт',
          dataIndex: 'start_date_fact',
          key: 'start_date_fact',
        },
      ],
    },
    {
      title: 'Дата завершения',
      children: [
        {
          title: 'План',
          dataIndex: 'finish_date_plan',
          key: 'finish_date_plan',
        },
        {
          title: 'Факт',
          dataIndex: 'finish_date_fact',
          key: 'finish_date_fact',
        },
      ],
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        return {
          props: {
            style: { background: status_colors[getStatusText(text, record.overdue)] },
          },
          children: <div>{getStatusText(text, record.overdue, true)}</div>,
        };
      },
    },
    {
      title: 'Примечание',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Стоимость задачи, в млн тг.',
      children: [
        {
          title: 'План',
          dataIndex: 'sum_plan',
          key: 'sum_plan',
        },
        {
          title: 'Факт',
          dataIndex: 'sum_fact',
          key: 'sum_fact',
        },
      ],
    },
    {
      title: 'Источник финансирования в млн тг.',
      dataIndex: 'budget_source',
      key: 'budget_source',
    },
  ];
}

const fixedColumns = [
  {
    title: 'Дата начала',
    children: [
      {
        title: 'План',
        dataIndex: 'start_date_plan',
        key: 'fixed_start_date_plan',
      },
      {
        title: 'Факт',
        dataIndex: 'start_date_fact',
        key: 'fixed_start_date_fact',
      },
    ],
  },
  {
    title: 'Дата завершения',
    children: [
      {
        title: 'План',
        dataIndex: 'finish_date_plan',
        key: 'fixed_finish_date_plan',
      },
      {
        title: 'Факт',
        dataIndex: 'finish_date_fact',
        key: 'fixed_finish_date_fact',
      },
    ],
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'fixed_status',
    render: (text, record, index) => {
      return {
        props: {
          style: { backgroundImage: text, width: '111px' },
        },
        children: <Popover title={legend} placement={'right'}>
          <div style={{width: '100%', height: '20px'}} />
        </Popover>,
      };
    },
  },
];

const legend = <div>
  {Object.entries(status_colors).map(([key, value]) => {
    return <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={{width: '20px', height: '20px', background: value, marginRight: '10px'}} />
      <div>{key}</div>
    </div>
  })}
</div>

function CustomRow(props) {
  const overdue = props.children[0]?.props?.record?.overdue
  const rejected = props.children[0]?.props?.record?.status === 'Отказано'

  return (
      <Tooltip title={overdue ? 'Просрочка' : rejected ? 'Отказано' : ''} placement={'top'}>
        <tr {...props} />
      </Tooltip>
  );
}

function ProjectStagesTables({showOverdue, setShowOverdue}) {
  const stages_tables = useStoreMap($projectStore, (p) => p.stages_tables ?? {});
  const phases = useStoreMap($dictionariesMaps, (maps) => maps.phasesMap ?? {})
  const orgsMap = useStore($projParticipantsInfo)
  const [activeCollapse, setActiveCollapse] = useState(null);

  useEffect(() => {
    if (showOverdue) {
      const keys = Object.entries(stages_tables)?.map(([key, value]) => {
        if (value?.some(i => i?.overdue)) {
          return key
        } else return null
      })?.filter(i => !!i)

      setActiveCollapse(keys)
    }
  }, [showOverdue, stages_tables]);

  const onCollapseChange = (keys) => {
    if (showOverdue) {
      setShowOverdue(false)
    }
    if (keys.length > 1) {
      // setActiveCollapse(keys.at(-1));
      setActiveCollapse(keys);
    } else {
      setActiveCollapse(keys[0]);
    }
  };

  const collapseItems = Object.entries(stages_tables)?.map(([phase_id, data]) => {
      return {
        key: phase_id,
        label: (
            <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
            >
              <div>{phases[phase_id]}</div>
              <div style={{ width: '50%', marginLeft: '20px' }}>
                <Table
                    columns={fixedColumns}
                    dataSource={toFixedData(data)}
                    pagination={false}
                    size="small"
                    showHeader={activeCollapse === phase_id}
                />
              </div>
            </div>
        ),
        children: (
            <Table
                id={'stages-table'}
                columns={getColumns(orgsMap)}
                rowClassName={(record) => {
                  if ((record.overdue || record.status === 'Отказано')) {
                    return style[rowClass[getStatusText(record.status, record.overdue)]]
                  } else {
                    return ''
                  }
                }}
                // components={{body: {row: CustomRow}}}
                dataSource={data}
                pagination={false}
                size="small"
            />
        ),
      }
  })


  return (
    <div style={{ marginTop: ' 72px' }}>
      <div className={style.block_title}>Стадии реализации проекта</div>
      <Collapse
        className={style.collapse}
        items={collapseItems}
        // ghost
        onChange={onCollapseChange}
        activeKey={activeCollapse}
      />
    </div>
  );
}

export default ProjectStagesTables;
