import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Descriptions from 'antd/es/descriptions';
import { useTranslation } from 'react-i18next';
import message from 'antd/es/message';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import {
  $editProfileStatus,
  editProfileEv,
  resetEditProfileStatusEv,
} from '../../../models/authModel/index.js';

export default function ProjectProfile({ curUsr }) {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const editStatus = useUnit($editProfileStatus);
  const resetStatus = useUnit(resetEditProfileStatusEv);

  useEffect(() => {
    if (editStatus) {
      setEdit(false);
      resetStatus();
    }
  }, [editStatus]);

  return (
    <Card
      title={t('Данные профиля')}
      extra={
        <Button type="primary" onClick={() => setEdit(!edit)}>
          {edit ? t('Отмена') : t('Редактировать')}
        </Button>
      }
    >
      {edit ? (
        <EditMode curUsr={curUsr} edit={edit} t={t} />
      ) : (
        <ViewMode curUsr={curUsr} />
      )}
    </Card>
  );
}

function ViewMode({ curUsr }) {
  const { t } = useTranslation();

  const items = [
    { key: 'surname', label: t('Фамилия'), children: curUsr.surname ?? '-' },
    { key: 'firstname', label: t('Имя'), children: curUsr.firstname ?? '-' },
    {
      key: 'patronymic',
      label: t('Отчество'),
      children: curUsr.patronymic ?? '-',
    },
    {
      key: 'email',
      label: t('Электронная почта'),
      children: curUsr.email ?? '-',
    },
    {
      key: 'phone',
      label: t('Рабочий телефон'),
      children: curUsr.work_phone ?? '-',
    },
    {
      key: 'inner_phone',
      label: t('Внутренний телефон'),
      children: curUsr.inner_phone ?? '-',
    },
    {
      key: 'title',
      label: t('Должность'),
      children: curUsr.title ?? '-',
    },
    {
      key: 'division',
      label: t('Подразделение'),
      children: curUsr.division ?? '-',
    },
    {
      key: 'organization',
      label: t('Организация'),
      children: curUsr.organization ?? '-',
    },
    { key: 'gov_id', label: t('БИН'), children: curUsr.gov_id ?? '-' },
    { key: 'login', label: t('Логин'), children: curUsr.username ?? '-' },
    { key: 'role', label: t('Роль'), children: curUsr.role ?? '-' },
    // { key: 'access', label: t('Доступ'), children: curUsr.access ?? '-' },
  ];
  return (
    <Descriptions
      items={items}
      column={1}
      bordered
      labelStyle={{ width: '30%' }}
    />
  );
}

function EditMode({ curUsr, edit, t }) {
  const [profileForm] = Form.useForm();
  const submit = useUnit(editProfileEv);

  useEffect(() => {
    if (curUsr && edit) {
      profileForm.setFieldsValue(curUsr);
    }
  }, [curUsr, edit]);

  useEffect(() => {
    if (!edit) {
      profileForm.resetFields();
    }
  }, [edit]);

  const onFinish = (values) => {
    const payload = {};
    for (const [key, value] of Object.entries(values)) {
      if (value !== curUsr[key]) {
        payload[key] = value;
      }
    }
    if (Object.keys(payload).length === 0) {
      return message.error(t('Изменения отсутствуют'));
    }
    submit(payload);
  };

  return (
    <Form form={profileForm} layout="vertical" onFinish={onFinish}>
      <Form.Item name="surname" label={t('Фамилия')}>
        <Input />
      </Form.Item>
      <Form.Item name="firstname" label={t('Имя')}>
        <Input />
      </Form.Item>
      <Form.Item name="patronymic" label={t('Отчество')}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label={t('Электронная почта')}>
        <Input />
      </Form.Item>
      <Form.Item name="work_phone" label={t('Рабочий телефон')}>
        <Input />
      </Form.Item>
      <Form.Item name="inner_phone" label={t('Внутренний телефон')}>
        <Input />
      </Form.Item>
      <Form.Item name="title" label={t('Должность')}>
        <Input />
      </Form.Item>
      <Form.Item name="division" label={t('Подразделение')}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}
