import { Switch, Tooltip } from 'antd';
import { useEvent, useStore } from 'effector-react';
import {
  $filters,
  changeFiltersEv,
} from '../../models/organizationsModel/index.js';
import styles from './OrganizationsDashboard.module.css';

export default function SwitchFilters() {
  const { show_all, have_dvc, is_gov, have_contacts } = useStore($filters);
  const changeFilters = useEvent(changeFiltersEv);
  return (
    <div className={styles.switchContainer}>
      <div className={styles.switchItem}>
        <Tooltip title="С участием государства">
          <span>Гос.</span>
        </Tooltip>
        <Switch
          checked={is_gov}
          onChange={(checked) => changeFilters({ is_gov: checked })}
        />
      </div>
      <div className={styles.switchItem}>
        <Tooltip title="Включая нулевые">
          <span>Нулевые</span>
        </Tooltip>
        <Switch
          checked={show_all}
          onChange={(checked) => changeFilters({ show_all: checked })}
        />
      </div>
      <div className={styles.switchItem}>
        <Tooltip title="Только ОТП">
          <span>ОТП</span>
        </Tooltip>
        <Switch
          checked={have_dvc}
          onChange={(checked) => changeFilters({ have_dvc: checked })}
        />
      </div>
      <div className={styles.switchItem}>
        <Tooltip title="Только с контактными данными">
          <span>Контакты</span>
        </Tooltip>
        <Switch
          checked={have_contacts}
          onChange={(checked) => changeFilters({ have_contacts: checked })}
        />
      </div>
    </div>
  );
}
