import { createStore } from 'effector';

const initial_state = {
  region_id: 0,
  district_id: 0,
  main_activity_id: [],
  organization_id: [],
  organization_bin: 0,
  gradient: {
    min: 0,
    max: 0,
  },
  regionIdByGradient: [],
  category: 'mal',
  subCategory: 'people',
  is_gov: false,
  business_climate_yearly: false,
  project_type_id: [],
};

export const $activeFilters = createStore(initial_state);
$activeFilters.watch((state) => console.log('$activeFilters', state));
