import {sample} from "effector";
import {resetProjRegFilteredStoreEv, setProjRegistryDataEv} from "./events.js";
import {$projRegistryFilteredStore, $projRegistryStartStore, $projRegistryStore} from "./stores.js";
import {prepareFilteredRegistryData, prepareProjRegistryData} from "../../counters/projRegistry/index.js";
import {$activeFilters, resetActiveFiltersEv} from "../activeFiltersModel";
import {$globalStore, $projectsStore} from "../globalModel";
import {hasActiveFilters} from "../../utils/active-filters-utils.js";

$projRegistryFilteredStore.reset(resetProjRegFilteredStoreEv)

sample({
	clock: setProjRegistryDataEv,
	fn: prepareProjRegistryData,
	target: [$projRegistryStore, $projRegistryStartStore]
})

sample({
	source: [$globalStore, $projRegistryStartStore, $activeFilters],
	clock: $projectsStore.updates,
	filter: ([source, startData, activeFilters]) => {
		return Object.values(startData).every(item => {
			if(Array.isArray(item)){
				return item.length > 0
			} else {
				return item > 0
			}
		})
	},
	fn: ([global, startData, activeFilters], clock) => {
		if(clock.length === global.projects.length){
			return startData
		} else {
			return prepareFilteredRegistryData(clock, global, activeFilters)
		}
	},
	target: $projRegistryStore
})

sample({
	source: $projRegistryStartStore,
	clock: $projRegistryFilteredStore.updates,
	fn: (_, clock) => clock,
	target: $projRegistryStore,
})

sample({
	source: $projRegistryStartStore,
	clock: resetProjRegFilteredStoreEv,
	fn: (source) => source,
	target: $projRegistryStore
})

sample({
	source: $projRegistryStartStore,
	clock: resetActiveFiltersEv,
	fn: (source, clock) => source,
	target: [$projRegistryStore, resetProjRegFilteredStoreEv]
})
