import {createStore} from "effector";

const $organizationInitialState = {
	subjectInfoProps: {
		bin: '',
		krp: '',
		oked: '',
		activity: '',
		address: '',
		oked_secondary: '',
		supervisor: '',
		addition: '',
		krp_count: '',
		reg_date: '',
		name: '',
		reg: '',
	},
	projectsData: [],
	taxesChartData: [],
	dots: [],
	taxesDates: [],
}

export const $organizationStore = createStore($organizationInitialState)
