import { Button, Select, Space, Table, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { useStore, useUnit } from 'effector-react';
import { DownloadOutlined, TableOutlined } from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group.js';
import dayjs from 'dayjs';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {
  $pollsResults,
  BizPollResultsGate,
  downloadResultsEv,
  downloadResultsFx,
  getResultsListFx,
} from '../../models/bizPollsModel/index.js';
import { openReportsModalEv } from '../../models/organizationsModel/index.js';
import ReportsModal from '../OrganizationsDashboard/ReportsModal.jsx';
import { $regionsSelect } from '../../models/globalModel/index.js';
import { regions_dictionary } from '../../dictionaries/regions_districts_dictionary.js';

export default function BizPollsResults() {
  const download = useUnit(downloadResultsEv);
  const openResultsModal = useUnit(openReportsModalEv);
  const loading = useUnit(getResultsListFx.pending);
  const [activeTab, setActiveTab] = useState('small');
  const regions = useStore($regionsSelect);
  const buttonLoading = useUnit(downloadResultsFx.pending);
  const [activeRegion, setActiveRegion] = useState(null);
  const [activeQuarter, setActiveQuarter] = useState(null);

  const results = useUnit($pollsResults);
  // const childrenCols =
  //   results[0] &&
  //   results[0]?.categories &&
  //   Object.entries(results[0]?.categories)?.map(([category, value]) => ({
  //     title: category,
  //     dataIndex: ['categories', category],
  //     key: category,
  //   }));

  const columns = [
    { title: 'Регион', dataIndex: 'region', key: 'region' },
    // { title: 'Значения по категориям', children: childrenCols },
    { title: 'Количество', dataIndex: 'value', key: 'count' },
  ];

  const items = [
    {
      key: 'small',
      label: 'Микро и малый бизнес',
      children: (
        <Table
          dataSource={results.filter((item) =>
            activeRegion
              ? item.region === regions_dictionary[activeRegion]
              : true
          )}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      ),
    },
    {
      key: 'medium',
      label: 'Средний бизнес',
      children: (
        <Table
          dataSource={results.filter((item) =>
            activeRegion
              ? item.region === regions_dictionary[activeRegion]
              : true
          )}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      ),
    },
  ];

  const quarterItems = useMemo(() => {
    const quarters = {
      2024: [],
    };
    const currentYear = dayjs().year();
    if (!quarters[currentYear]) {
      for (let i = currentYear; i >= 2024; i--) {
        if (i === currentYear) {
          quarters[i] = [
            ...Array(dayjs().quarter() - 1)
              .keys()
              .map((item) => item + 1),
          ];
        } else {
          quarters[i] = [1, 2, 3, 4];
        }
      }
    } else {
      quarters[2024] = [
        ...Array(dayjs().quarter())
          .keys()
          .map((item) => item + 1),
      ];
    }

    const result = [];
    Object.keys(quarters).forEach((year) => {
      Object.values(quarters[year]).forEach((quarter) => {
        result.push({
          label: `${quarter} квартал ${year}`,
          value: `${year}_${quarter}`,
        });
      });
    });

    return result;
  }, []);

  return (
    <ContainerInner
      title="Результаты опросов бизнеса"
      extra={
        <div>
          <Space>
            <Select
              style={{
                width: '200px',
              }}
              options={regions}
              value={activeRegion}
              onChange={setActiveRegion}
              allowClear
              showSearch
              placeholder="Выберите регион"
            />
            <Select
              style={{
                width: '200px',
              }}
              options={quarterItems}
              value={activeQuarter}
              onChange={setActiveQuarter}
              allowClear
              showSearch
              placeholder="Выберите квартал"
            />
            <ButtonGroup>
              <Button
                icon={<TableOutlined />}
                onClick={() => openResultsModal()}
              >
                Результаты выгрузки
              </Button>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                style={{ boxShadow: 'none' }}
                onClick={() => download()}
                loading={buttonLoading}
              >
                Выгрузить
              </Button>
            </ButtonGroup>
          </Space>
        </div>
      }
    >
      <ReportsModal type="business_climate_report" />
      <BizPollResultsGate
        business_type={activeTab}
        region_id={activeRegion}
        activeQuarter={activeQuarter}
      />
      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={(key) => setActiveTab(key)}
      />
    </ContainerInner>
  );
}
