import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useEvent, useStore } from 'effector-react';
import { useLocation } from 'react-router-dom';
import {
  ExtendDrawBar,
  saveObject,
  deleteAll,
  deleteObject,
} from '../../utils/mapbox-utils.js';
import {
  $globalStore,
  resetMapLoadedEv,
  setMapLoadedEv,
} from '../../models/globalModel/index.js';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZnJvbnRpZXJkIiwiYSI6ImNrYTNuMjBvNjAxNnAzbW85N3ZsbmRvZmYifQ.9OWZ7YWGJ4DDIV4bCTwsaA';

function Map({ isInnerPage, isEditPage, offDrag }) {
  const location = useLocation();

  const isProject = isInnerPage && location.pathname.includes('view-project');

  const mapContainer = useRef(null);

  const setMapLoaded = useEvent(setMapLoadedEv);
  const resetMapLoaded = useEvent(resetMapLoadedEv);
  // const map = useRef(null);

  // FIXME Init map
  useEffect(() => {
    if (window.map) return;
    window.map = new mapboxgl.Map({
      container: mapContainer.current,
      // style: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [68.71584352632227, 48.58775813682156],
      // zoom: window.location.href.includes('projects-map') ? 4.1 : 3.1,
      zoom: 3.1,
      interactive: !isInnerPage,
      dragRotate: false,
    });

    // FIXME Init draw polygon
    const Draw = new MapboxDraw({
      displayControlsDefault: false,
      touchEnabled: false,
      controls: {
        // polygon: true,
        point: true,
        trash: true,
      },
      // FIXME Custom styles of points and polygons MADE BY controls
      // for ref look in geo
      // styles: customStyles,
    });

    window.draw = Draw;

    // FIXME Custom controls
    // for ref look in geo
    const drawControls = new ExtendDrawBar({
      draw: Draw,
      buttons: [
        {
          on: 'click',
          action: deleteAll,
          classes: ['mapbox-gl-draw_ctrl-draw-btn', 'mapbox-gl-draw_trash'],
        },
      ],
    });

    // FIXME Modes of mapbox draw
    // Used to override drag option
    const { modes } = MapboxDraw;
    if (offDrag) {
      modes.simple_select.onDrag = function (state, e) {};
    }

    if (isEditPage) {
      window.map.addControl(Draw, 'top-left');
    }
    window.map.on('draw.create', saveObject);
    window.map.on('draw.delete', deleteObject);

    window.map.on('load', () => {
      setMapLoaded(true);
    });

    // window.map.scrollZoom.disable();
    // window.map.dragPan.disable();
    // window.map.doubleClickZoom.disable();
    // window.map.on('draw.modechange', e => console.log(e));

    return () => {
      resetMapLoaded();
      delete window.map;
    };
  }, []);

  return (
    <div
      ref={mapContainer}
      className={isProject ? 'map-container-project' : 'map-container'}
    />
  );
}

export default Map;
