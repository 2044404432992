import {Button, Form, Input, Modal, Select, TreeSelect, Upload} from "antd";
import {useEvent, useStore} from "effector-react";
import {
    countriesSelect as countries,
    activitiesSelect as activities,
    $forProjModalState, $selectedForeignProject, createForInvestFx,
    resetForProjModalStateEv, submitCreateFormEv, submitUpdateFormEv, updateForInvestFx
} from "../../../models/foreignInvestmentsModel/index.js";
import {useEffect} from "react";
import {UploadOutlined} from "@ant-design/icons";

const ProjectCreateEditModal = () => {
    const [projForm] = Form.useForm()

    const closeModal = useEvent(resetForProjModalStateEv)
    const submitCreate = useEvent(submitCreateFormEv)
    const submitEdit = useEvent(submitUpdateFormEv)

    const {visible, mode, submitStatus} = useStore($forProjModalState)

    const selectedProject = useStore($selectedForeignProject)

    const createLoading = useStore(createForInvestFx.pending)
    const updateLoading = useStore(updateForInvestFx.pending)

    const onClose = () => {
        projForm.resetFields()
        closeModal()
    }

    const onSubmit = (values) => {
        if (mode === 'create') {
            submitCreate(values)
        } else if (mode === 'edit') {
            submitEdit(values)
        }
    }

    useEffect(() => {
        if (submitStatus) {
            onClose()
        }
    }, [submitStatus])

    useEffect(() => {
        if (mode === 'edit' && selectedProject) {
            projForm.setFieldsValue(selectedProject)
        }
    }, [selectedProject, mode]);

    return <Modal width={'80vw'} open={visible} footer={null} style={{top: '5vh'}} onCancel={onClose}
                  title={mode === 'edit' ? `Редактировать заявку - ${selectedProject?.name}` : 'Новая заявка'}
    >
        <Form layout={'vertical'} form={projForm} onFinish={onSubmit}>
            <Form.Item name={'name'} label={'Наименование компании'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
                <Input />
            </Form.Item>
            <Form.Item name={'activity_id'} label={'Вид деятельности компании (ОКЭД НК РК 03-2019)'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
                <TreeSelect treeData={activities}/>
            </Form.Item>
            <Form.Item name={'phone_number'} label={'Телефон'}>
                <Input type={'tel'}/>
            </Form.Item>
            <Form.Item name={'email'} label={'Электронная почта'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
                <Input type={'email'} />
            </Form.Item>
            <Form.Item name={'country'} label={'Страна'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
                <Select options={countries} allowClear={true} showSearch={true}/>
            </Form.Item>
            <Form.Item name={'purpose_of_interest'} label={'Цель интереса в Казахстане'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
                <Select options={[
                    {label: 'Заключение инвестиционного контракта', value: 'Заключение инвестиционного контракта'},
                    {label: 'Продажа продукции', value: 'Продажа продукции'},
                    {label: 'Услуги ЕРС', value: 'Услуги ЕРС'}
                ]}/>
            </Form.Item>
            <Form.Item name={'additional_goal_details'} label={'Дополнительные детали целей'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
                <Input.TextArea />
            </Form.Item>
            <Form.Item name={'file'} label={'Приложите документ'}>
                <Upload beforeUpload={() => false} maxCount={1}>
                    <Button className={'info-btn'} icon={<UploadOutlined />} shape={'round'} size={'large'}/>
                </Upload>
            </Form.Item>
            {mode === 'edit'
                ? <p>{selectedProject?.file_path ? `Существующий файл - ${selectedProject?.file_path.split('/').at(-1)}` : 'Файл не загружен'}</p>
                : null
            }
        </Form>
        <Button className={'btn-primary'} onClick={() => projForm.submit()} loading={createLoading || updateLoading}>
            Отправить
        </Button>
    </Modal>
}

export default ProjectCreateEditModal