import { createEffect } from 'effector';
import { api } from '../../api/axiosInstance.js';

export const getBizPollOptsFx = createEffect().use(
  async (type) =>
    (await api().get(`/business-climate-form/?business_type=${type}`)).data
);

export const getBinInfoFx = createEffect().use(
  async ({ bin, type }) =>
    (await api().get(`/business-climate-form/gov/${bin}?business_type=${type}`))
      .data
);

export const submitFormFx = createEffect().use(
  async (payload) => (await api().post('/business-climate-form/', payload)).data
);

export const getResultsListFx = createEffect().use(
  async (query) =>
    (await api().get(`/business-climate-form/list?${query}`)).data
);

export const downloadResultsFx = createEffect().use(async (query) => {
  return (await api().get(`/business-climate-form/export?${query}`)).data;
});
