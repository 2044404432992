export const infoSourceDictionary = {
  'people': 'АО "Единый накопительный пенсионный фонд"',
  'taxes': 'Комитет государственных доходов',
  'medium_companies': 'Бюро национальной статистики',
  'projectCount': 'НПП РК "Атамекен", МИО',
  'problemProjects': 'НПП РК "Атамекен", МИО',
  'business_inspections': 'НПП РК "Атамекен"',
  'mio_performance': 'НПП РК "Атамекен"',
  'otp_count': 'НПП РК "Атамекен"',
  'otp_avg_workload': 'НПП РК "Атамекен"',
};

export const actualityDictionary = {
  'people': 'ежемесячно',
  'taxes': 'ежемесячно',
  'medium_companies': 'ежемесячно',
  'projectCount': 'ежедневно',
  'problemProjects': 'ежедневно',
  'business_inspections': 'ежеквартально',
  'mio_performance': 'ежеквартально',
  'otp_count': 'ежемесячно',
  'otp_avg_workload': 'ежемесячно',
};
