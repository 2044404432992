import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useStore} from "effector-react";
import {$projRegistryStore} from "../../models/projRegistryModel/index.js";
import {Link, useLocation} from "react-router-dom";
import {Button, Card, Dropdown, Input, Row, Table, Tooltip} from "antd";
import {EditOutlined, PlusCircleOutlined, ProjectOutlined} from "@ant-design/icons";
import ContainerInner from "../ContentContainer/ContainerInner.jsx";
import {$userInfo} from "../../models/authModel/index.js";
import {$globalStore} from "../../models/globalModel/index.js";

const EditSubjectsList = () => {
    const subjects = useStore($globalStore).organizations || []

    const subjectsTableDataInit = subjects.map(item => {
        return {
            key: `${item.name}${item.id}`,
            subj_id: item.id,
            subjectName: item.name,
            gov_id: item.gov_id
        }
    })

    const [nameSearch, setNameSearch] = useState('')
    const [binSearch, setBinSearch] = useState('')

    const subjectsTableDataFiltered = useMemo(() => {
        let subjectsTableData = subjectsTableDataInit
        if (nameSearch.length > 0) {
            subjectsTableData = subjectsTableData
                .filter(item => item?.subjectName?.toLowerCase()?.includes(nameSearch.toLowerCase()))
        }
        if (binSearch.length > 0) {
            subjectsTableData = subjectsTableData
                .filter(item => item?.gov_id?.toString()?.includes(binSearch))
        }

        return subjectsTableData
    }, [subjectsTableDataInit, nameSearch, binSearch])

    const dropdownItems = useCallback((subj_id) => {
        return [
            {
                key: 'subj',
                label: <Link to={`/view-subject/${subj_id}`} target={'_blank'}>Просмотр предприятия</Link>
            },
        ]
    }, [])

    const DropDown = useCallback(({field, record}) => {
        return <Dropdown
            menu={{items: dropdownItems(record.subj_id)}}
            placement={'topLeft'}
            arrow
            trigger={['click']}
        >
            <div style={{cursor: 'pointer'}}>{field}</div>
            {/*<Tooltip arrow={false} placement="topLeft" title={field}>{field}</Tooltip>*/}
        </Dropdown>
    }, [])

    const columns = [
        {
            key: 'name',
            title: 'Наименование предприятия',
            dataIndex: 'subjectName',
            render: (name, record) => <DropDown field={name} record={record} />
        },
        {
            key: 'bin',
            title: 'БИН',
            dataIndex: 'gov_id',
        },
        {
            key: 'edit',
            title: 'Редактировать',
            render: (_, record) => (
                <Tooltip title={'Редактировать предприятие'}>
                    <Link to={`/edit-subject/${record.subj_id}`}>
                        <Button className="info-btn" icon={<EditOutlined />} />
                    </Link>
                </Tooltip>
            ),
            align: 'right',
        },

    ]

    return <ContainerInner title={'Список предприятий'}>
        <Row style={{marginBottom: 24}}>
            <Card style={{width: '100%'}}>
                <Row justify={'space-between'}>
                    <Input.Search placeholder="Поиск по наименованию" allowClear onSearch={(value) => setNameSearch(value)} style={{width: '40%'}}/>
                    <Input.Search placeholder="Поиск по БИН" allowClear onSearch={(value) => setBinSearch(value)} style={{width: '40%'}}/>
                </Row>
            </Card>
        </Row>
        <Row style={{minHeight:'40%'}}>
            <Table
                columns={columns}
                dataSource={subjectsTableDataFiltered}
                size={'large'}
                pagination={{position: ['bottomCenter'], defaultPageSize: 20, pageSizeOptions: [20, 50, 100]}}
                style={{width: '100%'}}
            />
        </Row>
    </ContainerInner>
};

export default EditSubjectsList;
