import { sample } from 'effector';
import { center } from '@turf/turf';
import {
  createObjectEv,
  deleteCreatedObjectEv,
  resetCreatedObjectsEv,
} from './events.js';
import { $createdObjects } from './stores.js';

$createdObjects.reset(resetCreatedObjectsEv);

// FIXME gets created object (transforms if needed) and saves it in store
sample({
  source: $createdObjects,
  clock: createObjectEv,
  fn: (source, drawnObject) => {
    const update = { ...source };
    if (Array.isArray(drawnObject)) {
      // FIXME If we got drawnObject FROM bottombar form Point mode
      update.points = [...source.points, ...drawnObject];
      window.draw.set({
        type: 'FeatureCollection',
        features: [...window.draw.getAll().features, ...drawnObject],
      });
    } else {
      const objectType = drawnObject.geometry.type;
      if (objectType === 'Point') {
        if (source.points.length === 0) {
          const mutatedDrawnObject = {
            ...drawnObject,
            properties: {
              id:
                update.points.length > 0
                  ? update.points[update.points.length - 1].properties.id + 1
                  : 0,
              name: '',
              organization_id: '',
              address: '',
              indicators: [],
              object_id: '',
            },
          };
          update.points = [...source.points, mutatedDrawnObject];
          window.draw.set({
            type: 'FeatureCollection',
            features: update.points,
          });
        }
      } else {
        const centerOfPolygon = center(drawnObject);
        update.points = [...source.points, centerOfPolygon];
        update.polygons = [...source.polygons, drawnObject];

        // FIXME If we created object FROM bottombar form
        // it puts center of polygon with IT or without It
        if (!drawnObject.id) {
          window.draw.set({
            type: 'FeatureCollection',
            features: [
              ...window.draw.getAll().features,
              drawnObject,
              centerOfPolygon,
            ],
          });
        } else {
          window.draw.set({
            type: 'FeatureCollection',
            features: [...window.draw.getAll().features, centerOfPolygon],
          });
        }
      }
    }
    return update;
  },
  target: $createdObjects,
});

sample({
  source: $createdObjects,
  clock: deleteCreatedObjectEv,
  fn: (source) => ({ ...source, points: window.draw.getAll().features }),
  target: $createdObjects,
});
