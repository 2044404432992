import { TreeSelect } from 'antd';
import { useEvent, useStore } from 'effector-react';
import { $activitiesTreeSelect } from '../../models/globalModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';

const { SHOW_PARENT } = TreeSelect;

export default function OkedSelector() {
  const activitiesTreeSelect = useStore($activitiesTreeSelect);
  const activeActivity = useStore($activeFilters).oked_id;
  const changeActiveFilter = useEvent(changeActiveFilterEv);

  const onActivitySelect = (value, label, extra) => {
    const payloadValue = [];

    const getOkedIds = (node) => {
      if (!payloadValue.includes(node.node.props.value)) {
        payloadValue.push(node.node.props.value);
      }
      if (node.children.length > 0) {
        node.children.forEach((child) => getOkedIds(child));
      }
    };

    if (extra.allCheckedNodes.length > 0) {
      extra.allCheckedNodes.forEach((checkedNode) => {
        getOkedIds(checkedNode);
      });
    }
    changeActiveFilter({
      field: 'main_activity_id',
      value: payloadValue,
    });
  };

  const filterOption = (input, node) =>
    (node?.title ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <TreeSelect
      style={{
        width: '220px',
        color: 'black',
        marginBottom: '10px',
      }}
      treeData={activitiesTreeSelect}
      value={activeActivity}
      onChange={onActivitySelect}
      showCheckedStrategy={SHOW_PARENT}
      allowClear
      multiple
      treeCheckable
      showSearch
      dropdownStyle={{ width: 500 }}
      filterTreeNode={filterOption}
      placeholder="Выберите ОКЭД"
    />
  );
}
