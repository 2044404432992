import { Form, Input, Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { $pollOptions } from '../../models/bizPollsModel/index.js';

const limits = {
  6: 2,
  7: 3,
  8: 2,
  9: 2,
  10: 3,
};

function Question({ q, type, blocked }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  return (
    <>
      <Form.Item
        name={[q.id, 'value']}
        label={t(`biz-polls.questions.${type}.${q.id}`)}
        rules={
          blocked.includes(q.id)
            ? null
            : [{ required: true, message: t('Обязательное поле') }]
        }
      >
        {q.is_multiselect ? (
          <Select
            onChange={(val) => setValue(val)}
            options={q.options.map((opt) => ({
              value: opt.is_custom ? 'custom' : opt.value,
              label: t(
                `biz-polls.answers.${type}.q${opt.question_id}.${opt.id}`
              ),
            }))}
            mode="multiple"
            maxCount={limits[q.id]}
          />
        ) : (
          <Radio.Group
            onChange={({ target: { value } }) => setValue(value)}
            disabled={blocked.includes(q.id)}
          >
            <Space direction="vertical">
              {q.options.map((opt) => (
                <Radio
                  value={opt.is_custom ? 'custom' : opt.value}
                  key={opt.value}
                >
                  {t(`biz-polls.answers.${type}.q${opt.question_id}.${opt.id}`)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      </Form.Item>
      {(value === 'custom' || value?.includes('custom')) && (
        <Form.Item
          name={[q.id, 'custom']}
          label={t('biz-polls.custom')}
          rules={[{ required: true, message: t('Обязательное поле') }]}
        >
          <Input.TextArea />
        </Form.Item>
      )}
    </>
  );
}

export default function BizPollQuestions({ type, blocked }) {
  const questions = useUnit($pollOptions);

  const formItems = useMemo(() => {
    if (questions && questions.length > 0) {
      return questions.map((q) => (
        <Question key={q.id} q={q} type={type} blocked={blocked} />
      ));
    }
  }, [type, questions, blocked]);

  return <>{formItems}</>;
}
