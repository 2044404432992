import { Column } from '@ant-design/charts';
import React, { useEffect, useState } from 'react';
import style from './ViewProjectComponents.module.css';


function ProjectChart({ data }) {
  const config = {
    data,
    title: 'Этапы',
    xField: 'category',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    color: ['l(90) 0:#4318FF 1:#4318FF47', 'l(90) 0:#6AD2FF 1:#6AD2FF47'],
    minColumnWidth: 8,
    maxColumnWidth: 16,
    legend: {
      position: 'top-right',
    },
    height: 255,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <div className={style.project_view_card}>
      <h1 className={style.project_view_card_title_new}>Сквозной контроль</h1>
      <Column {...config} style={{ marginTop: '20px' }} />
    </div>
  );
}

export default ProjectChart;
