import {useEffect, useState} from "react";
import {Col, Form, Switch, Typography} from "antd";
import SoilTemplate from "./SoilTemplate.jsx";
import ElGasHeatTemplate from "./ElGasHeatTemplate.jsx";
import WaterSupplyTemplate from "./WaterSupplyTemplate.jsx";
import BuildingsTemplate from "./BuildingsTemplate.jsx";

const ResourcesForm = ({form, editMode, project}) => {
    const [soil, setSoil] = useState(form.getFieldValue(['resources', 'need_soil']) ?? false)
    const [buildings, setBuildings] = useState(form.getFieldValue(['resources', 'need_buildings']) ?? false)
    const [water, setWater] = useState(form.getFieldValue(['resources', 'need_water']) ?? false)
    const [electro, setElectro] = useState(form.getFieldValue(['resources', 'need_electricity']) ?? false)
    const [gas, setGas] = useState(form.getFieldValue(['resources', 'need_gas']) ?? false)
    const [heat, setHeat] = useState(form.getFieldValue(['resources', 'need_heat']) ?? false)

    useEffect(() => {
        if (editMode && project?.resources) {
            setSoil(project?.resources?.need_soil)
            setBuildings(project?.resources?.need_buildings)
            setWater(project?.resources?.need_water)
            setElectro(project?.resources?.need_electricity)
            setGas(project?.resources?.need_gas)
            setHeat(project?.resources?.need_heat)
        }
    }, [editMode, project])

    const totalProductionFields = Form.useWatch(['resources', 'soil', 'production'], form)
    const totalProductionValue = totalProductionFields
    && Object?.values(totalProductionFields)?.filter(value => typeof value === 'number')?.length > 0
        ? Object?.values(totalProductionFields)?.filter(value => typeof value === 'number')
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalFarmFields = Form.useWatch(['resources', 'soil', 'farm'], form)
    const totalFarmValue = totalFarmFields
    && Object?.values(totalFarmFields)?.filter(value => typeof value === 'number')?.length > 0
        ? Object?.values(totalFarmFields)
            ?.filter(value => typeof value === 'number')?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalWeatreapFields = Form.useWatch(['resources', 'soil', 'weatreap'], form)
    const totalWeatreapValue = totalWeatreapFields
    && Object?.values(totalWeatreapFields)?.filter(value => typeof value === 'number')?.length > 0
        ? Object?.values(totalWeatreapFields)?.filter(value => typeof value === 'number')
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalFeedFields = Form.useWatch(['resources', 'soil', 'feed'], form)
    const totalFeedValue = totalFeedFields
    && Object?.values(totalFeedFields)?.filter(value => typeof value === 'number')?.length > 0
        ? Object?.values(totalFeedFields)?.filter(value => typeof value === 'number')
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalOtherFields = Form.useWatch(['resources', 'soil', 'other'], form)
    const totalOtherValue = totalOtherFields
    && Object?.values(totalOtherFields)?.filter(value => typeof value === 'number')?.length > 0
        ? Object?.values(totalOtherFields)?.filter(value => typeof value === 'number')
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalWaterTechFields = Form.useWatch(['resources', 'water', 'technical'], form)
    const totalWaterTechValue = totalWaterTechFields
    && Object?.values(totalWaterTechFields)?.length > 0
        ? Object?.values(totalWaterTechFields)
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalWaterIrrigateFields = Form.useWatch(['resources', 'water', 'irrigation'], form)
    const totalWaterIrrigateValue = totalWaterIrrigateFields
    && Object?.values(totalWaterIrrigateFields)?.length > 0
        ? Object?.values(totalWaterIrrigateFields)
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalWaterDrinkFields = Form.useWatch(['resources', 'water', 'drink'], form)
    const totalWaterDrinkValue = totalWaterDrinkFields
    && Object?.values(totalWaterDrinkFields)?.length > 0
        ? Object?.values(totalWaterDrinkFields)
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalProdBuilds = Form.useWatch(['resources', 'buildings', 'manufacture'], form)
    const totalProdBuildsValue = totalProdBuilds
    && Object?.values(totalProdBuilds)?.length > 0
        ? Object?.values(totalProdBuilds)
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalUtilsBuilds = Form.useWatch(['resources', 'buildings', 'utils'], form)
    const totalUtilsBuildsValue = totalUtilsBuilds
    && Object?.values(totalUtilsBuilds)?.length > 0
        ? Object?.values(totalUtilsBuilds)
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0

    const totalStorageBuilds = Form.useWatch(['resources', 'buildings', 'storage'], form)
    const totalStorageBuildsValue = totalStorageBuilds
    && Object?.values(totalStorageBuilds)?.length > 0
        ? Object?.values(totalStorageBuilds)
            ?.reduce((acc = 0, curr) => acc + (curr ?? 0))
        : 0


    const totalSoil = totalProductionValue + totalFarmValue + totalWeatreapValue + totalFeedValue + totalOtherValue
    const totalBuildings = totalProdBuildsValue + totalUtilsBuildsValue + totalStorageBuildsValue
    const totalWater = totalWaterTechValue + totalWaterIrrigateValue + totalWaterDrinkValue

    const resetField = (name) => {
        form.resetFields([name])
    }


    return <>
    <Form.Item name={'resources'}>
        <Form.Item name={['resources', 'need_soil']} label={'Земля'} valuePropName={'checked'} initialValue={false}>
            <Switch onChange={(checked) => setSoil(checked)}/>
        </Form.Item>
        {soil && (
            <Col span={24}>
                <Typography.Title level={5}>{`Итоговая потребность в земле: ${totalSoil}`}</Typography.Title>
                <Form.Item name={['resources', 'soil']}>
                    <SoilTemplate title={'земли промышленности'} namespace={['resources', 'soil', 'production']} total={totalProductionValue}
                                  resetVal={resetField} form={form}/>
                    <SoilTemplate title={'пашня'} namespace={['resources', 'soil', 'farm']} total={totalFarmValue}
                                  resetVal={resetField} form={form}/>
                    <SoilTemplate title={'сенокосы'} namespace={['resources', 'soil', 'weatreap']} total={totalWeatreapValue}
                                  resetVal={resetField} form={form}/>
                    <SoilTemplate title={'выпасы'} namespace={['resources', 'soil', 'feed']} total={totalFeedValue}
                                  resetVal={resetField} form={form}/>
                    <SoilTemplate title={'прочие'} namespace={['resources', 'soil', 'other']} total={totalOtherValue}
                                  resetVal={resetField} form={form}/>
                </Form.Item>
            </Col>
        )}

        <Form.Item name={['resources', 'need_buildings']} label={'Здания и сооружения'} valuePropName={'checked'} initialValue={false}>
            <Switch onChange={(checked) => setBuildings(checked)}/>
        </Form.Item>
        {buildings && <Col span={24}>
            <Typography.Title level={5}>{`Итоговая потребность в сооружениях: ${totalBuildings}`}</Typography.Title>
            <Form.Item name={['resources', 'buildings']}>
                <BuildingsTemplate namespace={['resources', 'buildings', 'manufacture']} title={'Производственные, тыс. кв. метров'}
                                   total={totalProdBuildsValue}/>
                <BuildingsTemplate namespace={['resources', 'buildings', 'utils']} title={'Вспомогательные, тыс. кв. метров'}
                                   total={totalUtilsBuildsValue}/>
                <BuildingsTemplate namespace={['resources', 'buildings', 'storage']} title={'Складские, тыс. кв. метров'}
                                   total={totalStorageBuildsValue}/>
            </Form.Item>
        </Col>}

        <Form.Item name={['resources', 'need_water']} label={'Водоснабжение и КНС'} valuePropName={'checked'} initialValue={false}>
            <Switch onChange={(checked) => setWater(checked)}/>
        </Form.Item>
        {water && <Col span={24}>
            <Typography.Title level={5}>{`Итоговая потребность в воде: ${totalWater}`}</Typography.Title>
            <Form.Item name={['resources', 'water']}>
                <WaterSupplyTemplate namespace={['resources', 'water', 'technical']} title={'Промышленное техническое'}
                                     total={totalWaterTechValue}/>
                <WaterSupplyTemplate namespace={['resources', 'water', 'irrigation']} title={'Ирригационное'} total={totalWaterIrrigateValue}/>
                <WaterSupplyTemplate namespace={['resources', 'water', 'drink']} title={'Питьевое'} total={totalWaterDrinkValue}/>
            </Form.Item>
        </Col>
        }

        <Form.Item name={['resources', 'need_electricity']} label={'Электроэнергия'} valuePropName={'checked'} initialValue={false}>
            <Switch onChange={(checked) => setElectro(checked)}/>
        </Form.Item>
        {electro && (
            <Col span={24}>
                <ElGasHeatTemplate title={'Мегаватт/час'} namespace={['resources', 'electricity']}/>
            </Col>
        )}

        <Form.Item name={['resources', 'need_gas']} label={'Газоснабжение'} valuePropName={'checked'} initialValue={false}>
            <Switch onChange={(checked) => setGas(checked)}/>
        </Form.Item>
        {gas && (
            <Col span={24}>
                <ElGasHeatTemplate title={'Кубометров/час'} namespace={['resources', 'gas']}/>
            </Col>
        )}

        <Form.Item name={['resources', 'need_heat']} label={'Теплоснабжение'} valuePropName={'checked'} initialValue={false}>
            <Switch onChange={(checked) => setHeat(checked)}/>
        </Form.Item>
        {heat && (
            <Col span={24}>
                <ElGasHeatTemplate title={'Гкал/час'} namespace={['resources', 'heat']}/>
            </Col>
        )}
    </Form.Item>
    </>
}

export default ResourcesForm