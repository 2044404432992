import {createEffect} from "effector";
import {NCALayerClient} from "ncalayer-js-client";
import {api} from "../../api/axiosInstance.js";

export const getRegInfoFx = createEffect()
    .use(async () => (await api().get('/info/unauthed')).data)

// Get token
export const loginFx = createEffect()
  .use(async ({username, password}) => {
    const formdata = new FormData
    formdata.append('grant_type', 'password')
    formdata.append('username', username)
    formdata.append('password', password)

    return (await api(false).post('/token/', formdata)).data
  })
// BIN 131140022032

export const logoutFx = createEffect()
  .use(async () => (await api().post('/token/revoke')).data)

// Local request, without back-end interaction. Getting key from NCA Layer
export const authorizeDSFx = createEffect()
  .use(async () => {
      const client = new NCALayerClient()

      try {
        await client.connect();
      } catch (error) {
        alert(`Не удалось подключиться к NCALayer: ${error.toString()}`);
        return;
      }

      let base64EncodedSignature;
      try {
        base64EncodedSignature = await client.basicsSignCMS(
          NCALayerClient.basicsStoragesAll,
          'MTEK',
          NCALayerClient.basicsCMSParamsDetached,
          NCALayerClient.basicsSignerSignAny
        );
      } catch (error) {
        if (error.canceledByUser) {
          alert('Действие отменено пользователем.');
        }

        alert(error.toString());
        return;
      }

      return base64EncodedSignature;
    }
  )

// Submitting registration with EDS key and password
export const completeRegFx = createEffect()
  .use(async (payload) => {
    return (await api(false).post('/users/', {cert: payload.cert.split('\n').join(''), password: payload.password})).data
  })

export const getCurrentUserFx = createEffect()
  .use(async () => (await api().get('users/current')).data)

export const submitInvestorRegFx = createEffect()
    .use(async (payload) => (await api().post('/users/register', payload)).data)

export const verifyEmailFx = createEffect()
    .use(async (token) => (await api().get(`/users/verify/${token}`)).data)

export const changePasswordFx = createEffect()
    .use(async (payload) => (await api().put('/users/change-password', payload)).data)

export const editProfileFx = createEffect()
    .use(async (payload) => (await api().put('/users/profile', payload)).data)
