import {createEffect} from 'effector';
import {api} from '../../api/axiosInstance.js';

export const getManufacturerDataFx = createEffect()
.use(async (id) => (await api().get(`/dashboard-organizations/${id}`)).data)

export const getManufacturerInfoGovFx = createEffect()
.use(async (gov_id) => (await api().get(`/organization/gov/${gov_id}`)).data)

export const getManufacturerInfoFullFx = createEffect()
    .use(async (id) => (await api().get(`/organization/${id}`)).data)