import { Bar, Column, Line } from '@ant-design/charts';
import { useEvent, useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import {
  $otpStore,
  changeActiveProblemRespEv,
  setIsModalOpenEv,
} from '../../models/overviewModel/index.js';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import { $dictionariesStore } from '../../models/dictionariesModel/index.js';
import { $activitiesMap } from '../../models/globalModel/index.js';

const title_dict = {
  otp_count: 'Количество предприятий',
  otp_avg_workload: 'Количество предприятий',
};

export default function OtpCharts() {
  const otpStore = useStore($otpStore);
  const activeSubCategory = useStore($activeFilters).subCategory;
  const [title, setTitle] = useState(title_dict[activeSubCategory]);
  const [data, setData] = useState([]);
  const workingIndexes = ['otp_count', 'otp_avg_workload'];

  const activitiesMap = useStore($activitiesMap);

  useEffect(() => {
    if (
      activeSubCategory !== '' &&
      workingIndexes.includes(activeSubCategory)
    ) {
      if (
          activeSubCategory !== ''
          && (activeSubCategory === 'otp_count' || activeSubCategory === 'otp_avg_workload')
      ) {
        setTitle(title_dict[activeSubCategory]);
        const existingData = Object.entries(otpStore?.oked_chart ?? {})
          ?.map(([key, value]) => {
            return {
              oked: activitiesMap[key],
              value,
            };
          })
          .filter((item) => item.value > 0)
          .sort((a, b) => b.value - a.value);

        for (const key of Object.values(activitiesMap)) {
          if (!existingData.find((item) => item.oked === key)) {
            existingData.push({ oked: key, value: 0 });
          }
        }
        setData(existingData);
      }
    }
  }, [activeSubCategory, otpStore, activitiesMap]);

  return (
    <div
      style={{
        background: '#fff',
        borderRadius: '12px',
        padding: '18px',
        // height: '85%',
        width: '100%',
      }}
    >
      <div
        style={{
          margin: '0',
          fontSize: '24px',
          fontWeight: '700',
          color: '#2b3674',
          marginBottom: '20px',
        }}
      >
        {title}
      </div>
      <BarChart
        data={data}
        height={382}
        xField="value"
        yField="oked"
      />
    </div>
  );
}

function ColumnChart({ data }) {
  const formatYLabel = (v) => {
    if (!isNaN(parseInt(v))) {
      return new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
        typeof v === 'number' ? v : parseInt(v)
      );
    }
    return v;
  };

  return (
    <Column
      style={{ marginTop: '30px' }}
      height={215}
      xField="month"
      yField="value"
      data={data}
      yAxis={{ label: { formatter: (v) => v } }}
      columnStyle={{
        radius: [20, 20, 0, 0],
        fill: 'l(90) 0:#4318FF 1:#4318FF47',
      }}
      minColumnWidth={14}
      maxColumnWidth={14}
      meta={{
        value: { alias: 'Количество', formatter: (v) => formatYLabel(v) },
      }}
      // scrollbar={{
      //   type: 'horizontal',
      //   animate: false,
      // }}
    />
  );
}

function LineGraph({ data }) {
  const formatYLabel = (v) => {
    return `${v}`;
  };

  const config = {
    data: data.filter((item) => item.year !== '2022'),
    xField: 'month',
    yField: 'change',
    height: 131,
    color: '#4318FF',
    label: { position: 'right', formatter: (v) => `${v.change}%` },
    yAxis: { label: { formatter: (v) => formatYLabel(v) } },
    meta: {
      change: { alias: 'Процент', formatter: (v) => `${v}%` },
    },
    animate: false,
  };
  return <Line {...config} />;
}

function BarChart({ xField, yField, data, height, width, marginTop }) {
  const formatYLabel = (v) => {
    return v.length > 10 ? `${v.slice(0, 10)}...` : v;
  };

  const format = (v) => {
    return typeof v === 'number'
      ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
      : v;
  };

  const config = {
    xField,
    yField,
    style: { height: height || 215, marginTop, width: width ?? '100%' },
    barStyle: {
      radius: [20, 20, 0, 0],
      fill: 'l(180) 0:#4318FF 1:#4318FF47',
    },
    scrollbar: { type: 'vertical', width: 15 },
    label: { position: 'right', formatter: (v) => format(v[xField]) },
    yAxis: { label: { formatter: (v) => formatYLabel(v) } },
    meta: {
      [xField]: { alias: 'Сумма', formatter: (v) => format(parseInt(v)) },
    },
    data,
  };
  return <Bar {...config} />;
}

function DoubleColumnChart({ data, marginTop }) {
  const dictionariesStore = useStore($dictionariesStore);
  const changeActiveProblemResp = useEvent(changeActiveProblemRespEv);
  const changeIsModalOpen = useEvent(setIsModalOpenEv);

  const roles_dict = dictionariesStore.role;

  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (Object.keys(roles_dict).length !== 0) {
      setColumnData(
        data.map((item) => {
          return {
            ...item,
            name: roles_dict[item.name]?.name_ru,
          };
        })
      );
    }
  }, [roles_dict, data]);

  const selectOptions = {
    Инициатор: 0,
    МИО: 1,
    ЦГО: 2,
    ИР: 3,
  };

  const config = {
    style: {
      marginTop,
    },
    data: columnData,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    color: ['l(90) 0:#4318FF 1:#4318FF47', 'l(90) 0:#6AD2FF 1:#6AD2FF47'],
    minColumnWidth: 8,
    maxColumnWidth: 16,
    legend: {
      position: 'top-right',
    },
    meta: {
      value: { alias: 'Количество этапов', formatter: (v) => v },
      // stage_problems: {
      //   alias: 'Количество проблемных этапов',
      //   formatter: (v) => v,
      // },
    },
    height: 215,
    onReady: (plot) => {
      plot.on('plot:click', (evt) => {
        const { x, y } = evt;
        const chosen_item = plot.chart.getTooltipItems({ x, y })[0].data;
        let resp_id = 0;
        Object.keys(selectOptions).forEach((item) => {
          if (chosen_item.name.includes(item)) {
            resp_id = selectOptions[item];
          }
        });
        changeActiveProblemResp(resp_id);
        changeIsModalOpen(true);
      });
    },
    xAxis: {
      label: {
        formatter: (v) => {
          let label = '';
          Object.keys(selectOptions).forEach((item) => {
            if (v.includes(item)) {
              label = item;
            }
          });
          return label;
        },
      },
    },
  };

  return (
    // <div className={style.project_view_card}>
    <Column {...config} />
    // </div>
  );
}
