import { createEffect } from 'effector';
import { api } from '../../api/axiosInstance.js';
import notification from "antd/es/notification";

export const getProjectFx = createEffect().use(async (id) => {
  return (await api().get(`/project/${id}`)).data;
});

export const editProjectStagesFx = createEffect().use(async (payload) => {
  const { id, changes } = payload;
  return (await api().put(`/project/stages/${id}`, { stages: changes })).data;
});

editProjectStagesFx.doneData.watch((ev) => ev && notification.success({message: 'Успешно', description: 'Этапы проекта обновлены'}));

export const getInvestorInfoFx = createEffect()
    .use(async (id) => (await api().get(`/organization/${id}`)).data)

export const getParticipantsInfoFx = createEffect()
    .use(async (org_ids) => {
      return ((await Promise.allSettled([...org_ids.map(id => api().get(`/organization/${id}`))])).map(res => res.value.data))
    })