import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useStoreMap } from 'effector-react';
import style from './ViewProjectComponents.module.css';
import { $dictionariesStore } from '../../models/dictionariesModel/index.js';

// const statuses = [
//   {
//     label: 'Прединвестиционная стадия',
//     name: 'predInv1',
//   },
//   {
//     label: 'Прединвестиционная стадия',
//     name: 'predInv2',
//   },
//   {
//     label: 'Инвестиционная стадия',
//     name: 'inv',
//   },
//   {
//     label: 'Постинвестиционная',
//     name: 'postInv',
//   },
// ];

const rome_dict = {
  0: 'I',
  1: 'II',
  2: 'III',
  3: 'IV',
};

function ProjectStatus({ activeStatus }) {
  const phases = useStoreMap($dictionariesStore, (dicts) =>
    Object.values(dicts?.phases)?.map((p, index) => ({
      label: p?.name,
      id: p?.id,
      rome_num: rome_dict[index],
    }))
  );

  const indexOfActive = phases.map((item) => item.id).indexOf(activeStatus);

  return (
    <div className={style.project_view_card} style={{ padding: '20px' }}>
      <h1 className={style.project_view_card_title}>Стадия проекта</h1>
      <div className={style.flex_column} style={{ marginTop: '16px' }}>
        {phases.map((item, index) => (
          <div
            className={style.status}
            style={{
              color:
                activeStatus === 5
                  ? '#05CD99'
                  :item.id === activeStatus
                  ? '#4318FF'
                  : index < indexOfActive
                  ? '#05CD99'
                  : '',
              fontWeight: item.id === activeStatus ? '700' : '',
            }}
            key={item.id + index}
          >
            {`${item.rome_num} ${item.label}`}
            {(index < indexOfActive || index < activeStatus - 1) ? (
              <CheckCircleOutlined style={{ marginLeft: '10px' }} />
            ) : index === indexOfActive ? (
              <ClockCircleOutlined style={{ marginLeft: '10px' }} />
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectStatus;
