export const krp_dictionary = {
	150: '41-50',
	110: '6 - 10',
	140: '31-40',
	220: '151-200',
	120: '16-20',
	225: '201-250',
	305: '251-500',
	311: '>1000',
	130: '21-30',
	160: '51-100',
	115: '11-15',
	215: '101-150',
	310: '501-1000',
	105: '<= 5',
}
