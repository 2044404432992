import { sample } from 'effector';
import { changeGradientTypeEv } from './events.js';
import { $activeGradient, $gradientBuckets } from './stores.js';

const bucketsDict = {
  projects_count: [25, 50],
  sum: [471207999999, 784034000000],
};

const colors = [
  'rgba(0, 102, 255, 0.5)',
  'rgba(240, 216, 30, 0.5)',
  'rgba(255, 0, 0, 0.7)',
];

sample({
  clock: changeGradientTypeEv,
  target: $activeGradient,
});

sample({
  clock: $activeGradient.updates,
  fn: (clock) => {
    return {
      buckets: bucketsDict[clock],
      colors,
    };
  },
  target: $gradientBuckets,
});
