import {
  Button,
  ConfigProvider,
  Dropdown,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { useEvent, useStore } from 'effector-react';
import { useEffect } from 'react';
import { CameraOutlined, InfoOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import locale from 'antd/locale/ru_RU';
import {
  $otpModalData,
  $otpModalState, getOtpOrgsDataFx,
  resetOtpModalEv,
  setOtpModalEv,
} from '../../../../models/overviewModel/index.js';
import {
  $activitiesMap,
  $districtsSelect,
  $regionsSelect,
} from '../../../../models/globalModel/index.js';
import { $activeFilters } from '../../../../models/activeFiltersModel/index.js';
import activities from '../../../../data/activity.json';

const activitiesSelect = activities
  .filter((i) => i.activity_type_id === 2)
  .map((i) => ({ label: i.name_ru, value: i.id }));

function ManufacturersModal() {
  const activeFiltersGlobal = useStore($activeFilters);
  const activitiesMap = useStore($activitiesMap);
  const { open, type, region_id, district_id, activity_id } =
    useStore($otpModalState);
  const setModal = useEvent(setOtpModalEv);
  const close = useEvent(resetOtpModalEv);
  const tableData = useStore($otpModalData);
  const loading = useStore(getOtpOrgsDataFx.pending)

  useEffect(() => {
    if (open) {
      if (activeFiltersGlobal.region_id && !activeFiltersGlobal.district_id) {
        setModal({ region_id: activeFiltersGlobal.region_id });
      }
      // else if (
      //   activeFiltersGlobal.region_id &&
      //   activeFiltersGlobal.district_id
      // ) {
      //   setModal({
      //     region_id: activeFiltersGlobal.region_id,
      //     district_id: activeFiltersGlobal.district_id,
      //   });
      // }
    }
  }, [open, activeFiltersGlobal]);

  const regionsSelect = useStore($regionsSelect);

  // const districts = useStore($districtsSelect);
  // const districtsSelect = useMemo(() => {
  //   if (!region_id) {
  //     return districts ?? [];
  //   }
  //   return districts?.filter((i) => i.region_id === region_id) ?? [];
  // }, [districts, region_id]);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal open={open} onCancel={close} width="80%" footer={[]} destroyOnClose>
      <Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>Область:</div>
          <Select
            options={regionsSelect}
            value={region_id}
            onChange={(value) =>
              setModal({ region_id: value, district_id: null })
            }
            allowClear
            style={{ width: '200px', marginRight: '20px' }}
            showSearch
            filterOption={filterOption}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <div>Район:</div> */}
          {/* <Select */}
          {/*  options={districtsSelect} */}
          {/*  value={district_id} */}
          {/*  onChange={(value) => setModal({ district_id: value })} */}
          {/*  allowClear */}
          {/*  style={{ width: '200px', marginRight: '20px' }} */}
          {/*  showSearch */}
          {/*  filterOption={filterOption} */}
          {/* /> */}
          <div>ОКЭД:</div>
          <Select
            options={activitiesSelect}
            value={activity_id}
            onChange={(value) => setModal({ activity_id: value })}
            allowClear
            style={{ width: '200px', marginRight: '20px' }}
            showSearch
            dropdownStyle={{ width: 300 }}
            filterOption={filterOption}
          />
        </div>
      </Row>
      <Row>
        <ConfigProvider locale={locale}>
          <Table
            loading={loading}
            dataSource={tableData}
            columns={getColumns(type, activitiesMap)}
            style={{ width: '100%' }}
            pagination={false}
          />
        </ConfigProvider>
      </Row>
    </Modal>
  );
}

export default ManufacturersModal;

function getColumns(type, okedMap) {
  if (type === 'activities') {
    return [
      {
        title: '№',
        width: '3%',
        align: 'center',
        key: 'number',
        render: (_, __, idx) => idx + 1,
      },
      {
        title: 'ОКЭД',
        dataIndex: 'activity_id',
        key: 'oked',
      },
      {
        title: 'Отрасль',
        dataIndex: 'activity_id',
        key: 'oked_name',
        render: (oked) => okedMap[oked],
      },
      {
        title: 'Загруженность',
        dataIndex: 'workload',
        key: 'load',
        render: (load) => `${parseInt(load) * 100}%`,
      },
      // {
      //   title: 'Оплаченных налогов',
      //   dataIndex: 'taxes',
      //   key: 'taxes',
      // },
      {
        key: 'actions',
        width: '5%',
        align: 'center',
        render: (record) => (
          <Link to={`/view-manufacturer/${record.id}`} target="_blank">
            <Button icon={<InfoOutlined />} className="btn-primary" />
          </Link>
        ),
      },
    ];
  }
  if (type === 'organizations') {
    return [
      {
        title: '№',
        width: '3%',
        align: 'center',
        key: 'number',
        render: (_, __, idx) => idx + 1,
      },
      {
        title: 'Компании',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'ОКЭД',
        dataIndex: 'oked',
        key: 'oked',
        sorter: (a, b) => a.oked.localeCompare(b.oked),
      },
      {
        title: 'Регион',
        key: 'region',
        dataIndex: 'region',
        sorter: (a, b) => a.region.localeCompare(b.region),
      },
      {
        title: 'Мощность',
        dataIndex: 'power',
        key: 'power',
        render: (power) =>
          power ? new Intl.NumberFormat('ru-RU').format(parseInt(power)) : '-',
      },
      {
        title: 'Загруженность',
        dataIndex: 'workload',
        key: 'load',
        render: (load) => `${parseInt(load * 100)}%`,
        sorter: (a, b) => a.workload - b.workload,
      },
      // {
      //   title: 'Оплаченных налогов',
      //   dataIndex: 'taxes',
      //   key: 'taxes',
      // },
      {
        key: 'actions',
        width: '5%',
        align: 'center',
        render: (record) =>
          record?.streams?.length > 0 ? (
            <Dropdown
              menu={{ items: getCameras(record?.streams) }}
              placement="bottom"
            >
              <div>
                <Link to={`/view-manufacturer/${record.id}`} target="_blank">
                  <Tooltip title="Просмотр предприятия">
                    <Button icon={<InfoOutlined />} className="btn-primary" />
                  </Tooltip>
                </Link>
              </div>
            </Dropdown>
          ) : (
            <Link to={`/view-manufacturer/${record.id}`} target="_blank">
              <Tooltip title="Просмотр предприятия">
                <Button icon={<InfoOutlined />} className="btn-primary" />
              </Tooltip>
            </Link>
          ),
      },
    ];
  }
}

function getCameras(links) {
  return links && links?.length > 0
    ? links.map((link, idx) => ({
        key: idx,
        label: (
          <Tooltip title={`Камера ${idx + 1}`} placement="left">
            <Button href={link} icon={<CameraOutlined />} />
          </Tooltip>
        ),
      }))
    : [];
}
