import { Button, Input, Select } from 'antd';
import { useEvent, useStore } from 'effector-react';
import { useState } from 'react';
import styles from './OrganizationsDashboard.module.css';
import {$filters, changeFiltersEv} from '../../models/organizationsModel/index.js';

export default function TableFilter() {
  const changeFilters = useEvent(changeFiltersEv);
  const filters = useStore($filters);
  const [input, setInput] = useState('')

  const onAdd = (type, value) => {
    changeFilters({
      [type]: filters?.bins?.length > 0
          ? [...filters.bins, value]
          : [value],
    });

    setInput('')
  };

  const onDeselect = (type, value) => {
    changeFilters({[type]: value});
  };


  return (
    <div className={styles.tableFilterContainer}>
        <div className={styles.tableFilterInput}>
          <span>БИН</span>
          <Input onChange={(e) => setInput(e.target.value)} value={input}/>
        </div>
        <Button type={'primary'} onClick={() => onAdd('bins', input)}>Добавить</Button>
        <div className={styles.filtersItem} style={{width: '70%'}}>
          <Select
            mode="tags"
            allowClear
            onChange={(value) => onDeselect('bins', value)}
            value={filters.bins ?? []}
          />
        </div>
      {/*<Button onClick={() => onReset()}>Сброс</Button>*/}
    </div>
  );
}
