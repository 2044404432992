import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n.js';
import { RouterProvider } from 'react-router-dom';
import routes from './router/Router.jsx';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ConfigProvider } from 'antd';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import dayjs from 'dayjs';

dayjs.extend(quarterOfYear);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1b2559',
          colorTextBase: '#2b3674',
          colorText: '#2b3674',
          fontFamily: 'Noto Sans',
        },
      }}
    >
      <RouterProvider router={routes} />
    </ConfigProvider>
  </React.StrictMode>
);
