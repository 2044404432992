import { sample } from 'effector';
import { $organizationData, $organizationInfo } from './stores';
import {
  getOrganizationDataFx,
  getOrganizationInfoFullFx,
  getOrganizationInfoGovFx
} from './effects.js';
import { ViewOrganizationGate } from './events';
import {prepareInvestorInfo} from '../viewProjectModel/utils';

$organizationData
  .on(getOrganizationDataFx.doneData, (_, payload) => payload)
  .reset(ViewOrganizationGate.close);

$organizationInfo
  .on(getOrganizationInfoFullFx.doneData, (_, payload) => prepareInvestorInfo(payload))
  .reset(ViewOrganizationGate.close);

// sample({
//   clock: ViewOrganizationGate.state,
//   filter: (clock) => !!clock.id,
//   fn: (clock) => clock.id,
//   target: getOrganizationDataFx,
// });

sample({
  clock: ViewOrganizationGate.state,
  filter: (clock) => !!clock.gov_id,
  fn: (clock) => clock.gov_id,
  target: getOrganizationInfoGovFx,
});

sample({
  clock: getOrganizationInfoGovFx.doneData,
  filter: (clock) => !!clock.id,
  fn: (clock) => clock.id,
  target: getOrganizationInfoFullFx,
})
