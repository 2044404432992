import {
  districts_dictionary,
  regions_dictionary,
  regions_districts_id_dictionary,
} from '../../dictionaries/regions_districts_dictionary.js';
import {
  districtProjectsCounter,
  districtSumWidgetDataCounter,
} from '../investments/counters.js';
import { filterProjects } from '../projects/index.js';

export const filterDistrictsByRegionId = (region_ids, polygons) => {
  // let regions_districts = {}
  // region_ids.forEach(id => {
  // 	regions_districts = {
  // 		...regions_districts,
  // 		...regions_districts_id_dictionary[id]
  // 	}
  // })
  // return Object.entries(regions_districts).filter(([key, value]) => value.length > 0 && Object.keys(value[0]).length > 0).map(([id, coords]) => {
  // 	return {
  // 		type: 'Feature',
  // 		properties: {
  // 			district_id: id,
  // 		},
  // 		geometry: {
  // 			...coords[0],
  // 		},
  // 		id: +id
  // 	}
  // })
  return polygons.filter((item) =>
    region_ids.includes(item.properties.region_id)
  );
};

export const filterDistrictsById = (polygons, district_id) => {
  return polygons.filter((item) => item.id === district_id);
};

export const prepareDistrictsFeatures = (features, data) => {
  const counters = {
    districtSumWidgetData: districtSumWidgetDataCounter,
    districtProjects: districtProjectsCounter,
  };

  const summary = Object.fromEntries(
    Object.keys(counters).map((prop) => [prop, {}])
  );

  data.forEach((item) => {
    summary.districtSumWidgetData = counters.districtSumWidgetData(
      item,
      summary.districtSumWidgetData
    );
    summary.districtProjects = counters.districtProjects(
      item,
      summary.districtProjects
    );
  });

  return features.map((item, index) => {
    return {
      ...item,
      properties: {
        ...item.properties,
        sum: summary.districtSumWidgetData[item.id] || 0,
        projects_count: summary.districtProjects[item.id] || 0,
        mal: index % 2 === 0 ? 0 : 1,
        sred: index % 2 === 0 ? 1 : 0,
        businessClimate: index % 2 === 0 ? 0 : 1,
        projectsRealisation: index % 2 === 0 ? 1 : 0,
      },
    };
  });
  // .filter(item => item.properties.sum !== 0)
};

export const prepareDistrictsFilteredFeatures = (projects, startData) => {
  const project_district_ids = new Set(
    projects.map((item) => item.district_id)
  );
  return startData.filter((item) => project_district_ids.has(item.id));
};

export const prepareDistrictsOverview = (filteredData, startData) => {
  return startData.map((item) => {
    const dist_name = districts_dictionary[item.id];
    const region_name = regions_dictionary[item.properties.region_id];

    if (!filteredData[region_name]) {
      return {
        ...item,
        properties: {
          ...item.properties,
          change: false,
        },
      };
    }

    return {
      ...item,
      properties: {
        ...item.properties,
        change:
          +filteredData[region_name].find((item) => item.name === dist_name)
            ?.value > 0,
      },
    };
  });
};

export const prepareDistrictsOtp = (filteredData, startData) => {
  return startData.map((item) => {
    // const dist_name = districts_dictionary[item.id].includes('г.')
    //   ? `${districts_dictionary[item.id].replace('г.', '')} Г.А.`
    //   : districts_dictionary[item.id];
    const dist_name = districts_dictionary[item.id];

    const region_name = regions_dictionary[item.properties.region_id];

    if (!filteredData[region_name]) {
      return {
        ...item,
        properties: {
          ...item.properties,
          otp_count: undefined,
        },
      };
    }

    return {
      ...item,
      properties: {
        ...item.properties,
        otp_count: filteredData[region_name][dist_name] || undefined,
      },
    };
  });
};

export const prepareDistrictsOrgs = (startData, orgsData) => {
  return startData.map((item) => {
    const dist_name = districts_dictionary[item.id];
    const region_name = regions_dictionary[item.properties.region_id];

    if (!orgsData.region_data[region_name]) {
      return {
        ...item,
        properties: {
          ...item.properties,
          orgsCount: 0,
        },
      };
    }

    return {
      ...item,
      properties: {
        ...item.properties,
        orgsCount: orgsData.region_data[region_name][dist_name] || 0,
      },
    };
  });
};
