import { sample } from 'effector';
import {
  $regionsLayerPolygons,
  $regionsLayerPolygonsFiltered,
  $regionsLayerPolygonsStart,
  $selectedRegion,
} from './stores.js';
import {
  resetRegionsLayerPolygonsFilteredEv,
  resetSelectedRegionEv,
  selectRegionEv,
  updateRegionsByOverviewEv,
  updateRegionsEv,
} from './events.js';
import {
  $activeFilters,
  changeActiveFilterEv,
  resetActiveFiltersEv,
} from '../activeFiltersModel/index.js';
import {
  filterRegionsByGradient,
  prepareRegionsFeatures,
  prepareRegionsFilteredFeatures,
  prepareRegionsOrgs,
  prepareRegionsOtp,
  prepareRegionsOverview,
} from '../../counters/regionsLayer/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { $globalStore, $projectsStore } from '../globalModel/index.js';
import { $activeGradient } from '../gradientModel/index.js';
import { $otpStore, $overviewStore } from '../overviewModel/index.js';
import { $orgsData } from '../organizationsModel/index.js';

$selectedRegion.reset(resetSelectedRegionEv);
$regionsLayerPolygonsFiltered.reset(resetRegionsLayerPolygonsFilteredEv);

sample({
  clock: selectRegionEv,
  target: $selectedRegion,
});

sample({
  source: $selectedRegion,
  clock: changeActiveFilterEv,
  filter: (source, payload) => payload.field === 'region_id',
  fn: (source, payload) => {
    if (payload.value === source) {
      return 0;
    }
    return payload.value;
  },
  target: $selectedRegion,
});

sample({
  source: $regionsLayerPolygons,
  clock: updateRegionsEv,
  fn: (source, clock) => {
    return prepareRegionsFeatures(source, clock);
  },
  target: [$regionsLayerPolygons, $regionsLayerPolygonsStart],
});

sample({
  source: [$globalStore, $regionsLayerPolygonsStart],
  clock: $projectsStore.updates,
  filter: ([global, startData], clock) =>
    startData.length > 0 && !window.location.href.includes('manufacturers'),
  fn: ([global, startData], clock) => {
    if (clock.length === global.projects.length) {
      return startData;
    }

    return prepareRegionsFilteredFeatures(clock, startData);
  },
  target: [$regionsLayerPolygons, updateRegionsByOverviewEv],
});

sample({
  clock: $regionsLayerPolygonsFiltered.updates,
  target: $regionsLayerPolygons,
});

sample({
  source: $regionsLayerPolygonsStart,
  clock: resetRegionsLayerPolygonsFilteredEv,
  target: $regionsLayerPolygons,
});

sample({
  source: [$regionsLayerPolygons, $activeGradient, $activeFilters],
  // clock: $activeFilters.updates,
  clock: changeActiveFilterEv,
  filter: ([regions, activeGradient, activeFilters], clock) => {
    return clock.field === 'gradient';
  },
  fn: ([regions, activeGradient, activeFilters], clock) => {
    let region_ids = [];
    if (
      clock.value.min === activeFilters.gradient.min &&
      clock.value.max === activeFilters.gradient.max
    ) {
      region_ids = filterRegionsByGradient(regions, clock, activeGradient);
    }
    return {
      field: 'regionIdByGradient',
      value: region_ids,
    };
  },
  target: changeActiveFilterEv,
});

sample({
  clock: resetActiveFiltersEv,
  fn: () => 0,
  target: $selectedRegion,
});

sample({
  source: [$regionsLayerPolygons, $activeFilters],
  clock: $overviewStore.updates,
  filter: ([regions, activeFilters], clock) => {
    const workingIndexes = [
      'people',
      'taxes',
      'medium_companies',
      'mio_performance',
      'business_inspections',
    ];
    return workingIndexes.includes(activeFilters.subCategory);
  },
  fn: ([startData, activeFilters], clock) => {
    const filteredData = {};
    Object.keys(clock[activeFilters.subCategory].map_data).forEach((item) => {
      filteredData[item] = {
        value:
          clock[activeFilters.subCategory].map_data[item]
            .map((district) => +district.value)
            .reduce((a, b) => a + b) > 0,
      };
    });

    return prepareRegionsOverview(filteredData, startData);
  },
  target: $regionsLayerPolygons,
});

sample({
  source: [$regionsLayerPolygons, $overviewStore, $activeFilters],
  clock: updateRegionsByOverviewEv,
  filter: ([regions, overviewData, activeFilters], clock) => {
    const workingIndexes = [
      'people',
      'taxes',
      'medium_companies',
      'mio_performance',
      'business_inspections',
    ];
    return (
      workingIndexes.includes(activeFilters.subCategory) &&
      window.location.href.includes('dashboard')
    );
  },
  fn: ([startData, overviewData, activeFilters], clock) => {
    const filteredData = {};
    // if (
    //   activeFilters.subCategory !== 'mio_performance' &&
    //   activeFilters.subCategory !== 'business_inspections'
    // ) {
    Object.keys(overviewData[activeFilters.subCategory].map_data).forEach(
      (item) => {
        filteredData[item] = {
          value:
            overviewData[activeFilters.subCategory].map_data[item]
              .map((district) => +district.value)
              .reduce((a, b) => a + b) > 0,
        };
      }
    );
    // } else {
    //   Object.keys(overviewData[activeFilters.subCategory].map_data).forEach(
    //     (item) => {
    //       if (
    //         overviewData[activeFilters.subCategory].map_data[item].type ===
    //         'region'
    //       ) {
    //         filteredData[item] = {
    //           value:
    //             overviewData[activeFilters.subCategory].map_data[item].change,
    //         };
    //       }
    //     }
    //   );
    // }
    return prepareRegionsOverview(filteredData, startData);
  },
  target: $regionsLayerPolygons,
});

sample({
  source: [$regionsLayerPolygonsStart, $activeFilters],
  clock: $otpStore.updates,
  fn: ([startData, activeFilters], otpStore) => {
    const filteredData = {};
    Object.keys(otpStore.map_data).forEach((item) => {
      filteredData[item] = {
        value: Object.values(otpStore.map_data[item]).reduce((a, b) => a + b),
      };
    });

    return prepareRegionsOtp(filteredData, startData);
  },
  target: [$regionsLayerPolygons, $regionsLayerPolygonsStart],
});

sample({
  source: $regionsLayerPolygonsStart,
  clock: $otpStore.updates,
  filter: (startData, otpStore) => {
    return startData.every((item) => !item.properties.otp_count);
  },
  fn: (startData, otpStore) => {
    const filteredData = {};
    Object.keys(otpStore.map_data).forEach((item) => {
      filteredData[item] = {
        value: Object.values(otpStore.map_data[item]).reduce((a, b) => a + b),
      };
    });
    return prepareRegionsOtp(filteredData, startData);
  },
  target: $regionsLayerPolygonsStart,
});

sample({
  source: [$regionsLayerPolygonsStart, $regionsLayerPolygons, $otpStore],
  clock: $activeFilters.updates,
  filter: () => window.location.href.includes('manufacturers'),
  fn: ([startData, data, otpStore], activeFilters) => {
    const source = startData.length > 0 ? startData : data;
    let result = [];
    if (activeFilters.region_id !== 0) {
      result = source.filter((item) => item.id === activeFilters.region_id);
    }

    if (result.length === 0) {
      result = source;
    }

    return result;
  },
  target: $regionsLayerPolygons,
});

sample({
  source: $regionsLayerPolygonsStart,
  clock: $orgsData.updates,
  fn: (startData, orgsData) => {
    return prepareRegionsOrgs(startData, orgsData);
  },
  target: $regionsLayerPolygons,
});

sample({
  source: [$regionsLayerPolygonsStart, $activeFilters],
  clock: $activeFilters.updates,
  filter: ([startData, activeFilters], clock) =>
    window.location.href.includes('organizations'),
  fn: ([startData, activeFilters], clock) => {
    let result = [];
    if (activeFilters.region_id !== 0) {
      result = startData.filter((item) => item.id === activeFilters.region_id);
    }

    if (result.length === 0) {
      result = startData;
    }

    return result;
  },
  target: $regionsLayerPolygons,
});

sample({
  source: $regionsLayerPolygonsStart,
  clock: $orgsData.updates,
  filter: (startData, clock) => {
    return startData.every((item) => !item.properties.orgsCount);
  },
  fn: (startData, orgsData) => {
    return prepareRegionsOrgs(startData, orgsData);
  },
  target: $regionsLayerPolygonsStart,
});
