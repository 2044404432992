import { Card, Skeleton } from 'antd';
import { Bar } from '@ant-design/charts';
import { useEvent, useStore } from 'effector-react';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { regions_dictionary } from '../../dictionaries/regions_districts_dictionary.js';

function BarChart({ title, data, xAlias, yAlias, filter, height, headStyle, style, concatLabel = true, scroll = true }) {
  const changeActiveFilter = useEvent(changeActiveFilterEv);
  const active_region = useStore($activeFilters).region_id;
  if (data.length > 0) {
    const [yField, xField] = Object.keys(data[0]);

    const format = (v) => {
      return typeof v === 'number'
        ? new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(v)
        : v;
    };

    const formatYLabel = (v) => {
      return v.length > 10 ? `${v.slice(0, 10)}...` : v;
    };

    const chooseColor = ({ area }) => {
      if (active_region > 0) {
        if (area === regions_dictionary[active_region]) return '#4328af';
        return '#6253a0';
      }
      return '#4328af';
    };

    return (
      <Card title={title} style={{ border: 0 }} headStyle={headStyle}>
        <Bar
          xField={xField}
          yField={yField}
          animation={false}
          data={data}
          label={{ position: 'start', formatter: (v) => format(v[xField]) }}
          barWidthRatio={0.6}
          yAxis={{ label: { formatter: (v) => concatLabel ? formatYLabel(v) : v } }}
          meta={{
            [xField]: { alias: xAlias, formatter: (v) => format(Number(v)) },
            [yField]: { alias: yAlias },
          }}
          style={{ maxHeight: height || '25vh', ...style }}
          color="#4328af"
          // color={chooseColor}
          // seriesField={'area'}
          scrollbar={scroll ?{ type: 'vertical', width: 15 } : false}
          // FIXME Click on widget item
          onReady={(plot) => {
            plot.on('plot:click', (evt) => {
              const { x, y } = evt;
              const chosen_item = plot.chart.getTooltipItems({ x, y })[0].data;
              if (filter === 'district_id') {
                changeActiveFilter({
                  field: 'region_id',
                  value: chosen_item.region_id,
                });
              }
              changeActiveFilter({
                field: filter,
                value: chosen_item.area_id,
              });
            });
          }}
          barStyle={{
            radius: [20, 20, 0, 0],
            fill: 'l(180) 0:#4318FF 1:#4318FF47',
          }}
        />
      </Card>
    );
  }
  return (
    <Card title={title} style={{ border: 0 }}>
      <Skeleton active title={false} paragraph={{ rows: 5 }} />
    </Card>
  );
}

export default BarChart;
