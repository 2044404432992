import {
  Button,
  Descriptions,
  Divider,
  Modal,
  Popconfirm,
  Skeleton,
} from 'antd';
import { useUnit } from 'effector-react';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  $selectedRequest,
  $viewModal,
  confirmReqEv,
  declineReqEv,
  downloadReqFileEv,
  getReqByIdFx,
  resetSelectedEv,
} from '../../../models/investorRequestsModel/index.js';
import { $dictionariesMaps } from '../../../models/dictionariesModel/index.js';
import { $regionsMap } from '../../../models/globalModel/index.js';
import { $userInfo } from '../../../models/authModel/index.js';

export default function ViewRequestModal() {
  const {t} = useTranslation()
  const { open } = useUnit($viewModal);

  const onClose = useUnit(resetSelectedEv);
  const download = useUnit(downloadReqFileEv);
  const approve = useUnit(confirmReqEv);
  const reject = useUnit(declineReqEv);

  const request = useUnit($selectedRequest);
  const loading = useUnit(getReqByIdFx.pending);

  const {
    countriesMap,
    invActMap,
    invUnitMap,
    invFileTypeMap,
    truIdToNameMap,
  } = useUnit($dictionariesMaps);
  const regionsMap = useUnit($regionsMap);

  const usr = useUnit($userInfo);

  const itemsCommon = [
    {
      key: 'name',
      label: t('Наименование проекта'),
      children: request?.name ?? '-',
    },
    {
      key: 'descr',
      label: t('Описание проекта'),
      children: request?.description ?? '-',
    },
    {
      key: 'initiator',
      label: t('Наименование компании инициатора'),
      children: request?.initiator ?? '-',
    },
    {
      key: 'cost',
      label: `${t('Стоимость проекта')}, (${t('тыс. $ США')})`,
      children: request?.cost ? new Intl.NumberFormat('ru-KZ').format(request?.cost) : '-',
    },
    {
      key: 'industry',
      label: t('Отрасль'),
      children: t(`industries.${invActMap[request?.activity_id]}`) ?? '-',
    },
    {
      key: 'region',
      label: t('Регион'),
      children: t(`regions.${regionsMap[request?.region_id]}`) ?? '-',
    },
    {
      key: 'sales',
      label: t('Рынок сбыта'),
      children: t(`countries.${countriesMap[request?.sales_market]}`) ?? '-',
    },
    {
      key: 'is_export',
      label: t('Экспорт продукции'),
      children: request?.export_products ? t('Да') : t('Нет'),
    },
    {
      key: 'raw_mats',
      label: t('Наличие необходимого сырья для производства продукции'),
      children: request?.raw_materials_available ? t('Да') : t('Нет'),
    },
    {
      key: 'rights',
      label: t('Наличие прав недропользования'),
      children: request?.subsoil_rights_available ? t('Да') : t('Нет'),
    },
    {
      key: 'workplaces',
      label: t('Кол-во создаваемых рабочих мест'),
      children: request?.workplaces ? new Intl.NumberFormat('ru-KZ').format(request.workplaces) : '-',
    },
    {
      key: 'deadline',
      label: t('Сроки реализации'),
      children: `${request?.deadline_from ?? ''} - ${
        request?.deadline_to ?? ''
      }`,
    },
  ];

  const finItems = [
    {
      key: 'inv_amount',
      label: `${t('Сумма инвестиций по проекту')}, ${t('тыс. $ США')}`,
      children: request?.investment_amount ? new Intl.NumberFormat('ru-KZ').format(request.investment_amount) : '-',
    },
    {
      key: 'npv',
      label: `${t('Чистая приведенная стоимость (NPV) по проекту')}, ${t('тыс. $ США')}`,
      children: request?.npv ? new Intl.NumberFormat('ru-KZ').format(request.npv) : '-',
    },
    {
      key: 'irr',
      label: `${t('Внутренняя ставка доходности (IRR)')}, %`,
      children: request?.irr ?? '-',
    },
    {
      key: 'ebitda',
      label: `${t('EBITDA')}, ${t('тыс. $ США')}`,
      children: request?.ebitda ? new Intl.NumberFormat('ru-KZ').format(request.ebitda) : '-',
    },
    {
      key: 'pb',
      label: `${t('Срок окупаемости')} (${t('лет')})`,
      children: request?.payback_period ?? '-',
    },
    {
      key: 'dpb',
      label: `${t('Дисконтированный срок окупаемости')} (${t('лет')})`,
      children: request?.discounted_payback_period ?? '-',
    },
  ];

  const itemsCapacity = request?.powers?.map((i, idx) => {
    const children = getCapacities(i, idx, truIdToNameMap, invUnitMap, t);
    return {
      key: `cap${idx}`,
      label: idx + 1,
      children: <Descriptions items={children} layout="vertical" column={4} />,
    };
  });

  const itemsDeposits = request?.deposits?.map((i, idx) => {
    const children = getDeposits(i, idx, invUnitMap, t);
    return {
      key: `dep${idx}`,
      label: idx + 1,
      children: <Descriptions items={children} layout="vertical" column={3} />,
    };
  });

  const itemsFiles = request?.files.map((i, idx) => {
    return {
      key: `file${idx}`,
      label: i.file_type_id === 4 ? i.name : t(`${invFileTypeMap[i.file_type_id]}`),
      children: (
        <Button
          icon={<DownloadOutlined />}
          size="small"
          onClick={() => download(i.id)}
        >
          {t('Скачать')}
        </Button>
      ),
    };
  });

  return (
    <Modal
      open={open}
      width="80vw"
      title={t('Просмотр инвестиционного предложения')}
      footer={null}
      onCancel={onClose}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Divider />
          <Descriptions
            title={t('Общие сведения')}
            layout="horizontal"
            items={itemsCommon}
            column={1}
            bordered
          />
          <Divider />
          <Descriptions
            title={t('Финансовые показатели проекта')}
            layout="horizontal"
            column={1}
            items={finItems}
            bordered
          />
          <Divider />
          <Descriptions
            title={t('Мощность проекта')}
            layout="horizontal"
            column={1}
            items={itemsCapacity}
            bordered
          />
          <Divider />
          <Descriptions
            title={t('Запасы месторождения')}
            layout="horizontal"
            column={1}
            items={itemsDeposits}
            bordered
          />
          <Divider />
          <Descriptions
            title={t('Файлы')}
            layout="horizontal"
            column={1}
            bordered
            items={itemsFiles}
          />
          {usr?.role === 'vtp' && request?.state === 'new' && (
            <>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  gap: 24,
                }}
              >
                <Popconfirm
                  title={t('Вы уверены, что хотите отклонить заявку?')}
                  onConfirm={() => reject()}
                  okText={t('Да')}
                  cancelText={t('Нет')}
                >
                  <Button danger type="primary" icon={<CloseOutlined />}>
                    {t('Отклонить')}
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title={t('Вы уверены, что подтверждаете эту заявку?')}
                  onConfirm={() => approve()}
                  okText={t('Да')}
                  cancelText={t('Нет')}
                >
                  <Button type="primary" icon={<CheckOutlined />}>
                    {t('Подтвердить')}
                  </Button>
                </Popconfirm>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
}

function getCapacities(i, idx, truMap, unitMap, t) {
  return [
    {
      key: `tru${idx}`,
      label: t('Вид ТРУ'),
      children: truMap[i.tru_type_id] ?? '-',
    },
    {
      key: `tnved${idx}`,
      label: t('Код ТН ВЭД'),
      children: i.tnved ?? '-',
    },
    {
      key: `qty${idx}`,
      label: t('Количество'),
      children: i.value ? new Intl.NumberFormat('ru-KZ').format(i.value) : '-',
    },
    {
      key: `unit${idx}`,
      label: t('Ед. измерения'),
      children: unitMap[i.unit_id] ?? '-',
    },
  ];
}

function getDeposits(i, idx, unitMap, t) {
  return [
    {
      key: `name${idx}`,
      label: t('Наименование'),
      children: i.name ?? '-',
    },
    {
      key: `qty${idx}`,
      label: t('Количество'),
      children: i.value ? new Intl.NumberFormat('ru-KZ').format(i.value) : '-',
    },
    {
      key: `unit${idx}`,
      label: t('Ед. измерения'),
      children: unitMap[i.unit_id] ?? '-',
    },
  ];
}
