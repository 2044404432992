import {Col, Divider, Form, InputNumber, Row} from "antd";
import {numberParser} from "../utils";

const TaxesForm = ({totalTaxBeforeValue, totalTaxAfterValue}) => <Row>
        <Col span={11}>
            <Divider>Инвестиционный период</Divider>
            <Row style={{marginBottom: 24}}>
                {`Ожидаемая сумма выплачиваемых налогов в год (млн. тенге): ${totalTaxBeforeValue}`}
            </Row>
            <Form.Item name={['effects']}>
                <Form.Item name={['effects', 'tax_fot', 'investment_period']} label={'Налоги с ФОТ в год (млн. тенге)'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
                <Form.Item name={['effects', 'tax', 'investment_period']} label={'Прочие налоги в год, (млн. тенге)'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
                <Form.Item name={['effects', 'workplaces', 'investment_period']} label={'Количество рабочих мест (единиц)'}
                           getValueFromEvent={(v) => numberParser(v)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Form.Item>
        </Col>
        <Col span={11}>
            <Divider>Постинвестиционный (эксплуатационный) период</Divider>
            <Row style={{marginBottom: 24}}>
                {`Ожидаемая сумма выплачиваемых налогов в год (млн. тенге): ${totalTaxAfterValue}`}
            </Row>
            <Form.Item name={['effects']}>
                <Form.Item name={['effects', 'tax_fot', 'post_investment_period']} label={'Налоги с ФОТ в год (млн. тенге)'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
                <Form.Item name={['effects', 'tax', 'post_investment_period']} label={'Прочие налоги в год, (млн. тенге)'}
                           trigger={'onBlur'}
                           getValueFromEvent={(e) => numberParser(e.target.value)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
                <Form.Item name={['effects', 'workplaces', 'post_investment_period']} label={'Количество рабочих мест (единиц)'}
                           getValueFromEvent={(v) => numberParser(v)}
                >
                    <InputNumber min={0} controls={false}/>
                </Form.Item>
            </Form.Item>
        </Col>
</Row>

export default TaxesForm