import { Col, Row } from 'antd';
import { useEvent, useStore } from 'effector-react';
import { useEffect } from 'react';
import SearchSelectWrapper from '../../components/SearchSelect/SearchSelectWrapper.jsx';
import StatsBlock from '../../components/StatsBlock/StatsBlock.jsx';
import MapWork from '../MapWork/MapWork.jsx';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import ColumnChart from '../../components/ColumnChart/ColumnChart.jsx';
import BarChart from '../../components/BarChart/BarChart.jsx';
import {
  $investmentsFilteredStore,
  $investmentsStore,
  resetInvestmentsFilteredStoreEv,
} from '../../models/investmentsModel/index.js';
import {
  $regionsLayerPolygonsFiltered,
  resetRegionsLayerPolygonsFilteredEv,
} from '../../models/regionsLayerModel/index.js';
import {
  $activeFilters,
  resetActiveFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import {
  $binDictionary,
  $binSelectOptions,
} from '../../models/globalModel/index.js';

function Investments() {
  const {
    region_widget_data,
    total_count,
    projects_count,
    members_count,
    activity_select,
    organizations_select,
    region_projects_data,
    district_widget_data,
  } = useStore($investmentsStore);
  const bin_select = useStore($binSelectOptions);

  const select_items = {
    activity_select,
    organizations_select,
    bin_select,
  };

  const stats = [
    { label: 'Кол-во проектов', value: projects_count },
    { label: 'Кол-во участников', value: members_count },
    { label: 'Сумма инвестиций', value: total_count },
  ];

  const columnChartData = [
    {
      year: 2016,
      amount: 100000000,
    },
    {
      year: 2017,
      amount: 200000000,
    },
    {
      year: 2018,
      amount: 300000000,
    },
    {
      year: 2019,
      amount: 450000000,
    },
    {
      year: 2020,
      amount: 500000000,
    },
    {
      year: 2021,
      amount: 350000000,
    },
    {
      year: 2022,
      amount: 620000000,
    },
    {
      year: 2023,
      amount: 550000000,
    },
  ];

  const activeFilters = useStore($activeFilters);
  const resetActiveFilters = useEvent(resetActiveFiltersEv);

  useEffect(() => {
    if (hasActiveFilters(activeFilters)) {
      setTimeout(() => {
        resetActiveFilters();
      }, 500);
    }
  }, []);

  return (
    <ContainerInner title="Реестр инвестиционных проектов">
      <Row justify="space-between">
        <Col
          style={{ width: '48%', boxShadow: '5px 5px 10px rgba(0,0,0,.4)' }}
          className="db-col1-top"
        >
          <StatsBlock data={stats} />
          <SearchSelectWrapper select_items={select_items} />
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '48%',
            boxShadow: '5px 5px 10px rgba(0,0,0,.4)',
          }}
          className="db-col-map"
        >
          <p style={{ marginLeft: '1.2rem' }}>Карта инвестиционных проектов</p>
          <MapWork />
        </Col>
      </Row>

      <Row justify="space-between" style={{ margin: '61px 0px' }}>
        <Col
          style={{ minWidth: '48%', boxShadow: '5px 5px 10px rgba(0,0,0,.4)' }}
          className="db-col-bottom-chart-1"
        >
          <BarChart
            title="Сумма инвестиций по регионам"
            data={region_widget_data}
            width="100%"
            xAlias="Сумма"
            yAlias="Область"
            filter="region_id"
          />
          {/* <BarChart title={'Количество проектов по регионам'} data={region_projects_data} */}
          {/*          width={'100%'} */}
          {/*          xAlias={'Сумма'} */}
          {/*          yAlias={'Область'} */}
          {/* /> */}
        </Col>
        <Col
          style={{ minWidth: '48%', boxShadow: '5px 5px 10px rgba(0,0,0,.4)' }}
          className="db-col-bottom-chart-1"
        >
          <BarChart
            title="Сумма инвестиций по районам"
            data={district_widget_data}
            width="100%"
            xAlias="Сумма"
            yAlias="Область"
            filter="district_id"
          />
        </Col>
        {/* <Col style={{width: '31%', boxShadow: '5px 5px 10px rgba(0,0,0,.4)'}} className={'db-col-bottom-chart-2'}>
                <ColumnChart
                    title={'Сумма инвестиций по годам'}
                    data={columnChartData}
                    xAlias={'Год'}
                    yAlias={'Сумма'}
                />
            </Col> */}
      </Row>
    </ContainerInner>
  );
}

export default Investments;
