import { Switch } from 'antd';

export default function GovSwitch({ changeActiveFilters }) {
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      <h3>С участием государства</h3>
      <Switch
        onChange={(checked) =>
          changeActiveFilters({ field: 'is_gov', value: checked })
        }
      />
    </div>
  );
}
