import { center } from '@turf/turf';
import { filterProjects } from '../projects/index.js';

export const prepareDotsFeatures = (data) => {
  const features = [];
  // data.projects.filter(item => item.objects_to_be).forEach((item, index) => {
  // 	item.objects_to_be.forEach(elem => {
  // 		let coords = elem.geo_territories.geometries[0].coordinates
  // 		if(elem.geo_territories.geometries[0].type === 'Polygon'){
  // 			coords = center(elem.geo_territories.geometries[0]).geometry.coordinates
  // 		}
  // 		features.push({
  // 			type: 'Feature',
  // 			id: item.id,
  // 			properties: {
  // 				project_id: item.id,
  // 				region_id: item.region_id,
  // 				name: item.name,
  // 				main_activity_id: item.main_activity_id,
  // 				main_activity_name: data.activities.find(activity => activity.id === item.main_activity_id)?.name_ru || '(Пусто)',
  // 				organization_name: item.organization_name,
  // 				district_id: item.district_id,
  // 			},
  // 			geometry: {
  // 				type: 'Point',
  // 				coordinates: coords,
  // 			}
  // 		})
  // 	})
  // })

  data.projects
    .filter((item) => item.location)
    .forEach((item, index) => {
      features.push({
        type: 'Feature',
        id: item.id,
        properties: {
          project_id: item.id,
          region_id: item.region_id,
          name: item.name,
          main_activity_id: item.main_activity_id,
          main_activity_name:
            data.activities.find(
              (activity) => activity.id === item.main_activity_id
            )?.name_ru || '(Пусто)',
          organization_name: item.organization_name,
          district_id: item.district_id,
        },
        geometry: {
          ...item.location,
        },
      });
    });

  return features;

  // return data.filter(item => item.organization_location).map((item, index) => {
  // 	return {
  // 		type: 'Feature',
  // 		id: index,
  // 		properties: {
  // 			project_id: item.id,
  // 			region_id: item.region_id,
  // 		},
  // 		geometry: {
  // 			...item.organization_location
  // 		}
  // 	}
  // })
};

export const filterDots = (projects, rawData) => {
  return prepareDotsFeatures({
    projects,
    activities: rawData.activities,
  });
};
