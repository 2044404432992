import {createStore} from "effector";

const initialState = {
	projects_count: 0,
	members_count: 0,
	invest_count: 0,
	activity_select: [],
	organizations_select: [],
	projects_table_data: [],
}

export const $projRegistryStore = createStore(initialState)

export const $projRegistryStartStore = createStore(initialState)

export const $projRegistryFilteredStore = createStore(initialState)
