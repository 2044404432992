import {Button, Popconfirm, Table, Tooltip} from 'antd';
import {useUnit} from 'effector-react';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ContainerInner from '../../components/ContentContainer/ContainerInner.jsx';
import {
  $okedsData, deleteOkedEv,
  getOkedsListFx, OkedsGate, setOkedsModalEv,
} from '../../models/okedsPriorityModel/index.js';
import OkedPriorityModal from './OkedPriorityModal.jsx';
import {$activitiesMap, $regionsMap, $districtsMap} from '../../models/globalModel/index.js';

export default function OkedsPriority() {
  const data = useUnit($okedsData);
  const loading = useUnit(getOkedsListFx.pending);
  const setModal = useUnit(setOkedsModalEv);
  const deleteOked = useUnit(deleteOkedEv);

  const activitiesMap = useUnit($activitiesMap);
  const regionsMap = useUnit($regionsMap);
  const districtsMap = useUnit($districtsMap);

  const columns = [
    {
      title: 'Регион',
      dataIndex: 'region_id',
      key: 'region',
      render: (id) => regionsMap[id]
    },
    {
      title: 'Район',
      dataIndex: 'district_id',
      key: 'district',
      render: (id) => districtsMap[id]
    },
    {
      title: 'ОКЭД',
      dataIndex: 'activity_id',
      key: 'oked',
      render: (id) => activitiesMap[id]
    },
    {
      title: 'Приоритет',
      dataIndex: 'note',
      key: 'priority',
    },
    {
      title: (
        <Tooltip title="Добавить">
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setModal({open: true, type: 'create'})}/>
        </Tooltip>
      ),
      key: 'actions',
      width: '5%',
      align: 'center',
      render: (record) => (
        <Button.Group>
          <Tooltip title="Редактировать">
            <Button icon={<EditOutlined />} onClick={() => setModal({open: true, type: 'edit', id: record.id})} />
          </Tooltip>
          <Popconfirm onConfirm={() => deleteOked(record.id)}
                      title={'Вы уверены, что хотите удалить эту запись?'}
                      okText={'Да'}
                      placement={'left'}
          >
            <Tooltip title="Удалить">
              <Button icon={<DeleteOutlined />} danger/>
            </Tooltip>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <ContainerInner title="Приоритеты ОКЭД">
      <OkedsGate />
      <OkedPriorityModal />
      <Table dataSource={data} columns={columns} loading={loading} />
    </ContainerInner>
  );
}
